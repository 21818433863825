//天、周、月的日历表
import * as React from 'react';
import { Form, Button, Row, Col, Icon, Radio, DatePicker } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
// import createFormatter from "@fullcalendar/core/datelib/";
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import * as styles from './calander.module.css';

import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';
// @see https://fullcalendar.io/docs
const defaultTimeRange = {
  minTime: '08:00:00',
  maxTime: '23:59:59',
};
class MuCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.state = {
      title: '',
      viewType: 'dayGridMonth',
      calendarWeekends: true,
      bMinTimeSlot: false,
      bMaxTimeSlot: false,
      size: 'month',
      calendarEvents: [
        // initial event data
        { id: 1, title: 'Event Now', start: new Date() },
        { id: 2, title: 'start 2', start: '2019-06-02 08:00:00' },
        { id: 3, title: 'event 1', date: '2019-05-01' },
        { id: 4, title: 'event 2', date: '2019-05-22' },
      ],
      statusType: '',
      monthDay: { display: 'inline-block', border: 'none' },
      weekday: { display: 'none', border: 'none' },
      today: { display: 'none', border: 'none' },
      monthDate: '',
      timeDate: '',
      selectTimes: '', //选中的天
    };
  }
  componentDidMount() {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.setOption('locale', 'zh-cn');
    calendarApi.setOption('allDaySlot', false);
    calendarApi.setOption('minTime', defaultTimeRange.minTime);
    calendarApi.setOption('maxTime', defaultTimeRange.maxTime);
    this.updateTitle();
    if (this.state.viewType != 'dayGridMonth') {
      this.setState({ monthDay: { display: 'none' } });
    } else {
      this.setState({ monthDay: { display: 'inline-block' } });
    }
  }
  componentWillMount() {
    const d = new Date();
    const month = d.getMonth() + 1; // 获取当前月
    this.setState({
      monthDate: moment(d.getFullYear() + '/' + month + ''),
    });
  }
  handleDateClick = arg => {
    // this.setState({current:arg.date});
    // let calendarApi = this.calendarRef.current.getApi()
    this.props.clickDate(arg.date);
  };
  // toggleWeekends = () => {
  //     this.setState({
  //         calendarWeekends: !this.state.calendarWeekends
  //     });
  // };
  toggleDay = () => {
    let viewType = 'timeGridDay';
    this.updateTitle(viewType);
    this.setState({ monthDay: { display: 'none' } });
  };
  // title = '2019年5月26日-2019年6月1日';
  toggleWeek = () => {
    let viewType = 'timeGridWeek';
    this.updateTitle(viewType);
    this.setState({ monthDay: { display: 'none' } });
  };
  toggleMonth = () => {
    let viewType = 'dayGridMonth';
    this.updateTitle(viewType);
    this.setState({ monthDay: { display: 'inline-block' } });
  };
  toggleDateSelector = () => {
    console.log(this.state.statusType);
    //let calendarApi = this.calendarRef.current.getApi();
    //calendarApi.triggerDateSelect(calendarApi.getDate());
  };
  goLeft = () => {
    // console.log('向左');
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.prev();
    this.updateTitle();
  };
  goRight = () => {
    // console.log('向右');
    let calendarApi = this.calendarRef.current.getApi();
    console.log(calendarApi);
    calendarApi.next();
    this.updateTitle();
  };
  eventClick = e => {
    let id = e.event.id;
    // console.log('点击了一条计划，id=' + id + ',标题是:' + e.event.title);
    // console.log('等产品设计要弹窗显示详情，还是跳转到什么页面？');
  };
  updateTitle = vt => {
    // console.log('vt:' + vt);
    this.setState({
      // monthDay: { display: 'none' },
      statusType: vt,
    });
    let viewType = vt || this.state.viewType;
    let calendarApi = this.calendarRef.current.getApi();
    let current = calendarApi.getDate();
    // let current = this.state.selectTimes
    // console.log(this.state.selectTimes);
    // console.log(this.state.timeDate);
    // console.log('updateTitle方法' + current); //Sun Sep 15 2019 00:00:00 GMT+0800 (中国标准时间)

    let title = '';
    let data = { viewType: viewType };
    // console.log(viewType);
    if (viewType == 'timeGridDay') {
      //如果选中了天
      this.setState({
        today: { display: 'inline-block' },
        monthday: { display: 'none' },
        weekday: { display: 'none' },
      });
      title = calendarApi.formatDate(current, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      // console.log('如果选中了天');
      // console.log(title);
      // console.log(current);
      // console.log(this.state.timeDate);
      data.start = current; //天的选中时间
      data.end = new Date(current.getTime()).setDate(current.getDate() + 1); //向右选中
    } else if (viewType === 'timeGridWeek') {
      // 如果选中了周
      this.setState({
        weekday: { display: 'inline-block' },
        today: { display: 'none' },
        monthday: { display: 'none' },
      });
      let firstDay = new Date(current.getTime());
      firstDay.setDate(firstDay.getDate() - firstDay.getDay() + 1);
      let endDay = new Date(current.getTime());
      endDay.setDate(endDay.getDate() - endDay.getDay() + 7);
      data.start = firstDay;
      data.end = endDay;
      title =
        calendarApi.formatDate(firstDay, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }) +
        '-' +
        calendarApi.formatDate(endDay, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
    } else if (viewType === 'dayGridMonth') {
      //如果选中了月
      this.setState({
        monthday: { display: 'inline-block' },
        weekday: { display: 'none' },
        today: { display: 'none' },
      });
      title = calendarApi.formatDate(current, {
        year: 'numeric',
        month: 'long',
      });
      data.start = new Date(current.getTime()).setDate(1);
      data.end = new Date(current.getTime()).setMonth(current.getMonth() + 1);
    }

    this.props.updateData(data);

    if (vt === undefined) {
      this.setState({ timeDate: title });
    } else {
      this.setState({ viewType: viewType, timeDate: title }, () =>
        calendarApi.changeView(viewType)
      );
    }
    calendarApi.setOption('height', 610);
  };
  selectMonth = (data, dataString) => {
    // console.log(data);
    // console.log('选中的时间' + dataString + '-01');
    const she = dataString.replace('-', '年').replace('年0', '年') + '月';
    // console.log('改变后的时间' + she); //选中的时间
    let this_data = {
      start: dataString,
      end: dataString + '-30',
    };
    this.setState({
      timeDate: she,
    });
    this.props.updateData(this_data);
  };
  selectWeek = data => {
    const startDate = moment(data)
      .day(1)
      .format('YYYY年M月D日'); // 周一日期
    const endDate = moment(data)
      .day(7)
      .format('YYYY年M月D日'); // 周日日期
    const start = moment(data)
      .day(1)
      .format('YYYY-MM-DD');
    const end = moment(data)
      .day(7)
      .format('YYYY-MM-DD');
    let this_week = {
      start: start,
      end: end,
    };
    this.setState({
      timeDate: startDate + '-' + endDate,
    });
    // console.log(startDate + '-' + endDate);
    this.props.updateData(this_week);
  };

  selectDay = (data, dataString) => {
    const selectDay = new Date(dataString);
    this.setState({ selectTimes: new Date(dataString) });
    this.state.selectTimes = selectDay;
    // console.log(selectDay);
    const time_value =
      selectDay.getFullYear() +
      '-' +
      (selectDay.getMonth() + 1) +
      '-' +
      (selectDay.getDate() + 1);
    // console.log(time_value); //加了一天的数据
    const chinlde = dataString.substr(0, 4) + '年';
    const chinlde1 = (
      dataString.replace(/-/g, '月').substr(5, 5) + '日'
    ).replace('0', '');
    const times = chinlde1.replace('月0', '月');
    // let this_day = {
    //   start: dataString,
    //   end: time_value,
    // };
    let this_day = {
      start: new Date(dataString),
      end: new Date(dataString).getTime() + 24 * 60 * 60 * 1000, //时间戳
    };
    this.setState({
      timeDate: chinlde + times,
    });

    let calendarApi = this.calendarRef.current.getApi();
    this.state.timeDate = calendarApi.formatDate(selectDay, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    // calendarApi.changeView('timeGridDay')
    this.props.updateData(this_day);
    this.setState({ viewType: 'timeGridDay' }, () =>
      calendarApi.changeView('timeGridDay')
    );
    calendarApi.setOption('height', 600);
  };
  expandUp = () => {
    let bMinTimeSlot = !this.state.bMinTimeSlot;
    let minTime = bMinTimeSlot ? '00:00:00' : defaultTimeRange.minTime;
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.setOption('minTime', minTime);
    calendarApi.updateSize();
    this.setState({ bMinTimeSlot: bMinTimeSlot });
  };
  expandDown = () => {
    let bMaxTimeSlot = !this.state.bMaxTimeSlot;
    let maxTime = bMaxTimeSlot ? '24:00:00' : defaultTimeRange.maxTime;
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.setOption('maxTime', maxTime);
    calendarApi.updateSize();
    this.setState({ bMaxTimeSlot: bMaxTimeSlot });
  };
  handleSize = e => {
    this.setState({ size: e.target.value });
  };
  render() {
    /**
     * 第一行
     * 左侧是筛选下拉框 可能是一个或两个
     * 中间是 天、周、月的三个按钮
     * 第二行
     * 中间分别是左箭头、时间信息、右箭头；点击时间信息是日期选择器
     * 日历组件的标题操作区，不用了
     */
    const ButtonGroup = Button.Group;
    const { size } = this.state;
    const { MonthPicker, WeekPicker } = DatePicker;
    const format = 'YYYY-MM';
    return (
      <div>
        <Row gutter={20}>
          <Col span={8}>
            {this.props.listTitle()}
            {/*<ListTitle selected={1}*/}
            {/*           data={ [{id:1, name:'一'}, {id:2, name:'二'}, {id:3, name:'三'}] }*/}
            {/*           callback={this.handleSelectChange}*/}
            {/*/>*/}
          </Col>
          <Col span={8}>
            <div style={{ textAlign: 'center' }}>
              <Radio.Group
                size="large"
                value={size}
                onChange={this.handleSize}
                className="btn-radio"
              >
                <Radio.Button
                  value="day"
                  style={{ action: { backgroundColor: '#ffffff' } }}
                  onClick={this.toggleDay}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;天&nbsp;&nbsp;&nbsp;&nbsp;
                </Radio.Button>
                <Radio.Button value="week" onClick={this.toggleWeek}>
                  &nbsp;&nbsp;&nbsp;&nbsp;周&nbsp;&nbsp;&nbsp;&nbsp;
                </Radio.Button>
                <Radio.Button
                  value="month"
                  className="month"
                  onClick={this.toggleMonth}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;
                </Radio.Button>
                {/*<Button type='primary' className='btn-add' onClick={this.gotoPast}>go to a date in the past</Button>*/}
              </Radio.Group>
            </div>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}></Col>
          <Col span={8}>
            <div
              style={{
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '15px',
                cursor: 'pointer',
              }}
            >
              {/*左边按钮*/}
              <Button className="btn-add" onClick={this.goLeft}>
                {' '}
                <Icon type="left" />
              </Button>
              &nbsp;&nbsp;&nbsp;
              <span
                className={styles['timeDate']}
                onClick={this.toggleDateSelector}
              >
                {this.state.timeDate}
              </span>
              &nbsp;&nbsp;&nbsp;
              <span>
                <MonthPicker
                  format="YYYY-MM"
                  // defaultValue={this.state.monthDate}
                  //showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  //showTime={{ defaultValue: moment(current) }}
                  locale={locale}
                  onChange={this.selectMonth}
                  style={this.state.monthDay}
                  className="month"
                />
                <WeekPicker
                  format="YYYY-MM-DD"
                  locale={locale}
                  onChange={this.selectWeek}
                  style={this.state.weekday}
                  className="week"
                />
                <DatePicker
                  locale={locale}
                  format="YYYY-MM-DD"
                  onChange={this.selectDay}
                  style={this.state.today}
                  className="toDay"
                />
              </span>
              {/*右边的按钮*/}
              <Button className="btn-add" onClick={this.goRight}>
                {' '}
                <Icon type="right" />
              </Button>
              &nbsp;
              {/*<Button type='primary' className='btn-add' onClick={this.gotoPast}>go to a date in the past</Button>*/}
            </div>
          </Col>
        </Row>
        <div>
          {this.state.viewType === 'timeGridDay' &&
            (this.state.bMinTimeSlot ? (
              <div
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={this.expandUp}
              >
                <span>
                  收到标准时间 <Icon type="down" />
                </span>
              </div>
            ) : (
              <div
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={this.expandUp}
              >
                <span>
                  展开更多时间 <Icon type="up" />
                </span>
              </div>
            ))}
          <FullCalendar
            ref={this.calendarRef}
            defaultView={this.state.viewType}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            weekends={this.state.calendarWeekends}
            header={{ left: '', center: '', right: '' }}
            events={this.props.calendarEvents}
            dateClick={this.handleDateClick}
            eventClick={this.eventClick}
            //droppable={true}
            //禁止拖拽
            //editable={true}
            // disableDragging={false}
            // disableResizing={false}
          />
          {this.state.viewType === 'timeGridDay' &&
            (this.state.bMaxTimeSlot ? (
              <div
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={this.expandDown}
              >
                <span>
                  收齐到标准时间 <Icon type="down" />
                </span>
              </div>
            ) : (
              <div
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={this.expandDown}
              >
                <span>
                  展开更多时间 <Icon type="up" />
                </span>{' '}
              </div>
            ))}
        </div>
      </div>
    );
  }
}
export default Form.create()(MuCalendar);
