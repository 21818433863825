import * as React from 'react';
import { Select } from 'antd';
const Option = Select.Option;

class ListTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
  }
  setState(state, callback) {}
  handleChange = value => {
    let intValue = parseInt(value);
    this.props.callback({ id: intValue, name: '' });
  };
  render() {
    return (
      <Select
        value={'' + this.props.selected}
        style={{ width: 240, marginRight: 20 }}
        onChange={this.handleChange.bind(this)}
        disabled={this.props.disabled}
      >
        {this.props.title !== undefined && this.props.title.length > 0 && (
          <Option key="0">{this.props.title}</Option>
        )}
        {this.props.data &&
          this.props.data.map(o => <Option key={o.id}>{o.name}</Option>)}
      </Select>
    );
  }
}
export default ListTitle;
