import React from 'react';
import { Row, Menu, Dropdown, message, Icon } from 'antd';
import { connect } from 'react-redux'; //数据状态存储并共享 connect的意思就是将两种组件连接起来
// import {NavLink} from "react-router-dom";
import './index.css';
import { ACCOUNT_QUERY, logout, WHOAMI } from '../../app/api';
import logo from '../../assets/img/logo1.jpg';
import pictureUrl from '../../assets/img/user.jpg';
import { jumpTo } from '../../router/Router';
import BreadcrumbCustom from './BreadcrumbCustom';
import Breadcrumbs from './breadcrumbs';
import NewRouter from '../../router/newRouter';
import { Route, Switch } from 'react-router';
// import Breadcrumbs from './bread'
import { Link } from 'react-router-dom';
// import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
// import Bread from './bread';

const imgs = require('../../assets/img/user.jpg');

const accountId = localStorage.getItem('accountId');

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      portraitUrl: '',
      mutrixName: [],
      mutrixes: [],
      account: '',
      currentOrg: '',
    };
  }
  componentWillMount() {
    this.setState({
      // userName: localStorage.getItem('name'),
    });
    this.queryMutrix();
  }
  userOut = () => {
    logout()
      .then(res => {
        if (res.code === 0) {
          message.success('退出成功');
          jumpTo('/pages/login');
          localStorage.removeItem('roleid');
          sessionStorage.removeItem('mutrix');
          // localStorage.removeItem("name");
        } else {
          message.error(res.data.message);
        }
      })
      .catch(err => console.log(err));
  };
  account = () => {
    const roleid = localStorage.getItem('roleid');
    if (roleid == 1) {
      jumpTo('/pages/accountCenter/accountCenter');
    } else if (roleid == 2) {
      jumpTo('/pages/accountCenter/mutrixCenter');
    } else if (roleid == 3 || 4) {
      jumpTo('/pages/accountCenter/customerCenter');
    }
    return <accountCenter> </accountCenter>;
  };
  queryMutrix = () => {
    //查询当前用户的详细信息
    WHOAMI({ id: accountId })
      .then(res => {
        let mutrixList = res.data.mutrixList;
        let dataList = res.data.account;
        if (res.code === 0) {
          this.setState({
            account: dataList,
            mutrixes: res.data.mutrixList,
            portraitUrl: dataList.portraitUrl,
            mutrixName: res.data.currentMutrix, //选择的工场
            currentOrg: res.data.currentOrg,
          });
        }
      })
      .catch(err => {
        message.error(err);
      });
  };

  change = () => {
    console.log('change!!');
    // translate();
  };
  render() {
    const { menuName, menuType } = this.props;
    const menu = (
      <Menu>
        <Menu.Item>
          <div onClick={this.account.bind(this)}>
            <span>
              <Icon type="user" />
              &nbsp;个人中心
            </span>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={this.userOut.bind(this)}>
            <span>
              <Icon type="logout" />
              &nbsp;退出登录
            </span>
          </div>
        </Menu.Item>
        {/*暂时注释了 仍需要努力写出来*/}
        {/*<Menu.Item>*/}
        {/*  <div onClick={this.change}>*/}
        {/*    <span>*/}
        {/*      <Icon type="logout" />*/}
        {/*      &nbsp;语言翻译*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*</Menu.Item>*/}
      </Menu>
    );

    return (
      <div className="header">
        <Row className="header-top">
          <div>
            <div className="logo">
              {/*<NavLink to ='/home' onClick={this.homeHandleClick}>*/}
              <div className="logo-box">
                <img src={logo} alt="智慧足球工场logo" />
              </div>
              {/*</NavLink>*/}
              <div
                style={{
                  display: 'inline-block',
                  color: '#ffffff',
                  marginLeft: '168px',
                }}
              >
                {/*智慧足球工场-后台管理系统*/}
                {this.state.mutrixName !== null ? (
                  <span>
                    {this.state.mutrixName.name == undefined ||
                    this.state.mutrixName.name == null
                      ? '智慧足球工场-后台管理系统'
                      : this.state.mutrixName.name}
                  </span>
                ) : this.state.currentOrg !== null ? (
                  <span> {this.state.currentOrg.name}</span>
                ) : (
                  ''
                )}
                <span></span>
              </div>
              {/*//用户名及头像*/}
              <Dropdown overlay={menu} placement="bottomLeft">
                <div className="user-name">
                  <div>
                    <div className="weather-img">
                      <span>
                        {this.state.portraitUrl == null || '' ? (
                          <img src={pictureUrl} alt="" />
                        ) : (
                          <img src={this.state.portraitUrl} alt="" />
                        )}
                      </span>
                      {/*<img src={pictureUrl} alt=""/>*/}
                      {/*<img src={this.state.portraitUrl} alt="nihao"/>*/}
                      {/*<img*/}
                      {/*  // src={this.state.portraitUrl === '' ? pictureUrl : this.state.portraitUrl}*/}
                      {/*  src={pictureUrl} alt="user-img"/>*/}
                    </div>
                    <span className="weather-detail">
                      {this.state.account.name == undefined || null
                        ? ' '
                        : this.state.account.name}
                    </span>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </Row>
        {/*{menuType ? (*/}
        {/*  { menuName }*/}
        {/*) : (*/}
        {/*  <Row className="breadcrumb">*/}
        {/*    <div className="breadcrumb-title">*/}
        {/*      当前位置：*/}
        {/*      /!*<Link to={menuName}>{menuName}</Link>*!/*/}
        {/*      <span className="breadcrumb-p">*/}
        {/*        /!*<BreadcrumbCustom />*!/*/}
        {/*      </span>*/}
        {/*      /!*<Bread />*!/*/}
        {/*      /!*<span>{Breadcrumbs}123</span>*!/*/}
        {/*    </div>*/}
        {/*  </Row>*/}
        {/*)}*/}
      </div>
    );
  }
}
{
  /*{menuName} / */
}
const mapStateToProps = (state, ownProps) => {
  return {
    menuName: state.menuName,
  };
};

export default connect(mapStateToProps)(Header); //从 mapStateToProps的 state 中获取数据)(再将数据绑定到Header组件)
