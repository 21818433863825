import * as React from 'react';
import {
  Table,
  Divider,
  Button,
  Modal,
  Form,
  Input,
  Select,
  message,
  Radio,
  DatePicker,
  Icon,
  Col,
  Popconfirm,
} from 'antd';
import {
  COACH_LIST,
  COACH_STATUS,
  COACH_DELETE,
  CUSTOM_LISTNAME,
  TEAM_LISTNAME,
  ORGANIZATION_LISTNAME,
} from '../../app/api';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import GeoForm from '../../components/GeoForm'; //城市表单
import ListTitle from '../../components/ListTitle/listTiltle';
import moment from 'moment';
import * as styles from './coach.module.css';
import { jumpTo } from '../../router/Router';
// import moment from 'moment';

//城市联动数据 select
const Option = Select.Option;

class TeamManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      title: '新增教练',
      data: [],
      pagination: {},
      modalData: {},
      sexStatus: '',
      organizationList: [], //所有组织存放
      source: 1,
      value: '',
      id: [], //组织的ID
      selectedTeam: 0,
      stateValue: '', //状态
      birthday: '',
      thisMoment: '',
      name: '',
      allOrgs: [], //所有客户数据
      selectedOrgs: 0,
      display: 'none',
      color: { color: '#1890ff', display: 'inline-block' },
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'id',
        width: '5%',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        key: '2',
        title: '姓名',
        dataIndex: 'name',
        width: '9%',
      },
      {
        key: '3',
        title: '联系电话',
        dataIndex: 'phone',
        width: '10%',
      },
      {
        key: '4',
        title: '性别',
        dataIndex: 'gender',
        width: '5%',
        align: 'center',
        render: (text, record) => {
          return <span>{record.gender === 1 ? '男' : ' 女'}</span>;
        },
      },
      {
        key: '5',
        title: '年龄',
        dataIndex: 'age',
        width: '5%',
        align: 'center',
      },

      {
        key: '6',
        title: '地区',
        dataIndex: 'fullAddress',
        width: '12%',
      },

      // {
      //   key: '7',
      //   title: '所属组织',
      //   dataIndex: 'orgs',
      //   width: '8%',
      //   align: 'center',
      //   render: (text, record) => {
      //     return <span>{record.orgs.length}</span>;
      //   },
      // },
      {
        key: '8',
        title: '执教球队',
        dataIndex: 'teams',
        width: '8%',
        align: 'center',
        render: (text, record) => {
          return <span>{record.teams.length}</span>;
        },
      },

      {
        key: '9',
        title: '签约类型',
        dataIndex: 'source',
        width: '6',
        align: 'center',
        render: (text, record) => {
          return <span>{record.source === 100 ? 'APP' : 'web签约'}</span>;
        },
      },

      {
        key: '10',
        title: '状态',
        dataIndex: 'valid',
        width: '6%',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
      {
        title: '操作',
        key: 'operation',
        width: '22%',
        align: 'center',
        render: (text, record) => (
          <span>
            <button
              onClick={() =>
                jumpTo('/pages/coach/coachDetails', { id: record.id })
              }
            >
              <Icon type="unordered-list" />
              详情
            </button>
            <Divider type="vertical" style={this.state.color} />
            {/*<button*/}
            {/*  onClick={this.editModal.bind(this, record)}*/}
            {/*  style={this.state.color}*/}
            {/*>*/}
            {/*  <Icon type="appstore" style={{ color: '#1890ff' }} />*/}
            {/*  编辑*/}
            {/*</button>*/}

            <button
              onClick={() =>
                jumpTo('/pages/coach/coachEdit', { id: record.id })
              }
              style={this.state.color}
            >
              <Icon type="appstore" style={{ color: '#1890ff' }} />
              编辑
            </button>

            {/* <Popconfirm title={"删除【" + record.name+ "】不可恢复，你确定要删除吗?"} onConfirm={() => this.handleDelete(record)}>*/}
            {/*    <a title='用户删除'  href="javascript:;">删除</a>*/}
            {/*</Popconfirm>*/}
          </span>
        ),
      },
    ];
  }
  componentWillMount() {
    this.queryTeam();
    this.organList();
    this.loadingData();
  }
  componentDidMount() {
    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    let f = document.getElementById('addCoach');
    if (custmer == 2 || custmer == 4) {
      //工场管理员登录时隐藏此项
      f.style.display = 'none';
      this.setState({
        color: { display: 'none' },
      });
    }
  }
  organList = params => {
    if (params == undefined) {
      params = {};
    }
    if (this.state.selectedOrgs != 0) {
      params.orgId = this.state.selectedOrgs;
    }
    if (this.state.name != '') {
      params.name = this.state.name;
    } else if (params.name) {
      delete params.name;
    }
    if (this.state.id != 0) {
      params.customId = this.state.id;
    }
    if (this.state.selectedTeam != 0) {
      params.teamId = this.state.selectedTeam;
    }
    if (this.state.source != 1) {
      params.source = this.state.source;
    } else if (params.source) {
      delete params.source;
    }
    COACH_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      pagination.current = res.data.list.number + 1;
      this.setState({
        fullAddress: data,
        data: data.map(v =>
          Object.assign({}, v.entity, v.coaches, {
            rowKey: v.entity.id,
            footballerNumber: v.footballerNumber,
            fullAddress: v.fullAddress,
            orgs: v.orgs,
            teams: v.teams,
            age: v.age,
          })
        ),
        pagination,
      });
    });
  };
  handleTableChange = pagination => {
    const params = pagination.current;
    this.setState({
      pages: params, //点击的当前页码
    });
    if (params !== undefined) {
      this.organList({ pageNum: params });
    }
  };
  /**
   * 编辑弹窗
   */
  // editModal = record => {
  //   this.setState({
  //     visible: true,
  //     modalData: record,
  //     title: '编辑教练',
  //   });
  //   console.log(record);
  // };
  editCancel = () => {
    //编辑取消
    this.setState({
      visible: false,
    });
  };
  handleDelete = record => {
    COACH_DELETE({ id: record.id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.name + '】成功');
          this.organList();
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };
  /**
   * 编辑完成，提交表单
   */
  editOk = () => {
    if (
      this.state.modalData.province === 0 ||
      this.state.modalData.city === 0 ||
      this.state.modalData.region === 0
    ) {
      this.setState({ display: 'block' });
    } else {
      this.setState({ display: 'none' });
      this.props.form.validateFields((errors, values) => {
        if (!errors) {
          // let body = {     //暂时注释了  后面产品出新需求了再来
          //     "entity": {
          //         "id": this.state.modalData.id,
          //         // "name": values.name,
          //         // "phone":values.phone,
          //         // "gender":this.state.sexStatus,
          //         // "birthday":this.state.birthday === '' ? this.state.modalData.birthday : this.state.birthday,
          //         // "province": parseInt(this.state.modalData.province),
          //         // "city": parseInt(this.state.modalData.city),
          //         // "region": parseInt(this.state.modalData.region),
          //         "valid": this.state.stateValue
          //     },
          // };
          // let api = COACH_MODIFY;
          //
          // api(body).then(res => {
          //     this.setState({visible:false});
          //     message.success(this.state.title + '【' +values.name+ '】成功');
          //     this.organList();
          // }).catch(err => {
          //     if (err) {message.error(err)}
          //
          // })
          COACH_STATUS({ id: this.state.modalData.id })
            .then(res => {
              if (res.code === 0) {
                this.setState({ visible: false });
                message.success(this.state.title + '成功');
                this.organList();
              }
            })
            .catch(err => {
              message.error(err);
            });
        }
      });
    }
  };
  /**
   * 更新地理信息到本地存储
   */
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
  };
  sexChange = e => {
    this.setState({
      sexStatus: e.target.value,
    });
  };
  /**
   * 新增状态
   */
  statusChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  queryTeam = () => {
    CUSTOM_LISTNAME().then(res => {
      this.setState({
        organizationList: res.data.list,
      });
    });
  };
  /**
   * 查询教练
   */
  searchCoach = value => {
    this.setState({ name: value }, () => this.organList());
  };
  sourceChange = (value, key) => {
    this.setState(
      {
        source: value,
      },
      () => this.organList()
    );
  };
  coachChange = (value, key) => {
    const thisId = key.key;
    this.setState(
      {
        id: key.key,
      },
      () => this.organList()
    );
  };
  loadingData = () => {
    TEAM_LISTNAME().then(res => {
      this.setState({
        allTeam: res.data.list,
      });
    });
    ORGANIZATION_LISTNAME().then(res => {
      this.setState({
        allOrgs: res.data.list,
      });
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { organizationList } = this.state;
    const Search = Input.Search;
    const dateFormat = 'YYYY-MM-DD';
    const column = this.column.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          rowKey: record.key,
        }),
      };
    });

    console.log('column', column);
    console.log('state.data', this.state.data);
    return (
      <div>
        <div className={styles['organizationSelect']}>
          <ListTitle
            selected={this.state.selectedOrgs}
            title="全部客户"
            data={this.state.allOrgs}
            callback={v =>
              this.setState({ selectedOrgs: v.id }, () => this.organList())
            }
          />
          <Select
            defaultValue="全部组织"
            onChange={this.coachChange}
            style={{ width: 150, marginBottom: '16px', marginRight: '10px' }}
          >
            <Option key="0">全部组织</Option>
            {organizationList.map(item => (
              <Option value={item.name} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
          <ListTitle
            selected={this.state.selectedTeam}
            title="全部执教球队"
            data={this.state.allTeam}
            callback={v =>
              this.setState({ selectedTeam: v.id }, () => this.organList())
            }
          />
          <Select
            defaultValue="全部来源"
            onChange={this.sourceChange}
            style={{ width: 150, marginBottom: '16px', marginRight: '10px' }}
          >
            <Option key={'1'}>全部来源</Option>
            <Option key={'100'}>APP注册</Option>
            <Option key={'0'}>web签约</Option>
          </Select>
          <Col span={5}>
            <Search
              placeholder="请输入教练姓名/联系电话"
              onSearch={this.searchCoach}
            />
          </Col>
          <div className={styles['btn-box']} id="addCoach">
            <Button
              type="primary"
              onClick={() => jumpTo('/pages/coach/coachAdd')}
              className={styles['btn-add']}
            >
              新增教练
            </Button>
          </div>
        </div>
        {/*<Modal*/}
        {/*  title={this.state.title}*/}
        {/*  visible={this.state.visible}*/}
        {/*  onOk={this.editOk}*/}
        {/*  onCancel={this.editCancel}*/}
        {/*  destroyOnClose={true}*/}
        {/*  footer={[*/}
        {/*    <Button key="back" onClick={this.editCancel}>*/}
        {/*      取消*/}
        {/*    </Button>,*/}
        {/*    <Button key="submit" type="primary" onClick={this.editOk}>*/}
        {/*      确定*/}
        {/*    </Button>,*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <Form>*/}
        {/*    <Form.Item label="状态" className="organizationName">*/}
        {/*      {getFieldDecorator('valid', {*/}
        {/*        initialValue: this.state.modalData.valid,*/}
        {/*        rules: [*/}
        {/*          {*/}
        {/*            required: true,*/}
        {/*            message: '请选择状态',*/}
        {/*          },*/}
        {/*        ],*/}
        {/*      })(*/}
        {/*        // setFieldsValue={this.state.stateValue}*/}
        {/*        <RadioGroup onChange={this.statusChange}>*/}
        {/*          <Radio value={true}>启用</Radio>*/}
        {/*          <Radio value={false}>禁用</Radio>*/}
        {/*        </RadioGroup>*/}
        {/*      )}*/}
        {/*    </Form.Item>*/}
        {/*  </Form>*/}
        {/*</Modal>*/}
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowKey={record => record.key}
        />
      </div>
    );
  }
}
export default Form.create()(TeamManage);
