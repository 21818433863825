import { Button, Modal, Upload, message } from 'antd';
import * as React from 'react';
import logo from '../../assets/img/user.jpg';

import { COACH_UPLOADPORTRAIT } from '../../app/api';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
function beforeUpload(file, { fileList }) {
  console.log(file);
  console.log(fileList);
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('只能上传JPG/PNGWE文件!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('图像必须小于2MB!');
  }
  return isJpgOrPng && isLt2M;
  if (file) {
  }
}

class UploadImg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      // fileList: [],//存放上传信息：比如路径、文件名
      fileList: [],
      coachId: '',
      imageUrl: '',
    };
    // {
    //     uid: '1',
    //     name: 'xxx.png',
    //     status: 'done',
    //     response: 'Server Error 500', // custom error message to show
    //     url: 'http://www.baidu.com/xxx.png',

    // uid: '-1',
    //     name: 'img.jpg',
    //     status: 'done',
    //     url: logo
    // }
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    // this.setState({teamId: params.get("id")})
    this.state.coachId = params.get('id');
  }

  handlePreview = async file => {
    //预览图片
    console.log(file);
    // console.log(file.url);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };
  handleRemove = file => {
    console.log('移除了');
    console.log(file);
  };
  handleCancel = () => {
    console.log('111');
    this.setState({
      previewVisible: false,
    });
  };
  handleChange = ({ fileList }, info) => {
    console.log(fileList);
    console.log(info);
    this.state.fileList = fileList;
    this.setState({ fileList: fileList });
    getBase64(fileList[0].originFileObj, imageUrl =>
      this.setState({
        imageUrl: imageUrl,
        loading: false,
      })
    );
    this.setState({
      imageUrl: fileList.url,
    });
    console.log(this.state.imageUrl);

    console.log(this.state.fileList[0].thumbUrl);
    console.log(this.state.fileList.url);
    this.state.fileList[0].thumbUrl = this.state.fileList.url;
    if (fileList.length > 1) {
      console.log('进来了 上传吧');
      let content = getBase64(fileList[0].originFileObj);
      console.log(content);
    }
  };
  handleAction = async fileList => {
    //确定上传
    console.log(fileList);
    const formData = new FormData();
    formData.append('file', fileList.file);
    console.log(formData.get('file'));
    console.log('传');
    let params = { coachId: this.state.coachId };
    const url = 'api/web/coach/uploadPortrait?coachId=' + this.state.coachId;
    // fetch(url,{
    //     method:'post',
    //     headers:new Headers({
    //         credentials: 'same-origin'
    //     }),
    //     body:formData,
    // }).then(response => response.json())
    //     .then(result =>{
    //         console.log(result)
    //     })
    COACH_UPLOADPORTRAIT(params, formData).then(res => {
      console.log(res);
      message.info('上传成功');
      this.state.fileList.url = res.data.url;
    });
  };

  render() {
    const { previewVisible, previewImage, fileList, imageUrl } = this.state;
    return (
      <div>
        <Upload
          beforeUpload={beforeUpload}
          customRequest={this.handleAction} //自定义上传
          listType="picture-card"
          className={'avatar-uploader'}
          fileList={fileList}
          showUploadList={false}
          onPreview={this.handlePreview} //预览图片时的回调
          onChange={this.handleChange}
          onRemove={this.handleRemove} //移除图片
        >
          {fileList.length >= 1 ? (
            <img src={imageUrl} alt="userImg" />
          ) : (
            <Button>更换头像</Button>
          )}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default UploadImg;
