import React, { Component } from 'react';
import { Breadcrumb, Switch, Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
//具体导航的名称
const id = JSON.parse(localStorage.getItem('roleid'));
// key: id==2 ?'/pages/homePage/mutrixHome' : id==3 ?'/pages/homePage/customHome':'/pages/homePage/systemHome',
// /homePage/systemHome

const breadcrumbNameMap = {
  '/pages': <Icon type="environment" />,

  '/pages/homePage/systemHome': '首页',
  '/pages/homePage/MutrixHome': '首页',
  '/pages/orgManage': '用户管理',
  '/pages/orgManage/customerManage': '客户管理',
  '/pages/orgManage/OrganizationHome': '客户主页',
  '/pages/orgManage/customerDetails?id=1': '客户详情',
  '/pages/orgManage/organizationManage': '组织管理',
  '/pages/teamManage': '用户管理',
  '/pages/coach': '用户管理',
  '/pages/player': '用户管理',
  '/pages/teamManage/teamManage': '球队管理',
  '/pages/coach/coach': '教练管理',
  '/pages/player/player': '球员管理',
  '/pages/MutrixManage': '系统管理',
  '/pages/courseManage': '系统管理',
  '/pages/alarmManage': '系统管理',
  '/pages/authorityManage': '系统管理',
  '/pages/MutrixManage/mutrixManage': '工场管理',
  '/pages/courseManage/courseConfigManage': '教案管理',
  '/pages/alarmManage/alarmManage': '告警管理',
  '/pages/authorityManage/authorityManage': '管理员账号',
  '/pages/accountCenter': '用户中心',
  '/pages/accountCenter/mutrixCenter': '个人中心',
};
class BreadcrumbCustom extends Component {
  static contextTypes = {
    //利用PropTypes记住所跳转每个页面的位置
    router: PropTypes.object,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      pathSnippets: null,
      extraBreadcrumbItems: null,
    };
  }
  getPath() {
    // οnclick="getDateTime();return false;"
    //对路径进行切分，存放到this.state.pathSnippets中
    console.log(this.context.router.history.location.pathname);
    this.state.pathSnippets = this.context.router.history.location.pathname
      .split('/')
      .filter(i => i);
    console.log('面包屑pathSnippets:::', this.state.pathSnippets);
    //将切分的路径读出来，形成面包屑，存放到this.state.extraBreadcrumbItems
    this.state.extraBreadcrumbItems = this.state.pathSnippets.map(
      (_, index) => {
        console.log(
          '将路径读出来形成面包削：：',
          this.state.extraBreadcrumbItems
        );
        const url = `/${this.state.pathSnippets.slice(0, index + 1).join('/')}`;
        console.log('url：' + url);
        return (
          <Breadcrumb.Item key={url}>
            <NavLink to={url}>{breadcrumbNameMap[url]}</NavLink>
          </Breadcrumb.Item>
        );
      }
    );
  }
  componentWillMount() {
    this.getPath();
  }
  componentWillReceiveProps(nextProps, nextContext) {
    this.getPath();
  }
  render() {
    // console.log(this.state.extraBreadcrumbItems);
    return (
      <span>
        <Breadcrumb style={{ display: 'inline-block' }}>
          {/*将形成的面包屑引用进来*/}
          {/*<span>首页</span>*/}
          {this.state.extraBreadcrumbItems}
        </Breadcrumb>
      </span>
    );
  }
}

export default BreadcrumbCustom;
