import * as React from 'react';
// import {Link,Route} from 'react-router-dom';
import { Button, Form, Upload, Icon, Modal, message } from 'antd';
import {
  THEME_PICTURE_LIST,
  THEME_PICTURE_UPLOAD,
  THEME_PICTURE_DELETE,
} from '../../app/api';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class MutrixThemePicture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picturesWall: {
        previewVisible: false,
        previewImage: '',
        fileList: [],
      },
      data: [],
    };
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.update === true && nextProps.themeId !== undefined) {
      this.list({ themeId: nextProps.themeId });
    }
  }
  componentWillUpdate(nextProps, nextState, nextContext) {}
  componentWillMount() {}
  /**
   * 加载数据API
   */
  list = params => {
    THEME_PICTURE_LIST(params).then(res => {
      const data = res.data.list; //list外层面的详细地址数据
      let picturesWall = this.state.picturesWall;
      picturesWall.fileList = [];
      data.map(v =>
        picturesWall.fileList.push({ uid: v.id, url: v.url, status: 'done' })
      );
      /**
             * id: 1
             sort: 1
             url: "/1/theme/1/201906121851.png"
             */
      this.setState({ picturesWall });
    });
  };
  handleMainModalCancel = () => {
    this.props.callback();
  };

  handleMainModalOK = async () => {
    let picturesWall = this.state.picturesWall;
    let fileList = picturesWall.fileList;

    let body = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.status === undefined) {
        let content = await getBase64(file.originFileObj);
        body.push(content);
      }
    }
    let params = { themeId: this.props.themeId };
    if (body.length > 0) {
      THEME_PICTURE_UPLOAD(params, body).then(res => {
        message.info('上传成功');
        picturesWall.fileList = [];
        this.setState({ picturesWall });
      });
    }
    this.props.callback();
  };

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    let picturesWall = this.state.picturesWall;
    picturesWall.previewImage = file.url || file.preview;
    picturesWall.previewVisible = true;
    this.setState({ picturesWall });
  };
  handleChange = ({ fileList }) => {
    let picturesWall = this.state.picturesWall;
    picturesWall.fileList = fileList;
    this.setState({ picturesWall });
  };
  handleCancel = () => {
    let picturesWall = this.state.picturesWall;
    picturesWall.previewVisible = false;
    this.setState({ picturesWall });
  };
  handleRemove = file => {
    THEME_PICTURE_DELETE({ id: file.uid }).then(res => {
      message.info('删除成功');
    });
  };
  handleAction = file => {
    // console.log(file);
    // let picturesWall = this.state.picturesWall;
    // const index = picturesWall.fileList.indexOf(file);
    // let body = picturesWall.fileList[index];
    // console.log(body);
    // if (body !== undefined) {
    //     let params = {themeId: this.props.themeId};
    //
    //     THEME_PICTURE_UPLOAD(params, body).then(res => {
    //         message.info("上传成功");
    //         if (res.data.list.size > 0) {
    //             let picture = res.data.list[0];
    //             picturesWall.fileList[index] = {uid: picture.id, url: picture.url, status: 'done'};
    //             this.setState({picturesWall});
    //         }
    //     });
    // }
  };
  processModal = () => {
    const { previewVisible, previewImage, fileList } = this.state.picturesWall;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Modal
        title={this.props.title}
        visible={this.props.visible}
        closable={false}
        destroyOnClose={true}
        width={600}
        footer={[
          <Button key="back" onClick={this.handleMainModalCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleMainModalOK}>
            确定
          </Button>,
        ]}
      >
        <div className="clearfix">
          <Upload
            // action={this.handleAction}
            beforeUpload={() => {
              return false;
            }}
            customRequest={this.handleAction}
            listType="picture-card"
            fileList={fileList}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            onRemove={this.handleRemove}
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </div>
      </Modal>
    );
  };

  render() {
    return (
      <div>
        <this.processModal />
      </div>
    );
  }
}

export default Form.create()(MutrixThemePicture);
