//客户详情
import * as React from 'react';
import * as styles from './mutrixStyle.module.css';
import MutrixList from '../MutrixManage/MutrixList';

import {
  ORGANIZATION_MODIFY,
  ORGANIZATION_QUERY,
  CHANGEORG,
  LISTBYORG,
  MUTRIX_LIST,
  ACCOUNT_LIST,
  ACCOUNT_ADD,
  ORGANIZATION,
} from '../../app/api';
import {
  Button,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Spin,
  DatePicker,
  Table,
} from 'antd';
import GeoForm from '../../components/GeoForm';
import OrganizationList from './organizationList.js';
import 'moment/locale/zh-cn';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/zh_CN';

import { jumpTo, jumpBack, jumpGo } from '../../router/Router';

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

moment.locale('zh-cn');

let nameId = localStorage.getItem('roleid');
class customerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: 0, //客户管理的ID
      customerData: {},
      queryList: [],
      visible: false, //编辑基础资料模态窗
      showMutrix: false, //编辑基础资料模态窗
      modalData: {},
      stateValue: '', //状态启用禁用
      loading: false,
      mutrixId: 0,
      orgId: 0,
      customId: 0,

      startDate: '',
      endDate: '',

      mutrixList: [],

      list: {},
      startValue: '',
      endValue: '',
      endOpen: false,
      display: 'none',
      orgList: [],
      mutrixDate: [],
      selectedRowKeys: '',
      selectedRows: [],
      those_date: '',
      custmer: '',
      accountNumber: false,
      accountData: '',
      recordData: '', //勾选工场的行数据
      this_selected: '', //是否勾选

      columns: [1, 2, 5, 6, 7, 8, 10, 11, 9, 'operation1'], //工场的table
      column: [1, 2, 3, 4, 5, 12, 11, 6, 8, 10, 'operation1'], //组织的table
    };
  }

  componentWillMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.state.customerId = params.get('id');
    this.query();
    this.thisLoading(); //加载loading的方法
    this.queryAuthority();
  }
  componentDidMount() {
    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    let t = document.getElementById('edit-btn');
    let f = document.getElementById('addMutrix');
    let c = document.getElementById('addOrg');
    if (custmer == 2) {
      t.style.display = 'none'; //工场管理员登录时隐藏此项
      f.style.display = 'none';
      c.style.display = 'none';
    }
  }
  componentDidCatch(error, errorInfo) {
    // this.list();
  }
  thisLoading = () => {
    this.setState({ loading: true });
    setTimeout(this.state.loading, 800);
    this.setState({
      loading: false,
    });
  };
  query = () => {
    //客户基本资料查询
    let params =
      this.state.customerId !== 0 ? { id: this.state.customerId } : {};
    ORGANIZATION_QUERY(params).then(res => {
      this.setState({
        customerData: res.data.query.entity,
        queryList: res.data.query,
      });
    });
  };

  //查询客户管理员有哪些
  queryAuthority = () => {
    // console.log('开始查询客户管理员了');
    //
    if (nameId == 1) {
      ACCOUNT_LIST({ roleid: 3, orgId: this.state.customerId }).then(res => {
        let content = res.data.list.content;
        if (content.length == 0) {
          return;
        } else {
          let reArry = content;
          let arr = reArry[0].entity;
          this.setState({
            accountData: arr,
          });
        }
      });
    }
  };

  queryMutrix = () => {
    //查询当前客户可以授权的工场list清单
    const id = this.state.customerId;
    LISTBYORG({ orgId: id }).then(res => {
      this.setState({
        mutrixList: res.data.list,
      });
    });
  };
  updateGeoForm = o => {
    this.setState({
      modalData: { ...this.state.modalData, ...o },
    });
  };
  editModel = () => {
    this.setState({
      visible: true,
      modalData: this.state.customerData,
    });
  };
  /**
   * 新增签约工场模态框
   */
  showModel = () => {
    this.setState({
      showMutrix: true,
    });
    this.queryMutrix();
  };
  MutrixCancel = () => {
    this.setState({
      showMutrix: false,
    });
  };
  /**
   * 编辑客户完成，提交表单
   */
  editOk = () => {
    if (
      this.state.modalData.province === 0 ||
      this.state.modalData.city === 0 ||
      this.state.modalData.region === 0
    ) {
      this.setState({
        display: 'block',
      });
    } else {
      this.setState({ display: 'none' });
      this.props.form.validateFields((errors, values) => {
        if (!errors) {
          let body = {
            entity: {
              id: this.state.customerData.id,
              name: values.name,
              contactName: values.contactName,
              contactPhone: values.contactPhone,
              province: parseInt(this.state.modalData.province),
              city: parseInt(this.state.modalData.city),
              region: parseInt(this.state.modalData.region),
              address: values.address,
              valid: this.state.stateValue,
            },
          };
          ORGANIZATION_MODIFY(body)
            .then(res => {
              this.setState({ visible: false });
              //需重新加载数据渲染一遍
              message.success('编辑客户' + '【' + values.name + '】成功');
              this.query();
              this.forceUpdate(); //更新刷新数据
            })
            .catch(err => {
              if (err) {
                message.error(err);
              }
            });
        }
      });
    }
  };
  editCancel = () => {
    this.setState({
      visible: false,
    });
  };
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  // changeDate = value => {
  //   console.log("这个是什么"+ value);
  //   let startDate = moment(value[0]).format('YYYY-MM-DD');
  //   let endDate = moment(value[1]).format('YYYY-MM-DD');
  //   this.setState({
  //     startDate: startDate,
  //     endDate: endDate,
  //   });
  // };
  list = params => {
    params = params === undefined ? {} : params;
    params.orgId = this.state.customerId;
    MUTRIX_LIST(params).then(res => {
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      let those_data = res.data.list.content;
      const allData = those_data.map(v =>
        Object.assign({}, v.entity, {
          endDate: v.endDate,
          startDate: v.startDate,
        })
      );
      this.setState({
        muLength: allData.length,
      });

      let result = ['', ''];
      for (let i = 0; i < allData.length; i++) {
        // this.state.mutrixData.push(i);
        this.setState({
          mutrixDate: i,
          muLength: allData.length,
          those_date: allData[i].id,
        });
      }
    });
  };

  setClassName = (record, index) => {
    //隐藏吧
    // console.log(this.state.muLength); // 2
    // console.log('数据的id：' + this.state.those_date); // 2
    // console.log('mutrixId:' + record.mutrixId);
    return record.mutrixId == this.state.those_date ? 'mutrixStyle' : '';
  };
  getArrIndex = (arr, obj) => {
    let index = null;
    let key = Object.keys(obj)[0];
    arr.every(function(value, i) {
      if (value[key] === obj[key]) {
        index = i;
        return false;
      }
      return true;
    });
    return index;
  };

  /**
   * 管理签约工场选中事件
   */
  onSelectChange = (selectedRowKeys, selectedRows) => {
    console.log('选中change事件selectedRowKeys:', selectedRowKeys);
    console.log('选中change事件selectedRows:', selectedRows); //勾选的详细数据
    this.setState({
      selectedRowKeys: selectedRowKeys, //勾选的index
      selectedRows: selectedRows,
    });
    let time = ' 00:00:00';

    selectedRows.forEach((val, index) => {
      // console.log('val:', val);
      // console.log('this.state.startDate:', this.state.startDate);
      // console.log('this.state.endDate:', this.state.endDate);
      // let she = this.state.mutrixList.indexOf(selectedRows);
      //想要获取当前的index  是不是等于selectedRowKeys  然后把时间附上去
      // let getIndex = this.getArrIndex(this.state.mutrixList,selectedRows);

      // if(selectedRowKeys == index){
      val.startDate = this.state.startDate + time;
      val.endDate = this.state.endDate + time;

      val.orgId = +this.state.customerId;
      // this.state.list = selectedRows;
      // }
    });
    // this.setState({list:selectedRows});
    this.state.list = selectedRows;
  };
  /**
   * 复选框点击事件record, selected, selectedRows
   */
  selectEvent = (record, selected, selectedRows) => {
    console.log('复选框点击事件record:', record); //当前点击的record
    console.log('复选框点击事件selected:', selected); //点了没点
    console.log('复选框点击事件selectedRows:', selectedRows);
    this.setState({ this_selected: selected });
    if (selected) {
      //点击事件，如果点击了true
      this.setState({
        recordData: record.mutrixId,
      });
      // this.state.selectedRows.push(record);
    } else {
      console.log('如果为false');
      console.log('如果为不点击false', this.state.list);
      //1.新增勾选了的话  之前默认的工场的时间获取不到
      //2.取消勾选（批量的取消）要多次测试

      let array = {
        mutrixId: record.mutrixId,
        mutrixName: record.mutrixName,
        orgId: '',
        startDate: '',
        endDate: '',
      };

      this.state.list.push(array); //push 不签约的工场
      console.log('push过的list', this.state.list);

      // this.state.selectedRows = this.state.selectedRows.filter(
      //   item => item.mutrixId !== record.mutrixId
      // );
    }
    console.log('this.state.list：', this.state.list);
  };
  /**
   * 确定签约工场
   */
  MutrixOk = () => {
    //console.log('确定签约工场selectedRows：：', this.state.selectedRows);
    console.log('确定签约工场list：：', this.state.list);
    // if (this.state.list.length !== 0) {
    let array = [
      {
        mutrixId: 1,
        mutrixName: '光明工场2号',
        orgId: 1,
        startDate: ' 2020-01-01 00:00:00',
        endDate: ' 2022-12-25 00:00:00',
      },
      {
        mutrixId: 15,
        mutrixName: '测试工场',
        orgId: '',
        startDate: '',
        endDate: '',
      },
    ];
    CHANGEORG(this.state.list)
      .then(res => {
        if (res.code === 0) {
          message.success('签约成功');
          this.setState({
            showMutrix: false,
          });
          // 先不刷新
          // setTimeout(() => {
          //   jumpGo();
          // }, 1000);
          // this.list();
        } else {
          message.warning('提交失败');
        }
      })
      .catch(err => message.warning('提交失败'));
    // }
  };
  onRef = ref => {
    this.mutrixList = ref;
  };

  disabledStart = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEnd = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, dateString) => {
    // console.log('field:', field);
    // console.log('dateString:', dateString); //选择的时间
    this.setState({
      [field]: dateString,
    });
  };

  //签约工场选择开始时间---结束时间
  onStartDate = (date, dateString) => {
    // console.log('没选时间，刚刚进来时selectedRows:', this.state.selectedRows);
    // console.log('选了时间dateString:', dateString);
    this.onChange('startValue', dateString);
    this.setState({
      startDate: dateString[0],
      endDate: dateString[1],
    });
    //这里选了时间后 就给了selectedRows了时间了  注释了看看有没有时间
    // 得想个办法  勾选了当前的工场  这个是个数组，
    // 0:
    // mutrixId: 13
    // mutrixName: " 宝安体育工场"
    // orgId: 4
    // startDate: null
    // endDate: null
    // __proto__: Object
    // length: 1
    // __proto__: Array(0)
    let time = ' 00:00:00';
    if (this.state.this_selected) {
      for (let i = 0; i < this.state.selectedRows.length; i++) {
        if (this.state.recordData == this.state.selectedRows[i].mutrixId) {
          this.state.selectedRows[i].startDate = dateString[0] + time;
          this.state.selectedRows[i].endDate = dateString[1] + time;
        }
      }
    }
    // console.log('选了时间后selectedRows:', this.state.selectedRows);
    // this.state.selectedRows.startDate = dateString[0];
    // this.state.selectedRows.endDate = dateString[1];
    this.setState({
      list: this.state.selectedRows,
    });
    this.state.list = this.state.selectedRows;
  };

  // 结束时间
  // onEndDate = value => {
  //   let endDate = moment(value).format('YYYY-MM-DD');
  //   this.onChange('endValue', value);
  //   this.setState({
  //     endDate: endDate,
  //   });
  // };
  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };
  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  // 设置管理员账号
  showAccount = () => {
    this.setState({ accountNumber: true });
  };
  accountNumberCancel = () => {
    this.setState({ accountNumber: false });
  };
  setAccountNumber = () => {
    this.props.form.validateFields((errors, values) => {
      // console.log('errors:', errors);
      // console.log('values:', values);
      if (!errors) {
        let body = {
          entity: {
            account: values.account,
            phone: values.phone,
            username: values.contactName,
            roleid: 3,
            valid: true,
          },
          role: { id: 3, name: '客户管理员' },
          organization: { id: +this.state.customerId, name: values.name },
          // 客户管理员用organization，因为是单个客户  所以传对象， 运营管理员用organizations 传数组类型
        };
        ACCOUNT_ADD(body).then(res => {
          if (res.code === 0) {
            this.setState({
              accountNumber: false,
            });
            message.success('新增客户管理员【' + values.account + ' 】成功');
            this.queryAuthority();
          }
        });
      }
    });
  };

  basicData = () => {
    const { getFieldDecorator } = this.props.form;
    let state = this.state.accountData;
    return (
      <div>
        <div className={styles['basic-data']}>
          <span>基础资料</span>
          <div
            onClick={this.editModel}
            style={{ float: 'right', width: '700px' }}
            id="edit-btn"
          >
            <span
              style={{
                display: 'inline-block',
                marginLeft: '88%',
                cursor: 'pointer',
                fontSize: '16px',
                color: '#1890ff',
              }}
            >
              <Icon type="edit" /> 编辑
            </span>
          </div>
        </div>
        <div className={styles['data-list']}>
          <p>客户名称: {this.state.customerData.name}</p>
          <p>联系人：{this.state.customerData.contactName}</p>
          <p>联系电话：{this.state.customerData.contactPhone}</p>
          <p>地区：{this.state.queryList.fullAddress}</p>
          <p>详细地址：{this.state.customerData.address}</p>
          <p>
            {nameId == 1 ? (
              <span>
                <span>客户管理员帐号： {state.account} &nbsp;&nbsp;</span>
                <span> {state === '' ? '' : '手机号：' + state.phone} </span>
                <span
                  className={styles['accountNumber']}
                  onClick={this.showAccount}
                >
                  {' '}
                  <Icon type="edit" />{' '}
                </span>
              </span>
            ) : (
              ''
            )}
          </p>
        </div>

        <Modal
          title=""
          visible={this.state.accountNumber}
          onOk={this.setAccountNumber}
          onCancel={this.accountNumberCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={this.accountNumberCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.setAccountNumber}>
              确定
            </Button>,
          ]}
        >
          <Form>
            <Form.Item label="帐号" className="organizationName">
              {getFieldDecorator('account', {
                initialValue: state.account,
                rules: [
                  {
                    required: true,
                    message: '请输入客户管理员帐号',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="手机号" className="organizationName">
              {getFieldDecorator('phone', {
                initialValue: state.phone,
                rules: [
                  {
                    required: true,
                    message: '请输入手机号',
                  },
                  {
                    pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/,
                    message: '请输入正确的手机号',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  };
  ContractWorkshop = () => {
    return (
      <div>
        <div className={styles['basic-data']}>
          <span>签约工场</span>
          <span
            id="addMutrix"
            style={{
              display: 'inline-block',
              marginLeft: '88%',
              cursor: 'pointer',
              fontSize: '16px',
              color: '#1890ff',
            }}
            onClick={this.showModel}
          >
            <Icon type="plus-circle" /> 新增
          </span>
        </div>
        <Spin spinning={this.state.loading} tip="Loading...">
          <MutrixList
            onRef={this.onRef}
            orgId={this.state.customerId}
            columns={
              this.state.custmer == 2
                ? [1, 2, 5, 6, 7, 8, 10, 11, 9]
                : this.state.columns
            }
          />
        </Spin>
        {/*onClick={jumpTo("/pages/orgManage/organizationAdd")}*/}
        <div className={styles['basic-data']}>
          <span>签约组织</span>
          <p
            id="addOrg"
            style={{
              display: 'inline-block',
              marginLeft: '88%',
              cursor: 'pointer',
              fontSize: '16px',
              color: '#1890ff',
            }}
            onClick={() =>
              jumpTo('/pages/orgManage/organizationAdd', {
                id: this.state.customerId,
              })
            }
          >
            <Icon type="plus-circle" /> 新增
          </p>
        </div>
        <OrganizationList
          columns={
            this.state.custmer == 2
              ? [1, 2, 3, 4, 5, 12, 11, 6, 8, 10]
              : this.state.column
          }
          orgId={this.state.customerId}
          // customId={this.state.selectedCustom}
        />
      </div>
    );
  };
  render() {
    const RadioGroup = Radio.Group;
    const { getFieldDecorator } = this.props.form;
    const dateFormat = 'YYYY-MM-DD';
    const thisColumn = [
      {
        key: 'two',
        title: '工场名称',
        dataIndex: 'mutrixName',
      },
      {
        key: 'three',
        title: '签约时间',
        dataIndex: 'startDate',
        width: '65%',
        align: 'center',
        render: (text, record, index) => {
          return (
            <span>
              <span>{record.startTime}</span>
              <span>{record.endTime}</span>
              {/*text显示了时间*/}
              {/*<DatePicker*/}
              {/*  format={dateFormat}*/}
              {/*  placeholder="签约时间"*/}
              {/*  defaultValue={[this.state.startDate][this.state.endDate]}*/}
              {/*  disabledDate={this.disabledStart}*/}
              {/*  onChange={this.onStartDate}*/}
              {/*  onOpenChange={this.handleStartOpenChange}*/}
              {/*  locale={locale}*/}
              {/*/>*/}
              <RangePicker
                onChange={this.onStartDate}
                locale={locale}
                defaultValue={
                  text === null
                    ? null
                    : [
                        moment(text, dateFormat),
                        moment('2022-12-25', dateFormat),
                      ]
                }
              />
            </span>
          );
        },
      },
      // {
      //   key: 'four',
      //   title: '服务期限',
      //   dataIndex: 'startDate',
      //   width: '40%',
      //   align: 'center',
      //   render: (text, record, index) => {
      //     return (
      //       <span>
      //         {' '}
      //         <DatePicker
      //           defaultValue={[this.state.startDate][this.state.endDate]}
      //           format={dateFormat}
      //           placeholder="服务期限"
      //           disabledDate={this.disabledEnd}
      //           onChange={this.onEndDate}
      //           onOpenChange={this.handleEndOpenChange}
      //           locale={locale}
      //         />
      //       </span>
      //     );
      //   },
      // },
    ];
    const rowSelection = {
      type: 'checkbox',
      // selectedRowKeys:[0,this.state.mutrixDate],
      onChange: this.onSelectChange, //签约工场的选中事件

      // getCheckboxProps: record => ({
      //     disabled: record.name === 'Disabled User',
      //     // name: record.name,
      // }),
      /**
       * 选择框的默认属性配置
       */
      getCheckboxProps: record => ({
        // disabled: this.memberList.some(item => item.id === record.userId)
        defaultChecked: record.orgId !== null,
      }),

      /**
       * 复选框点击事件
       */
      onSelect: this.selectEvent,
      // getCheckboxProps: record => ({
      // disabled: record.name === '2223',
      // disabled: this.props.editStatue,
      // checked: (this.props.selectedRowKeys.indexOf(record.Id)>-1?true:false),
      // defaultChecked: record.mutrixId == this.state.mutrixDate,
      // defaultChecked: (this.state.mutrixDate > 0 ? true : false)
      // defaultChecked:record.mutrixId === this.state.those_date,
      //defaultChecked:this.state.mutrixDate.indexOf(record.id) > -1 ? true:false
      // }),
      // onSelect(record, selected, selectedRows) {
      //   console.log('record:',record.startDate);
      //   console.log('selected:',selected); //true
      //   console.log('selectedRows:',selectedRows);
      // },
      // onSelectAll(selected, selectedRows, changeRows) {
      //   console.log(selected, selectedRows, changeRows);
      // },
    };
    return (
      <div>
        <this.basicData />
        <this.ContractWorkshop />
        <div>
          <Modal
            title="编辑客户"
            visible={this.state.visible}
            onOk={this.editOk}
            onCancel={this.editCancel}
            destroyOnClose={true}
            footer={[
              <Button key="back" onClick={this.editCancel}>
                取消
              </Button>,
              <Button key="submit" type="primary" onClick={this.editOk}>
                确定
              </Button>,
            ]}
          >
            <Form>
              <Form.Item label="组织名称" className="organizationName">
                {getFieldDecorator('name', {
                  initialValue: this.state.customerData.name, //填充input默认数据
                  rules: [
                    {
                      required: true,
                      message: '请输入组织名称',
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item label="联系人" className="organizationName">
                {getFieldDecorator('contactName', {
                  initialValue: this.state.customerData.contactName,
                  rules: [
                    {
                      required: true,
                      message: '请输入联系人',
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item label="联系电话" className="organizationName">
                {getFieldDecorator('contactPhone', {
                  initialValue: this.state.customerData.contactPhone,
                  rules: [
                    {
                      required: true,
                      message: '请输入联系电话',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Form>

            <Form.Item label="地区:" className="organizationAddress">
              <GeoForm
                regionId={this.state.customerData.region}
                provinceId={this.state.customerData.province}
                cityId={this.state.customerData.city}
                updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
              />
              <span style={{ color: 'red', display: this.state.display }}>
                请输入省市区
              </span>
            </Form.Item>
            <Form.Item label="详细地址" className="organizationName">
              {getFieldDecorator('address', {
                initialValue: this.state.customerData.address,
                rules: [
                  {
                    required: true,
                    message: '请输入详细地址',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="状态" className="organizationName">
              {getFieldDecorator('valid', {
                initialValue: this.state.customerData.valid,
                rules: [
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ],
              })(
                <RadioGroup
                  onChange={this.stateChange}
                  setFieldsValue={this.state.modalData.valid}
                >
                  <Radio value={true}>启用</Radio>
                  <Radio value={false}>禁用</Radio>
                </RadioGroup>
              )}
            </Form.Item>
          </Modal>
        </div>

        <div>
          <Modal
            title="管理签约工场"
            visible={this.state.showMutrix}
            destroyOnClose={true}
            onCancel={this.MutrixCancel}
            footer={[
              <Button key="back" onClick={this.MutrixCancel}>
                取消
              </Button>,
              <Button key="submit" type="primary" onClick={this.MutrixOk}>
                确定
              </Button>,
            ]}
          >
            <div>
              <Table
                columns={thisColumn}
                bordered
                showHeader={true}
                rowSelection={rowSelection} //复选框的onchange方法
                rowClassName={this.setClassName}
                dataSource={this.state.mutrixList}
                pagination={this.state.pagination}
                rowKey={record => record.id}
              />
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Form.create()(customerDetails);
