import React from 'react';
import {Col, Form, Row, Select} from 'antd';
import {MUTRIX_DASHBOARD, MUTRIX_LISTNAME,} from "../../app/api"
import AlarmList from "../alarmManage/AlarmList";
import {jumpTo} from "../../router/Router";
// import ReactEcharts from 'echarts-for-react';

const Option = Select.Option;

class OrganizationHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            selectMutrixes: [],
            selectedMutrix: 0,
            loading : false,
        };
    }
    // 加载数据
    componentWillMount() {
        let params = new URLSearchParams( window.location.search.split( '?' )[ 1 ] );
        this.setTitle({'orgId':params.get("id")});
    }
    componentDidMount() {
    }
    setTitle = params => {
        MUTRIX_LISTNAME( params ).then(res =>{
            console.log(res);
            if(res.data.list == ''){
                console.log('123')
            }else{
                this.setState({
                    selectMutrixes : res.data.list,
                    selectedMutrix : res.data.list.length > 0 ? res.data.list[0].id : 0
                }, this.dashboard);
            }
        });
    };
    dashboard = () => {
        let params = this.state.selectedMutrix !== 0 ? {"mutrixId": this.state.selectedMutrix} : {};
        if(params !== undefined || params !== null){
            console.log(params);
            MUTRIX_DASHBOARD (params).then(res =>{
                let data = res.data.dashboard;
                console.log(params);
                console.log(typeof (data));
                console.log(data);
                if ( data !== undefined || data !== null) {
                    this.setState({
                        data: data, loading : false
                    });
                }else{
                    this.setState({
                        data:0
                    })
                }
            }).catch(err => console.log(err)) ;
        }
    };
    handleMutrixChange = value => {
        this.setState({
            selectedMutrix: value ,
            loading : true}, this.dashboard);
    };
    /**
     * 筛选、新增控制栏
     */
    titleSection = () => {
        return <div style={{display:"flex", width:"100%" }}>
            <Select
                value={'' + this.state.selectedMutrix}
                style={{ width: 240,marginRight:20 }}
                onChange={this.handleMutrixChange}
            >
                {/*<Option key='0' >工场名称</Option>*/}
                {this.state.selectMutrixes.map(o => (
                    <Option key={o.id} >{o.name}</Option>
                ))}
            </Select>
        </div>
    };
    /**
     * 柱状图
     */
    // trendChart=()=>{
    //     // return
    //     {/*<ReactEcharts*/}
    //     {/*    option={this.getBarChartOption()}*/}
    //     {/*    notMerge={true}*/}
    //     {/*    lazyUpdate={true}*/}
    //     {/*    onChartReady={this.onChartReadyCallback}*/}
    //     {/*    theme={"theme_name"}*/}
    //     {/*/>*/}
    // };
    /**
     * activeAlarm: 23
     coachCount: 2
     courseCount: 7
     footballerCount: 664
     serverCount: 1
     teamCount: 43
     */
    gridSection = () => {
        // const data = this.state.data;
        // console.log(data);
        return <div style={{height:"200px", width:"100%", marginTop:"40px" }}>
            {/*<Row style={{padding:"0 80px"}} gutter={16}>*/}
            <Row gutter={16}>
                <Col span={4}>
                    <div className='grid-dashboard'>
                        <span style={{fontSize:"48px", color:"#fff", fontWeight: 900}}>{this.state.data == undefined ||''|| null ? 0 :this.state.data.activeAlarm}</span>
                        <br/>
                        <span style={{fontSize:"28px", color:"#fff", fontWeight: "bold"}}>未解决告警</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className='grid-dashboard' onClick={() =>jumpTo("/pages/mutrixManage/MutrixItem",{mutrixId:this.state.selectedMutrix})}>
                        <span style={{fontSize:"48px", color:"#fff", fontWeight: 900}}>{this.state.data == undefined ||''|| null ? 0 : this.state.data.serverCount}</span>
                        <br/>
                        <span style={{fontSize:"28px", color:"#fff", fontWeight: "bold"}}>服务器数量</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className='grid-dashboard'>
                        <span style={{fontSize:"48px", color:"#fff", fontWeight: 900}}>{this.state.data.teamCount}</span>
                        <br/>
                        <span style={{fontSize:"28px", color:"#fff", fontWeight: "bold"}}>球队数量</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className='grid-dashboard'>
                        <span style={{fontSize:"48px", color:"#fff", fontWeight: 900}}>{this.state.data.coachCount}</span>
                        <br/>
                        <span style={{fontSize:"28px", color:"#fff", fontWeight: "bold"}}>教练数量</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className='grid-dashboard'>
                        <span style={{fontSize:"48px", color:"#fff", fontWeight: 900}}>{this.state.data.footballerCount}</span>
                        <br/>
                        <span style={{fontSize:"28px", color:"#fff", fontWeight: "bold"}}>球员数量</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className='grid-dashboard'>
                        <span style={{fontSize:"48px", color:"#fff", fontWeight: 900}}>{this.state.data.courseCount}</span>
                        <br/>
                        <span style={{fontSize:"28px", color:"#fff", fontWeight: "bold"}}>计划总数</span>
                    </div>
                </Col>
            </Row>
        </div>
    }
    render() {
        return (
            <div>
                {/* 标题栏 包括下拉框、新增按钮 等*/}
                <this.titleSection />
                <this.gridSection />
                {/*<Spin spinning={true} className='loading-spin'/>*/}
                {/*<AlarmList />*/}
                {/*let params = this.state.selectedMutrix !== 0 ? {"mutrixId": this.state.selectedMutrix} : {};*/}

                <AlarmList
                    mutrixId={this.state.selectedMutrix}
                    columns={[1,2,3,5,6,7,8]}
                />

            </div>
        )
    }
}

export default Form.create()(OrganizationHome);






