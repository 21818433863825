import * as React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Select,
  message,
} from 'antd';
import * as styles from './player.module.css';
import Input from 'antd/es/input';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import GeoForm from '../../components/GeoForm';

import {
  FOOTBALLER_QUERY,
  GEO_NATIONALITY,
  FOOTBALLER_MODIFY,
} from '../../app/api';
import moment from 'moment';
import { jumpTo } from '../../router/Router';
import { string } from 'prop-types';
import { number } from 'echarts/lib/export';

class CoachEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nationality: '', //全世界
      province: '',
      city: '',
      region: '',
      modalData: {},
      coachId: '',
      sexStatus: '',
      stateValue: '',
      teams: [],
    };
  }
  componentWillMount() {
    this.queryCoachDetails();
  }
  componentDidMount() {
    // let params = new URLSearchParams(window.location.search.split('?')[1]);
    // console.log('params',params.get('id'));
    // this.setState({
    //     coachId:params.get('id')
    // });
  }
  queryCoachDetails = () => {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    console.log('params', params.get('id'));
    FOOTBALLER_QUERY({ id: params.get('id') }).then(res => {
      let brith = res.data.query.entity.birthday;
      let birth1 = new Date(brith);
      let day = new Date();
      console.log('birth1', birth1.getFullYear());
      console.log('day', day.getFullYear());
      this.setState(
        {
          modalData: res.data.query.entity,
          teams: res.data.query.teams,
        },
        () => {
          console.log('modalData', this.state.modalData);
        }
      );
    });

    GEO_NATIONALITY().then(res => {
      if (res.code === 0) {
        this.setState({ nationality: res.data.nationality });
      }
    });
  };
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
  };
  editCoach = () => {
    this.props.form.validateFields((errors, values) => {
      if (errors) return;
      if (
        (values.IDnumber !== undefined && values.IDnumber !== '') ||
        (values.passport !== '' && values.passport !== undefined && !errors)
      ) {
        let body = {
          //暂时注释了  后面产品出新需求了再来
          entity: {
            id: this.state.modalData.id,
            name: values.name,
            phone: values.phone,
            gender:
              this.state.sexStatus === ''
                ? this.state.modalData.gender
                : this.state.sexStatus,
            birthday: this.state.modalData.birthday,
            province: parseInt(this.state.modalData.province),
            city: parseInt(this.state.modalData.city),
            region: parseInt(this.state.modalData.region),
            valid:
              this.state.stateValue === ''
                ? this.state.modalData.valid
                : this.state.stateValue,
            email: values.email,
            address: values.address,
            idCard: values.IDnumber,
            passport: values.passport,
            nationality: values.nationality,
          },
          teams: this.state.teams,
        };
        console.log('body', body);
        FOOTBALLER_MODIFY(body)
          .then(res => {
            message.success('编辑' + '【' + values.name + '】成功');
            jumpTo('/pages/player/player');
          })
          .catch(err => {
            console.log(err);
          });
      } else if (
        values.IDnumber === undefined ||
        values.IDnumber === '' ||
        values.passport === '' ||
        values.passport === undefined
      ) {
        message.warn('请选择一种证件号码填写');
      }
    });
  };
  onCancelCoach = () => {
    jumpTo('/pages/player/player');
  };
  /**
   *
   * 获取证件里面的信息
   */
  IdCard(UUserCard) {
    const { getFieldValue } = this.props.form;
    let value = getFieldValue('IDnumber');

    let p = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (!p.test(value)) {
      console.log(false);
      return;
    }
    //获取出生日期
    let birth =
      value.substring(6, 10) +
      '-' +
      value.substring(10, 12) +
      '-' +
      value.substring(12, 14);
    let gender = '';
    //获取性别
    if (parseInt(value.substr(16, 1)) % 2 == 1) {
      //男
      gender = 1;
    } else {
      //女
      gender = 2;
    }

    //获取年龄
    // var myDate = new Date();
    // var month = myDate.getMonth() + 1;
    // var day = myDate.getDate();
    // var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
    // if (UUserCard.substring(10, 12) < month || UUserCard.substring(10, 12) == month && UUserCard.substring(12, 14) <= day) {
    //     age++;
    // }
    let modalData = this.state.modalData;
    modalData.birthday = birth;
    modalData.gender = gender;
    // modalData.
    this.setState({ modalData });
  }
  /***
   * 性别change
   */
  sexChange = e => {
    console.log('e:', e.target.value);
    this.setState({
      sexStatus: e.target.value,
    });
  };
  birthdayChange = (date, dateString) => {
    let modalData = this.state.modalData;
    modalData.birthday = dateString + ' 00:00:00';
    this.setState({
      thisMoment: dateString,
      modalData,
    });
  };
  statusChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const RadioGroup = Radio.Group;
    const { Option } = Select;
    let nationality = this.state.nationality;
    let modalData = this.state.modalData;
    const dateFormat = 'YYYY-MM-DD';

    // console.log('modalData:',  this.state.modalData);
    return (
      <div id="noID">
        <div className={styles['basic-data']}>
          <p>编辑资料</p>
        </div>
        <Form>
          <Row>
            <Col span={12}>
              <Form.Item label="姓名" className="organizationName">
                {getFieldDecorator('name', {
                  initialValue: modalData.name,
                  rules: [
                    {
                      required: true,
                      message: '请输入球员姓名',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="证件号码" className="organizationName ">
                {getFieldDecorator('IDnumber', {
                  initialValue: modalData.idCard,
                  rules: [
                    {
                      required: true,
                      message: '请输入证件号码',
                    },
                    {
                      pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                      message: '请填入正确的证件号码',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(<Input onBlur={e => this.IdCard(e)} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="手机号" className="organizationName">
                {getFieldDecorator('phone', {
                  initialValue: modalData.phone,
                  rules: [
                    {
                      pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/,
                      message: '请输入正确的电话号码',
                    },
                    {
                      required: true,
                      message: '请输入手机号',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="护照" className="organizationName">
                {getFieldDecorator('passport', {
                  initialValue: modalData.passport,
                  rules: [
                    {
                      required: false,
                      message: '请输入护照',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="性别" className="organizationName nameless">
                {getFieldDecorator('sexStatus', {
                  initialValue: modalData.gender,
                  rules: [
                    {
                      required: false,
                      message: '请选择性别',
                    },
                  ],
                })(
                  <RadioGroup
                    onChange={this.sexChange}
                    setFieldsValue={modalData.gender}
                  >
                    <Radio value={1}>男</Radio>
                    <Radio value={2}>女</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="出生日期" className="organizationName ">
                {getFieldDecorator('birthday', {
                  initialValue:
                    modalData.birthday === null
                      ? ''
                      : moment(modalData.birthday, dateFormat),
                  rules: [
                    {
                      required: true,
                      message: '请选择出生日期',
                    },
                  ],
                })(
                  <DatePicker
                    format={dateFormat}
                    onChange={this.birthdayChange}
                    placeholder="请选择年-月-日"
                    locale={locale}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="国籍：" className="organizationName nameless">
                {getFieldDecorator('nationality', {
                  initialValue: modalData.nationality,
                  rules: [
                    {
                      required: false,
                      message: '请选择国籍',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(
                  <Select style={{ width: 120 }}>
                    {(this.state.nationality || []).map((item, key) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            {/*coachLess*/}
            <Col span={12}>
              <Form.Item label="籍贯：" className="organizationName ">
                {getFieldDecorator('nativePlace', {
                  rules: [
                    {
                      required: false,
                      message: '请选择籍贯',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(
                  <div>
                    <Select
                      style={{ width: '22%', marginRight: '10px' }}
                      value={nationality === '' ? '' : nationality[39].name}
                    >
                      {(nationality || []).map((item, key) => (
                        <Option value={item.name} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <GeoForm />
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="通讯地址："
                className="organizationName nameless"
              >
                {getFieldDecorator('province', {
                  rules: [
                    {
                      required: false,
                      message: '请选择地区',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(
                  <div>
                    <Select
                      style={{ width: '22%', marginRight: '10px' }}
                      value={nationality === '' ? '' : nationality[39].name}
                    >
                      {(nationality || []).map((item, key) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    {JSON.stringify(this.state.modalData) == '{}' ? (
                      ''
                    ) : (
                      <GeoForm
                        regionId={this.state.modalData.region}
                        provinceId={this.state.modalData.province}
                        cityId={this.state.modalData.city}
                        updateGeoForm={(key, value) =>
                          this.updateGeoForm(key, value)
                        }
                      />
                    )}
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="邮箱" className="organizationName">
                {getFieldDecorator('email', {
                  initialValue: modalData.email,
                  rules: [
                    {
                      required: false,
                      message: '请输入邮箱',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {getFieldDecorator('address', {
                initialValue: modalData.address,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Input
                  style={{ width: '54%', marginLeft: '13%' }}
                  placeholder="请输入详情地址"
                />
              )}
            </Col>
            <Col span={12}>
              <Form.Item label="状态" className="organizationName">
                {getFieldDecorator('valid', {
                  initialValue: modalData.valid,
                  rules: [
                    {
                      required: true,
                      message: '请选择状态',
                    },
                  ],
                })(
                  // setFieldsValue={this.state.stateValue}
                  <RadioGroup
                    onChange={this.statusChange}
                    setFieldsValue={modalData.valid}
                  >
                    <Radio value={true}>启用</Radio>
                    <Radio value={false}>禁用</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
            </Col>
          </Row>
          <div className={styles['next-btn']}>
            <Button type="primary" onClick={this.editCoach}>
              完成
            </Button>
            <Button style={{ marginLeft: '20px' }} onClick={this.onCancelCoach}>
              取消
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
export default Form.create()(CoachEdit);
