import * as React from 'react';
import { Tabs, Button, DatePicker, Upload, Drawer, Icon, message } from 'antd';
import user from '../../assets/img/user.jpg';
import * as styles from './player.module.css';
import moment from 'moment';
import {
  MUTRIX_LISTNAME,
  TEAM_LISTNAME,
  COACH_DATE,
  FOOTBALLER_QUERY,
  FOOTBALLER_UPLOADPORTRAIT,
} from '../../app/api';
import ListTitle from '../../components/ListTitle/listTiltle';
import TeamList from '../teamManage/teamList';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import SeriesSummary from '../data/SeriesSummary.js';
import HomePage from '../player/PlayerHome';
import Uploadimg from '../../components/upload/uploadImg';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('只能上传JPG/PNG文件!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('图像必须小于2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export default class PlayerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coachId: '',
      data: [], //球员详细数据
      pagination: {}, //分页的数据存储
      entity: '',
      footballDate: '', //球员数据
      fullAddress: '',
      selectedMutrix: 0, //工场id
      allTeam: 0, //全部球队
      selecteDate: 0,
      selectedTeam: 0,
      theyTeamData: '', //教练的球队数据
      userPage: false,
      picture: {
        selectedThemeId: 0,
        title: '',
      },
      previewVisible: false,
      previewImage: '',
      fileList: [],
      loading: false,
      imageUrl: '',
      activeLink: '',
      custmer: '',
      styles: { display: 'inline-block' },
    };
    // 球员所属球队的表格数据
    // this.columns = [
    //   {
    //     key: '1',
    //     title: '序号',
    //     width: '5%',
    //     //render:(text, record, index) => (<span>{index+1 + ((this.state.pagination.current -1) * this.state.pagination.pageSize)}</span>),
    //   },
    //   {
    //     key: '2',
    //     title: '名称',
    //     dataIndex: 'name',
    //     width: '8%',
    //   },
    //   {
    //     key: '3',
    //     title: '教练',
    //     dataIndex: 'coaches',
    //     width: '8%',
    //   },
    //   {
    //     key: '4',
    //     title: '所属组织',
    //     dataIndex: 'orgs',
    //     width: '12%',
    //   },
    //   {
    //     key: '5',
    //     title: '所属客户',
    //     dataIndex: 'orgs',
    //     width: '8%',
    //     // render: (text, record) => {
    //     //     return <span>{record.orgs.length}</span>
    //     // }
    //   },
    //   {
    //     key: '6',
    //     title: '地区',
    //     dataIndex: 'fullAddress',
    //     width: '10%',
    //     align: 'center',
    //   },
    //   // {
    //   //     key:"7",
    //   //     title: '队长',
    //   //     dataIndex:'coaches',
    //   //     width:"10%",
    //   //     align:'center'
    //   // },
    //   {
    //     key: '8',
    //     title: '球员',
    //     dataIndex: 'coaches',
    //     width: '10%',
    //     align: 'center',
    //   },
    //   {
    //     key: '9',
    //     title: '状态',
    //     dataIndex: 'valid',
    //     width: '10%',
    //     align: 'center',
    //     render: (text, record) => {
    //       return <span>{record.valid === true ? '启用' : '禁用'}</span>;
    //     },
    //   },
    // ];
  }
  componentWillMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.setState({ coachId: params.get('id') });
    if (params.get('key') !== null || params.get('key') !== undefined) {
      this.setState({
        activeLink: params.get('key'),
      });
    }
  }
  componentDidMount() {
    this.queryFootballer();
    this.queryFootball();
    this.loadingData();
    this.queryListCourse();
    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    if (custmer == 2) {
      this.setState({
        styles: { display: 'none' },
      });
    }
  }
  /**
   * 加载球员【所属球队】表格数据
   */
  teamDateList = () => {
    return (
      <div>
        <TeamList
          footballerId={this.state.coachId}
          columns={[1, 2, 3, 4, 5, 7, 8]}
          scroll={{ x: 900, y: 100 }}
        />
      </div>
    );
  };
  queryFootballer = () => {
    let params = this.state.coachId !== 0 ? { id: this.state.coachId } : {};
    FOOTBALLER_QUERY(params)
      .then(res => {
        const data = [res.data.query];
        const pagination = { ...this.state.pagination };
        const theyData = data.map(v =>
          Object.assign({}, v.entity, {
            rowKey: v.entity.id,
            fullAddress: v.fullAddress,
            teams: v.teams,
          })
        );
        this.setState({
          //entity:res.data.list.content.entity,
          data: theyData,
          pagination,
        });
      })
      .catch(err => console.log(err));
  };
  /**
   * 根据id查询球员信息
   */
  queryFootball = () => {
    let params = this.state.coachId !== 0 ? { id: this.state.coachId } : {};
    FOOTBALLER_QUERY(params).then(res => {
      this.setState({
        footballDate: res.data.query.entity,
        fullAddress: res.data.query.fullAddress,
        imageUrl: res.data.query.entity.portraitUrl,
      });
    });
  };
  loadingData = params => {
    MUTRIX_LISTNAME(params).then(res => {
      this.setState({
        selectMutrixes: res.data.list,
      });
    });
    TEAM_LISTNAME().then(res => {
      this.setState({
        allTeam: res.data.list,
      });
    });
  };
  allTime = value => {
    const dateFormat = 'YYYY-MM-DD';
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if (value.id === 1) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date().setDate(1)).format(dateFormat);
    } else if (value.id === 2) {
      startDate = moment(new Date()).format(dateFormat);
      endDate = moment(new Date())
        .add(-1, 'month')
        .format(dateFormat);
    } else if (value.id === 3) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-3, 'month')
        .format(dateFormat);
    } else if (value.id === 4) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-6, 'month')
        .format(dateFormat);
    } else if (value.id === 5) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-12, 'month')
        .format(dateFormat);
    }
    this.setState({
      selecteDate: value.id,
      startDate: startDate,
      endDate: endDate,
    });
  };
  /**
   * 球员数据(筛选框)
   * @returns {*}
   */
  footballDate = () => {
    const { RangePicker } = DatePicker;
    return (
      <div>
        <div style={{ margin: '30px 0' }}>
          <ListTitle
            selected={this.state.selectedMutrix}
            title="所属工场"
            data={this.state.selectMutrixes}
            callback={v => this.setState({ selectedMutrix: v.id })}
          />
          <ListTitle
            selected={this.state.selectedTeam}
            title="所属球队"
            data={this.state.allTeam}
            callback={v => this.setState({ selectedTeam: v.id })}
          />
          <span>选择时间段 &nbsp;&nbsp;</span>
          <ListTitle
            selected={this.state.selecteDate}
            title="全部"
            data={[
              { id: 1, name: '本月' },
              { id: 2, name: '最近一个月' },
              { id: 3, name: '近三个月' },
              { id: 4, name: '近六个月' },
              { id: 5, name: '近一年' },
            ]}
            callback={this.allTime}
          />
          <span>自定义&nbsp;&nbsp;</span>
          <RangePicker onChange={this.onChange} locale={locale} />
        </div>
      </div>
    );
  };
  /**
   * 教练球队下的数据
   */
  queryListCourse = pagination => {
    let params =
      this.state.coachId !== 0 ? { memberId: this.state.coachId } : {};
    COACH_DATE(params, pagination).then(res => {
      const pagination = { ...this.state.pagination };
      //pagination.total = res.data.list.totalPages;
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      const data = res.data.list.content;
      const theyData = data.map(v =>
        Object.assign({}, v.entity, {
          rowKey: v.entity.id,
          percent: v.percent,
          status: v.status,
          team: v.team,
          groupNbr: v.groupNbr,
        })
      );
      this.setState({
        theyTeamData: theyData,
        pagination,
      });
    });
  };
  handlePaginationChange = pagination => {
    this.queryListCourse({
      // pageSize: pagination.current,
      pageNum: pagination.current,
    });
  };
  /**
   * 个人主页
   */
  showDrawer = () => {
    this.setState({ userPage: true });
  };
  onClose = () => {
    this.setState({ userPage: false });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  // handleChange = ({ file, fileList, event }) => {
  //   console.log('onchange');
  //   console.log(file);
  //   console.log(fileList);
  // };
  handleAction = (file, fileList) => {
    const formData = new FormData();
    formData.append('file', file.file);
    let params = { footballerId: this.state.coachId };
    FOOTBALLER_UPLOADPORTRAIT(params, formData).then(res => {
      message.info('上传成功');
      this.setState({
        imageUrl: res.data.url,
      });
    });
    this.queryFootball();
  };
  callChange = key => {
    // console.log(key);
  };
  footballDetails = () => {
    // console.log('footballDate.birthday', this.state.footballDate.birthday);
    const { previewVisible, previewImage } = this.state;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">点击上传头像</div>
      </div>
    );
    return (
      <div>
        <div className={styles['basic-data']}>
          <p>基础资料</p>
          <p style={{ fontSize: '16px' }}>
            签约类型：
            {this.state.footballDate.source === 100 ? 'APP' : 'web签约'}
          </p>
        </div>
        <div className={styles['coach-data-box']}>
          <div className={styles['coach-data']}>
            {/*<Uploadimg*/}
            {/*  coachId={this.state.coachId}*/}
            {/*  update={this.state.picture.update}*/}
            {/*  // callback={this.pictureCallback}*/}
            {/*/>*/}
            <Upload
              className="football-uploader"
              listType="picture-card"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onPreview={this.handlePreview}
              customRequest={this.handleAction}
            >
              {this.state.imageUrl ? (
                <img
                  src={this.state.imageUrl}
                  alt="userImg"
                  style={{ width: '100%' }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </div>
          <div>
            <p>姓名：{this.state.footballDate.name}</p>
            <p>性别: {this.state.footballDate.gender === 1 ? '男' : '女'}</p>
            <p>国籍：暂无</p>
            <p>证件号码：{this.state.footballDate.idCard}</p>
            <p>邮箱地址：暂无</p>
            <p>微信：暂无</p>
          </div>
          {/*//.replace(/ 00:00:00/,'')*/}
          <div className={styles['coach-data-list']}>
            <p>手机号码：{this.state.footballDate.phone}</p>
            <p>
              出生日期：
              {this.state.footballDate.birthday == undefined || null
                ? ''
                : this.state.footballDate.birthday.replace(/ 00:00:00/, '')}
            </p>
            <p>出生地：暂无</p>
            <p>最高学历：暂无</p>
            <p>通讯地址：{this.state.fullAddress}</p>
            <p>微博：暂无</p>
          </div>
        </div>

        <div className={styles['basic-data']}>
          <span>角色资料</span>
        </div>
        <div>
          <span> &nbsp;&nbsp;惯用脚:暂无 &nbsp;&nbsp;</span>
          <span>身高:暂无 &nbsp;&nbsp;</span>
          <span>体重:暂无 &nbsp;&nbsp;</span>
          <span>球衣尺码：暂无 &nbsp;&nbsp;</span>
          <span>球裤尺码：暂无 &nbsp;&nbsp;</span>
          <span>球鞋尺码：暂无 &nbsp;&nbsp;</span>
        </div>
        <div className={styles['basic-data']}>
          <p>所属球队</p>
          {/*<div style={{display:"inline-block",marginLeft:'88%',cursor:"pointer",fontSize:'16px',color:'#1890ff'}}*/}
          {/*     onClick={()=> jumpTo("/pages/teamManage/teamAdd",{id:this.state.teamId})}*/}
          {/*>*/}
          {/*    <Icon type="plus-circle"/> 新增*/}
          {/*</div>*/}
        </div>
      </div>
    );
  };
  coachTitle = () => {
    const { TabPane } = Tabs;
    return (
      <div>
        <div className="card-container">
          <Tabs
            type="card"
            onChange={this.callChange}
            defaultActiveKey={this.state.activeLink}
          >
            <TabPane tab="球员详情" key="playerList">
              <this.footballDetails />
              <this.teamDateList />
            </TabPane>
            <TabPane tab="球员数据" key="player" activeKey="player">
              <SeriesSummary />
              {/*<this.footballDate/>*/}
              {/*<this.coachDateList/>*/}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <this.coachTitle />
        <Button
          className={styles['userHome']}
          type="primary"
          onClick={this.showDrawer}
        >
          <span>个</span>
          <br />
          <span>人</span>
          <br />
          <span>主</span>
          <br />
          <span>页</span>
        </Button>
        <Drawer
          title=""
          placement="left"
          closable={false}
          width={1100}
          onClose={this.onClose}
          visible={this.state.userPage}
        >
          <HomePage></HomePage>
        </Drawer>
      </div>
    );
  }
}
