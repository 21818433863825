import * as React from 'react';
import {Button, Col, Divider, Form, Input, Modal, Select, Table} from 'antd';
import {COURSE_LIST} from "../../app/api";
//城市联动数据 select
const Option = Select.Option;
const provinceData = ['北京','江苏','浙江'];
const cityData = {
    北京:['朝阳区','海淀区'],
    江苏: ['南京', '苏州', '镇江'],
    浙江: ['杭州', '宁波', '温州'],

};


//table 表格数据
const column = [
    {
        key:"1",
        title: '教练ID',
        dataIndex:'id',
    },
    {
        key:"2",
        title: '姓名',
        dataIndex:'name',
        width:"15%"

    },
    {
        key:"3",
        title: '联系电话',
        dataIndex:'phone',
        width:"10%",
        // render: msg => msg !== ''? <span>{msg.length}</span>:<span>{""}</span>,
    },
    {
        key:"4",
        title: '所属组织',
        dataIndex:'orgs',
        width:"10%"

    },
    {
        key:"5",
        title: '执教球队',
        dataIndex:'fullAddress',
        width:"18%",
        align:'center'
    },

    {
        key:"7",
        title: '状态',
        dataIndex:'valid',
        width:"8%",
        render: msg => msg === true ? <span style={{color:"green"}}>启用</span> : <span style={{color:"orange"}}>禁用</span>


    },
    {
        title: '操作',
        key: 'operation',
        width: '23%',
        render:(text, record) => (
            <span>
              <a href="javascript:;">管理 {record.lastName}</a>
              <Divider type="vertical" />
              <a href="javascript:;">编辑</a>
               <Divider type="vertical" />
              <a href="javascript:;">删除</a>
        </span>
        )


    }

];




class courseManage extends React.Component {
    state = {
        visible: false,
        cities: cityData[provinceData[0]],   //城市数据
        secondCity: cityData[provinceData[0]][0],

    };

    componentWillMount() {
        this.organList();

    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    organList(){
        COURSE_LIST({}).then(res =>{
            console.log(res);
            const data = res.data.list.content;  //list外层面的详细地址数据
            const pagination = {...this.state.pagination};
            console.log(pagination);
            pagination.total = res.data.list.totalPages;

            this.setState({
                fullAddress:data,
                data: data.map(v => Object.assign({},v.entity,v.coaches,{rowKey:v.entity.id,footballerNumber:v.footballerNumber,fullAddress:v.fullAddress })),
                pagination,
            });
        })

    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { cities } = this.state;
        return (
            <div>
                <div className='btn-box'>
                    <Button type='primary' onClick={this.showModal} className='btn-add'>新增球队</Button>
                </div>
                <Modal
                    title="新增球队"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            确定
                        </Button>,
                    ]}
                >
                    <Form>
                        <Form.Item label="球队名称">
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ],
                            })(<Input />)}
                        </Form.Item>

                    </Form>
                    <span>地区</span>
                    <Select
                        defaultValue={provinceData[0]}
                        style={{ width: 120 }}
                        onChange={this.handleProvinceChange}
                    >
                        {provinceData.map(province => (
                            <Option key={province}>{province}</Option>
                        ))}
                    </Select>

                    <Select
                        style={{ width: 120 }}
                        value={this.state.secondCity}
                        onChange={this.onSecondCityChange}
                    >
                        {cities.map(city => (
                            <Option key={city}>{city}</Option>
                        ))}
                    </Select>
                    <Form.Item label="详细地址">
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                },
                            ],
                        })(<Input />)}
                    </Form.Item>
                </Modal>
                <Col span='12'>
                    <Select defaultValue="lucy" style={{ width: 150,marginBottom:'16px'}}>
                        <Option value="jack">发给</Option>
                        <Option value="lucy">梵蒂冈</Option>
                        <Option value="disabled">和规范化个</Option>
                        <Option value="Yiminghe">yiminghe</Option>
                    </Select>

                    <Input placeholder="请输入球队ID/名称" style={{width:200,marginRight:'16px'}}/>
                    <Button type='primary'> 查询</Button>
                </Col>
                <Table columns={column}
                       showHeader={true}
                       dataSource={this.state.data}
                       pagination={this.state.pagination}
                       onChange={this.handleTableChange}
                />

            </div>
        )
    }

}


export default Form.create()(courseManage);
