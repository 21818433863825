/**
 * reducer数据处理     reducer是专门处理数据逻辑的，通过传入（state,action），针对不同的action返回一个不同的store对象

 */
// import { combineReducers } from 'redux'
import { type } from '../action/index';
const ebikeData = (state, action) => {
  // console.log(state)   //state数据 {menuName: "组织管理"}
  // console.log(action)  // {type: "SWITCH_MENU", menuName: "球队管理"}
  switch (action.type) {
    case type.SWITCH_MENU:
      return {
        ...state,
        menuName: action.menuName,
      };
    default:
      return { ...state };
  }
};

export default ebikeData;
