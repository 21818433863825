//教案管理
import * as React from 'react';
import { Button, Form, Select, Table } from 'antd';
import { COURSE_CONFIG_LIST, DICTIONARY_LIST } from '../../app/api';
import { jumpTo } from '../../router/Router';

const Option = Select.Option;

class CourseConfigManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // API查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},
      selectModules: [],
      selectedModule: 0,
      selectNames: [],
      selectedName: 0,
      selectLevels: [],
      selectedLevel: 0,
      orgModalVisible: false,
      organizations: [],
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'id',
        width: '5%',
      },
      {
        key: '2',
        title: '模式',
        dataIndex: 'moduleName',
        width: '5%',
      },
      {
        key: '3',
        title: '教案名称',
        dataIndex: 'courseTypeName',
        width: '8%',
      },
      {
        key: '4',
        title: '级别',
        dataIndex: 'levelName',
        width: '5%',
      },
      {
        key: '5',
        title: '难度',
        dataIndex: 'difficultyName',
        width: '5%',
      },
      {
        key: '6',
        title: '参数配置',
        width: '5%',
        align: 'center',
        render: (text, record) => (
          <span>
            <a
              href="javascript:;"
              onClick={() =>
                jumpTo('/pages/courseManage/courseConfigEdit', {
                  id: record.id,
                })
              }
            >
              配置
            </a>
          </span>
        ),
      },
      {
        key: '7',
        title: '状态',
        dataIndex: 'validName',
        width: '5%',
        render: valid =>
          valid === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
      {
        key: 'organizations',
        title: '适用组织',
        width: '15%',
        render: (text, record) =>
          !record.special ? (
            '全部'
          ) : (
            <a
              href="javascript:;"
              onClick={() =>
                this.setState({
                  orgModalVisible: true,
                  organizations: record.organizations,
                })
              }
            >
              {record.organizations.length}
            </a>
          ),
      },
    ];
  }
  componentWillMount() {
    this.list();
    this.setTitle();
  }
  /**
   * 加载数据API
   */
  list = params => {
    if (params === undefined) {
      params = {};
    }
    if (this.state.selectedModule != 0) {
      params.module = this.state.selectedModule;
    }
    if (this.state.selectedName != 0) {
      params.courseType = this.state.selectedName;
    }
    if (this.state.selectedLevel != 0) {
      params.level = this.state.selectedLevel;
    }
    COURSE_CONFIG_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const theyData = data.map(v =>
        Object.assign({}, v.entity, {
          moduleName: v.module.name,
          courseTypeName: v.courseType.name,
          rowKey: v.entity.id,
          levelName: v.level.name,
          difficultyName: v.difficulty.name,
          organizations: v.organizations,
          // validName: v.entity.valid ? '启用':'禁用'}));
          validName: v.entity.valid,
        })
      );
      this.setState({
        data: theyData,
        pagination,
      });
    });
  };
  setTitle = () => {
    DICTIONARY_LIST({ type: 3005 }).then(res => {
      this.setState({
        selectModules: res.data.list,
      });
    });
    DICTIONARY_LIST({ type: 3003 }).then(res => {
      this.setState({
        selectNames: res.data.list,
      });
    });
    DICTIONARY_LIST({ type: 3004 }).then(res => {
      this.setState({
        selectLevels: res.data.list,
      });
    });
  };
  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
    });
  };

  handleModuleChange = value => {
    this.setState({ selectedModule: value }, this.list);
  };
  handleNameChange = value => {
    this.setState({ selectedName: value }, this.list);
  };
  handleLevelChange = value => {
    this.setState({ selectedLevel: value }, this.list);
  };
  /**
   * 筛选、新增控制栏
   */
  titleSection = () => {
    return (
      <div style={{ display: 'flex', width: '100%', marginBottom: '20px' }}>
        <Select
          value={'' + this.state.selectedModule}
          style={{ width: 240, marginRight: 20 }}
          onChange={this.handleModuleChange}
        >
          <Option key="0">模式</Option>
          {this.state.selectModules.map(o => (
            <Option key={o.id}>{o.name}</Option>
          ))}
        </Select>
        <Select
          value={'' + this.state.selectedName}
          style={{ width: 240, marginRight: 20 }}
          onChange={this.handleNameChange}
        >
          <Option key={0}>教案名称</Option>
          {this.state.selectNames.map(o => (
            <Option key={o.id}>{o.name}</Option>
          ))}
        </Select>
        <Select
          value={'' + this.state.selectedLevel}
          style={{ width: 240, marginRight: 20 }}
          onChange={this.handleLevelChange}
        >
          <Option key={0}>级别</Option>
          {this.state.selectLevels.map(o => (
            <Option key={o.id}>{o.name}</Option>
          ))}
        </Select>
        {/*<div className='btn-box' style={{marginRight: "20px", marginLeft : "auto"}}>*/}
        {/*    <Button type='primary' onClick={() => jumpTo("/pages/courseManage/courseConfigEdit",{})} className='btn-add'>新增教案</Button>*/}
        {/*</div>*/}
      </div>
    );
  };

  render() {
    const column = this.column.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <div>
        {/* 标题栏 包括下拉框、新增按钮 等*/}
        <this.titleSection />

        {/* 工场列表的弹窗*/}
        {/*<this.mutrixModal />*/}
        {/* 表格 */}
        <Table
          columns={column}
          bordered={true}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
        />
      </div>
    );
  }
}

export default Form.create()(CourseConfigManage);
