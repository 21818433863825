import * as React from 'react';
import {
  Table,
  Divider,
  Button,
  Modal,
  Form,
  Input,
  Select,
  message,
  Radio,
  Icon,
  Col,
  Upload,
} from 'antd';
import {
  FOOTBALLER_LIST,
  FOOTBALLER_DELETE,
  FOOTBALLER_STATUS,
  TEAM_LISTNAME,
  ORGANIZATION_LISTNAME,
  CUSTOM_LISTNAME,
  IMPORTFILE,
  UPLOADPORTRAIT,
} from '../../app/api';
import * as styles from './player.module.css';
import { jumpGo, jumpTo } from '../../router/Router';
import * as XLSX from 'xlsx';
import ListTitle from '../../components/ListTitle/listTiltle';

//城市联动数据 select
const Option = Select.Option;

class playerManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 显示模态窗开关
      // 模态窗类型
      title: '新增组织',
      // API查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},
      // 模态窗的数据存储
      modalData: {},
      teamList: [], //球队List
      value: '',
      sexStatus: 2, //性别
      valueStatus: true, //状态
      birthday: '', //生日
      teamId: 0, // 页面跳转的账号id，如果为0，是新增；否则为修改
      thisFile: false,
      fileList: [],
      allCustom: [], //所有客户数据
      selectedCustom: 0,
      selectedOrgs: 0,
      allorgs: [],
      source: 1,
      styles: { color: '#1890ff', display: 'inline-block' },
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'id',
        width: '6%',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        key: '2',
        title: '姓名',
        dataIndex: 'name',
        width: '7%',
      },
      {
        key: '3',
        title: '联系电话',
        dataIndex: 'phone',
        width: '10%',
      },
      {
        key: '4',
        title: '性别',
        dataIndex: 'gender',
        width: '5%',
        render: gender => (gender === 1 ? <span>男</span> : <span>女</span>),
      },
      // {
      //   key: '5',
      //   title: '年龄',
      //   dataIndex: 'birthday',
      //   width: '5%',
      //   align: 'center',
      //   render(birthday) {
      //     // .replace(/-/g, "\/"
      //     const heAge = new Date(birthday);
      //     const day = new Date();
      //     const age =
      //       day.getFullYear() -
      //       heAge.getFullYear() -
      //       (day.getMonth() < heAge.getMonth() ||
      //       day.getMonth() === heAge.getMonth() ||
      //       day.getDate() < heAge.getDate()
      //         ? 1
      //         : 0);
      //     return age;
      //   },
      // },
      {
        key: '5',
        title: '年龄',
        dataIndex: 'age',
        width: '5%',
        align: 'center',
      },
      {
        key: 'code',
        title: '身份证号',
        dataIndex: 'idCard',
        width: '9%',
        align: 'center',
        render(idCard) {
          return idCard;
        },
      },

      {
        key: '6',
        title: '地区',
        dataIndex: 'fullAddress',
        width: '18%',
        align: 'center',
      },
      {
        key: '9',
        title: '所属球队',
        dataIndex: 'teams',
        width: '8%',
        align: 'center',
        render: (text, record) => {
          return (
            <span style={{ color: '#1890ff', cursor: 'pointer' }}>
              {record.teams.length}
            </span>
          );
        },
      },
      {
        key: '8',
        title: '签约类型',
        dataIndex: 'source',
        width: '8%',
        align: 'center',
        render(source) {
          return <span>{source === 100 ? 'APP' : 'web签约'}</span>;
        },
      },
      {
        key: '7',
        title: '状态',
        dataIndex: 'valid',
        width: '6%',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
      {
        title: '操作',
        key: 'operation',
        width: '23%',
        align: 'center',
        render: (text, record) => (
          <span>
            {/*<button*/}
            {/*  style={this.state.styles}*/}
            {/*  onClick={this.addModal.bind(this, record)}*/}
            {/*>*/}
            {/*  <Icon type="edit" style={{ color: '#1890ff' }} />*/}
            {/*  编辑*/}
            {/*</button>*/}

            <button
              style={this.state.styles}
              onClick={() =>
                jumpTo('/pages/player/playerEdit', { id: record.id })
              }
            >
              <Icon type="edit" style={{ color: '#1890ff' }} />
              编辑
            </button>

            <Divider type="vertical" />

            <button
              // onClick={() =>
              //   jumpTo('/pages/player/playerDetails', { id: record.id, key: 'playerList'})
              // }

              onClick={() => this.JumpsPlayerDetails(record)}
            >
              <Icon type="unordered-list" />
              详情
            </button>
            <Divider type="vertical" />

            <button
              // onClick={() =>
              //   jumpTo('/pages/player/playerDetails', {
              //     id: record.id,
              //     key: 'player',
              //   })
              //   // localStorage.setItem('token', token);
              // }
              onClick={() => this.clickJumps(record)}
              style={this.state.styles}
            >
              <Icon type="bar-chart" style={{ color: '#1890ff' }} />
              数据
            </button>
            {/*<Popconfirm title={"删除【" + record.name + "】不可恢复，你确定要删除吗?"}*/}
            {/*            onConfirm={() => this.handleDelete(record)}>*/}
            {/*    <a title='用户删除' href="javascript:;">删除</a>*/}
            {/*</Popconfirm>*/}
          </span>
        ),
      },
    ];
  }
  componentWillMount() {
    this.organList();
    this.queryTeam();
    this.loadingData();
  }
  componentDidMount() {
    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    let d = document.getElementById('addPlayer');
    if (custmer == 2) {
      d.style.display = 'none'; //工场管理员登录时隐藏此项
      this.setState({
        styles: { display: 'none' },
      });
    }
  }
  clickJumps = record => {
    jumpTo('/pages/player/playerDetails', {
      id: record.id,
      key: 'player',
    });
    let fan = 'player';
    sessionStorage.setItem('player', fan);
    jumpGo();
  };
  JumpsPlayerDetails = record => {
    jumpTo('/pages/player/playerDetails', { id: record.id, key: 'playerList' });
    // localStorage.setItem('player', 'playerList');
    sessionStorage.removeItem('player');
    jumpGo();
  };

  organList = params => {
    if (params === undefined) {
      params = {};
    }
    if (this.state.selectedCustom !== 0) {
      params.orgId = this.state.selectedCustom;
    }
    if (this.state.selectedOrgs !== 0) {
      params.customId = this.state.selectedOrgs;
    }
    if (this.state.teamId !== 0) {
      params.teamId = this.state.teamId;
    }
    if (this.state.source != 1) {
      params.source = this.state.source;
    } else if (params.source) {
      delete params.source;
      console.log('params', params);
    }

    FOOTBALLER_LIST(params).then(res => {
      const data = res.data.list.content;
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      pagination.current = res.data.list.number + 1;
      this.setState({
        fullAddress: data,
        data: data.map(v =>
          Object.assign({}, v.entity, v.coaches, {
            rowKey: v.entity.id,
            footballerNumber: v.footballerNumber,
            fullAddress: v.fullAddress,
            teams: v.teams,
            age: v.age,
          })
        ),
        pagination,
      });
    });
  };
  teamEdit = (text, record) => {
    this.props.history.push({
      pathname: '/teamManage/teamEdit', //路由跳转
      state: { thisData: record },
    });
  };
  handleTableChange = pagination => {
    this.organList({
      pageNum: pagination.current,
    });
  };
  addModal = record => {
    this.setState({ modalData: record, visible: true, title: '编辑球员' });
  };
  editCancel = () => {
    //编辑取消
    this.setState({
      visible: false,
    });
  };
  handleDelete = record => {
    FOOTBALLER_DELETE({ id: record.id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.name + '】成功');
          this.organList();
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };
  editOk = () => {
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        // let body = {   //等产品改需求了 来接这写
        //     "entity": {
        //         "id": this.state.modalData.id,
        //         "name": values.name,
        //         "phone": values.phone,
        //         "province": parseInt(this.state.modalData.province),
        //         "city": parseInt(this.state.modalData.city),
        //         "region": parseInt(this.state.modalData.region),
        //         "gender": this.state.sexStatus,   //性别
        //         "birthday": this.state.birthday,
        //         "valid": this.state.valueStatus  //状态
        //     },
        // };
        // let api = '';
        // if (this.state.title === '新增球员') {
        //     api = FOOTBALLER_ADD;
        // } else {
        //     api = FOOTBALLER_MODIFY;
        // }
        // api(body).then(res => {
        //     this.setState({visible: false});
        //     message.success(this.state.title + '【' + values.name + '】成功');
        //     this.organList();
        // }).catch(err => {
        //     if (err) {
        //         message.error(err)
        //     }
        // })
        FOOTBALLER_STATUS({ id: this.state.modalData.id })
          .then(res => {
            console.log(res);
            if (res.code === 0) {
              this.setState({ visible: false });
              message.success(this.state.title + '成功');
              this.organList();
            }
          })
          .catch(err => {
            message.error(err);
          });
      }
    });
  };
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
  };
  queryTeam = () => {
    TEAM_LISTNAME().then(res => {
      const data = res.data.list;
      this.setState({
        teamList: res.data.list,
      });
    });
  };
  teamonChange = (value, key) => {
    this.setState({ teamId: key.key }, this.organList);
    this.setState({
      id: key.key,
    });
  };
  /**
   * 点击按钮 查询 组织
   */
  searchOrganization = value => {
    this.organList({
      name: value,
    });
  };
  sexChange = e => {
    this.setState({
      // 单选radio
      sexStatus: e.target.value,
    });
  };
  statusChange = e => {
    this.setState({
      valueStatus: e.target.value,
    });
  };
  handleCancel = () => {
    this.setState({
      thisFile: false,
    });
  };
  /**
   * 下载模板
   */
  batchImport = event => {
    event.preventDefault(); // 阻止任何操作
    event.stopPropagation(); //阻止冒泡到父级
    const url = '/api/web/footballer/exportTemplate';
    fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(response => {
        response.blob().then(blob => {
          let filename =
            response.headers.get('Content-Disposition').split(';', 1) + '.xls'; //设置文件名
          // let filename = response.headers.get('Content-Disposition').split('filename=')[1].split('.')+'.xls';//设置文件名
          let blobUrl = window.URL.createObjectURL(blob);
          let aElement = document.getElementById('downloadDiv'); //获取a标签元素
          aElement.href = blobUrl; //设置a标签路径
          aElement.download = filename;
          aElement.click();
          window.URL.revokeObjectURL(blobUrl);
        });
      })
      .catch(error => {
        message.error('文件下载失败');
        console.log('文件下载失败', error);
      });
  };
  /**excal
   * 上传表格
   * @returns {*}
   */
  importExcel = fileList => {
    console.log(fileList);
    let params = { teamId: '1' };
    let formData = new FormData();
    formData.append('file', fileList.file);
    console.log(formData.get('file'));
    console.log('传');
    const url = '/api/web/footballer/importFile?' + { teamId: 1 };

    fetch(url, {
      method: 'post',
      headers: new Headers({
        // 'Content-Type': 'application/x-www-form-urlencoded',
        credentials: 'same-origin',
      }),
      body: formData,
      success: res => {
        console.log(res);
        //res为文件上传成功之后返回的信息，res.responseText为接口返回的值
        let fileInfo = JSON.parse(res.responseText);
        message.success('上传成功');
        if (res) {
          this.setState({
            fileInfo: fileInfo,
            loading: false,
            uploading: false,
            defaultFile: false,
          });
        }
      },
      error: () => {
        this.setState({
          loading: false,
          uploading: false,
        });
        message.error('文件上传失败');
      },
    }).then(function(response) {
      console.log(response);
    });
    // IMPORTFILE(params, formData).then(res => {
    //     console.log(res);
    //     message.info("上传成功");
    // });
  };
  sourceChange = (value, key) => {
    this.setState({ source: value }, () => {
      this.organList();
    });
  };
  loadingData = () => {
    TEAM_LISTNAME().then(res => {
      this.setState({
        allTeam: res.data.list,
      });
    });
    ORGANIZATION_LISTNAME().then(res => {
      //客户
      this.setState({
        allCustom: res.data.list,
      });
    });
    CUSTOM_LISTNAME().then(res => {
      //组织
      this.setState({
        allorgs: res.data.list,
      });
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { teamList } = this.state;
    const Search = Input.Search;
    const column = this.column.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          rowKey: record.key,
        }),
      };
    });
    const An = props => {
      return <a {...props}>{props.children}</a>;
    };
    return (
      <div>
        <div className={styles['organizationSelect']} id="addPlayer">
          <ListTitle
            selected={this.state.selectedCustom}
            title="全部客户"
            data={this.state.allCustom}
            callback={v =>
              this.setState({ selectedCustom: v.id }, this.organList)
            }
          />
          <ListTitle
            selected={this.state.selectedOrgs}
            title="所属组织"
            data={this.state.allorgs}
            callback={v =>
              this.setState({ selectedOrgs: v.id }, this.organList)
            }
          />
          <Select
            defaultValue="全部球队"
            onChange={this.teamonChange}
            style={{ width: 150, marginBottom: '16px', marginRight: '10px' }}
          >
            <Option key={'0'}>全部球队</Option>
            {teamList.map(item => (
              <Option key={item.id}>{item.name}</Option>
            ))}
          </Select>
          <Select
            defaultValue="全部来源"
            onChange={this.sourceChange}
            style={{ width: 150, marginBottom: '16px', marginRight: '10px' }}
          >
            <Option key={'1'}>全部来源</Option>
            <Option key={'100'}>APP注册</Option>
            <Option key={'0'}>web签约</Option>
          </Select>

          <Col span={5}>
            <Search
              placeholder="请输入姓名/联系电话"
              onSearch={this.searchOrganization}
            />
          </Col>

          {/*<div className={styles['btn-box']}>
                    {/*    <Button type='primary' className={styles['btn-add']} onClick={this.she=()=>{this.setState({thisFile:true})}}>批量导入</Button>*/}
          <Button
            type="primary"
            className={styles['btn-box']}
            onClick={() => jumpTo('/pages/player/playerAdd')}
          >
            新增球员
          </Button>
          {/*</div>*/}
          <Modal
            title="导入球员信息"
            visible={this.state.thisFile}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" type="primary" onClick={this.handleCancel}>
                关闭
              </Button>,
            ]}
          >
            {/*onChange={(e)=>this.importExcel(e)}*/}
            <Upload
              customRequest={e => this.importExcel(e)}
              listType="text"
              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              <Button className={styles['btn-add']}>
                <Icon type="upload" />
                {/*<input className={styles['file-uploader']} type='file' accept='.xlsx, .xls' onChange={this.importExcel} />*/}
                上传表格
              </Button>
            </Upload>

            <Button onClick={this.batchImport} style={{ marginTop: '8px' }}>
              <Icon type="download" />
              下载模板
            </Button>
            {/*</a>*/}
            <An id="downloadDiv" style={{ display: 'none' }}></An>
          </Modal>
        </div>

        <Modal
          title={this.state.title}
          visible={this.state.visible}
          onOk={this.editOk}
          onCancel={this.editCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={this.editCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.editOk}>
              确定
            </Button>,
          ]}
        >
          <Form.Item label="状态" className="organizationName">
            {getFieldDecorator('valid', {
              initialValue: this.state.modalData.valid,
              rules: [
                {
                  required: true,
                  message: '请选择状态',
                },
              ],
            })(
              <Radio.Group
                onChange={this.statusChange}
                setFieldsValue={this.state.valueStatus}
              >
                <Radio value={true}>启用</Radio>
                <Radio value={false}>禁用</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Modal>
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowKey={record => record.key}
        />
      </div>
    );
  }
}

export default Form.create()(playerManage);
