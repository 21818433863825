//告警管理
import * as React from 'react';
import {Button,Form} from 'antd';
import {MUTRIX_LISTNAME, ORGANIZATION_LISTNAME} from "../../app/api";
import { jumpTo} from "../../router/Router"
import AlarmList from "./AlarmList";
import ListTitle from "../../components/ListTitle/listTiltle";

class AlarmManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectOrgs:[],
            selectedOrg : 0,
            selectMutrixes: [],
            selectedMutrix: 0,
            selectStatus: [{"id":1, "name":"待确认"}, {"id":2, "name":"待解决"}, {"id":3, "name":"已解决"}],
            selectedStatus : 0,
            alarmColumns : [1,3,2,4,5]
        };
    }
    // 加载数据
    componentWillMount() {
        this.initData();
    }
    componentDidMount() {
    }
    initData = params => {
        MUTRIX_LISTNAME( params ).then(res =>{
            this.setState({
                selectMutrixes : res.data.list
            });
        });
        ORGANIZATION_LISTNAME( params ).then(res =>{
            this.setState({
                selectOrgs : res.data.list
            });
        });
    };
    titleSection = () => {
        return <div style={{display:"flex", width:"100%",marginBottom:'20px'  }}>
            <ListTitle selected={this.state.selectedOrg}
                       title='全部客户'
                       data={ this.state.selectOrgs }
                       callback={ (v)=>this.setState({selectedOrg: v.id }) }
            />
            <ListTitle selected={this.state.selectedMutrix}
                       title='全部工场'
                       data={ this.state.selectMutrixes }
                       callback={ (v)=>this.setState({selectedMutrix: v.id }) }
            />
            <ListTitle selected={this.state.selectedStatus}
                       title='状态'
                       data={ this.state.selectStatus }
                       callback={ (v)=>this.setState({selectedStatus: v.id }, () =>console.log(this.state.selectedStatus))}
            />
            <div className='btn-box' style={{marginRight: "20px", marginLeft : "auto"}}>
                <Button type='primary' onClick={() => jumpTo("/pages/alarmManage/AlarmDefine",{})} className='btn-add'>告警定义</Button>
            </div>
        </div>
    }


    render() {
        return (
            <div>
                <this.titleSection />
                <AlarmList
                    mutrixId={this.state.selectedMutrix}
                    orgId={this.state.selectedOrg}  //客户
                    status={this.state.selectedStatus}
                    // columns={this.state.alarmColumns}
                />
            </div>
        )
    }

}


export default Form.create()(AlarmManage);
