import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { Badge, Breadcrumb, Icon } from 'antd/lib/index';
// import {Link} from "react-router-dom";
import { NavLink } from 'react-router-dom';
import * as React from 'react';
// import {NewRouter} from '../../router/newRouter';
// import Link from 'umi/link'

const userNamesById = { '1': 'John' };

const DynamicUserBreadcrumb = ({ match }) => (
  <span>{userNamesById[match.params.userId]}</span>
);

const NewRouter = [
  { path: '/users/:userId', breadcrumb: DynamicUserBreadcrumb },
  { path: '/example', breadcrumb: 'Custom Example' },
];

////map和渲染你的面包屑组件，不管你想要什么。
const Breadcrumbs = ({ breadcrumbs }) => (
  <div>
    {breadcrumbs.map(({ match, breadcrumb }) => (
      // 在渲染期间还可以使用其他道具，例如'location`
      // 在你的路由对象中找到的任何道具也将通过
      <span key={match.url}>
        <NavLink to={match.url}>{breadcrumb}</NavLink>
      </span>
    ))}
  </div>
);

export default withBreadcrumbs(NewRouter)(Breadcrumbs);
