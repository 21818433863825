//管理员账号
import * as React from 'react';
// import {Link,Route} from 'react-router-dom';
import {
  Button,
  Divider,
  Form,
  Icon,
  message,
  Modal,
  Popconfirm,
  Select,
  Table,
} from 'antd';
import {
  ACCOUNT_DELETE,
  ACCOUNT_LIST,
  MUTRIX_LISTNAME,
  ORGANIZATION_LISTNAME,
} from '../../app/api';
import { jumpTo } from '../../router/Router';
import * as styles from './authority.module.css';

const Option = Select.Option;
const customer = localStorage.getItem('roleid');

class authorityManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // API查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},
      // 下拉选择框的组织列表
      selectOrges: [],
      selectOrgId: 0,
      // 下拉选择框的工场列表
      selectMutrixes: [],
      selectMutrixId: 0,
      // 模态窗
      mutrixModalVisible: false,
      mutrixes: [],
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: 'ID',
        dataIndex: 'id',
        width: '5%',
      },
      {
        key: '2',
        title: '姓名',
        dataIndex: 'username',
        width: '5%',
      },
      {
        key: '9',
        title: '账号',
        dataIndex: 'account',
        width: '5%',
      },

      {
        key: '3',
        title: '联系电话',
        dataIndex: 'phone',
        width: '8%',
        // render: msg => msg !== ''? <span>{msg.length}</span>:<span>{""}</span>,
      },
      {
        key: '4',
        title: '邮箱',
        dataIndex: 'email',
        width: '10%',
      },
      {
        key: '5',
        title: '角色',
        dataIndex: 'roleName',
        width: '10%',
        align: 'center',
      },
      {
        key: '6',
        title: '授权客户',
        width: '10%',
        align: 'center',
        render: (text, record) =>
          record.roleId === 1
            ? '全部'
            : record.roleId === 3
            ? record.orgname
            : record.roleId === 4
            ? record.organizations.length
            : '',
      },
      {
        key: '7',
        title: '授权工场',
        // dataIndex:'mutrixName',
        width: '8%',
        align: 'center',
        render: (text, record) =>
          record.roleId === 1 ? (
            '全部'
          ) : record.roleId === 2 ? (
            <button
              onClick={() =>
                this.setState({
                  mutrixModalVisible: true,
                  mutrixes: record.mutrixes,
                })
              }
            >
              {record.mutrixes.length}
            </button>
          ) : (
            ''
          ),
      },

      {
        key: '8',
        title: '状态',
        dataIndex: 'valid',
        width: '6%',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
      {
        title: '操作',
        key: 'operation',
        width: '15%',
        render: (text, record) => (
          <span>
            <Button
              className={styles['att-btn']}
              onClick={() =>
                jumpTo('/pages/authorityManage/authorityEdit', {
                  id: record.id,
                })
              }
            >
              <Icon type="edit" style={{ color: '#1890ff' }} />
              编辑
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title={'删除【' + record.username + '】不可恢复，你确定要删除吗?'}
              onConfirm={() => this.handleDelete(record)}
              okText="确定"
              cancelText="取消"
            >
              <Button title="用户删除" className={styles['att-btn']}>
                <Icon type="delete" style={{ color: '#1890ff' }} />
                删除
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  // 加载数据
  componentWillMount() {
    this.list();
    this.setTitle();
  }
  componentDidMount() {
    if (customer == 3) {
      let f = document.getElementById('addAuthority');
      f.style.display = 'none';
    }
  }
  /**
   * 加载数据API
   */
  list = params => {
    ACCOUNT_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      // pagination.total = res.data.list.totalPages;
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const theyData = data.map(v =>
        Object.assign({}, v.entity, {
          rowKey: v.entity.id,
          roleId: v.role.id,
          roleName: v.role.name,
          orgId: v.organization.id,
          orgname: v.organization.name,
          mutrixes: v.mutrixes,
          organizations: v.organizations,
        })
      );

      this.setState({
        data: theyData,
        pagination,
      });
    });
  };
  setTitle = params => {
    ORGANIZATION_LISTNAME(params).then(res => {
      this.setState({
        selectOrges: res.data.list,
      });
    });
    MUTRIX_LISTNAME(params).then(res => {
      this.setState({
        selectMutrixes: res.data.list,
      });
    });
  };
  /**
   * 删除，确认
   */
  handleDelete = record => {
    ACCOUNT_DELETE({ id: record.id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.username + '】成功');
          this.list();
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };
  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
    });
  };

  handleOrganizationChange = value => {
    this.setState({ selectOrgId: value, selectMutrixId: 0 });
    this.list({ orgId: value });
  };

  handleMutrixChange = value => {
    this.setState({ selectOrgId: 0, selectMutrixId: value });
    this.list({ mutrixId: value });
  };

  /**
   * 工场管理员，点击数字弹出工场列表
   */
  mutrixModal = () => {
    const columns = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'index.module.css.module.css',
        width: '20%',
      },
      {
        key: '2',
        title: '工场ID',
        dataIndex: 'id',
        width: '30%',
      },
      {
        key: '3',
        title: '工场名称',
        dataIndex: 'name',
        width: '50%',
      },
    ];
    const data = [];
    for (let i = 0; i < this.state.mutrixes.length; i++) {
      let mutrix = this.state.mutrixes[i];
      data.push({
        index: i,
        id: mutrix.id,
        name: mutrix.name,
      });
    }
    return (
      <Modal
        title="工场列表"
        visible={this.state.mutrixModalVisible}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => this.setState({ mutrixModalVisible: false })}
          >
            确定
          </Button>,
        ]}
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </Modal>
    );
  };

  /**
   * 筛选、新增控制栏
   */
  titleSection = () => {
    return (
      <div style={{ display: 'flex', width: '100%', marginBottom: '20px' }}>
        <Select
          // value={this.state.selectOrgId}
          value={'' + this.state.selectOrgId}
          style={{ width: 240, marginRight: 20 }}
          onChange={this.handleOrganizationChange}
        >
          <Option key="0">全部客户</Option>
          {this.state.selectOrges.map(o => (
            <Option key={o.id}>{o.name}</Option>
          ))}
        </Select>
        <Select
          value={'' + this.state.selectMutrixId}
          style={{ width: 240, marginRight: 20 }}
          onChange={this.handleMutrixChange}
        >
          <Option key={0}>全部工场</Option>
          {this.state.selectMutrixes.map(o => (
            <Option key={o.id}>{o.name}</Option>
          ))}
        </Select>
        <div
          className="btn-box"
          style={{ marginRight: '20px', marginLeft: 'auto' }}
          id="addAuthority"
        >
          <Button
            type="primary"
            onClick={() => jumpTo('/pages/authorityManage/authorityEdit', {})}
            className="btn-add"
          >
            新增权限
          </Button>
        </div>
      </div>
    );
  };
  render() {
    if (customer == 3) {
      this.column = this.column.slice(0, 8);
    }
    const column = this.column.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });
    return (
      <div>
        {/* 标题栏 包括下拉框、新增按钮 等*/}
        <this.titleSection />

        {/* 工场列表的弹窗*/}
        <this.mutrixModal />
        {/* 表格 */}
        <Table
          columns={column}
          bordered={true}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
        />
      </div>
    );
  }
}

export default Form.create()(authorityManage);
