import * as React from 'react';
import { Button, Form, Input, message, Radio } from 'antd';
import {
  ACCOUNT_ADD,
  ACCOUNT_MODIFY,
  ACCOUNT_QUERY,
  MUTRIX_LISTNAME,
  ORGANIZATION_LISTNAME,
} from '../../app/api';
import { jumpBack } from '../../router/Router';
import SelectModal from '../../components/SelectModal/SelectModal';
// import AlarmList from "../alarmManage/AlarmList";
const RadioGroup = Radio.Group;

const defaultModalData = {
  entity: {
    // id: 0,
    account: undefined,
    username: undefined,
    phone: undefined,
    email: undefined,
    roleid: 1,
    valid: true,
  },
  role: { id: 1, name: '' },
  mutrixes: [],
  organizations: [],
};

class authorityEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 页面跳转的账号id，如果为0，是新增；否则为修改
      id: 0,
      // API查询的数据存储
      data: Object.assign({}, defaultModalData),
      // 模态窗的数据存储
      // 通过工场listName接口获取的所有工场列表
      radioValue: 1,
      // 多选模态窗的参数值
      allOrgs: [],
      selectedOrg: {},
      // 模态窗的显示控制
      modalVisible: false,
      // 单选模态窗的参数值
      allMutrixes: [],
      selectedMutrixes: [],
      // 状态
      statusValue: true,
    };
  }

  // 加载数据
  componentWillMount() {
    let params = new window.URLSearchParams(
      window.location.search.split('?')[1]
    );
    let id = params && params.get('id');

    if (id !== null || undefined) {
      this.state.id = params.get('id');
      this.query({ id: this.state.id });
    } else {
    }
  }
  componentDidMount() {
    this.initData();
  }

  initData = params => {
    MUTRIX_LISTNAME(params).then(res => {
      this.setState({
        allMutrixes: res.data.list,
      });
    });
    ORGANIZATION_LISTNAME(params).then(res => {
      this.setState({
        allOrgs: res.data.list,
      });
    });
  };
  query = params => {
    ACCOUNT_QUERY(params).then(res => {
      let data = res.data.query;
      if (data !== undefined && data != null) {
        this.setState({
          data: data,
          radioValue: data.entity.roleid,
          statusValue: data.entity.valid,
          selectedOrg: data.organizations,
          selectedMutrixes: data.mutrixes,
        });
      }
    });
  };
  /**
   * 编辑完成，提交表单
   */
  saveAll = () => {
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        let body = this.state.data;
        body.entity.account = values.account; //产品说要加账号
        body.entity.phone = values.phone;
        body.entity.username = values.username;
        body.entity.email = values.email;
        body.entity.roleid = this.state.radioValue;
        body.entity.valid = this.state.statusValue;

        body.role.id = this.state.radioValue;
        let title = this.state.id === 0 ? '新增管理员' : '修改管理员';
        let api = '';
        if (this.state.id === 0) {
          api = ACCOUNT_ADD;
        } else {
          api = ACCOUNT_MODIFY;
        }
        api(body)
          .then(res => {
            this.setState({ visible: false });
            message.success(title + '【' + values.username + '】成功');
            jumpBack();
          })
          .catch(err => {
            if (err) {
              message.error(err);
            }
          });
      }
    });
  };
  /**
   * 处理角色radio事件
   */
  handleRadioChange = e => {
    this.setState({
      radioValue: e.target.value,
    });
  };
  /**
   * 处理状态radio事件
   */
  handleStatusChange = e => {
    this.setState({
      statusValue: e.target.value,
    });
  };
  handleCancel = data => {
    this.setState({ modalVisible: data });
  };
  mainForm = () => {
    const { getFieldDecorator } = this.props.form;
    const data = this.state.data;
    return (
      <Form>
        <Form.Item label="账号" className="organizationName">
          {getFieldDecorator('account', {
            initialValue: data.entity.account, //填充input默认数据
            rules: [
              {
                required: true,
                message: '请输入账号',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="手机号" className="organizationName">
          {getFieldDecorator('phone', {
            initialValue: data.entity.phone, //填充input默认数据
            rules: [
              {
                required: true,
                message: '请输入手机号',
              },
              {
                pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/,
                message: '请输入正确的手机号',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="姓名" className="organizationName">
          {getFieldDecorator('username', {
            initialValue: data.entity.username,
            rules: [
              {
                required: true,
                message: '请输入姓名',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="邮箱" className="organizationName">
          {getFieldDecorator('email', {
            initialValue: data.entity.email,
            rules: [
              {
                required: true,
                message: '请输入邮箱',
              },
            ],
          })(<Input />)}
        </Form.Item>
        {/*角色选择区*/}
        <Form.Item label="角色" className="organizationName">
          <RadioGroup
            onChange={this.handleRadioChange}
            value={this.state.radioValue}
          >
            {/*（一级）*/}
            <Radio value={1}>系统管理员</Radio>
            {/*（组织）*/}
            <Radio value={4}>运营管理员</Radio>
            {/*（二级）*/}
            <Radio value={2}>工场管理员</Radio>

            {/*<Radio value={3}>客户管理员</Radio>*/}
          </RadioGroup>
        </Form.Item>
        {/*所属工场列表 一级管理员隐藏；二级管理现有显示所属工场；组织管理员显示所属组织*/}
        {this.state.radioValue === 2 && (
          <div>
            <Form.Item label="授权工场" className="organizationName">
              <span>{data.mutrixes.map(o => o.name).join(',')}</span>
            </Form.Item>
            <div className="btn-box" style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    selectedMutrixes: data.mutrixes,
                    modalVisible: true,
                  });
                }}
                className="btn-add"
              >
                编辑
              </Button>
            </div>
          </div>
        )}
        {this.state.radioValue === 4 && (
          <div>
            <Form.Item label="授权客户" className="organizationName">
              {/*<span>{data.organization.forEach(item =>item.name)}</span>*/}
              <span>{data.organizations.map(i => i.name).join(',')}</span>
            </Form.Item>
            <div className="btn-box" style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    selectedOrg: data.organizations,
                    modalVisible: true,
                  });
                }}
                className="btn-add"
              >
                编辑
              </Button>
            </div>
          </div>
        )}
        {/*状态选择区*/}
        <Form.Item label="状态" className="organizationName">
          <RadioGroup
            onChange={this.handleStatusChange}
            value={this.state.statusValue}
          >
            <Radio value={true}>启用</Radio>
            <Radio value={false}>禁用</Radio>
          </RadioGroup>
        </Form.Item>
        <div className="btn-box" style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            onClick={this.saveAll.bind(this)}
            className="btn-add"
          >
            保存
          </Button>
        </div>
      </Form>
    );
  };
  /**
   * 工场多选框的ok回调方法
   */
  handleMutrixModal = value => {
    let selected = [];
    if (value !== undefined) {
      this.state.allMutrixes.forEach(o => {
        if (value.indexOf(o.name) !== -1) {
          selected.push(o);
        }
      });
    }
    if (selected.length === 0) {
      message.warn('未授权任何工场');
    }
    let data = this.state.data;
    data.mutrixes = selected;
    data.entity.roleid = 2;
    this.setState({
      modalVisible: false,
      data: data,
      selectedMutrixes: selected,
    });
  };
  /**
   * 客户【【目前改为多选框了】】 的ok回调方法
   */
  handleOrgModal = value => {
    let selected = [];
    // if (value !== undefined) {
    //   let data = this.state.data;
    //   console.log('选择客户',data);
    //   data.entity.roleid = 3;
    //   data.organizations = value;
    //   this.setState({ modalVisible: false, selectedOrg: value, data: data });
    // }
    if (value !== undefined) {
      this.state.allOrgs.forEach(i => {
        if (value.indexOf(i.name) !== -1) {
          selected.push(i);
        }
      });
    }
    if (selected.length === 0) {
      message.warn('未授权任何客户');
    }
    let data = this.state.data;
    data.organizations = selected;
    data.entity.roleid = 3;
    this.setState({ modalVisible: false, selectedOrg: selected, data: data });
  };
  render() {
    return (
      <div>
        {/* 工场选择的弹窗*/}
        {/*<this.mutrixModal />*/}
        <SelectModal
          title="请选择授权工场"
          visible={this.state.modalVisible && this.state.radioValue === 2}
          data={this.state.allMutrixes}
          type="checkbox"
          cancel={this.handleCancel}
          selected={this.state.selectedMutrixes}
          callback={this.handleMutrixModal.bind(this)}
        />
        {/* 客户选择的弹窗  多选  GAI*/}
        <SelectModal
          title="请选择授权客户"
          visible={this.state.modalVisible && this.state.radioValue === 4}
          data={this.state.allOrgs}
          // type="radio"
          type="checkbox"
          cancel={this.handleCancel}
          selected={this.state.selectedOrg}
          callback={this.handleOrgModal.bind(this)}
        />
        {/*<this.organizationModal />*/}
        {/* 主表单区 */}
        <this.mainForm />
      </div>
    );
  }
}

export default Form.create()(authorityEdit);
