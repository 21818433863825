//工场管理员个人中心
import React from 'react';
import { Button, Form, Icon, Input, message, Modal, Radio, Upload } from 'antd';
import {
  CHANGEPASSWORD,
  ACCOUNT_QUERY,
  HOME_UPLOADPORTRAIT,
  CHOOSEMUTRIX,
  WHOAMI,
} from '../../app/api';
import { jumpTo, jumpGo } from '../../router/Router';
import * as styles from '../login/App.module.css';

const accountId = localStorage.getItem('accountId');

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('只能上传JPG/PNG文件!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('图像必须小于2MB!');
  }
  return isJpgOrPng && isLt2M;
}
class MutrixCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: false,
      mutrixes: [],
      organization: [],
      imageUrl: '',
      changeMutrix: false,
      checkedList: '',
      portraitUrl: '',
      account: '',
      mutrixName: '',
      currentOrg: '',
    };
  }
  componentWillMount() {
    this.queryWhoAmI();
  }

  // queryMutrix = () => {
  //   ACCOUNT_QUERY({ id: accountId }).then(res => {
  //     this.setState({
  //       account: res.data.query.entity.account,
  //       mutrixes: res.data.query.mutrixes,
  //       organization: res.data.query.organization,
  //       portraitUrl: res.data.query.entity.portraitUrl,
  //     });
  //   });
  // };

  queryWhoAmI = () => {
    WHOAMI({ id: accountId })
      .then(res => {
        console.log('res.data:::', res.data);
        let mutrixList = res.data.mutrixList;
        let dataList = res.data.account;
        if (res.code === 0) {
          this.setState({
            account: dataList,
            mutrixes: res.data.mutrixList,
            organization: res.data.orgList,
            portraitUrl: dataList.portraitUrl,
            mutrixName: res.data.currentMutrix,
            currentOrg: res.data.currentOrg,
          });
        }
      })
      .catch(err => {
        message.error(err);
      });
  };

  showMutrix = () => {
    this.setState({ changeMutrix: true });
  };
  showModel = () => {
    this.setState({ changePassword: true });
  };
  /**
   * 切换工场
   * @param e
   */
  mutrixChange = e => {
    let id = e.target.value;
    this.setState({
      checkedList: id,
    });
  };
  changeMutrixOk = () => {
    if (this.state.checkedList == '') {
      message.warning('请选择要切换的工场');
    } else {
      CHOOSEMUTRIX({ id: this.state.checkedList }).then(res => {
        console.log('res::::::', res);
        if (res.code === 0) {
          this.setState({ changeMutrix: false });
          jumpGo();
          setTimeout(() => {
            message.success('切换成功');
          }, 4500);
        }
      });
      // sessionStorage.setItem('mutrix', this.state.checkedList);

      // this.setState({ changeMutrix: false });
      // window.location.reload();
    }
  };
  editOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        CHANGEPASSWORD({
          account: this.state.account,
          newPassword: values.newPassword,
          oldPassword: values.oldPassword,
        }).then(res => {
          if (res.code === 0) {
            this.setState({ changePassword: false });
            jumpTo('/pages/login');
            message.success('密码修改成功，请直接登录');
          } else {
            message.error(res.data.message);
          }
        });
      }
    });
  };
  editCancel = () => {
    this.setState({ changePassword: false });
  };
  //上传头像
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  handleChange = ({ file, fileList, event }) => {
    console.log(file);
    console.log(fileList);
  };
  handleAction = (file, fileList) => {
    const formData = new FormData();
    formData.append('file', file.file);
    HOME_UPLOADPORTRAIT(formData).then(res => {
      message.info('上传成功');
      this.setState({ portraitUrl: res.data.url });
    });
    this.queryMutrix();
  };
  changeMutrixCancel = () => {
    this.setState({ changeMutrix: false });
  };
  thisModel = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const getTwoPassword = getFieldValue('newPassword');
    return (
      <Modal
        title={this.state.title}
        visible={this.state.changePassword}
        onOk={this.editOk}
        onCancel={this.editCancel}
        destroyOnClose={true}
        footer={[
          <Button key="submit" type="primary" onClick={this.editOk}>
            确定
          </Button>,
          <Button key="cancel" onClick={this.editCancel}>
            取消
          </Button>,
        ]}
      >
        <Form>
          <Form.Item label="旧密码" className="organizationName">
            {getFieldDecorator('oldPassword', {
              rules: [
                {
                  required: true,
                  message: '请输入旧密码',
                },
              ],
            })(<Input autoComplete="off" />)}
          </Form.Item>
          <Form.Item label="新密码" className="organizationName">
            {getFieldDecorator('newPassword', {
              rules: [
                {
                  required: true,
                  message: '请输入新密码',
                },
              ],
            })(<Input.Password autocomplete="new-password" />)}
          </Form.Item>
          <Form.Item label="确认密码" className="organizationName">
            {getFieldDecorator('confirm', {
              rules: [
                { required: true, message: '请再次确认密码' },
                {
                  validator: (rule, value, callback) => {
                    if (value !== getTwoPassword) {
                      callback('两次密码输入不一致');
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<Input.Password autoComplete="off" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  mutrixModel = () => {
    const { mutrixes } = this.state;
    const RadioGroup = Radio.Group;
    return (
      <div>
        <Modal
          title={this.state.title}
          visible={this.state.changeMutrix}
          onOk={this.changeMutrixOk}
          onCancel={this.changeMutrixCancel}
          destroyOnClose={true}
          footer={[
            <Button key="submit" type="primary" onClick={this.changeMutrixOk}>
              确定
            </Button>,
            <Button key="cancel" onClick={this.changeMutrixCancel}>
              取消
            </Button>,
          ]}
        >
          <RadioGroup onChange={this.mutrixChange}>
            {mutrixes.map((o, key) => {
              return (
                <Radio value={o.id} key={o.id}>
                  {o.name}
                </Radio>
              );
            })}
          </RadioGroup>
        </Modal>
      </div>
    );
  };
  render() {
    const { organization } = this.state;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">点击上传头像</div>
      </div>
    );
    return (
      <div>
        <div
          style={{
            height: '30px',
            borderBottom: '1px solid #cccccc',
            marginBottom: '30px',
          }}
        >
          个人中心
        </div>
        <div style={{ display: 'inline-block' }}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            customRequest={this.handleAction}
          >
            {this.state.portraitUrl ? (
              <img
                src={this.state.portraitUrl}
                alt="userImg"
                style={{ width: '100%' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        <div style={{ display: 'inline-block', verticalAlign: '-21px' }}>
          <p>
            当前账号：{this.state.account.rawAccount}
            <Button style={{ marginLeft: '10px' }} onClick={this.showModel}>
              修改密码
            </Button>
          </p>
          <p>
            当前角色：
            {this.state.account.roleId == 1
              ? '系统管理员'
              : this.state.account.roleId == 2
              ? '工场管理员'
              : this.state.account.roleId == 3
              ? '客户管理员'
              : ''}
          </p>
          <p>
            当前工场：
            {this.state.mutrixName.name == undefined
              ? ''
              : this.state.mutrixName.name}
            <Button onClick={this.showMutrix} style={{ marginLeft: '15px' }}>
              {' '}
              切换工场
            </Button>
          </p>
          <p>
            所属客户：
            <span style={{ color: 'rgb(24, 144, 255)' }}>
              {this.state.currentOrg.name == undefined
                ? '暂无'
                : this.state.currentOrg.name}
            </span>
          </p>
          <div style={{ height: 'calc(56vh)' }}></div>
        </div>
        <this.thisModel />
        <this.mutrixModel />
      </div>
    );
  }
}

export default Form.create()(MutrixCenter);
