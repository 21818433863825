import * as React from 'react'
import {Button, Checkbox, Modal, Radio} from 'antd';

class SelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            multipleCheck : {
                checkedList :[],
                indeterminate: true,
                checkAll: false,
            },
            singleCheck:{
                radioValue: 1,
            }
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.type === 'radio') {
            if (nextProps.selected.id !== this.state.singleCheck.radioValue) {
                let singleCheck = this.state.singleCheck;
                singleCheck.radioValue = nextProps.selected.id;
                this.setState({singleCheck: singleCheck});
            }
        }
        if (this.props.type === 'checkbox') {
            if (nextProps.selected.length > 0) {
                let multipleCheck = this.state.multipleCheck;
                multipleCheck.checkedList = nextProps.selected.map(o=>o.name);
                this.setState({multipleCheck: multipleCheck});
            }
        }
    }
    handleRadioChange = e => {
        console.log(e);
        this.setState({
            singleCheck:{radioValue: e.target.value}
        });
    };
    handleRadioOk = () => {
        let value = undefined;
        this.props.data.forEach( o => {
                if (o.id === this.state.singleCheck.radioValue) {
                    value = o;
                }
            }
        );
        this.props.callback(value);
    };
    /**
     * 处理工场选择列表弹窗中的多选窗的点击事件
     */
    handleCheckboxChange = (checkedList) => {
        this.setState({
            multipleCheck: {
                checkedList,
                indeterminate: !!checkedList.length && checkedList.length < this.props.data.length,
                checkAll: checkedList.length === this.props.data.length,
            }
        })
    };
    /**
     * 处理工场选择列表弹窗中的全选的点击事件
     */
    handleCheckboxAllChange = e => {
        this.setState({
            multipleCheck: {
                checkedList: e.target.checked ? this.props.data.map(o=>o.name) : [],
                indeterminate: false,
                checkAll: e.target.checked
            }
        })
    };
    handleCancel = (visible)=>{
        // this.props.callback(visible)//这个地方把值传递给了props的事件当中
    };
// {/*<Button key="cancel" onClick={this.handleCancel}>*/}
// {/*    取消*/}
// // {/*</Button>,*/}
    radioModal = () =>{
        return  <Modal
            title={this.props.title}
            visible={this.props.visible}
            closable = {false}
            onCancel={this.handleCancel}
            destroyOnClose={true}
            footer=
                {[
                    <Button key="submit" type="primary"
                            onClick={this.handleRadioOk}>
                        确定
                    </Button>
                ]}
        >
            <Radio.Group
                options={this.props.data.map(o=>Object.assign({label: o.name, name:o.name, value: o.id}))}
                onChange={this.handleRadioChange}
                value={this.state.singleCheck.radioValue} />
        </Modal>
    };
// {/*<Button key="cancel" onClick={this.handleCancel}>*/}
// {/*    取消*/}
// {/*</Button>,*/}
    checkboxModal = () =>{
        return  <Modal
            title={this.props.title}
            visible={this.props.visible}
            closable = {false}
            onCancel={this.handleCancel.bind(this,this.state.visible)}
            destroyOnClose={true}
            footer={[
                <Button key="submit" type="primary" style={{ textAlign : "center"}}
                        onClick={()=>this.props.callback(this.state.multipleCheck.checkedList)}>
                    确定
                </Button>
            ]}
        >
            <div style={{marginBottom:'24px'}}>
                <Checkbox indeterminate={this.state.multipleCheck.indeterminate}
                          onChange={this.handleCheckboxAllChange}
                          checked={this.state.multipleCheck.checkAll}
                >全选</Checkbox>
            </div>
            <Checkbox.Group options={this.props.data.map(o=>o.name)}
                            onChange={this.handleCheckboxChange}
                            value={this.state.multipleCheck.checkedList}
            />
        </Modal>
    };
    render() {
        return (
            <div>
                { this.props.type === 'checkbox' && <this.checkboxModal/> }
                { this.props.type === 'radio' && <this.radioModal /> }
            </div>
        )
    }
}

export default SelectModal;
