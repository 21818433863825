import * as React from 'react';
import { Select, DatePicker, Button, Form, Table, message, Radio } from 'antd';
// import debounce from 'lodash/debounce';  //防抖函数
import {
  CUSTOM_LIST,
  MUTRIX_LISTNAME,
  ORGANIZATION_LISTNAME,
  CUSTOM_ADD,
  CUSTOM_RELATION_ADD,
  MUTRIX_LIST,
} from '../../app/api';
import Input from 'antd/es/input';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import GeoForm from '../../components/GeoForm';
import ListTitle from '../../components/ListTitle/listTiltle';
import * as styles from './organizationManage.module.css';
import { jumpTo } from '../../router/Router';
// import AsyncValidator from 'async-validator'

class organizationAdd extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    // this.fetchUser = debounce(this.fetchUser, 800);
  }
  state = {
    data: '',
    value: [],
    selectedItems: [],
    fetching: false,
    selectedMutrix: 0,
    selectMutrixes: [],
    // style:{color:'red',marginLeft:'80px',marginTop:'10px',display:'none'},
    startDate: '', //开始时间
    endDate: '', //结束时间
    organizaData: [],
    modalData: {},
    confirmDirty: false,
    stateValue: '', //状态
    allOrgs: [], //全部客户
    selectedOrgs: '',
    orgId: '', //新增组织的id
    existedId: '',
    allMutrix: [], //存放某客户下的工场数据
    disabled: false,
  };
  componentWillMount() {
    //render之前调用
    this.loadingData();
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    let this_id = params.get('id');
    if (this_id == null) {
      this.setState({
        selectedOrgs: '',
        disabled: false,
      });
    } else {
      this.setState({
        selectedOrgs: this_id,
        disabled: true,
      });
      this.selectedCustomer(this_id);
    }
  }
  componentDidMount() {}
  //如果已经默认选了客户
  selectedCustomer = id => {
    console.log('id:', id);
    MUTRIX_LIST({ orgId: id }).then(res => {
      if (res.code === 0) {
        const data = res.data.list.content;
        const theyData = data.map(v =>
          Object.assign({}, v.entity, {
            rowKey: v.entity.id,
          })
        );
        this.setState({ allMutrix: theyData });
      }
    });
  };
  componentDidCatch(error, errorInfo) {}
  //  选择签约工场的id
  organizationChange = selectedItems => {
    console.log('selectedItems:', selectedItems);

    this.setState({ selectedItems });
  };
  onStartTime = (date, dateString) => {
    // 开始时间
    console.log(dateString);
    this.setState({ startDate: dateString });
  };
  onChangeTime = (date, dateString) => {
    //结束时间
    console.log(dateString);
    this.setState({ endDate: dateString });
  };
  loadingData = () => {
    ORGANIZATION_LISTNAME().then(res => {
      this.setState({
        allOrgs: res.data.list,
      });
    });
  };
  /**
   * 点击下一步
   */
  nextFunction = () => {
    console.log(this.state.selectedOrgs);
    let t = document.getElementById('zuID'); //初始状态
    let f = document.getElementById('nextID');
    let h = document.getElementById('noID');
    // let h = this.noOrganiza().props.id
    this.props.form.validateFields((err, values) => {
      console.log('err：', err);
      if (!err && this.state.selectedOrgs !== '') {
        this.setState({ style: { display: 'none' } });
        // t.style.display ='none'; //隐藏 =》 然后加载已存在的组织
        // f.style.display ='block'; //显示 =》 已存在的组织
        const text = {
          nameMode: 1,
          name: values.name,
        };
        CUSTOM_LIST(text)
          .then(res => {
            const data = res.data.list.content;
            if (data.length === 0) {
              h.style.display = 'block';
              t.style.display = 'none';
              f.style.display = 'none';
            } else {
              t.style.display = 'none'; //隐藏 => 然后加载已存在的组织
              f.style.display = 'block'; //显示已存在的组织
              const allData = data.map(v =>
                Object.assign({}, v.entity, {
                  fullAddress: v.fullAddress,
                  orgNumber: v.orgNumber,
                  mutrixNumber: v.mutrixNumber,
                  teamNumber: v.teamNumber,
                  rowKey: v.entity.id,
                })
              );
              this.setState({
                organizaData: allData,
              });
            }
          })
          .catch(err => console.log(err));
      } else if (this.state.selectedOrgs === '') {
        message.error('请选择客户');
      }
    });
  };
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  existedOk = () => {
    //新增下一步
    let text = {
      entity: {
        orgId: this.state.selectedOrgs,
        customId: this.state.existedId, //组织已存在的Id
        startDate: this.state.startDate + ' ' + '00:00:00',
        endDate: this.state.endDate + ' ' + '00:00:00',
      },
      mutrixIds: this.state.selectedItems,
    };
    CUSTOM_RELATION_ADD(text).then(res => {
      console.log(res);
      if (res.code === 0) {
        message.success('新增成功');
        // jumpTo("/pages/orgManage/organizationManage")
        jumpTo(
          '/pages/orgManage/customerDetails?id=' + this.state.selectedOrgs
        );
      }
    });
  };
  existedCancel = () => {
    let t = document.getElementById('zuID');
    let f = document.getElementById('nextID');
    t.style.display = 'block';
    f.style.display = 'none';
  };
  /**
   * 第二步（组织名已经存在）
   * @returns {*}
   */
  nextLoading = () => {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`);
        console.log('selectedRows: ', selectedRows);
        this.setState({ existedId: selectedRows[0].id });
      },
    };
    const column = [
      {
        key: '1',
        title: '组织名称',
        dataIndex: 'name',
        width: '12%',
      },
      {
        key: '2',
        title: '联系人',
        dataIndex: 'contactName',
      },
      {
        key: '3',
        title: '联系电话',
        dataIndex: 'contactPhone',
        // render: (text, record, index) => {
        //     return (
        //         <span>{index + 1 + ((this.state.pagination.current - 1) * this.state.pagination.pageSize)}</span>
        //     )
        // }
      },
      {
        key: '4',
        title: '地区',
        dataIndex: 'fullAddress',
      },
      {
        key: '5',
        title: '详细地址',
        dataIndex: 'address',
      },
      {
        key: '6',
        title: '所属客户',
        dataIndex: 'orgNumber',
      },
      {
        key: '7',
        title: '授权工场',
        dataIndex: 'mutrixNumber',
      },
      {
        key: '8',
        title: '球队数量',
        dataIndex: 'teamNumber',
      },
    ];
    return (
      <div id="nextID" style={{ display: 'none' }}>
        <p
          style={{
            marginBottom: '20px',
            fontSize: '18px',
            background: '#f3f4f8',
            padding: '8px 5px',
          }}
        >
          该组织已存在
        </p>
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.organizaData}
          rowSelection={rowSelection}
          onChange={this.handleTableChange}
          rowKey={record => record.key}
        />
        <div className={styles['next-btn']}>
          <Button type="primary" onClick={this.existedOk}>
            完成
          </Button>
          <Button style={{ marginLeft: '15px' }} onClick={this.existedCancel}>
            {' '}
            取消
          </Button>
        </div>
      </div>
    );
  };
  /**
   * 【完成】新增组织不存在
   */
  onValidateForm = () => {
    //提交完成// const {form: { validateFields },} = this.props;
    console.log(this.state.modalData);
    const allData = this.state.modalData;
    const { validateFields } = this.props.form;
    validateFields(
      ['name', 'contactName', 'contactPhone', 'region', 'address', 'province'],
      (errors, values) => {
        console.log(values);
        if (values.contactName === undefined || values.contactName === '') {
          message.error('请输入联系人');
        } else if (
          values.contactPhone === undefined ||
          values.contactPhone === ''
        ) {
          message.error('请输入联系电话');
        } else if (Object.keys(allData).length == 0) {
          message.error('请选择地区');
        } else if (values.address === undefined || values.address === '') {
          message.error('请输入详细地址');
        } else if (this.state.stateValue === '') {
          message.error('请选择状态');
        } else {
          let body = {
            entity: {
              //"orgId": this.state.selectedOrgs,
              //"endDate":this.state.time,
              //"mutrixId":'',  //工场ID
              name: values.name, //组织名
              contactName: values.contactName, // 联系人
              contactPhone: values.contactPhone,
              province: parseInt(this.state.modalData.province),
              city: parseInt(this.state.modalData.city),
              region: parseInt(this.state.modalData.region),
              address: values.address,
              valid: this.state.stateValue,
            },
          };
          console.log(body);
          CUSTOM_ADD(body).then(res => {
            console.log(res);
            if (res.code === 0) {
              this.setState({
                orgId: res.data.id,
              });
              let bodyAdd = {
                entity: {
                  orgId: this.state.selectedOrgs,
                  customId: this.state.orgId,
                  startDate: this.state.startDate + ' ' + '00:00:00',
                  endDate: this.state.endDate + ' ' + '00:00:00',
                },
                mutrixIds: this.state.selectedItems,
              };
              CUSTOM_RELATION_ADD(bodyAdd).then(res => {
                console.log(res);
                if (res.code === 0) {
                  message.success('新增成功');
                  // jumpTo("/pages/orgManage/organizationManage")
                  jumpTo(
                    '/pages/orgManage/customerDetails?id=' +
                      this.state.selectedOrgs
                  );
                }
              });
            } else {
              message.error(res.message);
            }
          });
        }
      }
    );
  };
  /**
   * 取消 返回上一步
   */
  onAddCancel = () => {
    let t = document.getElementById('zuID');
    let h = document.getElementById('noID');
    t.style.display = 'block';
    h.style.display = 'none';
  };
  /**
   * 组织名不存在
   */
  noOrganiza = () => {
    const { getFieldDecorator } = this.props.form;
    const RadioGroup = Radio.Group;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <div id="noID" style={{ display: 'none', marginTop: '30px' }}>
        <Form {...formItemLayout}>
          <Form.Item label="组织名称" className="organizationName">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: '请输入组织名称',
                },
              ],
            })(<Input disabled />)}
          </Form.Item>
          <Form.Item label="联系人" className="organizationName nameless">
            {getFieldDecorator('contactName', {
              rules: [
                {
                  required: false,
                  message: '请输入联系人',
                },
              ],
              validateTrigger: 'onBlur',
            })(<Input />)}
          </Form.Item>
          <Form.Item label="联系电话" className="organizationName nameless">
            {getFieldDecorator('contactPhone', {
              rules: [
                {
                  required: false,
                  message: '请输入联系电话',
                },
              ],
              validateTrigger: 'onBlur',
            })(<Input />)}
          </Form.Item>
          <div>
            <Form.Item label="地区:" className="organizationName nameless">
              {getFieldDecorator('province', {
                rules: [
                  {
                    required: false,
                    message: '请选择地区',
                  },
                ],
                validateTrigger: 'onBlur',
              })(
                <GeoForm
                  regionId={this.state.modalData.region}
                  provinceId={this.state.modalData.province}
                  cityId={this.state.modalData.city}
                  updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
                />
              )}
            </Form.Item>
          </div>
          <Form.Item label="详细地址" className="organizationName nameless">
            {getFieldDecorator('address', {
              rules: [
                {
                  required: false,
                  message: '请输入详细地址',
                },
              ],
              validateTrigger: 'onBlur',
            })(<Input />)}
          </Form.Item>
          <div>
            <span className={styles['teamSelect']}>状态</span>
            <RadioGroup
              onChange={this.stateChange}
              value={this.state.stateValue}
            >
              <Radio value={true}>启用</Radio>
              <Radio value={false}>禁用</Radio>
            </RadioGroup>
          </div>

          <div className={styles['next-btn']}>
            <Button type="primary" onClick={this.onValidateForm}>
              完成
            </Button>
            <Button style={{ marginLeft: '20px' }} onClick={this.onAddCancel}>
              取消
            </Button>
          </div>
        </Form>
      </div>
    );
  };
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
  };
  // getData =()=>{
  //     MUTRIX_LISTNAME().then(res=>{
  //         console.log(res.data.list);
  //         this.setState({
  //             data:res.data.list
  //         });
  //     });
  // };

  //选择所属客户
  selectChange = () => {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    if (this.state.selectedOrgs == '') {
      this.setState({
        selectedOrgs: params.get('id'),
      });
    }
    MUTRIX_LIST({ orgId: this.state.selectedOrgs }).then(res => {
      if (res.code === 0) {
        const data = res.data.list.content;
        const theyData = data.map(v =>
          Object.assign({}, v.entity, {
            rowKey: v.entity.id,
          })
        );
        this.setState({ allMutrix: theyData });
      }
    });
  };
  /**
   * 新增刚进来时状态
   */
  newChoice = () => {
    const { Option } = Select;
    //const {  RangePicker } = DatePicker;  //判空
    const { selectedItems } = this.state;
    const { getFieldDecorator } = this.props.form;
    const children = [];
    for (let i = 0; i < this.state.allMutrix.length; i++) {
      children.push(
        <Option
          key={this.state.allMutrix[i].id}
          value={this.state.allMutrix[i].id}
        >
          {this.state.allMutrix[i].name}
        </Option>
      );
    }
    return (
      <div id="zuID">
        <h3
          style={{
            marginBottom: '20px',
            paddingLeft: '5px',
            display: 'inline-block',
          }}
        >
          新增组织
        </h3>
        <div
          style={{
            height: '3px',
            background: '#f0f0f0f0',
            marginBottom: '30px',
          }}
        >
          {' '}
        </div>
        <div>
          <div style={{ display: 'inline-block', paddingTop: '3px' }}>
            <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
              {' '}
              <span style={{ color: '#f5222d', fontSize: '15px' }}>*</span>{' '}
              选择所属客户 :{' '}
            </span>

            <div style={{ display: 'inline-block' }}>
              <ListTitle
                selected={this.state.selectedOrgs}
                data={this.state.allOrgs}
                disabled={this.state.disabled}
                callback={v => {
                  this.setState({ selectedOrgs: v.id }, this.selectChange);
                }}
              />
            </div>
          </div>
          <div
            style={{
              marginBottom: '10px',
              display: 'inline-block',
              width: '70%',
              verticalAlign: 'top',
            }}
          >
            {/*<span style={{color:'rgba(0, 0, 0, 0.85)'}}> <span style={{color:"#f5222d",fontSize:'15px'}}> * </span> 选择签约工场 :  </span>*/}
            <Form>
              <Form.Item label="选择签约工场" className="mutrixName">
                {getFieldDecorator('mutrix', {
                  rules: [
                    {
                      required: true,
                      message: '请选择签约工场',
                    },
                  ],
                })(
                  <Select
                    mode="multiple"
                    placeholder="选择签约的工场"
                    setFieldsValue={selectedItems}
                    onChange={this.organizationChange}
                    style={{ width: '385px' }}
                  >
                    {/*{children}*/}
                    {this.state.allMutrix.map(item => (
                      <Option key={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>

        <div
          className={styles['organiza-input']}
          style={{ marginBottom: '40px', paddingLeft: '25px' }}
        >
          {/*<span>组织名称：</span>*/}
          {/*<Input placeholder="请输入组织名称" onChange={this.inputChange}/>*/}
          <Form>
            <Form.Item label="组织名称" className="organizationName">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: '请输入组织名称',
                  },
                ],
              })(<Input placeholder="请输入组织名称" />)}
            </Form.Item>
            {/**/}
            {/*<RangePicker onChange={this.onChangeTime} locale={locale} />*/}
            <Form.Item label="签约时间：" className="organizationName">
              {getFieldDecorator('startDate', {
                rules: [
                  {
                    required: true,
                    message: '请选择签约时间',
                  },
                ],
              })(
                <DatePicker
                  placeholder="请选择签约时间"
                  onChange={this.onStartTime}
                  locale={locale}
                />
              )}
            </Form.Item>
            <Form.Item label="服务期限：" className="organizationName">
              {getFieldDecorator('endDate', {
                rules: [
                  {
                    required: true,
                    message: '请选择服务期限',
                  },
                ],
              })(
                <DatePicker
                  placeholder="请选择服务期限"
                  onChange={this.onChangeTime}
                  locale={locale}
                />
              )}
            </Form.Item>
          </Form>
        </div>
        <div className={styles['next-btn']}>
          <Button type="primary" onClick={this.nextFunction}>
            下一步
          </Button>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div>
        <this.newChoice />
        {/*下一步组织名已经存在*/}
        <this.nextLoading />
        {/*组织名不存在*/}
        <this.noOrganiza />
      </div>
    );
  }
}

export default Form.create()(organizationAdd);
