//教练List
import React from 'react';
import { Table, Popconfirm, Form, message } from 'antd';
import { COACH_LIST, COACH_DELETE } from '../../app/api';
// import {jumpTo} from "../../router/Router";
// import './organizationManage.module.css';

class CoachList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // API球队查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},

      teamId: 0,
      orgId: 0,
      customId: 0,

      selectCustoms: [],

      selectOrgs: [],

      mainModal: '',
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'id',
        width: '5%',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        key: '2',
        title: '姓名',
        dataIndex: 'name',
        width: '9%',
      },
      {
        key: '3',
        title: '联系电话',
        dataIndex: 'phone',
        width: '10%',
      },
      {
        key: '4',
        title: '性别',
        dataIndex: 'gender',
        width: '5%',
        align: 'center',
        render: (text, record) => {
          return <span>{record.gender == 1 ? '男' : ' 女'}</span>;
        },
      },
      {
        key: '5',
        title: '年龄',
        dataIndex: 'age',
        width: '5%',
        align: 'center',
        // render(birthday) {
        //   const heAge = new Date(birthday);
        //   const day = new Date();
        //   const age =
        //     day.getFullYear() -
        //     heAge.getFullYear() -
        //     (day.getMonth() < heAge.getMonth() ||
        //     (day.getMonth() == heAge.getMonth() &&
        //       day.getDate() < heAge.getDate())
        //       ? 1
        //       : 0);
        //   return age;
        // },
      },
      {
        key: '6',
        title: '地区',
        dataIndex: 'fullAddress',
        width: '12%',
      },
      // {
      //     key:"7",
      //     title: '所属组织',
      //     dataIndex:'orgs',
      //     width:"8%",
      //     align:'center',
      //     render:(text,record)=>{
      //         return  <span>{record.orgs.length}</span>
      //     }
      // },
      {
        key: '8',
        title: '执教球队',
        dataIndex: 'teams',
        width: '8%',
        align: 'center',
        render: (text, record) => {
          return <span>{record.teams.length}</span>;
        },
      },
      {
        key: '9',
        title: '签约类型',
        dataIndex: 'source',
        width: '6%',
        align: 'center',
        render: (text, record) => {
          return <span>{record.source == 100 ? 'APP' : 'web签约'}</span>;
        },
      },
      {
        key: '10',
        title: '状态',
        dataIndex: 'valid',
        width: '6%',
        render: msg => (msg === true ? <span>启用</span> : <span>禁用</span>),
      },
      {
        title: '操作',
        key: 'operation',
        width: '22%',
        render: (text, record) => (
          <span>
            {/*<button onClick={() => jumpTo("/coach/coachDetails",{id:record.id}) }>*/}
            {/*    <Icon type="unordered-list"/>*/}
            {/*    详情</button>*/}
            {/*<Divider type="vertical"/>*/}
            {/*<button onClick = {this.editModal.bind(this,record)}>*/}
            {/*    <Icon type="appstore" style={{color:'#1890ff'}}/>编辑</button>*/}
            <Popconfirm
              title={'删除【' + record.name + '】不可恢复，你确定要删除吗?'}
              onConfirm={() => this.handleDelete(record)}
              okText="确定"
              cancelText="取消"
            >
              <a title="用户删除" href="javascript:;">
                删除
              </a>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }
  // componentWillReceiveProps(nextProps, nextContext) {
  //     let param = {};
  //     let update = false;
  //     if (nextProps.teamId !== this.state.teamId) {  //球队id
  //         param.teamId = nextProps.teamId;
  //         update = true;
  //     }
  //     if (nextProps.customId !== this.state.customId) {  //组织id
  //         param.customId = nextProps.customId;
  //         update = true;
  //     }
  //     if (nextProps.orgId !== this.state.orgId) {  //客户id
  //         param.orgId = nextProps.orgId;
  //         update = true;
  //     }
  //
  //     if (update) {
  //         this.setState({...param}, this.list);
  //     }
  // }
  // 加载数据
  componentWillMount() {
    this.list();
  }
  /**
   * 加载数据API
   */
  list = params => {
    params = params === undefined ? {} : params;
    params.teamId = this.props.teamId;
    params.orgId = this.state.orgId;
    params.customId = this.state.customId;
    COACH_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const allData = data.map(v =>
        Object.assign({}, v.entity, {
          teams: v.teams,
          orgs: v.orgs,
          fullAddress: v.fullAddress,
          rowKey: v.entity.id,
          age: v.age,
        })
      );
      this.setState({
        data: allData,
        pagination,
      });
    });
  };
  /**
   * 编辑弹窗
   */
  editModal = record => {
    this.setState({
      visible: true,
      modalData: record,
      title: '编辑客户',
    });
  };
  /**
   * 删除当前工场行
   */
  handleDelete = record => {
    COACH_DELETE({ id: record.id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.name + '】成功');
          this.list();
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };

  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      pageNum: pagination.current,
    });
  };
  render() {
    // 根据props输入的数组进行过滤和筛选
    let inputColumns = [];
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            rowKey: record.key,
          }),
        };
      }
    );
    console.log(this.state.data);
    return (
      <div>
        {/*<this.processModal />*/}
        {/* 表格 */}
        <Table
          columns={column}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
          bordered={true}
          rowKey={record => record.key}
        />
      </div>
    );
  }
}
export default Form.create()(CoachList);
