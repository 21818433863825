//接口统一管理
import { post, upload, get } from './http';
let api = '';
if (process.env.NODE_ENV === 'development') {
  api = '/api';
}
//登录
export const LOGIN = params => post(api + '/web/home/login', params);
//登录选择客户   /web/home/chooseOrg?id=1
export const CHOOSEORG = params => post(api + '/web/home/chooseOrg', params);

//登录后导航菜单
// http://10.0.1.20:8071/web/menu/list
export const MENULIST = params => post(api + '/web/menu/list', params);
//登录选择工场
export const CHOOSEMUTRIX = params =>
  post(api + '/web/home/chooseMutrix', params);

// 登录后查询我是谁
export const WHOAMI = params => post(api + '/web/home/whoAmI', params);

//退出登录
export const logout = params => post(api + '/web/home/logout', params);
//获取验证码
export const verifyCode = params => post(api + '/web/home/verifyCode', params);
//找回密码
export const changePasswordByCode = params =>
  post(api + '/web/home/changePasswordByCode', params);
export const CHANGEPASSWORD = params =>
  post(api + '/web/home/changePassword', params);
//用户协议
export const PROTOCOL = params => get(api + '/common/protocol', params);
export const DISCLAIMER = params => get(api + '/common/disclaimer', params);
export const PRIVACY = params => get(api + '/common/privacy', params);
//省市县
export const GEO_PROVINCE = params =>
  post(api + '/common/geo/findAllProvince', params);

//国家   /common/geo/findAllNationality
export const GEO_NATIONALITY = params =>
  post(api + '/common/geo/findAllNationality', params);

export const GEO_CITY = params => post(api + '/common/geo/findAllCity', params);
export const GEO_REGION = params =>
  post(api + '/common/geo/findAllRegion', params);
//上传头像
export const COACH_UPLOADPORTRAIT = (params, body) =>
  post(api + '/web/coach/uploadPortrait', params, body);
export const FOOTBALLER_UPLOADPORTRAIT = (params, body) =>
  post(api + '/web/footballer/uploadPortrait', params, body);
export const HOME_UPLOADPORTRAIT = body =>
  post(api + '/web/home/uploadPortrait', undefined, body);

export const COURSE_LIST = params => post(api + '/web/admin/lists', params); //临时处理/web/

// 管理员管理
export const ACCOUNT_LIST = params => post(api + '/web/admin/list', params);
export const ACCOUNT_QUERY = params => post(api + '/web/admin/query', params);
export const ACCOUNT_DELETE = params => post(api + '/web/admin/delete', params);
export const ACCOUNT_STATUS = params =>
  post(api + '/web/admin/changeStatus', params);
export const ACCOUNT_ADD = body =>
  post(api + '/web/admin/add', undefined, body);
export const ACCOUNT_MODIFY = body =>
  post(api + '/web/admin/modify', undefined, body);
// 2.0的组织管理 ==改名为==>3.0客户管理
export const ORGANIZATION_DASHBOARD = params =>
  post(api + '/web/organization/dashboard', params);
export const ORGANIZATION = params =>
  post(api + '/web/organization/list', params);
export const ORGANIZATION_QUERY = params =>
  post(api + '/web/organization/query', params);
export const ORGANIZATION_DELECT = params =>
  post(api + '/web/organization/delete', params);
//export const ORGANIZATION_STATUS = params => post(api + '/web/organization/changeStatus', params);
export const ORGANIZATION_ADD = body =>
  post(api + '/web/organization/add', undefined, body);
export const ORGANIZATION_MODIFY = body =>
  post(api + '/web/organization/modify', undefined, body); //修改
export const ORGANIZATION_LISTNAME = params =>
  post(api + '/web/organization/listName', params);

// 教练管理
export const COACH_LIST = params => post(api + '/web/coach/list', params);
export const COACH_QUERY = params => post(api + '/web/coach/query', params);
export const COACH_DELETE = params => post(api + '/web/coach/delete', params);
export const COACH_STATUS = params =>
  post(api + '/web/coach/changeStatus', params);
export const COACH_ADD = body => post(api + '/web/coach/add', undefined, body);
export const COACH_MODIFY = body =>
  post(api + '/web/coach/modify', undefined, body);
export const COACH_DATE = params => post(api + '/web/data/listCourse', params);

// 2.0的客户管理==改名为==> 3.0的组织管理
export const CUSTOM_LIST = params => post(api + '/web/custom/list', params);
export const CUSTOM_QUERY = params => post(api + '/web/custom/query', params);
export const CUSTOM_DELETE = params => post(api + '/web/custom/delete', params);
export const CUSTOM_STATUS = params =>
  post(api + '/web/custom/changeStatus', params);
export const CUSTOM_ADD = body =>
  post(api + '/web/custom/add', undefined, body);
export const CUSTOM_RELATION_ADD = body =>
  post(api + '/web/custom/relation/add', undefined, body);
export const CUSTOM_MODIFY = body =>
  post(api + '/web/custom/modify', undefined, body);
export const CUSTOM_LISTNAME = params =>
  post(api + '/web/custom/listName', params);
export const CUSTOM_RELATION_LIST = params =>
  post(api + '/web/custom/relation/list', params);
export const CUSTOM_RELATION_DELETE = params =>
  post(api + '/web/custom/relation/delete', params);
// 球队管理
export const TEAM_LIST = params => post(api + '/web/team/list', params);
export const TEAM_QUERY = params => post(api + '/web/team/query', params);
export const TEAM_DELECT = params => post(api + '/web/team/delete', params);
export const TEAM_STATUS = params =>
  post(api + '/web/team/changeStatus', params);
export const TEAM_ADD = body => post(api + '/web/team/add', undefined, body);
export const TEAM_MODIFY = body =>
  post(api + '/web/team/modify', undefined, body);
export const TEAM_LISTNAME = params => post(api + '/web/team/listName', params);
export const DELETE_RELATION = params =>
  post(api + '/web/team/deleteRelation', params);

// 球员管理
export const FOOTBALLER_LIST = params =>
  post(api + '/web/footballer/list', params);
export const FOOTBALLER_QUERY = params =>
  post(api + '/web/footballer/query', params);
export const FOOTBALLER_DELETE = params =>
  post(api + '/web/footballer/delete', params);
export const FOOTBALLER_STATUS = params =>
  post(api + '/web/footballer/changeStatus', params);
export const FOOTBALLER_ADD = body =>
  post(api + '/web/footballer/add', undefined, body);
export const FOOTBALLER_MODIFY = body =>
  post(api + '/web/footballer/modify', undefined, body);
export const FOOTBALLER_SUMMARY = params =>
  post(api + '/web/footballer/getSummary', params);
export const EXPORTTEMPLATE = params =>
  get(api + '/web/footballer/exportTemplate', params); //下载
export const IMPORTFILE = (params, body) =>
  post(api + '/web/footballer/importFile', params, body); //上传
export const FOOTBALL_DELETERELATION = (params, body) =>
  post(api + '/web/footballer/deleteRelation', params, body); //上传

// 工场管理
export const MUTRIX_DASHBOARD = params =>
  post(api + '/web/mutrix/dashboard', params);
export const MUTRIX_LIST = params => post(api + '/web/mutrix/list', params);
export const MUTRIX_QUERY = params => post(api + '/web/mutrix/query', params);
export const MUTRIX_DELETE = params => post(api + '/web/mutrix/delete', params);
export const MUTRIX_STATUS = params =>
  post(api + '/web/mutrix/changeStatus', params);
export const MUTRIX_ADD = body =>
  post(api + '/web/mutrix/add', undefined, body);
export const MUTRIX_MODIFY = body =>
  post(api + '/web/mutrix/modify', undefined, body);
export const MUTRIX_LISTNAME = params =>
  post(api + '/web/mutrix/listName', params);
export const LISTBYORG = params => post(api + '/web/mutrix/listByOrg', params);
export const CHANGEORG = body =>
  post(api + '/web/mutrix/changeOrg', undefined, body);
// 摄像头管理
export const CAMERA_LIST = params => post(api + '/web/mutrix/cam/list', params);
export const CAMERA_QUERY = params =>
  post(api + '/web/mutrix/cam/query', params);
export const CAMERA_DELETE = params =>
  post(api + '/web/mutrix/cam/delete', params);
export const CAMERA_STATUS = params =>
  post(api + '/web/mutrix/cam/changeStatus', params);
export const CAMERA_ADD = body =>
  post(api + '/web/mutrix/cam/add', undefined, body);
export const CAMERA_MODIFY = body =>
  post(api + '/web/mutrix/cam/modify', undefined, body);

// uwb管理
export const UWB_LIST = params => post(api + '/web/mutrix/uwb/list', params);
export const UWB_QUERY = params => post(api + '/web/mutrix/uwb/query', params);
export const UWB_DELETE = params =>
  post(api + '/web/mutrix/uwb/delete', params);
// export const UWB_STATUS = params => post(api + '/web/mutrix/uwb/changeStatus', params);
export const UWB_ADD = body =>
  post(api + '/web/mutrix/uwb/add', undefined, body);
export const UWB_QUERY_ByMutrix = params =>
  post(api + '/web/mutrix/uwb/queryByMutrix', params);
export const UWB_MODIFY = body =>
  post(api + '/web/mutrix/uwb/modify', undefined, body);

// PLC管理
export const MCU_LIST = params => post(api + '/web/mutrix/mcu/list', params);
export const MCU_QUERY = params => post(api + '/web/mutrix/mcu/query', params);
export const MCU_DELETE = params =>
  post(api + '/web/mutrix/mcu/delete', params);
export const MCU_STATUS = params =>
  post(api + '/web/mutrix/mcu/changeStatus', params);
export const MCU_ADD = body =>
  post(api + '/web/mutrix/mcu/add', undefined, body);
export const MCU_MODIFY = body =>
  post(api + '/web/mutrix/mcu/modify', undefined, body);
// PLCMS管理
export const PLCMS_LIST = params =>
  post(api + '/web/mutrix/plcms/list', params);
export const PLCMS_QUERY = params =>
  post(api + '/web/mutrix/plcms/query', params);
export const PLCMS_DELETE = params =>
  post(api + '/web/mutrix/plcms/delete', params);
export const PLCMS_STATUS = params =>
  post(api + '/web/mutrix/plcms/changeStatus', params);
export const PLCMS_ADD = body =>
  post(api + '/web/mutrix/plcms/add', undefined, body);
export const PLCMS_MODIFY = body =>
  post(api + '/web/mutrix/plcms/modify', undefined, body);
// 工场主题管理
export const THEME_LIST = params =>
  post(api + '/web/mutrix/theme/list', params);
export const THEME_QUERY = params =>
  post(api + '/web/mutrix/theme/query', params);
export const THEME_DELETE = params =>
  post(api + '/web/mutrix/theme/delete', params);
export const THEME_ADD = body =>
  post(api + '/web/mutrix/theme/add', undefined, body);
export const THEME_MODIFY = body =>
  post(api + '/web/mutrix/theme/modify', undefined, body);
// 工场主题图片管理
export const THEME_PICTURE_LIST = params =>
  post(api + '/web/mutrix/theme/picture/list', params);
export const THEME_PICTURE_UPLOAD = (params, body) =>
  post(api + '/web/mutrix/theme/picture/uploadBatch', params, body);
export const THEME_PICTURE_DELETE = params =>
  post(api + '/web/mutrix/theme/picture/delete', params);
export const THEME_PICTURE_changeSort = body =>
  post(api + '/web/mutrix/theme/picture/changeSort', undefined, body);

// 水平角
export const PARAM_BILATERAL_LIST = params =>
  post(api + '/web/param/bilateral/list', params);
export const PARAM_BILATERAL_QUERY = params =>
  post(api + '/web/param/bilateral/query', params);
export const PARAM_BILATERAL_DELETE = params =>
  post(api + '/web/param/bilateral/delete', params);
export const PARAM_BILATERAL_STATUS = params =>
  post(api + '/web/param/bilateral/changeStatus', params);
export const PARAM_BILATERAL_ADD = body =>
  post(api + '/web/param/bilateral/add', undefined, body);
export const PARAM_BILATERAL_MODIFY = body =>
  post(api + '/web/param/bilateral/modify', undefined, body);
// 俯仰角
export const PARAM_ELEVATION_LIST = params =>
  post(api + '/web/param/elevation/list', params);
export const PARAM_ELEVATION_QUERY = params =>
  post(api + '/web/param/elevation/query', params);
export const PARAM_ELEVATION_DELETE = params =>
  post(api + '/web/param/elevation/delete', params);
export const PARAM_ELEVATION_STATUS = params =>
  post(api + '/web/param/elevation/changeStatus', params);
export const PARAM_ELEVATION_ADD = body =>
  post(api + '/web/param/elevation/add', undefined, body);
export const PARAM_ELEVATION_MODIFY = body =>
  post(api + '/web/param/elevation/modify', undefined, body);
// 旋转度
export const PARAM_ROTATION_LIST = params =>
  post(api + '/web/param/rotation/list', params);
export const PARAM_ROTATION_QUERY = params =>
  post(api + '/web/param/rotation/query', params);
export const PARAM_ROTATION_DELETE = params =>
  post(api + '/web/param/rotation/delete', params);
export const PARAM_ROTATION_STATUS = params =>
  post(api + '/web/param/rotation/changeStatus', params);
export const PARAM_ROTATION_ADD = body =>
  post(api + '/web/param/rotation/add', undefined, body);
export const PARAM_ROTATION_MODIFY = body =>
  post(api + '/web/param/rotation/modify', undefined, body);
// 速度
export const PARAM_SPEED_LIST = params =>
  post(api + '/web/param/speed/list', params);
export const PARAM_SPEED_QUERY = params =>
  post(api + '/web/param/speed/query', params);
export const PARAM_SPEED_DELETE = params =>
  post(api + '/web/param/speed/delete', params);
export const PARAM_SPEED_STATUS = params =>
  post(api + '/web/param/speed/changeStatus', params);
export const PARAM_SPEED_ADD = params =>
  post(api + '/web/param/speed/add', params);
export const PARAM_SPEED_MODIFY = params =>
  post(api + '/web/param/speed/modify', params);
// 教案
export const COURSE_CONFIG_LIST = params =>
  post(api + '/web/param/course/list', params);
export const COURSE_CONFIG_QUERY = params =>
  post(api + '/web/param/course/query', params);
export const COURSE_CONFIG_DELETE = params =>
  post(api + '/web/param/course/delete', params);
export const COURSE_CONFIG_ADD = body =>
  post(api + '/web/param/course/add', undefined, body);
export const COURSE_CONFIG_MODIFY = body =>
  post(api + '/web/param/course/modify', undefined, body);
// 设备管理
export const FM_FACILITY_LIST = params =>
  post(api + '/web/fm/facility/list', params);
export const FM_FACILITY_QUERY = params =>
  post(api + '/web/fm/facility/query', params);
export const FM_FACILITY_DELETE = params =>
  post(api + '/web/fm/facility/delete', params);
export const FM_FACILITY_ADD = body =>
  post(api + '/web/fm/facility/add', undefined, body);
export const FM_FACILITY_MODIFY = body =>
  post(api + '/web/fm/facility/modify', undefined, body);
// 告警配置
export const FM_CONFIG_LIST = params =>
  post(api + '/web/fm/config/list', params);
export const FM_CONFIG_QUERY = params =>
  post(api + '/web/fm/config/query', params);
export const FM_CONFIG_DELETE = params =>
  post(api + '/web/fm/config/delete', params);
export const FM_CONFIG_ADD = body =>
  post(api + '/web/fm/config/add', undefined, body);
export const FM_CONFIG_MODIFY = body =>
  post(api + '/web/fm/config/modify', undefined, body);
// 告警操作
export const ALARM_LIST = params => post(api + '/web/fm/alarm/list', params);
export const ALARM_PROCESS = params =>
  post(api + '/web/fm/alarm/process', params);
// 字典查询
export const DICTIONARY_LIST = params =>
  post(api + '/common/dictionary/listByType', params);
// 首页的一些查询接口
export const CALENDAR_LIST = params => post(api + '/web/dash/calendar', params);
export const COURSE_USAGE = params =>
  post(api + '/web/dash/courseUsage', params);
// 管理员首页的接口
// 地图的信息的更新
export const SYSTEM_DASH_EVENT = params =>
  post(api + '/web/dash/event', params);
export const SYSTEM_DASH_RECORDS = params =>
  post(api + '/web/dash/records', params);
// 数据查询的一些借口
// 单次训练结果详情
export const DATA_RESULT = params =>
  post(api + '/web/data/getResultInfo', params);
// 按球员/起止时间 查询统计数据(饼图）
export const DATA_STATISTIC = body =>
  post(api + '/web/data/getStatistic', undefined, body);
// 左下角的计划列表
export const DATA_SERIESSUMMARY = body =>
  post(api + '/web/data/getSeriesSummary', undefined, body);

// 下载球员数据
export const PLAYERDATA = params => get(api + '/web/data/download', params);
