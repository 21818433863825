import React from 'react';
import { Col, message, Row, Spin, Icon } from 'antd';
import {
  DICTIONARY_LIST,
  SYSTEM_DASH_EVENT,
  SYSTEM_DASH_RECORDS,
  MUTRIX_LIST,
  CUSTOM_LIST,
  TEAM_LIST,
} from '../../app/api';
import './index.css';
import AlarmList from '../alarmManage/AlarmList';
import RecordList from './RecordList';
// import ImgUrl from "../../assets/img/yellow.gif";
import ImgUrl from '../../assets/img/green.jpg';
import Yellow from '../../assets/img/yello.jpg';
import ListTitle from '../../components/ListTitle/listTiltle';
// import emitter from '../../services/event'
// import svgUrl from '../../assets/img/map.svg';
// http://localhost:3000/homePage/systemHome
const svgUrl = 'http://mutrix.oss-cn-shenzhen.aliyuncs.com/system/map.svg';

class SystemHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      svg: '',
      tt: false,
      timer: undefined,
      running: false,
      udpateTime: '',
      mapData: {
        totalMutrix: 0,
        onlineMutrix: 0,
        onlineUser: 0,
        runningMutrix: 0,
        totalCoach: 0,
        totalFootballer: 0,
      },
      records: [],
      onLine: [],
      selectedStatus: 0,
      priority: 0,
      loading: true,
      selectStatus: [
        { id: 1, name: '待确认' },
        { id: 2, name: '待解决' },
        { id: 3, name: '已解决' },
      ],
      scroll: { y: 240 },
      totalElements: 'none', //接收兄弟组件的值
      BMap: '',
      mutrixData: [],
      latitude: [],
      longitude: [],
      yellowLatitude: [],
      yellowLongitude: [],
      orgsNum: '',
      teamNum: '',
      mutrixNum: '',
    };
  }
  // 加载数据
  componentWillMount() {
    this.queryMutrix(); //查询工场坐标
  }
  componentDidMount() {
    this.querySvg();
    this.loop();
    this.initData();
    this.mapDate();
    this.queryOrgs();
  }
  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }
  /**
   * 查询所有工场，定位到地图上
   */
  queryMutrix = () => {
    MUTRIX_LIST().then(res => {
      const data = res.data.list.content;
      const allData = data.map(v =>
        Object.assign({}, v.entity, {
          rowKey: v.entity.id,
        })
      );
      this.setState({
        mutrixData: allData,
        mutrixNum: data,
      });
      this.state.mutrixData.forEach((item, index) => {
        if (index === 0) {
          this.setState({
            longitude: this.state.mutrixData[0].latitude,
            latitude: this.state.mutrixData[0].longitude,
          });
        } else {
          this.setState({ latitude: item.latitude });
          this.setState({ longitude: item.longitude });
        }
        if (item.valid === false) {
          //不在线的经度纬度工场
          this.setState({
            yellowLatitude: item.latitude,
            yellowLongitude: item.longitude,
          });
        }
        let pic = ImgUrl;
        let icon = new window.BMap.Icon(pic, new window.BMap.Size(60, 50), {
          scale: 5,
          stokeWeight: 1,
          rotation: 180,
          //anchor:new window.BMap.Size(40,15),
          imageOffset: new window.BMap.Size(0, 0),
        });
        let yellow = Yellow;
        let iconYellow = new window.BMap.Icon(
          yellow,
          new window.BMap.Size(60, 50),
          {
            scale: 5,
            stokeWeight: 1,
            rotation: 180,
            imageOffset: new window.BMap.Size(0, 0),
          }
        );
        let yellowMkr = new window.BMap.Marker(
          new window.BMap.Point(
            this.state.yellowLongitude,
            this.state.yellowLatitude
          ),
          { icon: iconYellow }
        );
        this.map.addOverlay(yellowMkr); //将坐标放进来
        let mkr = new window.BMap.Marker(
          new window.BMap.Point(this.state.longitude, this.state.latitude),
          { icon: icon }
        );
        this.map.addOverlay(mkr); //将坐标放进来
      });
    });
  };
  queryOrgs = () => {
    CUSTOM_LIST().then(res => {
      const data = res.data.list.totalElements;
      this.setState({ orgsNum: data });
    });
    TEAM_LIST().then(res => {
      const data = res.data.list.totalElements;
      this.setState({ teamNum: data });
    });
  };
  mapDate = () => {
    //创建地图
    this.map = new window.BMap.Map('orderDetailMap');
    // let point = new window.BMap.Point(104.437115, 39.581634);
    let point = new window.BMap.Point(100.582593, 40.602074);

    this.map.centerAndZoom(point, 5);
    this.map.enableScrollWheelZoom(true);
    this.map.setMapStyleV2({
      styleId: '2f90392f5557eac9f8381f4d65dbce77',
    });
    this.map.addControl(new window.BMap.NavigationControl());
    this.map.addControl(new window.BMap.ScaleControl());
  };

  //异步加载地图

  //此方法不行，适用于dom而已
  // changeSize = () => {
  //   //地图全屏
  //   let showMap = document.getElementById('orderDetailMap');
  //   showMap.style.width = document.documentElement.clientWidth + 'px';
  //   showMap.style.height = document.documentElement.clientHeight + 'px';
  //   //改变大小的时候，div也占满全屏//window.onload = loadScript;
  // };
  querySvg = () => {
    fetch(svgUrl, {
      method: 'GET',
      mode: 'cors',
      //headers: {'Content-Type': 'image/svg+xml'},
    })
      .then(response => {
        if (response.ok) {
          response
            .text()
            .then(text => {
              this.setState({ svg: text });
            })
            .catch(err => {
              message.error(err.message);
            });
        } else {
          message.error("获取数据错误!'");
        }
      })
      .catch(function(e) {
        message.error(e);
      });
  };
  // test = () => {
  //测试
  // let item = document.getElementById('_x3C_gm_x3E_'); //获取svg id
  // let item1 = document.getElementById('_x3C_hz_x3E_'); //获取svg id
  // let pic = require("../../assets/img/circle-yellow.gif");
  // let pic1 = require("../../assets/img/circle-yellow.gif");
  // item.setAttribute("xlink:href", "../../assets/img/circle-red.gif");

  // item.setAttribute("xlink:href", pic);
  // item1.setAttribute("xlink:href", pic1);
  // item.setAttribute("width","30" );
  // item.setAttribute("height", "30");
  // item1.setAttribute("width","30");
  // item1.setAttribute("height","30");
  // item.setAttribute("transform",'translate(310 310)');
  // item1.setAttribute("transform",'translate(286 295)');
  // };

  //全屏显示
  fullScreen = () => {
    let showMap = document.getElementById('fullScreenMap');
    showMap.requestFullscreen();
    if (showMap.mozRequestFullScreen) {
      showMap.mozRequestFullScreen();
      let point = new window.BMap.Point(88.072432, 44.253257);
      this.map.centerAndZoom(point, 6);
    }
    if (showMap.webkitRequestFullscreen) {
      showMap.webkitRequestFullscreen();
      let point = new window.BMap.Point(88.072432, 44.253257);
      this.map.centerAndZoom(point, 6);
    }
    if (showMap.msRequestFullscreen) {
      showMap.msRequestFullscreen();
      let point = new window.BMap.Point(88.072432, 44.253257);
      this.map.centerAndZoom(point, 6);
    }
  };

  loop = () => {
    if (!this.state.running) {
      let timer = setTimeout(() => {
        this.updateDashData();
      }, 2000);
      this.setState({ timer, running: true });
    }
  };
  updateDashData = () => {
    if (this.state.running) {
      Promise.all([SYSTEM_DASH_EVENT(), SYSTEM_DASH_RECORDS()])
        .then(([res1, res2]) => {
          let mapData = res1.data.event;
          let records = res2.data.records.content;
          let onLine = res1.data.monitor; //在线工场判定
          // alarmCount: 0
          // endTime: "2019-06-25 17:35:00"
          // error: false
          // mutrixId: 1
          // online: false
          // running: false
          // startTime: "2019-06-25 17:30:00"
          if (res2.code === 0) {
            this.setState({
              loading: false,
            });
          }
          this.setState({ mapData, records, onLine, updateTime: new Date() });
          // this.setState({ running:false}, () => this.loop());
        })
        .catch(err => {
          message.error(err.message);
        });
      // this.test();
    } else {
    }
  };
  // loadSvg = () => {
  //     return this.state.svg.length > 0 && (
  //             <div id="svgObject" style={{height:'90%'}} ref={r => {
  //                 if ( r ) {
  //                     r.innerHTML = this.state.svg
  //                 }
  //             }}/>
  //         )
  // };

  initData = () => {
    //告警级别
    DICTIONARY_LIST({ type: 1004 }).then(res => {
      this.setState({
        prioritys: res.data.list,
      });
    });
  };
  render() {
    return (
      <div>
        <div className="home-warp">
          {/*<Button type='primary' className='btn-add' onClick={this.test} style={{marginBottom:'10px'}}>当前工场</Button>*/}
          <Row gutter={24} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Col span={10} style={{ paddingRight: '0', display: 'flex' }}>
              <div className="map" id="fullScreenMap">
                <Row
                  style={{
                    height: '20px',
                    display: 'flex',
                    fontsize: '8px',
                    textAlign: 'center',
                    color: '#fff',
                  }}
                >
                  <Col span={8}>
                    <span>
                      当前工场总数{this.state.mutrixNum.length}个&nbsp;
                      <p
                        style={{
                          display: 'inline-block',
                          width: '13px',
                          height: '13px',
                          background: '#FBFD13',
                          verticalAlign: '-15px',
                        }}
                      >
                        {' '}
                      </p>
                    </span>
                  </Col>
                  <Col span={8}>
                    <span>
                      {/*this.state.mapData.onlineMutrix*/}
                      当前在线工场{this.state.mapData.totalMutrix}个&nbsp;
                    </span>
                    <p
                      style={{
                        display: 'inline-block',
                        width: '13px',
                        height: '13px',
                        background: '#04FFA8',
                        verticalAlign: '-15px',
                      }}
                    >
                      {' '}
                    </p>
                  </Col>
                  <Col span={8}>
                    <span>当前故障工场0个 &nbsp;</span>
                    <p
                      style={{
                        display: 'inline-block',
                        width: '13px',
                        height: '13px',
                        background: '#CB4254',
                        verticalAlign: '-15px',
                      }}
                    >
                      {' '}
                    </p>
                  </Col>
                  {/*全屏*/}
                  <p className="fullscreen" onClick={this.fullScreen}>
                    <Icon type="fullscreen" />
                  </p>
                </Row>
                {/*<this.loadSvg />*/}
                <div id="orderDetailMap" style={{ width: '', height: '92%' }}>
                  {' '}
                </div>
                <Row
                  style={{
                    height: '20px',
                    display: 'flex',
                    fontsize: '8px',
                    textAlign: 'center',
                    color: '#fff',
                    paddingTop: '2px',
                  }}
                >
                  <Col span={6}>
                    <span>
                      组织数量:{' '}
                      <span style={{ color: '#D8B222' }}>
                        {this.state.orgsNum}
                      </span>
                      个
                    </span>
                  </Col>
                  <Col span={6}>
                    <span>
                      球队数量:{' '}
                      <span style={{ color: '#D8B222' }}>
                        {this.state.teamNum}
                      </span>{' '}
                      个
                    </span>
                  </Col>
                  <Col span={6}>
                    <span>
                      球员数量:{' '}
                      <span style={{ color: '#D8B222' }}>
                        {' '}
                        {this.state.mapData.totalFootballer}
                      </span>
                      个
                    </span>
                  </Col>
                  <Col span={6}>
                    <span>
                      用户数量:{' '}
                      <span style={{ color: '#D8B222' }}>
                        {' '}
                        {this.state.mapData.onlineUser}
                      </span>
                      个
                    </span>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={14} style={{ paddingLeft: '0' }}>
              <Spin spinning={this.state.loading} tip="Loading...">
                <div>
                  <RecordList
                    updateTime={this.state.updateTime}
                    data={this.state.records}
                    columns={[2, 3, 4, 5, 6, 7, 8]}
                    loading={this.state.loading}
                  />
                </div>
              </Spin>
            </Col>
          </Row>
          {/*<Button type='primary' className='btn-add' onClick={this.test}>当前工场</Button>*/}
        </div>
        <div style={{ marginTop: '20px' }}>
          <div style={{ marginBottom: '20px', background: '#F0F0F0' }}>
            <span style={{ display: 'inline-block', padding: '0 18px' }}>
              告警级别
            </span>
            <ListTitle
              selected={this.state.priority}
              title="全部"
              data={this.state.prioritys}
              callback={v =>
                this.setState({ priority: v.id }, () =>
                  console.log(this.state.priority)
                )
              }
            />
          </div>
          <AlarmList
            mutrixId={this.state.selectedMutrix}
            selected={this.state.id}
            scroll={this.state.scroll}
            priority={this.state.priority}
            onRow={record => {
              return {
                onClick: event => {
                  this.jump(event);
                },
              };
            }}
            columns={[1, 2, 3, 5, 6, 7, 8]}
          />
        </div>
      </div>
    );
  }
}
export default SystemHome;
