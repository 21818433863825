//球员数据
import * as React from 'react';
import ReactEcharts from 'echarts-for-react';
import * as styles from './player.module.css';
import user from '../../assets/img/user.jpg';
import Ranking from '../../assets/img/ranking.jpg';

// import ReactEchartsCore from 'echarts-for-react/lib/core';
// import 'echarts/lib/chart/bar';
// import 'echarts/lib/chart/line';
// import 'echarts/lib/component/tooltip';
// import 'echarts/lib/component/title';
// import 'echarts/lib/component/legend';
// import 'echarts/lib/component/toolbox';
// import 'echarts/lib/component/markPoint';
// import 'echarts/lib/component/markLine';
// import 'echarts/lib/component/polar';
// import 'echarts/lib/component/radar';
// import echarts from 'echarts/lib/echarts';

import { Divider } from 'antd';
import { FOOTBALLER_SUMMARY } from '../../app/api';

class PlayerHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playerId: '',
      football: '',
      data: {
        radar: [],
        footballer: { entity: {}, teams: [], fullAddress: '' },
        ability: { evaluation: '', list: [] },
      },
      radar: {
        indicator: '',
        data: [],
      },
      shes: '',
    };
    this.radarId = 'myRadar';
  }
  // 加载数据
  componentWillMount() {
    // this.query();
  }
  componentDidMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.setState({ playerId: params.get('id') }, this.query);
  }
  /**
   * 查询球员数据
   */
  query = () => {
    let params = { footballerId: this.state.playerId };
    FOOTBALLER_SUMMARY(params)
      .then(res => {
        const data = res.data; //list外层面的详细地址数据
        let radar = this.state.radar;
        if (
          data.radar !== '' ||
          data.radar !== undefined ||
          data.radar !== null
        ) {
          radar.indicator = data.radar.map(v =>
            Object.assign({}, { name: v.name, max: 100 })
          );
          radar.data = data.radar.map(v => v.value);
          this.setState(
            {
              data: data,
              radar: radar,
              football: data.footballer.entity,
            },
            this.update
          );
        }
      })
      .catch(err => console.log(err));
  };
  update = () => {
    console.log(typeof this.state.radar.data); //数据value
    console.log(JSON.stringify(this.state.radar.data)); //数据value
    console.log(this.state.radar.data);
    console.log(this.state.radar.indicator); //name值 和 max
    //this.updateRadar();
  };
  /**
   * 获取图表信息
   */
  getOption = () => {
    let shes = [
      { name: '身体素质', value: 380, max: 100 },
      { name: '球感', value: 290, max: 100 },
      { name: '比赛', value: 450, max: 100 },
      { name: '踢球', value: 300, max: 100 },
      { name: '运球', value: 480, max: 100 },
    ];
    return {
      radar: {
        name: {
          textStyle: {
            color: '#000000',
            borderRadius: 2,
            padding: [-5, 8],
          },
        },
        indicator:
          this.state.radar.indicator == '' || null
            ? shes
            : this.state.radar.indicator,
      },
      splitArea: {
        areaStyle: {
          color: ['#A1F6C0'],
          shadowColor: '#A1F6C0',
          shadowBlur: 10,
        },
      },
      series: [
        {
          type: 'radar',
          data: [
            {
              value: this.state.radar.data,
              name: '',
              areaStyle: {
                normal: {
                  color: '#20C1F6',
                },
              },
              lineStyle: {
                normal: {
                  color: '#4859F6',
                },
              },
            },
          ],
        },
      ],
    };
  };
  onChartReadyCallback = () => {};

  ability = () => {
    if (this.state.data.ability.list == null || '') {
      return (
        <div style={{ paddingLeft: '16%' }}>
          <h3
            style={{
              borderLeft: '4px solid #00a0e9',
              marginTop: '50px',
              marginBottom: '30px ',
              paddingLeft: '8px',
            }}
          >
            各项能力指标
          </h3>
          <h5
            style={{ paddingLeft: '10px', textAlign: 'center', width: '200px' }}
          >
            此球员暂时没有数据指标
          </h5>
        </div>
      );
    } else {
      return (
        <div style={{ paddingLeft: '16%' }}>
          <h3
            style={{
              borderLeft: '4px solid #00a0e9',
              marginTop: '50px',
              marginBottom: '30px ',
              paddingLeft: '10px',
            }}
          >
            各项能力指标
          </h3>
          <div className={styles['ability-box']}>
            {this.state.data.ability.list.map(o => (
              <div className={styles['ability-list']}>
                <span>{o.name}</span>
                <span
                  style={{
                    background:
                      o.value <= 60 && o.value !== 0
                        ? '#d14b5d'
                        : o.value <= 80 && o.value > 60
                        ? '#f7b923'
                        : o.value === 0
                        ? '#cccccc'
                        : '#6ce000',
                  }}
                >
                  {Math.round(o.value)}
                </span>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };
  render() {
    return (
      <div>
        <h3>个人主页</h3>
        <Divider />
        <div className={styles['football-title']}>
          <div className={styles['football-img']}>
            <img
              src={
                this.state.football.portraitUrl == null
                  ? user
                  : this.state.football.portraitUrl
              }
              alt=""
            />
          </div>
          <div className={styles['ranking']}>
            <h1>
              {this.state.data.ability.list.length === 0
                ? 0
                : Math.round(this.state.data.ability.rank.rank)}
              <img src={Ranking} alt="" />
            </h1>
            <p>全国排位名次</p>
          </div>
          <div className={styles['ability']}>
            <h1>
              {' '}
              {this.state.data.ability.score == null
                ? 0
                : Math.round(this.state.data.ability.score)}
              {''}分
            </h1>
            <p>综合能力评分</p>
          </div>
          <div className={styles['radar-chart']}>
            <ReactEcharts
              option={this.getOption()}
              notMerge={true}
              lazyUpdate={true}
              theme={'theme_name'}
              onChartReady={this.onChartReadyCallback}
              style={{
                height: '170px',
                width: '350px',
                margin: '10px 0 0 auto',
              }}
              //onEvents={}
              //opts={}
            />
          </div>
        </div>
        <div className={styles['football-list']}>
          <div className={styles['football-left']}>
            <h1>{this.state.football.name}</h1>
            <h3>能力概述</h3>
            <p>
              <span>
                {this.state.data.ability.evaluation == null
                  ? '此球员暂时没有数据指标'
                  : this.state.data.ability.evaluation}
              </span>
            </p>
          </div>
          <div>
            <this.ability />
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerHome;
