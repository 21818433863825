import * as React from 'react';
import { message, Select } from 'antd';
import { GEO_CITY, GEO_PROVINCE, GEO_REGION } from '../../app/api';

const Option = Select.Option; //省市区

class GeoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceId: '0',
      cityId: '0',
      regionId: '0',
      province: [],
      city: [],
      region: [],
    };
  }
  componentDidMount() {
    if (this.props.provinceId !== undefined) {
      this.state.provinceId = '' + this.props.provinceId;
    }
    if (this.props.cityId !== undefined) {
      this.state.cityId = '' + this.props.cityId;
      console.log(this.props.cityId);
      console.log(this.state.cityId);
    }
    if (this.props.regionId !== undefined) {
      this.setState({
        regionId: '' + this.props.regionId,
      });
      // this.state.regionId = '' + this.props.regionId;
    }

    this.findAllProvince();
    this.findAllCity(this.state.provinceId);
    this.findAllRegion(this.state.cityId);
  }

  findAllProvince() {
    GEO_PROVINCE().then(res => {
      if (res.code === 0) {
        this.setState({
          province: res.data.province,
        });
      } else {
        message.error(res.data.message);
      }
    });
  }

  findAllCity(provinceId) {
    if (provinceId === undefined || provinceId === 0) {
      this.setState({ city: [] });
      return;
    }
    GEO_CITY({ provinceId: provinceId }).then(res => {
      if (res.code === 0) {
        this.setState({
          city: res.data.city,
        });
      } else {
        message.error(res.data.message);
      }
    });
  }

  findAllRegion(cityId) {
    if (cityId === undefined || cityId === 0) {
      this.setState({ region: [] });
      return;
    }
    GEO_REGION({ cityId: cityId }).then(res => {
      if (res.code === 0) {
        this.setState({
          region: res.data.region,
        });
      } else {
        message.error(res.data.message);
      }
    });
  }

  handleProvinceChange = (value, key) => {
    this.setState({ provinceId: value });
    this.setState({ cityId: '0' });
    this.setState({ regionId: '0' });
    this.props.updateGeoForm({
      province: value,
      provinceName: key.props.children,
      city: 0,
      region: 0,
    });
    this.findAllCity(value);
  };

  handleCityChange = (value, key) => {
    this.setState({ cityId: value });
    this.setState({ regionId: '0' });
    this.props.updateGeoForm({
      city: value,
      cityName: key.props.children,
      region: 0,
    });
    this.findAllRegion(value);
  };

  handleRegionChange = (value, key) => {
    this.setState({ regionId: value });
    this.props.updateGeoForm({ region: value, regionName: key.props.children });
  };

  render() {
    return (
      <div style={{ marginBottom: '3px', display: 'inline-block' }}>
        {/*<span style={{display:"inline-block",width:'80px',textAlign:'right',color:'rgba(0, 0, 0, 0.85)',paddingRight:'10px'}}>地区</span>*/}
        <Select
          value={this.state.provinceId}
          style={{ width: 110, marginRight: 8 }}
          onChange={this.handleProvinceChange}
        >
          <Option key="0">请选择省份</Option>
          {this.state.province.map(province => (
            <Option key={province.id}>{province.name}</Option>
          ))}
        </Select>
        <Select
          value={this.state.cityId}
          style={{ width: 110, marginRight: 8 }}
          onChange={this.handleCityChange}
        >
          <Option value="0">请选择城市</Option>
          {this.state.city.map(city => (
            <Option key={city.id}>{city.name}</Option>
          ))}
        </Select>
        <Select
          value={this.state.regionId}
          style={{ width: 110, marginRight: 8 }}
          onChange={this.handleRegionChange}
        >
          <Option value="0">请选择地区</Option>
          {this.state.region.map(region => (
            <Option key={region.id}>{region.name}</Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default GeoForm;
