/*
 * action 按钮点击事件触发行为类型
 */
export const type = {
  SWITCH_MENU: 'SWITCH_MENU',
};

// 菜单点击切换，修改面包屑名称
export function switchMenu(menuName, key) {
  // console.log('menuName',menuName)
  // console.log('key',key)
  //点击switch按钮触发面包雪菜单
  return {
    type: type.SWITCH_MENU,
    menuName,
    key,
  };
}
