import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Input,
  Icon,
  Form,
  Checkbox,
  message,
  Modal,
  Radio,
} from 'antd';
import logo from '../../assets/img/motou2.jpg';
import logo1 from '../../assets/img/logo2.png';
import back2 from '../../assets/img/form-bg.jpg';
import * as styles from './App.module.css';
import { LOGIN, PROTOCOL, CHOOSEORG, CHOOSEMUTRIX } from '../../app/api';
import { jumpTo, jumpGo } from '../../router/Router';
import { connect } from 'react-redux';
import Statement from '../../components/Statement/statement';
// const ThemeContext = createContext();

// const ThemeContext = React.createContext({
//   theme:she,
// });
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, //选择客户组织
      mutrixList: [],
      checkedList: '',
      title: '选择工场',
      exampleData: [],
      treaty: false,
      roleid: '', //登录用户的id
      name: '', //用户名
      checked: true,
      protocol: '',
      checkedPassword: '',
      userName: '',
      passWord: '',
    };
  }

  componentDidMount () {
    this.GetCookie();
  }

  /**
   *  登录
   * @param e
   */
  postMessage = e => {
    e.preventDefault(); //阻止按钮提交  为onSubmit的方式
    this.props.form.validateFields((err, values) => {
      if (this.state.checked === true && !err) {
        LOGIN({ account: values.username, password: values.password })
          .then(res => {
            this.setState({
              exampleData: res, //赋值到state里
              roleid: res.data.roleid,
              name: res.data.account.name,
            });
            if (res.code === 0) {
              let token = res.data.token;
              if (token !== undefined || token.length > 0) {
                localStorage.setItem('token', token);
                localStorage.setItem('roleid', JSON.stringify(res.data.roleid));
                localStorage.setItem('accountId', res.data.account.accountId);
              }

              if (res.data.roleid === 1) {
                jumpTo('/pages/homePage/systemHome'); //跳到系统管理员首页
                message.success('欢迎' + res.data.account.name + '，登录成功');
                // window.location.reload();
                // setTimeout(() => {
                //   jumpGo();
                // }, 3000); //为了改变头像
              } else if (
                res.data.roleid === 2 &&
                res.data.mutrixList.length > 1
              ) {
                //2【工场管理员】，工场管理员登录时需选择登陆的工场。
                this.setState({
                  visible: true,
                  mutrixList: res.data.mutrixList,
                });
              } else if (res.data.roleid === 3) {
                //3【客户管理员】，客户管理员登录时需选择登陆的客户。
                this.setState({
                  visible: false,
                  mutrixList: res.data.orgList,
                });
                jumpTo('/pages/homePage/customHome', {
                  id: this.state.mutrixList[0].id,
                });
                message.success('登录成功');
              } else if (res.data.roleid === 4 && res.data.orgList.length > 1) {
                //4【运营管理员】，运营管理员登录时需选择登陆的客户。
                this.setState({
                  title: '选择客户',
                  visible: true,
                  mutrixList: res.data.orgList,
                });
              } else if (
                res.data.roleid === 2 &&
                res.data.mutrixList.length == 1
              ) {
                //2【工场管理员】当工场只有 1 的时候

                this.setState({ visible: false });
                jumpTo('/pages/homePage/mutrixHome', {});
                message.success('登录成功');
              }
              if (values.remember === true) {
                //如果勾选了记住密码
                this.setCookie(values.username, values.password);
              } else {
                this.setCookie('', '');
              }
            }
          })
          .catch(err => console.log(err)); //http.js拦截器中定义的返回数据
      } else if (this.state.checked === false) {
        message.error('请先勾选“ 我已阅读并接受《MuTrix用户使用协议》”');
      }
    });
  };

  setCookie = (username, password) => {
    let Then = new Date();
    Then.setTime(Then.getTime() + 1866240000000);
    document.cookie =
      'username=' +
      username +
      '%%' +
      password +
      ';expires=' +
      Then.toGMTString();
  };

  GetCookie = () => {
    //获取cookie中的用户名密码  显示到input中
    let nmpsd;
    let nm;
    let psd;
    let cookieString = new String(document.cookie);
    let a = Promise.resolve(cookieString);
    let cookieHeader = 'username=';
    let beginPosition = cookieString.indexOf(cookieHeader);
    cookieString = cookieString.substring(beginPosition);
    let ends = cookieString.indexOf(';');
    if (ends != -1) {
      cookieString = cookieString.substring(0, ends);
    }
    if (beginPosition > -1) {
      nmpsd = cookieString.substring(cookieHeader.length);
      if (nmpsd != '') {
        beginPosition = nmpsd.indexOf('%%');
        nm = nmpsd.substring(0, beginPosition); //获取了 用户名 密码
        psd = nmpsd.substring(beginPosition + 2);
        this.setState({
          userName: nm,
          passWord: psd,
        });
        this.setState({
          checkedPassword: true,
        });
      } else {
        this.setState({
          userName: '',
          passWord: '',
          checkedPassword: false,
        });
      }
    }
  };

  mutrixChange = e => {
    this.setState({
      checkedList: e.target.value,
    });
    // sessionStorage.setItem('mutrix', JSON.stringify(this.state.checkedList))
    sessionStorage.setItem('mutrix', e.target.value);
  };

  loginCancel = () => {
    this.setState({ visible: false });
  };

  loginOk = () => {
    if (this.state.checkedList === '') {
      message.warning('您还没有选择');
    } else {
      if (this.state.roleid === 2) {
        CHOOSEMUTRIX({ id: this.state.checkedList })
          .then(res => {
            if (res.code === 0) {
              jumpTo('/pages/homePage/mutrixHome', {});
              message.success('登录成功');
              // setTimeout(() => {
              //   jumpGo();
              // }, 2300);
            }
          })
          .catch(err => {
            message.error(err);
          });
      } else if (this.state.roleid === 4) {
        //运营管理员
        CHOOSEORG({ id: this.state.checkedList }).then(res => {
          if (res.code === 0) {
            jumpTo('/pages/homePage/customHome', {});
            message.success('登录成功');
            // setTimeout(() => {
            //   jumpGo();
            // }, 2300);
          }
        });
      }
    }
  };

  meChange = e => {
    this.setState({
      checked: e.target.checked,
    });
  };

  agree = () => {
    this.setState({
      treaty: false,
    });
  };
  userTreaty = () => {
    //用户协议DISCLAIMER  PRIVACY
    PROTOCOL({}).then(res => {
      this.setState({ protocol: res.data.text, treaty: true });
    });
  };

  //记住密码一系列操作
  changePassword = e => {
    // console.log('勾选记住密码：', e.target.checked);
    this.setState({
      checkedPassword: e.target.checked,
    });
  };
  render () {
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    return (
      <div className={styles['App']}>
        <header className={styles['App-body']}>
          <video autoPlay loop className={styles['video-1']} muted>
            <source
              src="http://mutrix.oss-cn-shenzhen.aliyuncs.com/system/mutrix1.mp4"
              type="video/mp4"
            />
          </video>
          <img
            src="http://mutrix.oss-cn-shenzhen.aliyuncs.com/system/frontpage-background.png"
            alt="木头科技"
            className={styles['back-img']}
          />
          <img src={logo1} alt="木头科技" className={styles['logo-img']} />
          <div className={styles['head']}>
            <div className={styles['login_box']}>
              <img src={logo} className={styles['App-logo']} alt="logo" />
              <img src={back2} className={styles['App-back']} alt="" />
              <Form onSubmit={this.postMessage} className={styles['login-ok']}>
                <Form.Item>
                  {getFieldDecorator('username', {
                    rules: [{ required: true, message: '请输入用户名' }],
                    initialValue: this.state.userName,
                  })(
                    <Input
                      size="large"
                      autoComplete="off"
                      setfieldsvalue={this.state.userName}
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="用户名"
                    />
                  )}
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: '请输入密码' }],
                    initialValue: this.state.passWord,
                  })(
                    <Input.Password
                      size="large"
                      autoComplete="off"
                      setfieldsvalue={this.state.passWord}
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="密码"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {/*setfieldsValue={this.state.checkedPassword}*/}
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: this.state.checkedPassword,
                  })(
                    <Checkbox
                      style={{ color: '#ffffff' }}
                      onChange={this.changePassword}
                    >
                      记住密码 <span>Remember my password</span>
                    </Checkbox>
                  )}
                </Form.Item>
                <div>
                  <Button
                    block
                    type="primary"
                    size="large"
                    htmlType="submit"
                    className={styles['login-form-button']}
                  >
                    登录 (Login)
                  </Button>
                </div>
              </Form>

              <div className={styles['login-ok-bottom']}>
                <div style={{ marginTop: '12px', fontSize: '14px' }}>
                  <Checkbox
                    style={{ color: '#ffffff' }}
                    checked={this.state.checked}
                    onChange={this.meChange}
                    value={true}
                  >
                    {' '}
                    我已阅读并接受{' '}
                  </Checkbox>
                  <button>
                    <span
                      style={{ color: '#1890ff', cursor: 'pointer' }}
                      onClick={this.userTreaty.bind(this)}
                    >
                      《MuTrix用户使用协议》
                    </span>
                  </button>
                </div>
                <p style={{ color: '#ffffff', fontSize: '14px' }}>
                  {' '}
                  I have accepted MuTrix user agreements{' '}
                </p>
                <div style={{ textAlign: 'center' }} className={styles['sign']}>
                  <Link to="/pages/findPassword">
                    <span>忘记密码或首次登录</span>
                    <p>Forgot my password or first time login</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Statement />
          <Modal
            title={this.state.title}
            visible={this.state.visible}
            onOk={this.loginOk}
            onCancel={this.loginCancel}
            destroyOnClose={true}
            footer={[
              <Button key="back" onClick={this.loginCancel}>
                取消
              </Button>,
              <Button key="submit" type="primary" onClick={this.loginOk}>
                确定
              </Button>,
            ]}
          >
            <div>
              <div className="checkbox-organization">
                {/*<Radio.Group*/}
                {/*  options={this.state.mutrixList.map((o,key) => o.name)}*/}
                {/*  onChange={(key)=>this.mutrixChange(key)}*/}
                {/*  // value={this.state.checkedList}*/}
                {/*  // value={this.state.checkedList}*/}
                {/*  className={styles['radioStyle']}*/}
                {/*>*/}
                {/*  {' '}*/}
                {/*</Radio.Group>*/}
                <Radio.Group
                  onChange={this.mutrixChange}
                  value={this.state.checkedList}
                >
                  {this.state.mutrixList.map(i => {
                    return <Radio value={i.id}>{i.name}</Radio>;
                  })}
                </Radio.Group>
              </div>
            </div>
          </Modal>

          <Modal
            title=""
            className="agreeModel"
            visible={this.state.treaty}
            destroyOnClose={true}
            onCancel={this.agree}
            closable={false}
            // onOk={this.agree}
            footer={[
              <Button key="sub" type="primary" onClick={this.agree}>
                OK
              </Button>,
            ]}
          >
            <TextArea
              style={{ height: '615px', border: 'none', resize: 'none' }}
            >
              {this.state.protocol}
            </TextArea>
          </Modal>
        </header>
        {/*<this.thisfun/>*/}
      </div>
    );
  }
}

// class Header  extends React.Component{
//   render() {
//     return(
//         <ThemeContext.Consumer>
//           {
//             theme => <h1>ThemeContext的值为: {[theme]}</h1>
//           }
//         </ThemeContext.Consumer>
//     )
//   }
//
// }
//
// class AccountCenter extends React.Component{
//   render() {
//     return <Header></Header>
//   }
// }

function mapStateToProps (state) {
  return {
    name: state.name,
  };
}
export default Form.create()(Login);
connect(mapStateToProps)(Login);
