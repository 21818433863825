import React from 'react';
import { DISCLAIMER, PRIVACY } from '../../app/api';
import { Button, Input, Modal } from 'antd';
const beian_logo = require('../../assets/img/beianbgs.png')

class Statement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      treaty: false,
    };
  }
  agree = () => {
    this.setState({
      treaty: false,
    });
  };
  disclaimer = () => {
    DISCLAIMER({}).then(res => {
      console.log(res.data.text);
      this.setState({ protocol: res.data.text, treaty: true });
    });
  };
  privacy = () => {
    PRIVACY({}).then(res => {
      console.log(res.data.text);
      this.setState({ protocol: res.data.text, treaty: true });
    });
  };

  render () {
    const { TextArea } = Input;

    return (
      <div className="footer">
        <div>
          <p>
            <span style={{ color: 'rgb(255, 255, 255)', marginRight: '10px' }}>
              Disclaimer
            </span>
            <a onClick={this.disclaimer.bind(this)}>免责声明</a>
            <span
              style={{ color: 'rgb(255, 255, 255)', margin: '0 10px 0 10px' }}
            >
              |
            </span>
            <a onClick={this.privacy.bind(this)}>隐私政策</a>
            <span style={{ color: 'rgb(255, 255, 255)', marginLeft: '10px' }}>
              Privacy Policy
            </span>
          </p>
          <p style={{ color: 'rgb(255, 255, 255)' }}>
            邮箱: MuTrix@mutoutech.com Tel: 0755-88388398
          </p>
          <div style={{ color: 'rgb(255, 255, 255)' }}>
            深圳木头科技有限公司 Copyright © 2018-至今&nbsp;
            {/* <img src={beian_logo} />&nbsp; */}
            <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备 15090463号</a>
          </div>

          <Modal
            title=""
            className="agreeModel"
            visible={this.state.treaty}
            destroyOnClose={true}
            onCancel={this.agree}
            closable={false}
            // onOk={this.agree}
            footer={[
              <Button key="sub" type="primary" onClick={this.agree}>
                OK
              </Button>,
            ]}
          >
            <TextArea
              style={{ height: '615px', border: 'none', resize: 'none' }}
            >
              {this.state.protocol}
            </TextArea>
          </Modal>
        </div>
      </div>
    );
  }
}
export default Statement;
