//球队List
import React from 'react';
import { Table, Popconfirm, Form, message } from 'antd';
import { TEAM_LIST, TEAM_MODIFY, DELETE_RELATION } from '../../app/api';

class TeamList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // API球队查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},

      coachId: 0,
      orgId: 0,
      customId: 0,
      playerId: 0,

      selectCustoms: [],

      selectOrgs: [],

      mainModal: '',
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'id',
        width: '4%',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        key: '2',
        title: '球队名称',
        dataIndex: 'name',
        width: '12%',
      },
      {
        key: '3',
        title: '教练',
        dataIndex: 'coaches',
        width: '4%',
        render: (text, record) => {
          return <span>{record.coaches.length}</span>;
        },
      },
      {
        key: '4',
        title: '地区',
        dataIndex: 'fullAddress',
        width: '12%',
      },
      // {
      //     key: "9",
      //     title: '队长',
      //     dataIndex: 'leader',
      //     width: "9%",
      //     render:(leader)=>{
      //         return <span>{leader.name == ''? '暂无' : leader.name}</span>
      //     }
      // },
      {
        key: '5',
        title: '球员',
        dataIndex: 'footballerNumber',
        width: '6%',
        render: (text, record) => {
          return (
            <span>
              {record.footballerNumber}
              {/*onClick={() => jumpTo("/player/player",{teamId:record.id})}>{record.footballerNumber}*/}
            </span>
          );
        },
      },
      {
        key: '6',
        title: '所属客户',
        dataIndex: 'orgs',
        width: '10%',
        render: orgs => {
          return <span> {orgs.length} </span>;
        },
      },
      {
        key: '7',
        title: '所属组织',
        dataIndex: 'customs',
        width: '10%',
        render: customs => {
          return <span> {customs.length} </span>;
        },
      },

      {
        key: '8',
        title: '状态',
        dataIndex: 'valid',
        width: '8%',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
      {
        title: '操作',
        key: 'operation',
        width: '13%',
        align: 'center',
        render: (text, record) => (
          <span>
            {/*<a href="javascript:;" onClick={() => jumpTo("/teamManage/teamEdit",{teamId:record.id})}>编辑</a>*/}
            <Popconfirm
              title={'删除【' + record.name + '】不可恢复，你确定要删除吗?'}
              okText="确定"
              cancelText="取消"
              onConfirm={() => this.handleDelete(record)}
            >
              <a title="用户删除" href="javascript:;">
                删除
              </a>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }
  // componentWillReceiveProps(nextProps, nextContext) {
  //     let param = {};
  //     let update = false;
  //     if (nextProps.coachId !== this.state.coachId) {  //教练id
  //         param.coachId = nextProps.coachId;
  //         update = true;
  //     }
  //     if (nextProps.customId !== this.state.customId) {  //组织id
  //         console.log(nextProps);
  //         param.customId = nextProps.customId;
  //         update = true;
  //         console.log(param)
  //     }
  //     if (nextProps.orgId !== this.state.orgId) {  //客户id
  //         param.orgId = nextProps.orgId;
  //         update = true;
  //     }
  //     if (nextProps.footballerId !== this.state.footballerId) {  //球员id
  //         param.footballerId = nextProps.footballerId;
  //         update = true;
  //     }
  //     if (update) {
  //         this.setState({...param}, this.list);
  //     }
  // }
  // 加载数据
  componentWillMount() {
    this.list();
  }
  list = params => {
    console.log(params);
    params = params === undefined ? {} : params;
    params.coachId = this.props.coachId;
    params.footballerId = this.props.footballerId;
    params.orgId = this.props.orgId;
    params.customId = this.props.customId;
    TEAM_LIST(params)
      .then(res => {
        const data = res.data.list.content; //list外层面的详细地址数据
        const pagination = { ...this.state.pagination };
        pagination.pageSize = res.data.list.size;
        pagination.total = res.data.list.totalElements;
        // 后台返回的原始分页从0开始，未做修正，这里需要加1
        pagination.current = res.data.list.number + 1;
        const allData = data.map(v =>
          Object.assign({}, v.entity, {
            rowKey: v.entity.id,
            fullAddress: v.fullAddress,
            footballerNumber: v.footballerNumber,
            orgs: v.orgs,
            customs: v.customs,
            coaches: v.coaches,
            leader: v.leader,
          })
        );
        this.setState({
          data: allData,
          pagination,
        });
      })
      .catch(err => console.log(err));
  };
  /**
   * 编辑弹窗
   */
  editModal = record => {
    this.setState({
      visible: true,
      modalData: record,
      title: '编辑客户',
    });
  };
  /**
   * 删除当前球队和组织的关系
   */
  handleDelete = record => {
    let customId = record.customs[0].id;
    DELETE_RELATION({ teamId: record.id, customId: customId })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.name + '】成功');
          this.list();
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
    // let text ={
    //     "entity": {
    //         "id":this.state.existedId,   //新增的球队ID
    //         "name": this.state.name,  //球队名
    //         "province": parseInt(this.state.province),
    //         "city": parseInt(this.state.city),
    //         "region": parseInt(this.state.region),
    //         "valid": this.state.valid
    //     },
    //     "customs":''
    // };
    // TEAM_MODIFY(text).then(res=>{  //用的编辑球队的接口
    //     console.log(res);
    //     if (res.code === 0) {
    //         message.success('新增成功');
    //         jumpTo("/pages/teamManage/teamManage")
    //     }
    // })
  };

  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
    });
  };
  render() {
    // 根据props输入的数组进行过滤和筛选
    let inputColumns = [];
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            rowKey: record.key,
          }),
        };
      }
    );
    return (
      <div>
        {/*<this.processModal />*/}
        {/* 表格 */}
        <Table
          columns={column}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
          bordered={true}
          rowKey={record => record.key}
        />
      </div>
    );
  }
}

export default Form.create()(TeamList);
