import React from 'react';
import { Button, Form, Tabs } from 'antd';
import AlarmConfig from './AlarmConfig';
import AlarmFacility from './AlarmFacility';
// import McuManage from "./McuManage";
// import CameraManage from "./CameraManage";
// import UwbManage from "./UwbManage";

const TabPane = Tabs.TabPane;
const customer = localStorage.getItem('roleid');
class AlarmDefine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabButtonTitle: '新增定义',
      addModalVisible: false,
      activeKey: '1',
      column:
        customer == 3 || customer == 2
          ? [1, 2, 3, 4, 5, 6]
          : [1, 2, 3, 4, 5, 6, 'operation'],
    };
    this.buttonTitles = { '1': '新增定义', '2': '新增设备', '3': '新增优先级' };
  }

  // 加载数据
  componentWillMount() {}
  componentDidMount() {}

  addMainModal = () => {
    this.setState({ addModalVisible: true });
  };
  onTabChange = activeKey => {
    this.setState({
      activeKey: activeKey,
      tabButtonTitle: this.buttonTitles[activeKey],
    });
  };
  callback = () => {
    this.setState({ addModalVisible: false });
  };

  tabSection = () => {
    this.operations = {}; //设置运营管理员的权限
    if (customer == 3 || customer == 2) {
      this.operations = '';
    } else {
      this.operations = (
        <Button type="primary" onClick={this.addMainModal} className="btn-add">
          {this.state.tabButtonTitle}
        </Button>
      );
    }
    return (
      <Tabs
        onChange={this.onTabChange}
        activeKey={this.state.activeKey}
        tabBarExtraContent={this.operations}
      >
        <TabPane tab="告警定义" key="1">
          <AlarmConfig
            columns={this.state.column}
            addModalVisible={
              this.state.activeKey === '1' && this.state.addModalVisible
            }
            callback={this.callback}
          />
        </TabPane>
        <TabPane tab="告警设备" key="2">
          <AlarmFacility
            columns={[1, 2, 3, 4]}
            addModalVisible={
              this.state.activeKey === '2' && this.state.addModalVisible
            }
            callback={this.callback}
          />
        </TabPane>
      </Tabs>
    );
  };

  render() {
    return (
      <div>
        {/* 标题栏 包括下拉框、新增按钮 等*/}
        <this.tabSection />
      </div>
    );
  }
}

export default Form.create()(AlarmDefine);
