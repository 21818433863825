import * as React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Table,
} from 'antd';
import GeoForm from '../../components/GeoForm'; //引入表单
import '../../styles/common.css';
import './teamManage.module.css';
import { COACH_LIST, ORGANIZATION_LISTNAME, TEAM_QUERY } from '../../app/api';

class TeamEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 页面跳转的账号id，如果为0，是新增；否则为修改
      teamId: 0,
      modalData: {},
      organizationList: [],
      stateValue: '',
      rowDate: '',
      thisData: {},
      theyData: [],
      entityList: '',
      orgsData: [], //组织数据
      coacheList: [], //教练数据
      editOrganization: false, //组织模态框
      indeterminate: true, //checkbox  样式的控制
      checked: false, //checkbox 全选
      checkedList: [], //checkbox 默认值
    };
    //教练的详细数据
    this.columns = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'id',
        editable: 'true',
      },
      {
        key: '2',
        title: '姓名',
        dataIndex: 'name',
        editable: 'true',
      },
      {
        key: '3',
        title: '电话',
        dataIndex: 'phone',
      },
      {
        key: '4',
        title: '地区',
        dataIndex: 'fullAddress',
      },
      {
        key: '5',
        title: '状态',
        dataIndex: 'valid',
        render: msg => (msg === true ? <span>启用</span> : <span>禁用</span>),
      },

      {
        title: '操作',
        dataIndex: 'operation',
        render: (text, record) => (
          <span>
            <a href="javascript:;">编辑</a>
            <Divider type="vertical" />
            <Popconfirm
              title="确定删除吗?"
              onConfirm={() => this.handleDelete(record.key)}
              okText="确定"
              cancelText="取消"
            >
              <a href="javascript:;">删除</a>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }
  componentWillMount() {
    let params = new window.URLSearchParams(
      window.location.search.split('?')[1]
    );
    this.state.teamId = params.get('teamId');
    // this.queryTeam();
    this.query();
    this.queryCoach();
  }
  /**
   * 根据id查询球队信息
   */
  query = () => {
    let params = this.state.teamId != 0 ? { id: this.state.teamId } : {};
    TEAM_QUERY(params).then(res => {
      let rowData = res.data.query;
      if (rowData !== undefined && rowData !== null) {
        this.setState({
          theyData: rowData,
          entityList: rowData.entity,
          orgsData: rowData.orgs,
          // coacheList:rowData.coaches,
          stateValue: rowData.entity.valid,
        });
      }
    });
  };
  /**
   * 城市更新
   */
  updateGeoForm = o => {
    this.setState({
      modalData: { ...this.state.modalData, ...o },
    });
  };
  /**
   * 状态启用禁用
   */
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  handleDelete = key => {
    const dataSource = [...this.state.dataSource];
    this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
  };
  /**
   * 添加同一行教练
   */
  handleAdd = () => {
    console.log('添加教练');
  };
  /**
   * 保存修改
   * @param row
   */
  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };
  /**
   * 查询教练信息
   */
  queryCoach = () => {
    let params = this.state.teamId != 0 ? { teamId: this.state.teamId } : {};
    COACH_LIST(params).then(res => {
      let coachData = res.data.list.content;
      this.setState({
        coacheList: coachData.map(item =>
          Object.assign({}, item.entity, {
            fullAddress: item.fullAddress,
            rowKey: item.entity.id,
            age: item.age,
          })
        ),
      });
    });
  };
  /**
   * 编辑球队信息
   */
  editForm = () => {
    const RadioGroup = Radio.Group;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <h3 style={{ marginBottom: '30px' }}>编辑球队信息</h3>
        <Form>
          <Form.Item label="球队名称" className="organizationName">
            {getFieldDecorator('name', {
              initialValue: this.state.entityList.name,
              rules: [
                {
                  required: true,
                  message: '请输入球队名称',
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Form>
        <Form.Item>
          <span className="teamSelect">所属组织 :</span>
          {this.state.orgsData.map(item => (
            <span key={item.id}>{item.name + '  '}</span>
          ))}
          <Button
            type="primary"
            onClick={this.showOrganization}
            className="edit-btn"
          >
            编辑
          </Button>
        </Form.Item>

        <GeoForm
          regionId={this.state.modalData.region}
          provinceId={this.state.modalData.province}
          cityId={this.state.modalData.city}
          updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
        />

        <div className="team-box">
          <span className="teamSelect">状态</span>
          <RadioGroup onChange={this.stateChange} value={this.state.stateValue}>
            <Radio value={true}>启用</Radio>
            <Radio value={false}>禁用</Radio>
          </RadioGroup>
        </div>

        <div className="coach-title">
          <h3>编辑教练信息</h3>
          <Button
            onClick={this.handleAdd}
            type="primary"
            style={{ marginBottom: 16, textAlign: 'right' }}
          >
            添加教练
          </Button>
        </div>
      </div>
    );
  };

  Cancel = () => {
    this.setState({
      editOrganization: false,
    });
  };

  /**
   * 全选
   */
  organizaAllChange = e => {
    this.setState({
      checkedList: e.target.checked
        ? this.state.organizationList.map(o => o.name)
        : this.state.orgsData.map(item => item.name),
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };
  /**
   * 查询所有组织api
   */
  showOrganization = () => {
    ORGANIZATION_LISTNAME().then(res => {
      this.setState({
        editOrganization: true,
        organizationList: res.data.list,
        checkedList: this.state.orgsData.map(item => item.name),
      });
    });
  };

  /**
   * 选择组织change
   */
  organizaChange = value => {
    this.setState({
      checkedList: value,
      indeterminate:
        !!value.length && value.length < this.state.organizationList.length,
      checkAll: value.length === this.state.organizationList.length,
    });
  };
  /**
   * 确定更改组织
   */
  editOrganizationOK = () => {
    //console.log(this.state.checkedList);  //已经选中的value值
    //console.log(this.state.orgsData);  //显示的值
    let arr = this.state.checkedList;
    arr.forEach((value, index) => {
      arr[index] = {
        name: value,
      };
    });
    this.setState({
      orgsData: arr,
      editOrganization: false,
    });
  };
  /**
   * 编辑所属组织model模态框
   */
  organizationModal = () => {
    return (
      <Modal
        title="请选择组织"
        visible={this.state.editOrganization}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key="1" style={{ textAlign: 'center' }} onClick={this.Cancel}>
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{ textAlign: 'center' }}
            onClick={this.editOrganizationOK}
          >
            确定
          </Button>,
        ]}
      >
        <div style={{ marginBottom: '24px' }}>
          <Checkbox
            indeterminate={this.state.indeterminate}
            onChange={this.organizaAllChange}
            checked={this.state.checkAll}
          >
            全选
          </Checkbox>
        </div>
        <div className="checkbox-organization">
          <Checkbox.Group
            options={this.state.organizationList.map(o => o.name)}
            onChange={this.organizaChange}
            value={this.state.checkedList}
          />
        </div>
      </Modal>
    );
  };

  /**
   * 添加教练Model
   */
  showCoach = () => {};

  render() {
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          rowKey: record.key,
        }),
      };
    });
    return (
      <div>
        <this.editForm />
        <Table columns={columns} dataSource={this.state.coacheList} />
        <this.organizationModal />
      </div>
    );
  }
}

export default Form.create()(TeamEdit);
