import * as React from 'react';
import { Table, Button, Form, message, Radio, Modal, Input } from 'antd';
import { ALARM_LIST, ALARM_PROCESS } from '../../app/api';
import { jumpTo } from '../../router/Router';

const defaultModalData = {
  visible: false,
  title: '处理告警',
  modalData: {
    org: { id: 0, name: '' },
    mutrix: { id: 0, name: '' },
    priority: { id: 0, name: '' },
    equip: { id: 0, name: '' },
  },
};
class AlarmList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMutrixId: 0,
      selectedOrgId: 0,
      selectedStatus: 0,
      selectedPriority: 0,
      // API查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},
      status: '',
      scroll: {},
      mainModal: Object.assign({}, defaultModalData),
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: 'ID',
        dataIndex: 'id',
        width: '6%',
      },
      {
        key: '2',
        title: '告警时间',
        dataIndex: 'startTime',
        width: '12%',
      },
      {
        key: '3',
        title: '工场名称',
        dataIndex: 'mutrixName',
        width: '12%',
      },
      {
        key: '4',
        title: '客户名称',
        dataIndex: 'orgName',
        width: '12%',
      },
      {
        key: '5',
        title: '告警级别',
        dataIndex: 'priority',
        width: '8%',
        align: 'center',
      },
      {
        key: '6',
        title: '告警设备',
        dataIndex: 'equipName',
        width: '10%',
        align: 'center',
      },
      {
        key: '7',
        title: '告警信息',
        dataIndex: 'text',
        width: '20%',
        align: 'center',
      },
      {
        key: '8',
        title: '状态',
        width: '6%',
        dataIndex: 'operateFlag',
        // render:(text, record) => record.operateFlag === 0 ?
        //     <span style={{color:'orange'}}>待确认</span>:
        //     (record.operateFlag === 1 ? <span style={{color:'#f5222d'}}>待解决</span>: <span style={{color:'green'}}>已解决</span>),
        render: operateFlag => {
          return operateFlag === 0 ? (
            <span style={{ color: 'orange' }}>待确认</span>
          ) : operateFlag === 1 ? (
            <span style={{ color: '#f5222d' }}>待解决</span>
          ) : (
            <span style={{ color: 'green' }}>已解决</span>
          );
        },
      },
      {
        title: '操作',
        key: 'operation',
        width: '5%',
        render: (text, record) => (
          <span>
            <button onClick={this.editMainModal.bind(this, record)}>
              处理
            </button>
          </span>
        ),
      },
      {
        title: '备注',
        key: '9',
        dataIndex: 'description',
        width: '15%',
        align: 'center',
      },

      // {
      //   key: '10',
      //   title: 'ID',
      //   dataIndex: 'id',
      //   width: '6%',
      //   render: (text, record) => (
      //       <span>
      //       <button onClick={this.jump.bind(this, record)}>
      //         {text}
      //       </button>
      //     </span>
      //   ),
      // },
    ];
  }
  componentWillReceiveProps(nextProps, nextContext) {
    let param = {};
    let update = false;
    if (nextProps.mutrixId !== this.state.selectedMutrixId) {
      param.selectedMutrixId = nextProps.mutrixId;
      update = true;
    }
    if (nextProps.orgId !== this.state.selectedOrgId) {
      param.selectedOrgId = nextProps.orgId;
      update = true;
    }
    if (nextProps.status !== this.state.selectedStatus) {
      param.selectedStatus = nextProps.status;
      update = true;
    }
    if (nextProps.priority !== this.state.selectedPriority) {
      param.selectedPriority = nextProps.priority;
      update = true;
    }
    if (update) {
      this.setState({ ...param }, this.list);
    }
  }
  componentWillUpdate(nextProps, nextState, nextContext) {}
  // 加载数据
  componentWillMount() {}
  componentDidMount() {
    this.list();
  }

  /**
   * 加载数据API
   */
  list = params => {
    params = params === undefined ? {} : params;
    params.mutrixId = this.props.mutrixId;
    params.orgId = this.state.selectedOrgId;
    params.status = this.state.selectedStatus;

    params.priority = this.state.selectedPriority;
    if (params.priority === 0) {
      params = params;
    }
    ALARM_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const theyData = data.map(v =>
        Object.assign(
          {},
          v.entity,
          {
            rowKey: v.entity.id,
            operateFlag:
              v.entity.operateFlag === undefined || v.entity.operateFlag == null
                ? 0
                : v.entity.operateFlag,
          },
          {
            mutrixName: v.mutrix == null ? '' : v.mutrix.name,
            orgName: v.org == null ? '' : v.org.name,
            org: v.org,
            mutrix: v.mutrix,
            equipName: v.equip == null ? '' : v.equip.name,
            priority: v.priority == null ? '' : v.priority.name,
            logs: v.logs,
          }
        )
      );
      this.setState({
        data: theyData,
        pagination,
      });
    });
  };
  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
    });
  };

  /**
   * 编辑弹窗
   * 如果record.operateFlag=2，则显示详情，不能继续处理
   * 状态标记： 待确认、待解决、已解决
   * 备注：
   * 历史记录 ： 操作时间、操作人员、操作内容等
   *
   */
  editMainModal = record => {
    //点击处理
    console.log(record.operateFlag);
    this.setState({ status: record.operateFlag });
    let mainModal = this.state.mainModal;
    console.log(mainModal);
    mainModal.modalData = record;
    mainModal.visible = true; //处理告警弹框

    this.setState({ mainModal: mainModal });
  };

  handleMainModalCancel = () => {
    let mainModal = this.state.mainModal;
    console.log(mainModal);
    mainModal.modalData.operateFlag = this.state.status;
    mainModal.visible = false;
    this.setState({ mainModal: mainModal });
  };

  handleMainModalOK = () => {
    //确定提交
    let mainModal = this.state.mainModal;
    mainModal.visible = false;
    this.setState({ mainModal: mainModal });
    // 提交数据发起修改了
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        let params = {
          alarmId: mainModal.modalData.id,
          status: mainModal.modalData.operateFlag,
          description: values.description,
        };
        ALARM_PROCESS(params)
          .then(res => {
            message.success(
              this.state.mainModal.title + '【' + values.name + '】成功'
            );
            this.list();
          })
          .catch(err => {
            if (err) {
              message.error(err);
            }
          });
      }
    });
  };
  jump = () => {
    let url = window.location.pathname;
    if (url === '/pages/homePage/systemHome') {
      jumpTo('/pages/alarmManage/alarmManage'); //跳到告警页
    }
  };
  statusChange = e => {
    console.log(e.target.value);
    let mainModal = this.state.mainModal;
    console.log(mainModal.modalData.operateFlag);

    mainModal.modalData.operateFlag = e.target.value;
    this.setState({ mainModal: mainModal });
  };
  /**
   * 处理告警的弹窗
   */
  processModal = () => {
    const { getFieldDecorator } = this.props.form;
    let mainModal = this.state.mainModal;
    const column = [
      {
        key: '1',
        title: '操作时间',
        dataIndex: 'operateTime',
        width: '25%',
      },
      {
        key: '2',
        title: '操作人员',
        dataIndex: 'account',
        width: '10%',
      },
      {
        key: '3',
        title: '操作内容',
        dataIndex: 'operation',
        width: '25%',
      },
    ];
    return (
      <Modal
        title={mainModal.title}
        visible={mainModal.visible}
        closable={false}
        destroyOnClose={true}
        width={600}
        footer={[
          <Button key="back" onClick={this.handleMainModalCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleMainModalOK}>
            确定
          </Button>,
        ]}
      >
        <Form>
          {/**/}
          <Form.Item label="状态标记">
            <Radio.Group
              options={[
                { label: '待确认', value: 0 },
                { label: '待解决', value: 1 },
                { label: '已解决', value: 2 },
              ]}
              // onChange={ (e) => {mainModal.modalData.operateFlag = e.target.value;this.setState({})}}
              onChange={this.statusChange}
              value={mainModal.modalData.operateFlag}
            />
          </Form.Item>
          <Form.Item label="备注">
            {getFieldDecorator('description', {
              initialValue: mainModal.modalData.description, //填充input默认数据
            })(<Input.TextArea rows={4} />)}
          </Form.Item>
        </Form>
        <p style={{ marginButton: '10px', color: '#000000' }}>历史记录：</p>
        <Table
          columns={column}
          bordered={true}
          showHeader={true}
          dataSource={mainModal.modalData.logs}
          pagination={false}
          scroll={{ y: 200 }}
          rowKey={record => record.index}
        />
      </Modal>
    );
  };
  render() {
    // 根据props输入的数组进行过滤和筛选
    let inputColumns = [];
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
      }
    );
    return (
      <div>
        <this.processModal />
        {/* 表格  this.jump.bind(this)  */}
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          // scroll={this.props.scroll}  滚动条
          onChange={this.handlePaginationChange}
          onRow={record => {
            return {
              onClick: event => {
                this.jump(event);
              },
            };
          }}
          rowKey={record => record.index}
        />
      </div>
    );
  }
}

export default Form.create()(AlarmList);
