import * as React from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Table,
} from 'antd';
import {
  ORGANIZATION_LISTNAME,
  TEAM_ADD,
  TEAM_DELECT,
  TEAM_LIST,
  CUSTOM_LISTNAME,
  TEAM_MODIFY,
} from '../../app/api';
import GeoForm from '../../components/GeoForm'; //引入表单
import * as styles from './teamManage.module.css';
// import emitter from '../../services/event'
import { jumpTo } from '../../router/Router';
import ListTitle from '../../components/ListTitle/listTiltle';
import { del } from 'request';

const Option = Select.Option;
class TeamManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 显示模态窗开关
      visible: false,
      // 模态窗类型
      title: '新增球队',
      // API查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},
      // 模态窗的数据存储
      modalData: {},
      organizationList: [],
      selectItems: '',
      pages: '',
      stateValue: true, //状态启用禁用
      organizaName: '',
      addId: '',
      thisData: {},
      teamManageVisible: false, //球员模态框
      teamId: '', //查询球员详细信息id
      allorgs: [], //所有组织的数据
      queryOrganizaId: 0, //查询客户的id
      selectedOrgs: 0, //选中的组织
      source: 1,
      display: 'none',
      color: { color: '#1890ff', display: 'inline-block' },
    };

    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'id',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        key: '2',
        title: '球队名称',
        dataIndex: 'name',
        width: '15%',
      },
      {
        key: '3',
        title: '教练',
        dataIndex: 'coaches',
        width: '8%',
        render: (text, record) => {
          return <span>{record.coaches.length}</span>;
        },
      },
      {
        key: '4',
        title: '地区',
        dataIndex: 'fullAddress',
        width: '12%',
      },
      {
        key: '5',
        title: '球员',
        dataIndex: 'footballerNumber',
        width: '8%',
        render: (text, record) => {
          return (
            <span
              onClick={() =>
                jumpTo('/pages/player/player', { teamId: record.id })
              }
            >
              {record.footballerNumber}
            </span>
          );
        },
      },
      // {
      //   key: '6',
      //   title: '所属客户',
      //   dataIndex: 'orgs',
      //   width: '10%',
      //   render: (text, record) => {
      //     return <span>{record.orgs.length}</span>;
      //   },
      // },
      {
        key: '7',
        title: '所属组织',
        dataIndex: 'customs',
        width: '10%',
        render: (text, record) => {
          return <span>{record.customs.length}</span>;
        },
      },
      {
        key: '8',
        title: '签约类型',
        dataIndex: 'source',
        width: '10%',
        render: (text, record) => {
          return <span>{record.source === 100 ? 'APP' : 'web签约'}</span>;
        },
      },
      {
        key: '9',
        title: '状态',
        dataIndex: 'valid',
        width: '8%',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
      {
        title: '操作',
        key: 'operation',
        width: '23%',
        align: 'center',
        render: (text, record) => (
          <span>
            {/*<a href="javascript:;" onClick={() => jumpTo("/teamManage/teamEdit",{teamId:record.id})}>编辑</a>*/}
            {/*<Popconfirm title={"删除【" + record.name + "】不可恢复，你确定要删除吗?"}*/}
            {/*            onConfirm={() => this.handleDelete(record)}>*/}
            {/*    <a title='用户删除' href="javascript:;">删除</a>*/}
            {/*</Popconfirm>*/}
            <button
              onClick={() =>
                jumpTo('/pages/teamManage/teamDetails', { id: record.id })
              }
            >
              <Icon type="unordered-list" />
              详情
            </button>
            <Divider type="vertical" />
            <button
              onClick={this.editModal.bind(this, record)}
              style={this.state.color}
            >
              <Icon type="edit" style={{ color: '#1890ff' }} />
              编辑
            </button>
          </span>
        ),
      },
    ];
  }
  componentWillMount() {
    this.queryTeam();
    this.organList();
    this.loadingData();
  }
  componentDidMount() {
    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    let s = document.getElementById('title-team');
    if (custmer == 2) {
      s.style.display = 'none';
      this.setState({
        color: { display: 'none' },
      });
    }
  }

  organList = params => {
    if (params === undefined) {
      params = {};
    }
    if (this.state.queryOrganizaId !== 0) {
      params.orgId = this.state.queryOrganizaId;
    }
    if (this.state.selectedOrgs !== 0) {
      params.customId = this.state.selectedOrgs;
    }
    if (this.state.source != 1) {
      params.source = this.state.source;
    } else if (params.source) {
      delete params.source;
    }
    TEAM_LIST(params)
      .then(res => {
        const data = res.data.list.content;
        const pagination = { ...this.state.pagination };
        pagination.pageSize = res.data.list.size;
        pagination.total = res.data.list.totalElements;
        pagination.current = res.data.list.number + 1;
        const allData = data.map(v =>
          Object.assign({}, v.entity, {
            rowKey: v.entity.id,
            footballerNumber: v.footballerNumber,
            fullAddress: v.fullAddress,
            orgs: v.orgs,
            coaches: v.coaches,
            customs: v.customs,
          })
        );
        this.setState({
          data: allData,
          pagination,
        });
      })
      .catch(err => console.log(err));
  };
  /**
   * 路由跳转
   */
  teamEdit = (text, record) => {
    this.props.history.push({
      pathname: '/teamManage/teamEdit',
      state: { thisData: record },
    });
  };
  /**
   * 分页的方法
   */
  handleTableChange = pagination => {
    const paging = pagination.current;
    this.organList({ pageNum: paging });
  };
  /**
   * 编辑弹窗
   */
  editModal = record => {
    this.setState({
      visible: true,
      modalData: record,
      title: '编辑球队',
    });
  };
  /**
   * 编辑取消
   */
  editCancel = () => {
    this.setState({
      visible: false,
    });
  };
  /**
   * 删除，确认
   */
  handleDelete = record => {
    TEAM_DELECT({ id: record.id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.name + '】成功');
          this.organList();
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };
  /**
   * 编辑球队完成，提交表单
   */
  editOk = () => {
    console.log('当前行的数据modalData:', this.state.modalData);
    let this_modalData = this.state.modalData;
    if (
      this.state.modalData.province === 0 ||
      this.state.modalData.city === 0 ||
      this.state.modalData.region === 0
    ) {
      this.setState({
        display: 'block',
      });
    } else {
      this.setState({ display: 'none' });
      this.props.form.validateFields((errors, values) => {
        if (!errors) {
          let body = {
            entity: {
              id: this.state.modalData.id,
              name: values.name,
              province: parseInt(this.state.modalData.province),
              city: parseInt(this.state.modalData.city),
              region: parseInt(this.state.modalData.region),
              valid: this.state.stateValue,
            },
            leader: {
              id: '-1',
              name: '',
            },
            orgs: this_modalData.orgs,
            customs: this_modalData.customs,
            coaches: this_modalData.coaches,
          };
          let api = '';
          if (this.state.title === '新增球队') {
            api = TEAM_ADD;
          } else {
            api = TEAM_MODIFY;
          }
          api(body)
            .then(res => {
              this.setState({ visible: false });
              message.success(this.state.title + '【' + values.name + '】成功');
              this.organList();
            })
            .catch(err => {
              if (err) {
                message.error(err);
              }
            });
        }
      });
    }
  };
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
  };
  /**
   * 查询所有组织
   */
  queryTeam = () => {
    ORGANIZATION_LISTNAME().then(res => {
      this.setState({
        organizationList: res.data.list,
      });
    });
  };
  /**
   * 客户下拉选择查询数据
   */
  organizationChange = key => {
    this.setState({ queryOrganizaId: key }, this.organList);
  };
  /**
   * model里面的组织下拉change
   * @param value
   * @param key
   */
  organizaChange = (value, key) => {
    this.setState({
      organizaName: value,
      addId: key.key,
    });
  };
  /**
   * 查询 组织
   */
  searchOrganization = value => {
    this.organList({ name: value });
  };
  /***
   * 状态启用禁用的方法
   */
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  loadingData = () => {
    CUSTOM_LISTNAME().then(res => {
      this.setState({
        allorgs: res.data.list,
      });
    });
  };
  sourceChange = (value, key) => {
    this.setState(
      {
        source: value,
      },
      () => this.organList()
    );
  };
  // showModel= record =>{
  //     this.setState({
  //         teamManageVisible:true,
  //         teamId:record.id
  //     })
  //     FOOTBALLER_LIST( {teamId:record.id} ).then(res =>{
  //         console.log(res.data.list.content);
  //         const data = res.data.list.content;
  //         //const theyData = data.map(v => Object.assign({},v.entity, {fullAddress:v.fullAddress,mutrixNumber:v.mutrixNumber }));
  //         data.map(v => Object.assign({},v.entity,{}))
  //
  //     })
  //
  //  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { organizationList } = this.state;
    const RadioGroup = Radio.Group;
    const Search = Input.Search;
    const column = this.column.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          rowKey: record.key,
        }),
      };
    });
    return (
      <div>
        <div className={styles['organizationSelect']} id="title-team">
          <ListTitle
            selected={this.state.queryOrganizaId}
            title="全部客户"
            data={organizationList}
            callback={v =>
              this.setState({ queryOrganizaId: v.id }, this.organList)
            }
          />
          {/*({customId: v.id})*/}
          <ListTitle
            selected={this.state.selectedOrgs}
            title="全部组织"
            data={this.state.allorgs}
            callback={v =>
              this.setState({ selectedOrgs: v.id }, this.organList)
            }
          />
          <Select
            defaultValue="全部来源"
            onChange={this.sourceChange}
            style={{ width: 150, marginBottom: '16px', marginRight: '10px' }}
          >
            <Option key={'1'}>全部来源</Option>
            <Option key={'100'}>APP注册</Option>
            <Option key={'0'}>web签约</Option>
          </Select>
          <Col span={5}>
            <Search
              placeholder="请输入球队名称"
              onSearch={this.searchOrganization}
            />
          </Col>
          {/*<Input placeholder="请输入球队名称"*/}
          {/*       onChange={this.getInput}*/}
          {/*       style={{width: 200, marginRight: '16px'}}/>*/}
          {/*<Button type='primary' onClick={this.searchOrganization}> 查询</Button>*/}
          <div className={styles['btn-box']}>
            <Button
              type="primary"
              onClick={() => jumpTo('/pages/teamManage/teamAdd')}
              className={styles['btn-add']}
            >
              新增球队
            </Button>
          </div>
        </div>
        <Modal
          title={this.state.title}
          visible={this.state.visible}
          onOk={this.editOk}
          onCancel={this.editCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={this.editCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.editOk}>
              确定
            </Button>,
          ]}
        >
          <Form>
            <Form.Item label="球队名称" className="organizationName">
              {getFieldDecorator('name', {
                initialValue: this.state.modalData.name,
                rules: [
                  {
                    required: true,
                    message: '请输入球队名称',
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="地区:" className="organizationAddress">
              <GeoForm
                regionId={this.state.modalData.region}
                provinceId={this.state.modalData.province}
                cityId={this.state.modalData.city}
                updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
              />
              <span style={{ color: 'red', display: this.state.display }}>
                请选择省地区
              </span>
            </Form.Item>

            <Form.Item label="状态" className="organizationName">
              {getFieldDecorator('valid', {
                initialValue: this.state.modalData.valid,
                rules: [
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ],
              })(
                <RadioGroup
                  onChange={this.stateChange}
                  setFieldsValue={this.state.stateValue}
                >
                  <Radio value={true}>启用</Radio>
                  <Radio value={false}>禁用</Radio>
                </RadioGroup>
              )}
            </Form.Item>
          </Form>
        </Modal>
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowKey={record => record.key}
        />
      </div>
    );
  }
}
export default Form.create()(TeamManage);
