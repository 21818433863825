import * as React from 'react';
import ReactEcharts from 'echarts-for-react';
// import ReactEchartsCore from 'echarts-for-react/lib/core';
// import 'echarts/lib/chart/bar';
// import 'echarts/lib/chart/line';
// import 'echarts/lib/component/tooltip';
// import 'echarts/lib/component/title';
// import 'echarts/lib/component/legend';
// import 'echarts/lib/component/toolbox';
// import 'echarts/lib/component/markPoint';
// import 'echarts/lib/component/markLine';
// import 'echarts/lib/component/polar';
// import 'echarts/lib/component/radar';
// import echarts from 'echarts/lib/echarts';
// FOOTBALLER_QUERY, ALARM_LIST
import { Col, Row, DatePicker, Tabs} from 'antd';
import {DATA_STATISTIC, } from "../../app/api";
import moment from "moment";

// @see https://www.echartsjs.com/examples/editor.html?c=radar

/**
 * http://localhost:3000/data/statistic?id=2&startDate=2018-12-01&endDate=2019-01-02
 */
const TabPane = Tabs.TabPane;

const PieChip =
    {
        1: [1, 2, 3], // 单人综合
        7: [1, 2, 3], // 对抗模拟
        2: [21, 22, 23], // 守门员
        8: [11, 12, 13], // 对抗VS
        3: [1, 2, 3]// 移动门框
    };

const ColorChip = {
    // 默认
    1: {color:['#afdb76','#9fc9ec','#f4a300','#ef5472'], name:'传接时长'},
    2: {color:['#afdb76','#9fc9ec','#f4a300','#ef5472'],name:'传接质量'},
    3: {color:['#9fc9ec','#f4a300','#ef5472'],name:'传球偏差'},
    // 对抗模拟的色值
    11: {color:['#afdb76','#9fc9ec','#f4a300','#ef5472'],name:'传接时长'},
    12: {color:['#afdb76','#9fc9ec','#f4a300','#ef5472'],name:'传接质量'},
    13: {color:['#9fc9ec','#f4a300','#ef5472'],name:'传球偏差'},
    // 守门员的色值
    21: {color:['#afdb76','#9fc9ec','#f4a300','#ef5472'],name:'命中时长'},
    22: {color:['#afdb76','#f4a300','#ef5472'],name:'扑救质量'},
    23: {color:['#afdb76','#ef5472'],name:'扑救失败'},
};
class FootballerStatistic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playerId: 0,
            activeKey: 1,
            startDate:'',
            endDate:'',
            data:{
                statistic :[],
                footballer:{},
                teams:[]
            }
            ,
            pieInfo : [],
        };
    }

    // 加载数据
    componentWillMount() {
        let params = new URLSearchParams( window.location.search.split( '?' )[ 1 ] );
        let playerId = params.get("id");
        let startDate = params.get("startDate");
        let endDate = params.get("endDate");
        this.setState({playerId:playerId, startDate:startDate, endDate:endDate},  this.query);
    }
    componentDidMount() {
    }


    /**
     { 11:xxx, 12:yyy}
     */
    query = () => {
        let playerId = this.state.playerId;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let body = {footballerId : playerId, startDate:startDate, endDate:endDate};

        DATA_STATISTIC( body ).then(res =>{
            const data = res.data;  //list外层面的详细地址数据
            let pieInfo = {};
            data.pieInfo.forEach(o => pieInfo[o.id] = o.name);
            this.setState({
                data: data, pieInfo:pieInfo});
        })
    }

    changeDate = value => {
        let startDate = moment(value[0]).format('YYYY-MM-DD');
        let endDate = moment(value[1]).format('YYYY-MM-DD');
        let playerId = this.state.playerId;
        this.setState({playerId:playerId, startDate:startDate, endDate:endDate},  this.query);
    }

    getOption = e => {
        let data = [];
        let legend = [];
        let color =[];
        let title ='';
        let balls =0;
        for (let i = 0; i < e.length; i++) {
            let item = e[i];
            let name = this.state.pieInfo[item.chipType];
            color = ColorChip[item.pieType].color;
            title = ColorChip[item.pieType].name;
            balls = item.total;
            data.push({
                value:item.percent,
                name:name,
            });
            legend.push({
                name:name,
                icon: 'circle',
                textStyle: {
                    color: '#777'
                }
            });
        }
        return {
            title : {
                text: '{style1| '+balls+'}\n{style2|球数}\n{style4|}\n{style3|'+title+'}',
                textStyle : {
                    rich:{
                        style1: {
                            color: '#333',
                            lineHeight: 20,
                            fontSize: 16,
                            fontFamily: 'Microsoft YaHei'
                        },
                        style2: {
                            color: '#333',
                            lineHeight: 20,
                            fontSize: 16,
                            fontFamily: 'Microsoft YaHei',
                        },
                        style3: {
                            color: '#888',
                            lineHeight: 20,
                            fontSize: 12,
                        },
                        style4 : {
                            lineHeight: 60,
                            fontSize: 12,
                        }
                    },
                },
                left: 'center',
                bottom: 40,
            },
            color:color,
            tooltip: {
                trigger: 'item',
                formatter: "{b}<br/>{c}%"
            },
            legend: {
                orient: 'horizontal',
                left: 'center',
                bottom: 0,
                width:'50%',
                data: legend,
                textStyle:{
                    width: '100%',
                }
            },
            series: [
                {
                    // name:'传接质量',
                    type:'pie',
                    radius: ['25%', '50%'],
                    avoidLabelOverlap: true,
                    label: {
                        formatter: "{c}%",
                        position:"inside"
                    },
                    data: data,
                }
            ]
        };
    }

    donutSection = e => {
        let chipItem = PieChip[this.state.activeKey];//[1,2,3]

        return e && e.length>0 ? <div>
            <Row>
                {chipItem.map( o=>
                    <Col span={8} >
                        <ReactEcharts
                            option={this.getOption(
                                e.filter(i => i.pieType === o)
                            )}
                            notMerge={true}
                            lazyUpdate={true}
                        />
                    </Col>
                )}
            </Row>
        </div>
        :
        <div>没有数据</div>
            ;
    }

    onTabChange = activeKey => {
        this.setState({ activeKey: activeKey });
    };

    titleSection = () => {
        const dateFormat = 'YYYY-MM-DD';
        return <div style={{textAlign:"center"}}>
            <DatePicker.RangePicker
                defaultValue={[moment(this.state.startDate, dateFormat), moment(this.state.endDate, dateFormat)]}
                format={dateFormat}
                onChange={this.changeDate}
            />
        </div>
    }
    tabSection = () => {
        return <Tabs
            onChange={this.onTabChange}
            activeKey={this.state.activeKey.toString()}
        >
            {this.state.data.statistic && this.state.data.statistic.map(o =>
                <TabPane tab={o.courseTypeName} key={o.courseType}>
                    {this.donutSection(o.list)}
                </TabPane>
            )}
        </Tabs>
    }
    render() {

        return (
            <div>
                <this.titleSection />
                <this.tabSection />
            </div>
        )
    }

}


export default FootballerStatistic;