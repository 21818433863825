import * as React from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
} from 'antd';
import {
  COURSE_CONFIG_ADD,
  COURSE_CONFIG_MODIFY,
  COURSE_CONFIG_QUERY,
  ORGANIZATION_LISTNAME,
} from '../../app/api';
import { jumpBack } from '../../router/Router';

const Option = Select.Option;
const RadioGroup = Radio.Group;

class CourseConfigEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 页面跳转的账号id，如果为0，是新增；否则为修改
      id: 0,
      // 显示模态窗开关
      orgModalVisible: false,
      // API查询的数据存储
      /**
       * {"entity":{"id":1,"name":null,"module":1,"courseType":1,"level":2,"difficulty":1,
       * "special":false,"valid":true,"targetRelation":"正下,侧下","targetDoor":"下层",
       * "sendRelation":"正面,侧面,背面","sendDoor":"下层","upperSendPercent":0.0,"lowerSendPercent":100.0,
       * "upperTargetPercent":0.0,"lowerTargetPercent":100.0,"upperSpeed":"[45-50]","upperElevation":"[0-6]","upperBilateral":"[-4-4]","upperRotation":"","lowerSpeed":"[60-80]","lowerElevation":"[0-6]","lowerBilateral":"[-4-4]","lowerRotation":"","percent":null,"description":""},"module":{"id":1,"name":"训练模式"},"courseType":{"id":1,"name":"单人综合"},
       * "level":{"id":2,"name":"职业级"},"difficulty":{"id":1,"name":"L1"},"organizations":[]}}}
       */
      data: {
        entity: {
          id: 0,
          name: '',
          module: 1,
          courseType: 2,
          level: 3,
          difficulty: 2,
          special: false,
          valid: true,
          targetRelation: '',
          targetDoor: '',
          sendRelation: '',
          sendDoor: '',
          upperSendPercent: undefined,
          lowerSendPercent: undefined,
          upperTargetPercent: undefined,
          lowerTargetPercent: undefined,
          upperSpeed: '',
          upperElevation: '',
          upperBilateral: '',
          upperRotation: '',
          lowerSpeed: '',
          lowerElevation: '',
          lowerBilateral: '',
          lowerRotation: '',
          percent: 0,
          description: '',
        },
        module: {
          id: 0,
          name: '',
        },
        courseType: {
          id: 0,
          name: '',
        },
        level: {
          id: 0,
          name: '',
        },
        difficulty: {
          id: 0,
          name: '',
        },
        organizations: [],
      },
      // 模态窗的数据存储
      // 通过工场listName接口获取的所有工场列表
      organizations: [],

      // 工场弹窗的多选控件
      organizationsCheck: {
        checkedList: [],
        indeterminate: true,
        checkAll: false,
      },
      // 通过组织listName接口获取的所有工场列表
      radioValue: true,
      statusValue: true,
    };
  }
  // 加载数据
  componentWillMount() {
    let params = new window.URLSearchParams(
      window.location.search.split('?')[1]
    );
    this.setState({ id: params.get('id') }, this.query);
    // this.state.id = params.get("id")
  }
  componentDidMount() {}
  query = () => {
    let params = this.state.id !== 0 ? { id: this.state.id } : {};
    COURSE_CONFIG_QUERY(params).then(res => {
      let data = res.data.query;
      if (data !== undefined && data !== null) {
        this.setState({
          data: data,
          radioValue: data.entity.special,
          statusValue: data.entity.valid,
        });
      }
    });
  };
  /**
   * 编辑完成，提交表单保存
   */
  saveAll = () => {
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        let body = this.state.data;
        console.log(body);
        // return
        // sendDoor
        body.entity.phone = values.phone;
        body.entity.username = values.username;
        body.entity.email = values.email;
        body.entity.special = this.state.radioValue;
        body.entity.sendDoor = values.sendDoor;
        body.entity.valid = this.state.statusValue;
        body.entity.targetDoor = values.targetDoor;
        body.entity.sendRelation = values.sendRelation;
        body.entity.targetRelation = values.targetRelation;
        body.entity.lowerSendPercent = values.lowerSendPercent;
        body.entity.lowerTargetPercent = values.lowerTargetPercent;
        body.entity.upperSpeed = values.upperSpeed;
        body.entity.lowerSpeed = values.lowerSpeed;
        body.entity.upperElevation = values.upperElevation;
        body.entity.lowerElevation = values.lowerElevation;
        body.entity.upperBilateral = values.upperBilateral;
        body.entity.lowerBilateral = values.lowerBilateral;
        body.entity.upperRotation = values.upperRotation;
        body.entity.lowerRotation = values.lowerRotation;
        body.entity.coefficient = values.coefficient;
        body.roleid = this.state.radioValue;
        let title = this.state.id == 0 ? '新增教案' : '修改教案';
        let api = '';
        if (this.state.id === 0) {
          api = COURSE_CONFIG_ADD;
        } else {
          api = COURSE_CONFIG_MODIFY;
        }
        api(body)
          .then(res => {
            this.setState({ visible: false });
            message.success(title + '编辑成功');
            jumpBack();
          })
          .catch(err => {
            if (err) {
              message.error(err);
            }
          });
      }
    });
  };
  /**
   * 处理角色radio事件
   */
  handleRadioChange = e => {
    let data = Object.assign({}, this.state.data, { organizations: [] });
    this.setState({
      radioValue: e.target.value,
      data: data,
    });
  };
  /**
   * 通过后台获取工场列表接口获取数据
   */
  editOrgList = params => {
    // 查询工场列表，并展示
    ORGANIZATION_LISTNAME(params).then(res => {
      this.setState({
        orgModalVisible: true,
        organizations: res.data.list,
      });
      this.handelOrgModalChange(this.state.data.organizations.map(o => o.name));
    });
  };
  /**
   * 处理工场选择列表弹窗中的确认按钮
   */
  handelOrgModalOK = () => {
    // 设置选择的工场 将mutrixCheck.checkedList中的列表，设置到this.state.data中，需要通过this.state.mutrixes 找到到对应的id
    let data = Object.assign({}, this.state.data, { mutrixes: [] });
    this.state.organizations.forEach(o => {
      if (this.state.organizationsCheck.checkedList.indexOf(o.name) !== -1) {
        data.organizations.push(o);
      }
    });
    this.setState({ orgModalVisible: false, data: data });
  };
  /**
   * 处理工场选择列表弹窗中的多选窗的点击事件
   */
  handelOrgModalChange = checkedList => {
    this.setState({
      organizationsCheck: {
        checkedList,
        indeterminate:
          !!checkedList.length &&
          checkedList.length < this.state.organizations.length,
        checkAll: checkedList.length === this.state.organizations.length,
      },
    });
  };
  /**
   * 处理工场选择列表弹窗中的全选的点击事件
   */
  handelOrgModalAllChange = e => {
    this.setState({
      organizationsCheck: {
        checkedList: e.target.checked
          ? this.state.organizations.map(o => o.name)
          : [],
        indeterminate: false,
        checkAll: e.target.checked,
      },
    });
  };
  handleStatusChange = e => {
    this.setState({
      statusValue: e.target.value,
    });
  };

  ChangeValue(e, index) {
    const data = this.state.data;
    data.ballCoefficients[index].coefficient = e;
    console.log(data);
    this.setState({ data });
  }

  mainForm = () => {
    const { getFieldDecorator } = this.props.form;
    const data = this.state.data;
    const formItemLayout = {
      labelCol: {
        xs: { span: 1 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 1 },
        sm: { span: 16 },
      },
    };
    return (
      <Form>
        {/*模式、教案、级别、难度*/}
        <Row type="flex" justify="start" style={{ marginLeft: 20 }}>
          <Col span={2}>{data.module.name}</Col>
          <Col span={2}>{data.courseType.name}</Col>
          <Col span={2}>{data.level.name}</Col>
          <Col span={2}>{data.difficulty.name}</Col>
        </Row>
        <Divider />
        {/*使用组织选择区*/}
        <Form.Item label="使用组织" className="large-form-label">
          {/*{getFieldDecorator ('radioValue',{*/}
          {/*    initialValue:data.entity.special,*/}
          {/*})(*/}
          <RadioGroup
            onChange={this.handleRadioChange}
            value={this.state.radioValue}
          >
            <Radio value={false}>通用</Radio>
            <Radio value={true}>专用</Radio>
          </RadioGroup>
          {/*)}*/}
        </Form.Item>
        {data && this.state.radioValue == true && (
          <div>
            <Form.Item label="全部组织" className="large-form-label">
              <span>{data.organizations.map(o => o.name).join(',')}</span>
            </Form.Item>
            <div className="btn-box" style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={this.editOrgList.bind(this, data.organizations)}
                className="btn-add"
              >
                编辑
              </Button>
            </div>
          </div>
        )}
        <Divider />
        <h3>参数配置</h3>
        <Row>
          <Col span={12}>
            <Form.Item label="发射球门" className="large-form-label">
              {getFieldDecorator('sendDoor', {
                initialValue: data.entity.sendDoor,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="目标球门" className="large-form-label">
              {getFieldDecorator('targetDoor', {
                initialValue: data.entity.targetDoor,
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="发射关联" className="large-form-label">
              {getFieldDecorator('sendRelation', {
                initialValue: data.entity.sendRelation,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="目标关联" className="large-form-label">
              {getFieldDecorator('targetRelation', {
                initialValue: data.entity.targetRelation,
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="发球比例(下层%)" className="large-form-label">
              {getFieldDecorator('lowerSendPercent', {
                initialValue: data.entity.lowerSendPercent,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="目标比例(下层%)" className="large-form-label">
              {getFieldDecorator('lowerTargetPercent', {
                initialValue: data.entity.lowerTargetPercent,
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="上层速度" className="large-form-label">
              {getFieldDecorator('upperSpeed', {
                initialValue: data.entity.upperSpeed,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="下层速度" className="large-form-label">
              {getFieldDecorator('lowerSpeed', {
                initialValue: data.entity.lowerSpeed,
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="上层俯仰角" className="large-form-label">
              {getFieldDecorator('upperElevation', {
                initialValue: data.entity.upperElevation,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="下层俯仰角" className="large-form-label">
              {getFieldDecorator('lowerElevation', {
                initialValue: data.entity.lowerElevation,
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="上层水平角" className="large-form-label">
              {getFieldDecorator('upperBilateral', {
                initialValue: data.entity.upperBilateral,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="下层水平角" className="large-form-label">
              {getFieldDecorator('lowerBilateral', {
                initialValue: data.entity.lowerBilateral,
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label="上层旋转角" className="large-form-label">
              {getFieldDecorator('upperRotation', {
                initialValue: data.entity.upperRotation,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="下层旋转角" className="large-form-label">
              {getFieldDecorator('lowerRotation', {
                initialValue: data.entity.lowerRotation,
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              label="数量难度系数"
              className="large-form-label organizationName"
            >
              {data &&
                data.ballCoefficients &&
                data.ballCoefficients.map((el, index) => (
                  <p>
                    {el.ballNum}球：
                    <InputNumber
                      onChange={e => this.ChangeValue(e, index)}
                      step={0.1}
                      value={el.coefficient}
                      defaultValue={el.coefficient}
                    />
                  </p>
                ))}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="教案难度系数" className="large-form-label">
              {getFieldDecorator('coefficient', {
                initialValue: data.entity.coefficient,
              })(<InputNumber step={0.1} />)}
            </Form.Item>
          </Col>
        </Row>

        {/*状态选择区*/}
        <Form.Item label="状态" className="large-form-label">
          <RadioGroup
            onChange={this.handleStatusChange}
            value={this.state.statusValue}
          >
            <Radio value={true}>启用</Radio>
            <Radio value={false}>禁用</Radio>
          </RadioGroup>
        </Form.Item>
        <div className="btn-box" style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            onClick={this.saveAll.bind(this)}
            className="btn-add"
          >
            保存
          </Button>
        </div>
      </Form>
    );
  };
  /**
   * 组织列表的弹窗
   */
  organizationModal = () => {
    return (
      <Modal
        title="请选择组织"
        visible={this.state.orgModalVisible}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button
            key="submit"
            type="primary"
            style={{ textAlign: 'center' }}
            onClick={this.handelOrgModalOK}
          >
            确定
          </Button>,
        ]}
      >
        <div style={{ marginBottom: '24px' }}>
          <Checkbox
            indeterminate={this.state.organizationsCheck.indeterminate}
            onChange={this.handelOrgModalAllChange}
            checked={this.state.organizationsCheck.checkAll}
          >
            全选
          </Checkbox>
        </div>
        <Checkbox.Group
          options={this.state.organizations.map(o => o.name)}
          onChange={this.handelOrgModalChange}
          value={this.state.organizationsCheck.checkedList}
        />
      </Modal>
    );
  };

  render() {
    return (
      <div>
        {/* 工场选择的弹窗*/}
        {/*<this.mutrixModal />*/}
        {/* 组织选择的弹窗*/}
        <this.organizationModal />
        {/* 主表单区 */}
        <this.mainForm />
      </div>
    );
  }
}

export default Form.create()(CourseConfigEdit);
