import * as React from 'react';
import { Tabs, Table, DatePicker, Upload, Icon, message } from 'antd';
import logo from '../../assets/img/user.jpg';
import * as styles from './coach.module.css';
import TeamList from '../teamManage/teamList';
import moment from 'moment';
import {
  TEAM_LIST,
  COACH_QUERY,
  MUTRIX_LISTNAME,
  TEAM_LISTNAME,
  COACH_DATE,
  COACH_UPLOADPORTRAIT,
} from '../../app/api';
import ListTitle from '../../components/ListTitle/listTiltle';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import Uploadimg from '../../components/upload/uploadImg';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('只能上传JPG/PNG文件!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('图像必须小于2MB!');
  }
  return isJpgOrPng && isLt2M;
}
export default class CoachDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coachId: '',
      data: [], //球队查询数据
      pagination: [], // 分页的数据存储
      entity: '',
      coachDate: '', //教练数据
      fullAddress: '',
      selectedMutrix: 0, //工场id
      allTeam: 0, //全部球队
      selecteDate: 0,
      selectedTeam: 0,
      theyTeamData: '', //教练的球队数据
      picture: {
        selectedThemeId: 0,
        title: '',
      },
      imageUrl: '',
      teamForm: [1, 2, 3, 5, 7, 4, 9, 8], //球队的数据表格
      styles: { display: 'inline-block' },
    };
  }
  componentWillMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.setState({
      coachId: params.get('id'),
    });
    // this.state.coachId = params.get("id");
  }
  componentDidMount() {
    this.queryTeamList();
    this.loadingData();
    this.queryListCourse();
    this.queryCoach();
    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    if (custmer == 2) {
      this.setState({
        styles: { display: 'none' },
      });
    }
  }
  /**
   * 加载教练下的球队表格数据
   */
  teamDateList = () => {
    return (
      <div>
        <TeamList
          coachId={this.state.coachId}
          columns={this.state.teamForm}
          scroll={{ x: 900, y: 100 }}
        />
      </div>
    );
  };
  queryTeamList = () => {
    let params =
      this.state.coachId !== 0 ? { coachId: this.state.coachId } : {};
    TEAM_LIST(params)
      .then(res => {
        const data = res.data.list.content;
        const pagination = { ...this.state.pagination };
        const theyData = data.map(v =>
          Object.assign({}, v.entity, {
            rowKey: v.entity.id,
            fullAddress: v.fullAddress,
            coaches: v.coaches,
          })
        );
        this.setState({
          entity: res.data.list.content.entity,
          data: theyData,
          pagination,
        });
      })
      .catch(err => console.log(err));
  };
  queryCoach = () => {
    let params = this.state.coachId !== 0 ? { id: this.state.coachId } : {};
    COACH_QUERY(params).then(res => {
      this.setState({
        coachDate: res.data.query.entity,
        fullAddress: res.data.query.fullAddress,
        imageUrl: res.data.query.entity.portraitUrl,
      });
    });
  };
  loadingData = params => {
    MUTRIX_LISTNAME(params).then(res => {
      this.setState({
        selectMutrixes: res.data.list,
      });
    });
    TEAM_LISTNAME().then(res => {
      this.setState({
        allTeam: res.data.list,
      });
    });
  };
  onTimeChange = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
      },
      this.queryListCourse
    );
  };
  allTime = value => {
    const dateFormat = 'YYYY-MM-DD';
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if (value.id === 0) {
      endDate = '00:00:00';
      startDate = '00:00:00';
    }
    if (value.id === 1) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date().setDate(1)).format(dateFormat);
    } else if (value.id === 2) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-1, 'month')
        .format(dateFormat);
    } else if (value.id === 3) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-3, 'month')
        .format(dateFormat);
    } else if (value.id === 4) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-6, 'month')
        .format(dateFormat);
    } else if (value.id === 5) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-12, 'month')
        .format(dateFormat);
    }
    this.setState(
      {
        selecteDate: value.id,
        startDate: startDate,
        endDate: endDate,
      },
      this.queryListCourse
    );
  };
  /**
   * 教练球队下的数据
   */
  queryListCourse = pagination => {
    let params =
      this.state.coachId !== 0 ? { memberId: this.state.coachId } : {};
    params.startDate = this.state.startDate + ' 00:00:00';
    params.endDate = this.state.endDate + ' 00:00:00';
    params.mutrixId = this.state.selectedMutrix;
    params.teamId = this.state.selectedTeam;
    if (params.mutrixId === 0) {
      delete params['mutrixId'];
    }
    if (params.teamId === 0) {
      delete params['teamId'];
    }
    let obj = Object.assign({}, pagination, params);
    COACH_DATE(obj).then(res => {
      const pagination = { ...this.state.pagination };
      //pagination.total = res.data.list.totalPages;
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      pagination.current = res.data.list.number + 1;
      const data = res.data.list.content;
      const theyData = data.map(v =>
        Object.assign({}, v.entity, {
          rowKey: v.entity.id,
          percent: v.percent,
          status: v.status,
          team: v.team,
          groupNbr: v.groupNbr,
          mutrix: v.mutrix,
        })
      );
      this.setState({
        theyTeamData: theyData,
        pagination,
      });
    });
  };
  handlePaginationChange = pagination => {
    this.queryListCourse({
      pageNum: pagination.current,
    });
  };
  pictureCallback = () => {
    let picture = this.state.picture;
    picture.visible = false;
    picture.update = false;
    this.setState({ picture });
  };
  /**
   * 教练数据(筛选框)
   * @returns  (v.id === 0 ? '' : {mutrixId:v.id})   {*}
   */
  coachDate = () => {
    const { RangePicker } = DatePicker;
    return (
      <div style={this.state.styles}>
        <div style={{ margin: '30px 0' }}>
          <ListTitle
            selected={this.state.selectedMutrix}
            title="全部工场"
            data={this.state.selectMutrixes}
            callback={v =>
              this.setState({ selectedMutrix: v.id }, this.queryListCourse)
            }
          />
          <ListTitle
            selected={this.state.selectedTeam}
            title="全部球队"
            data={this.state.allTeam}
            callback={v =>
              this.setState({ selectedTeam: v.id }, this.queryListCourse)
            }
          />
          <span>选择时间段 &nbsp;&nbsp;</span>
          <ListTitle
            selected={this.state.selecteDate}
            data={[
              { id: 0, name: '全部' },
              { id: 1, name: '本月' },
              { id: 2, name: '最近一个月' },
              { id: 3, name: '近三个月' },
              { id: 4, name: '近六个月' },
              { id: 5, name: '近一年' },
            ]}
            callback={this.allTime}
          />
          <span>自定义&nbsp;&nbsp;</span>
          <RangePicker onChange={this.onTimeChange} locale={locale} />
        </div>
      </div>
    );
  };
  /**
   * 教练数据（表格）
   * @returns {*}
   */
  coachDateList = () => {
    const column2 = [
      {
        title: '序号',
        key: 'id',
        width: '5%',
        dataIndex: 'id',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        title: '时间',
        key: 'time',
        width: 100,
        dataIndex: 'startTime',
      },
      {
        title: '工场',
        key: 'mutrix',
        width: 90,
        dataIndex: 'mutrix',
        render(mutrix) {
          return mutrix.name;
        },
      },
      {
        title: '队伍',
        key: 'team',
        width: 80,
        dataIndex: 'team',
        render(team) {
          return team.name;
        },
      },
      {
        title: '分组数量',
        key: 'groupNbr',
        width: 50,
        dataIndex: 'groupNbr',
      },
      {
        title: '状态',
        key: 'status',
        width: 50,
        dataIndex: 'status',
        render(status) {
          return status.name;
        },
      },
      {
        title: '完成度',
        key: 'percent',
        width: 80,
        dataIndex: 'percent',
        render(percent) {
          return Math.round(percent) + '%';
        },
      },
      // {  暂时么有删除的api
      //     title: '操作',
      //     key: 'operation',
      //     width: 80,
      //     dataIndex: 'operation',
      //     render:(text, record) =>(
      //         <span>
      //             <Popconfirm title={"删除【" + record.team.name+ "】不可恢复，你确定要删除吗?"} onConfirm={() => this.handleDeleteCoach(record)}
      //                         okText="确定"
      //                         cancelText="取消">
      //                 <Button title='用户删除' >删除</Button>
      //             </Popconfirm>
      //     </span>
      //     ),
      // }
    ];
    const column = column2.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          rowKey: record.key,
        }),
      };
    });
    return (
      <div>
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.theyTeamData}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
          rowKey={record => record.key}
        />
      </div>
    );
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  handleAction = (file, fileList) => {
    const formData = new FormData();
    formData.append('file', file.file);
    let params = { coachId: this.state.coachId };
    COACH_UPLOADPORTRAIT(params, formData).then(res => {
      message.info('上传成功');
      this.setState({
        imageUrl: res.data.url,
      });
    });
  };

  teamDate = () => {
    let coachDate = this.state.coachDate;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">点击上传头像</div>
      </div>
    );

    return (
      <div>
        <div className={styles['basic-data']}>
          <p>基础资料</p>
          <p style={{ fontSize: '16px' }}>
            签约类型：{this.state.coachDate.source === 100 ? 'APP' : 'web签约'}
          </p>
        </div>
        <div className={styles['coach-data-box']}>
          <div className={styles['coach-data']}>
            {/*<Uploadimg*/}
            {/*  coachId={this.state.coachId}*/}
            {/*  update={this.state.picture.update}*/}
            {/*  // callback={this.pictureCallback}*/}
            {/*/>*/}
            {/*上传头像*/}
            <Upload
              className="football-uploader"
              listType="picture-card"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onPreview={this.handlePreview}
              customRequest={this.handleAction}
            >
              {this.state.imageUrl ? (
                <img
                  src={this.state.imageUrl}
                  alt="userImg"
                  style={{ width: '100%' }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </div>
          <div>
            <p>姓名：{coachDate.name}</p>
            <p>性别: {coachDate.gender === 1 ? '男' : '女'}</p>
            <p>国籍：暂无</p>
            <p>
              证件号码：
              {coachDate.idCardCode === null
                ? coachDate.passport
                : coachDate.idCardCode}
            </p>
            <p>
              邮箱地址：{coachDate.email === null ? '暂无' : coachDate.email}
            </p>
          </div>
          <div className={styles['coach-data-list']}>
            <p>手机号码：{coachDate.phone}</p>
            <p>
              出生日期：
              {coachDate.birthday == null
                ? '暂无'
                : this.state.coachDate.birthday.replace(/ 00:00:00/, '')}
              {/*{this.state.coachDate.birthday === null ? '' : this.state.coachDate.birthday.replace(/ 00:00:00/, '')}*/}
            </p>
            <p>出生地：暂无</p>
            <p>最高学历：暂无</p>
            <p>通讯地址：{this.state.fullAddress}</p>
          </div>
        </div>

        <div className={styles['basic-data']}>
          <span>角色资料</span>
        </div>
        <div>
          <span>体重:暂无 &nbsp;&nbsp;</span>
          <span>鞋子尺码：暂无 &nbsp;&nbsp;</span>
          <span>上衣尺码：暂无 &nbsp;&nbsp;</span>
          <span>裤子尺码：暂无 &nbsp;&nbsp;</span>
          <span>鞋子尺码：暂无 &nbsp;&nbsp;</span>
        </div>
        <div className={styles['basic-data']}>
          <p>执教球队</p>
          {/*<div style={{display:"inline-block",marginLeft:'88%',cursor:"pointer",color:'rgb(24, 144, 255)',fontSize:'16px'}}*/}
          {/*     onClick={()=> jumpTo("/pages/teamManage/teamAdd",{id:this.state.teamId})}*/}
          {/*>*/}
          {/*    <Icon type="plus-circle"/>新增*/}
          {/*</div>*/}
        </div>
      </div>
    );
  };
  coachTitle = () => {
    const { TabPane } = Tabs;
    return (
      <div>
        <div className="card-container">
          <Tabs type="card" onChange={this.callback}>
            <TabPane tab="教练详情" key="1">
              <this.teamDate />
              <this.teamDateList />
            </TabPane>
            <TabPane tab="教练数据" key="2">
              <this.coachDate />
              <this.coachDateList />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <this.coachTitle />
      </div>
    );
  }
}
