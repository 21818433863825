import React from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Table,
} from 'antd';
import {
  PLCMS_ADD,
  PLCMS_DELETE,
  PLCMS_LIST,
  PLCMS_MODIFY,
} from '../../app/api';
import ListTitle from '../../components/ListTitle/listTiltle';
// import './organizationManage.module.css';

const defaultModalData = {
  visible: false,
  title: '新增服务器',
  modalData: {
    valid: true,
    org: { id: 0, name: '' },
    mutrix: { id: 0, name: '' },
  },
};
class PlcmsManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      mainModal: Object.assign({}, defaultModalData),
      pagination: {},
      selectedMutrixId: 0,
      mutrixId: '',
    };
    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'id',
        width: '5%',
        render: (text, record, index) => (
          <span>
            {index +
              1 +
              (this.state.pagination.current - 1) *
                this.state.pagination.pageSize}
          </span>
        ),
      },
      {
        key: '2',
        title: '服务器名称',
        dataIndex: 'name',
        width: '10%',
      },
      {
        key: '3',
        title: 'IP地址',
        dataIndex: 'ipAddress',
        width: '5%',
      },
      {
        key: '4',
        title: '端口',
        dataIndex: 'port',
        width: '3%',
      },
      // {
      //     key:"5",
      //     title: '所属工场',
      //     dataIndex:'mutrixName',
      //     width:"10%",
      //     align:'center'
      // },
      // {
      //     key:"6",
      //     title: '所属组织',
      //     dataIndex:'orgName',
      //     width:"15%",
      //     align:'center'
      // },
      {
        key: '7',
        title: '状态',
        dataIndex: 'validName',
        width: '5%',
      },
      {
        title: '操作',
        key: 'operation',
        width: '15%',
        render: (text, record) => (
          <span>
            <button onClick={this.editMainModal.bind(this, record)}>
              编辑
            </button>
            <Divider type="vertical" />
            <Popconfirm
              title={'删除【' + record.name + '】不可恢复，你确定要删除吗?'}
              onConfirm={() => this.handleDelete(record)}
              okText="确定"
              cancelText="取消"
            >
              <button>删除</button>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }
  componentWillMount() {
    // let params = new URLSearchParams(window.location.search.split('?')[1]);
    // this.state.mutrixId = params.get('id');
  }
  componentDidMount() {
    this.listByMutrixId(this.props.mutrixId);
  }
  componentWillReceiveProps(nextProps, nextContext) {
    this.listByMutrixId(nextProps.mutrixId);

    if (nextProps.addModalVisible) {
      this.addMainModal();
    }
  }
  listByMutrixId = mutrixId => {
    mutrixId !== this.state.mutrixId &&
      this.setState({ mutrixId: mutrixId }, () =>
        this.list({ mutrixId: mutrixId })
      );
  };
  list = params => {
    PLCMS_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const theyData = data.map(v =>
        Object.assign({}, v.entity, {
          orgName: v.org.name,
          mutrixName: v.mutrix.name,
          rowKey: v.entity.id,
          mutrix: v.mutrix,
          validName: v.entity.valid ? '启用' : '禁用',
        })
      );
      this.setState({
        data: theyData,
        pagination,
      });
    });
  };
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
      mutrixId: this.props.mutrixId,
    });
  };
  handleMutrixSelectChange = value => {
    let mainModal = this.state.mainModal;
    mainModal.modalData.mutrix = value;
    this.setState({ mainModal: mainModal });
  };
  addMainModal = () => {
    let mainModal = this.state.mainModal;
    mainModal.modalData = Object.assign({}, defaultModalData.modalData);
    mainModal.visible = true;
    mainModal.title = defaultModalData.title;
    this.setState({ modalData: mainModal });
  };
  editMainModal = record => {
    let mainModal = this.state.mainModal;
    mainModal.modalData = record;
    mainModal.visible = true;
    mainModal.title = '编辑服务器';
    this.setState({ modalData: mainModal });
    this.props.callback();
  };
  handleMainModalCancel = () => {
    let mainModal = this.state.mainModal;
    this.props.callback();
    mainModal.visible = false;
    this.setState({ modalData: mainModal, display: 'none' });
  };
  handleMainModalOK = () => {
    let mainModal = this.state.mainModal;
    mainModal.visible = false;
    this.setState({ mainModal: mainModal });
    let mutrix = {
      id: this.state.mutrixId,
      name: this.props.mutrixName,
    };
    this.props.callback();
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        let body = {
          entity: {
            id: mainModal.modalData.id,
            name: values.name,
            ipAddress: values.ipAddress,
            port: parseInt(values.port),
            valid: mainModal.modalData.valid,
          },
          mutrix: mutrix,
        };
        let api = '';
        if (mainModal.title === '新增服务器') {
          api = PLCMS_ADD;
        } else {
          api = PLCMS_MODIFY;
        }
        api(body)
          .then(res => {
            message.success(
              this.state.mainModal.title + '【' + values.name + '】成功'
            );
            this.list({ mutrixId: this.props.mutrixId });
          })
          .catch(err => {
            if (err) {
              message.error(err);
            }
          });
      }
    });
  };
  handleDelete = record => {
    PLCMS_DELETE({ id: record.id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.name + '】成功');
          this.list({ mutrixId: this.props.mutrixId });
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };
  mainModal = () => {
    const { getFieldDecorator } = this.props.form;
    let mainModal = this.state.mainModal;
    return (
      <Modal
        title={mainModal.title}
        visible={mainModal.visible}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={this.handleMainModalCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleMainModalOK}>
            确定
          </Button>,
        ]}
      >
        <Form>
          <Form.Item label="名称" className="organizationName">
            {getFieldDecorator('name', {
              initialValue: mainModal.modalData.name, //填充input默认数据
              rules: [
                {
                  required: true,
                  message: '请输入服务器名称',
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="IP地址" className="organizationName">
            {getFieldDecorator('ipAddress', {
              initialValue: mainModal.modalData.ipAddress,
              rules: [
                {
                  required: true,
                  message: '请输入IP地址',
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="端口" className="organizationName">
            {getFieldDecorator('port', {
              initialValue: mainModal.modalData.port,
              rules: [
                {
                  required: true,
                  message: '请输入端口',
                },
              ],
            })(<Input />)}
          </Form.Item>

          {/*所属工场*/}
          <Form.Item label="所属工场" className="organizationName">
            {/*<ListTitle*/}
            {/*  selected={mainModal.modalData.mutrix.id}*/}
            {/*  title="工场名称"*/}
            {/*  data={this.props.selectMutrixes}*/}
            {/*  callback={this.handleMutrixSelectChange}*/}
            {/*/>*/}
            <span>{this.props.mutrixName}</span>
          </Form.Item>
          <Form.Item label="状态" className="huge-form-label">
            <Radio.Group
              options={[
                { label: '启用', value: true },
                { label: '禁用', value: false },
              ]}
              onChange={e => {
                mainModal.modalData.valid = e.target.value;
                this.setState({});
              }}
              value={mainModal.modalData.valid}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  render() {
    let inputColumns = []; //根据props输入的数组进行过滤和筛选
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            rowKey: record.key,
          }),
        };
      }
    );
    return (
      <div>
        {/*<this.titleSection />*/}
        <this.mainModal />
        <Table
          columns={column}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
        />
      </div>
    );
  }
}

export default Form.create()(PlcmsManage);
