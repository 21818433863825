import React from 'react';
import {
  Router,
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Link,
} from 'react-router-dom';
import * as History from 'history';
import App from '../App';
import Home from '../pages/home/home';
import FindPassword from '../pages/Findpassword/findPassword';
import Login from '../pages/login/Login';
import AccountCenter from '../pages/accountCenter/accountCenter';
import MutrixCenter from '../pages/accountCenter/mutrixCenter';
import CustomerCenter from '../pages/accountCenter/customerCenter';
import MutrixHome from '../pages/homePage/MutrixHome';
import CustomHome from '../pages/homePage/CustomHome';
import SystemHome from '../pages/homePage/SystemHome';
import OrganizationManage from '../pages/orgManage/organizationManage';
import OrganizationHome from '../pages/orgManage/OrganizationHome';
import CustomerManage from '../pages/orgManage/customerManage'; //客户管理
import AccountNumber from '../pages/accountNumber/accountNumber'; //账号管理
import CustomerDetails from '../pages/orgManage/customerDetails'; //客户详情
import OrganizationDetails from '../pages/orgManage/organizationDetails'; //组织详情
import OrganizationAdd from '../pages/orgManage/organizationAdd'; //新增组织
import TeamDetails from '../pages/teamManage/teamDetails'; //球队详情
import TeamEdit from '../pages/teamManage/teamEdit';
import TeamManage from '../pages/teamManage/teamManage';
import TeamAdd from '../pages/teamManage/teamAdd';
import Player from '../pages/player/player';
import PlayerHome from '../pages/player/PlayerHome';
import PlayerDetails from '../pages/player/playerDetails'; //球员详情
import PlayerAdd from '../pages/player/playerAdd'; //球员详情
import PlayerEdit from '../pages/player/playerEdit'; //球员详情
import Coach from '../pages/coach/coach';
import CoachAdd from '../pages/coach/coachAdd';
import CoachDetails from '../pages/coach/coachDetails'; //教练详情
import CoachEdit from '../pages/coach/coachEdit'; //修改教练资料
import MutrixManage from '../pages/MutrixManage/MutrixManage';
import MutrixDetail from '../pages/MutrixManage/MutrixDetail';
import MutrixItem from '../pages/MutrixManage/MutrixItem';
import PlcmsManage from '../pages/MutrixManage/PlcmsManage';
import AlarmManage from '../pages/alarmManage/AlarmManage'; //告警管理
import AlarmDefine from '../pages/alarmManage/AlarmDefine';
import AuthorityManage from '../pages/authorityManage/authorityManage'; //管理员账号
import AuthorityEdit from '../pages/authorityManage/authorityEdit';
import CourseManage from '../pages/courseManage/courseManage';
import CourseConfigManage from '../pages/courseManage/CourseConfigManage'; //教案管理
import CourseConfigEdit from '../pages/courseManage/CourseConfigEdit';
import PasswordManage from '../pages/passwordManage/passwordManage';
import ParentComponent from '../pages/homePage/ParentComponent';

import { encodeSearchParams } from '../app/http';
import FootballerStatistic from '../pages/data/FootballerStatistic';
import SeriesSummary from '../pages/data/SeriesSummary';
import Result from '../pages/data/Result';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import Breadcrumbs from '../components/Header/breadcrumbs';

const history = History.createBrowserHistory();
const paths = '/pages';
{
  /*</BrowserRouter>*/
}
class IRouter extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Router history={history}>
          <App>
            {/*{Breadcrumbs}*/}
            <Breadcrumbs />
            <Route exact path={paths + '/'} component={Login} />
            <Switch>
              <Redirect exact from="/" to={paths + '/login'} />
              <Route path={paths + '/login'} component={Login} />

              <Route path={paths + '/findPassword'} component={FindPassword} />
              <Route
                path={paths + '/'}
                render={() => (
                  <Home>
                    <Switch>
                      <Route
                        path={paths + '/homePage/systemHome'}
                        component={SystemHome}
                      />
                      <Route
                        path={paths + '/homePage/customHome'}
                        component={CustomHome}
                      />
                      <Route
                        path={paths + '/homePage/MutrixHome'}
                        component={MutrixHome}
                      />
                      {/*2.0的组织管理 ==改名为==>客户管理*/}
                      <Route
                        path={paths + '/orgManage/customerManage'}
                        component={OrganizationManage}
                      />
                      <Route
                        path={paths + '/orgManage/OrganizationHome'}
                        component={OrganizationHome}
                        // breadcrumb={'Display Name'}
                        //为了自动生成有层级的面包屑
                      />
                      {/*2.0的客户管理==改名为==> 3.0的组织管理*/}
                      <Route
                        path={paths + '/orgManage/organizationManage'}
                        component={CustomerManage}
                      />
                      <Route
                        path={paths + '/orgManage/customerDetails'}
                        component={CustomerDetails}
                      />
                      <Route
                        path={paths + '/orgManage/organizationDetails'}
                        component={OrganizationDetails}
                      />
                      <Route
                        path={paths + '/orgManage/organizationAdd'}
                        component={OrganizationAdd}
                      />
                      {/*用户中心*/}
                      <Route
                        path={paths + '/accountCenter/accountCenter'}
                        component={AccountCenter}
                      />
                      <Route
                        path={paths + '/accountCenter/mutrixCenter'}
                        component={MutrixCenter}
                      />
                      <Route
                        path={paths + '/accountCenter/customerCenter'}
                        component={CustomerCenter}
                      />
                      <Route
                        path={paths + '/teamManage/teamManage'}
                        component={TeamManage}
                      />
                      <Route
                        path={paths + '/teamManage/teamEdit'}
                        component={TeamEdit}
                      />
                      <Route
                        path={paths + '/teamManage/teamDetails'}
                        component={TeamDetails}
                      />
                      <Route
                        path={paths + '/teamManage/teamAdd'}
                        component={TeamAdd}
                      />

                      <Route
                        path={paths + '/player/player'}
                        component={Player}
                      />
                      <Route
                        path={paths + '/player/homePage'}
                        component={PlayerHome}
                      />
                      <Route
                        path={paths + '/player/playerDetails'}
                        component={PlayerDetails}
                      />
                      <Route
                        path={paths + '/player/playerAdd'}
                        component={PlayerAdd}
                      />
                      <Route
                        path={paths + '/player/playerEdit'}
                        component={PlayerEdit}
                      />

                      <Route path={paths + '/coach/coach'} component={Coach} />
                      <Route
                        path={paths + '/coach/coachDetails'}
                        component={CoachDetails}
                      />
                      <Route
                        path={paths + '/coach/coachAdd'}
                        component={CoachAdd}
                      />
                      <Route
                        path={paths + '/coach/coachEdit'}
                        component={CoachEdit}
                      />
                      <Route
                        path={paths + '/MutrixManage/mutrixManage'}
                        component={MutrixManage}
                      />
                      <Route
                        path={paths + '/mutrixManage/mutrixDetail'}
                        component={MutrixDetail}
                      />
                      <Route
                        path={paths + '/mutrixManage/MutrixItem'}
                        component={MutrixItem}
                      />
                      <Route
                        path={paths + '/mutrixManage/PlcmsManage'}
                        component={PlcmsManage}
                      />

                      <Route
                        path={paths + '/alarmManage/alarmManage'}
                        component={AlarmManage}
                      />
                      <Route
                        path={paths + '/alarmManage/AlarmDefine'}
                        component={AlarmDefine}
                      />
                      <Route
                        path={paths + '/authorityManage/authorityManage'}
                        component={AuthorityManage}
                      />
                      <Route
                        path={paths + '/authorityManage/authorityEdit'}
                        component={AuthorityEdit}
                      />
                      <Route
                        path={paths + '/courseManage/courseManage'}
                        component={CourseManage}
                      />
                      <Route
                        path={paths + '/courseManage/courseConfigManage'}
                        component={CourseConfigManage}
                      />
                      <Route
                        path={paths + '/courseManage/courseConfigEdit'}
                        component={CourseConfigEdit}
                      />
                      <Route
                        path={paths + '/homePage/ParentComponent'}
                        component={ParentComponent}
                      />
                      {/*账号管理*/}
                      <Route
                        path={paths + '/accountNumber/accountNumber'}
                        component={AccountNumber}
                      />

                      <Route
                        path={paths + '/passwordManage/passwordManage'}
                        component={PasswordManage}
                      />

                      {/* 训练相关的数据访问接口 */}
                      <Route
                        path={paths + '/data/statistic'}
                        component={FootballerStatistic}
                      />
                      <Route path={paths + '/data/result'} component={Result} />
                      <Route
                        path={paths + '/data/summary'}
                        component={SeriesSummary}
                      />
                      <Redirect exact from="/" to={paths + '/login'} />
                    </Switch>
                  </Home>
                )}
              />
            </Switch>
          </App>
        </Router>
      </BrowserRouter>
    );
  }
}

export function jumpTo(url, params) {
  const paramsUrl = encodeSearchParams(params);
  if (typeof paramsUrl !== 'undefined') {
    url = url + '?' + paramsUrl;
  }
  history.push(url);
}
export function jumpBack() {
  history.goBack(1);
}
export function jumpGo() {
  history.go(0);
}

// export default withBreadcrumbs(IRouter)(Breadcrumbs)
export default IRouter;
