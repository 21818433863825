import * as React from 'react';
import {Table} from 'antd';
//系统管理员
class RecordList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // API查询的数据存储
            updateTime:'',
            data: [],
            // 分页的数据存储
            pagination: {},
        };
        //table 表格数据
        this.column = [
            {
                key:"1",
                title: '序号',
                dataIndex:'id',
                width:"6%",
                render:(text, record, index) => (<span>{index+1 + ((this.state.pagination.current -1) * this.state.pagination.pageSize)}</span>),

            },
            {
                key:"2",
                title: '时间',
                dataIndex:'operateTime',
                width:"12%"

            },
            {
                key:"3",
                title: '工场',
                dataIndex:'mutrixName',
                width:"10%",
            },
            {
                key:"4",
                title: '类型',
                dataIndex:'mutrixType',
                width:"7%",
            },
            {
                key:"5",
                title: '地址',
                dataIndex:'address',
                width:"9%",
                align:'center',
            },
            {
                key:"6",
                title: '状态',
                dataIndex:'mutrixStatus',
                width:"5%",
                align:'center',
            },
            {
                key:"7",
                title: '事件',
                dataIndex:'event',
                width:"20%",
                align:'center',
            },

        ];
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.updateTime !== this.state.updateTime) {
            this.setState({data : nextProps.data, updateTime:nextProps.updateTime});
        }
    }
    componentWillUpdate(nextProps, nextState, nextContext) {
    }
    // 加载数据
    componentWillMount() {
    }
    componentDidMount() {
    }



    /**
     *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
     */
    handlePaginationChange = (pagination,filters,sorter)=>{
        this.list({
            // pageSize: pagination.current,
            pageNum: pagination.current
        })
    };


    render() {
        // 根据props输入的数组进行过滤和筛选
        let inputColumns = [];
        if (this.props.columns !== undefined && this.props.columns.length > 0) {
            for (let i = 0; i < this.props.columns.length; i++) {
                let col = this.column.filter(col => col.key === "" + this.props.columns[i]);
                if (col.length === 1) {
                    inputColumns.push(col[0]);
                }
            }
        }
        const column = (inputColumns.length > 0 ? inputColumns : this.column)
            .map(col => {
                if (!col.editable) {
                    return col;
                }
                return {
                    ...col,
                    onCell: record => ({
                        record,
                        editable: col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        handleSave: this.handleSave,
                        rowKey:record.key

            }),
                };
            });
        return (
            <div>
                {/* 表格 */}
                <Table columns={column}
                       bordered
                       showHeader={true}
                       dataSource={this.state.data}
                       pagination={false}
                       // onChange={this.handlePaginationChange}
                       rowClassName={(record, index)=>index%2===0 ? 'table-row-even-short':'table-row-odd-short'}
                       rowKey = {record => record.index}
                />
            </div>
        )
    }
}

export default RecordList;
