//组织管理员主页
import React from 'react';
// import {} from "antd";
import MuCalendar from '../calander/MuCalendar';
import ListTitle from '../../components/ListTitle/listTiltle';
import { MUTRIX_LISTNAME, CALENDAR_LIST, WHOAMI } from '../../app/api';
import moment from 'moment';
import { message } from 'antd';
class MutrixHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventData: [],
      selectMutrixes: [],
      selectedMutrix: 0,
      startDate: '',
      endDate: '',
      customId: '',
      currentMutrix: '',
      roleid: '',
    };
  }
  componentDidMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.state.customId = params.get('id');
    this.initData();
  }
  initData = () => {
    const accountId = localStorage.getItem('accountId');
    //下拉全部工场
    let params = {
      customId: this.state.customId,
    };

    MUTRIX_LISTNAME(params).then(res => {
      this.setState({
        selectMutrixes: res.data.list,
      });
    });
    WHOAMI({ id: accountId })
      .then(res => {
        if (res.code === 0) {
          this.setState({
            currentMutrix: res.data.currentMutrix,
            roleid: res.data.roleid,
          });
        }
      })
      .catch(err => {
        message.error(err);
      });
  };

  handleSelectChange = value => {
    this.setState(
      {
        selectedMutrix: value.id,
      },
      this.updateData
    );
  };
  clickDate = value => {
    console
      .log
      // '点击日期了，等产品设计要弹窗，还是跳转到什么页面，是否需要调到新建计划页面呢？'
      ();
  };
  updateDate = e => {
    //console.log(e);
    let startDate = moment(e.start).format('YYYY-MM-DD');
    let endDate = moment(e.end).format('YYYY-MM-DD');
    this.setState({ startDate: startDate, endDate: endDate }, this.updateData);
  };
  updateData = () => {
    let params = {
      customId:
        this.state.roleid === 2
          ? this.state.currentMutrix.id
          : this.state.customId,
      mutrixId:
        this.state.selectedMutrix === 0 ? undefined : this.state.selectedMutrix,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
    /**
     * 3、已失效， 2、已结束， 0， 未开始， 1，11 进行中
     */
    CALENDAR_LIST(params).then(res => {
      let eventData = res.data.events.map(o =>
        Object.assign(
          {},
          {
            id: o.courseId,
            title: o.teamName,
            date: o.startDate,
            start: o.startTime,
            color: this.transfer(o.status),
          }
        )
      );
      this.setState({ eventData: eventData });
    });
  };
  transfer = status => {
    if (status === 0) {
      return '#00a6ac';
    }
    if (status === 2) {
      return '#4e72b8';
    }
    if (status === 3) {
      return '#77787b';
    }
    return '#e0861a';
  };
  listTitle = () => {
    return (
      <div>
        {this.state.roleid === 2 ? (
          <span>
            <ListTitle selected={this.state.currentMutrix.name} />
          </span>
        ) : (
          <ListTitle
            title="所属工场"
            selected={this.state.selectedMutrix}
            data={this.state.selectMutrixes}
            callback={this.handleSelectChange}
          />
        )}
      </div>
    );
  };
  render() {
    return (
      <div>
        <MuCalendar
          listTitle={this.listTitle}
          clickDate={this.clickDate}
          updateData={this.updateDate.bind(this)}
          calendarEvents={this.state.eventData}
        />
      </div>
    );
  }
}

export default MutrixHome;
