import * as React from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Select,
  message,
} from 'antd';
import * as styles from './coach.module.css';
import Input from 'antd/es/input';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import GeoForm from '../../components/GeoForm';
import { COACH_QUERY, GEO_NATIONALITY, COACH_MODIFY } from '../../app/api';
import moment from 'moment';
import { jumpTo } from '../../router/Router';

class CoachEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nationality: '', //全世界
      province: '',
      city: '',
      region: '',
      modalData: {},
      coachId: '',
      sexStatus: '',
      stateValue: '',
      teams: [],
    };
  }
  componentWillMount() {
    this.queryCoachDetails(); //在render之前调用
  }

  componentDidMount() {}
  queryCoachDetails = () => {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    COACH_QUERY({ id: params.get('id') }).then(res => {
      let data = res.data.query.entity;
      this.setState({
        modalData: res.data.query.entity,
        province: data.province,
        city: data.city,
        region: data.region,
        teams: res.data.query.teams,
      });
    });
    GEO_NATIONALITY().then(res => {
      if (res.code === 0) {
        this.setState({ nationality: res.data.nationality });
      }
    });
  };
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
  };
  // 编辑教练
  editCoach = () => {
    this.props.form.validateFields((errors, values) => {
      console.log('values:', values);

      if (
        (!errors &&
          (values.IDnumber !== undefined && values.IDnumber !== '')) ||
        (values.passport !== '' && values.passport !== undefined)
      ) {
        let body = {
          //暂时注释了  后面产品出新需求了再来
          entity: {
            id: this.state.modalData.id,
            name: values.name,
            phone: values.phone,
            gender:
              this.state.sexStatus === ''
                ? this.state.modalData.gender
                : this.state.sexStatus,
            birthday:
              this.state.birthday === ''
                ? this.state.modalData.birthday
                : this.state.birthday,
            province: parseInt(this.state.modalData.province),
            city: parseInt(this.state.modalData.city),
            region: parseInt(this.state.modalData.region),
            valid:
              this.state.stateValue === ''
                ? this.state.modalData.valid
                : this.state.stateValue,
            email: values.email,
            address: values.address,
            idCardCode: values.IDnumber,
            // "academic":values.academic, //学历不要；
            passport: values.passport,
            nationality: values.nationality,
          },
          teams: this.state.teams,
        };
        COACH_MODIFY(body)
          .then(res => {
            message.success('编辑' + '【' + values.name + '】成功');
            jumpTo('/pages/coach/coach');
          })
          .catch(err => {
            console.log(err);
          });
      } else if (
        values.IDnumber === undefined ||
        values.IDnumber === '' ||
        values.passport === '' ||
        values.passport === undefined
      ) {
        message.warn('请选择一种证件号码填写');
      }
    });
  };
  onCancelCoach = () => {
    jumpTo('/pages/coach/coach');
  };

  /***
   * 性别change
   */
  sexChange = e => {
    this.setState({
      sexStatus: e.target.value,
    });
  };
  birthdayChange = (date, dateString) => {
    this.setState({
      thisMoment: dateString,
      birthday: dateString + ' 00:00:00',
    });
  };
  statusChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const RadioGroup = Radio.Group;
    const { Option } = Select;
    let nationality = this.state.nationality;
    let modalData = this.state.modalData;
    const dateFormat = 'YYYY-MM-DD';

    return (
      <div id="noID">
        <div className={styles['basic-data']}>
          <p>编辑资料</p>
        </div>
        <Form>
          <Row>
            <Col span={12}>
              <Form.Item label="姓名" className="organizationName">
                {getFieldDecorator('name', {
                  initialValue: modalData.name,
                  rules: [
                    {
                      required: true,
                      message: '请输入教练姓名',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="证件号码" className="organizationName nameless">
                {getFieldDecorator('IDnumber', {
                  initialValue: modalData.idCardCode,
                  rules: [
                    {
                      required: false,
                      message: '请输入证件号码',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="手机号" className="organizationName">
                {getFieldDecorator('phone', {
                  initialValue: modalData.phone,
                  rules: [
                    {
                      required: true,
                      message: '请输入手机号',
                    },
                    {
                      pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/,
                      message: '请输入正确的手机号',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="护照" className="organizationName">
                {getFieldDecorator('passport', {
                  initialValue: modalData.passport,
                  rules: [
                    {
                      required: false,
                      message: '请输入护照',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="性别" className="organizationName nameless">
                {getFieldDecorator('sexStatus', {
                  initialValue: modalData.gender,
                  rules: [
                    {
                      required: false,
                      message: '请选择性别',
                    },
                  ],
                })(
                  <RadioGroup
                    onChange={this.sexChange}
                    setFieldsValue={modalData.gender}
                  >
                    <Radio value={1}>男</Radio>
                    <Radio value={2}>女</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="出生日期" className="organizationName nameless">
                {getFieldDecorator('birthday', {
                  initialValue:
                    modalData.birthday === null
                      ? ''
                      : moment(modalData.birthday, dateFormat),
                  rules: [
                    {
                      required: false,
                      message: '请选择出生日期',
                    },
                  ],
                })(
                  <DatePicker
                    format={dateFormat}
                    onChange={this.birthdayChange}
                    placeholder="请选择年-月-日"
                    locale={locale}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="国籍：" className="organizationName nameless">
                {getFieldDecorator('nationality', {
                  initialValue: modalData.nationality,
                  rules: [
                    {
                      required: false,
                      message: '请选择国籍',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(
                  <Select style={{ width: 120 }}>
                    {(this.state.nationality || []).map((item, key) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            {/*coachLess*/}
            <Col span={12}>
              <Form.Item label="籍贯：" className="organizationName ">
                {getFieldDecorator('nativePlace', {
                  rules: [
                    {
                      required: false,
                      message: '请选择籍贯',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(
                  <div>
                    <Select
                      style={{ width: '22%', marginRight: '10px' }}
                      value={nationality === '' ? '' : nationality[39].name}
                    >
                      {(nationality || []).map((item, key) => (
                        <Option value={item.name} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <GeoForm
                    // regionId={this.state.modalData.region}
                    // provinceId={this.state.modalData.province}
                    // cityId={this.state.modalData.city}
                    />
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="通讯地址："
                className="organizationName nameless"
              >
                {getFieldDecorator('province', {
                  rules: [
                    {
                      required: false,
                      message: '请选择地区',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(
                  <div>
                    <Select
                      style={{ width: '22%', marginRight: '10px' }}
                      value={nationality === '' ? '' : nationality[39].name}
                    >
                      {(nationality || []).map((item, key) => (
                        <Option value={item.name} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    {JSON.stringify(this.state.modalData) === '{}' ? (
                      ''
                    ) : (
                      <GeoForm
                        regionId={this.state.modalData.region}
                        provinceId={this.state.modalData.province}
                        cityId={this.state.modalData.city}
                        updateGeoForm={(key, value) =>
                          this.updateGeoForm(key, value)
                        }
                      />
                    )}
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="邮箱" className="organizationName">
                {getFieldDecorator('email', {
                  initialValue: modalData.email,
                  rules: [
                    {
                      required: false,
                      message: '请输入邮箱',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {getFieldDecorator('address', {
                initialValue: modalData.address,
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Input
                  style={{ width: '54%', marginLeft: '13%' }}
                  placeholder="请输入详情地址"
                />
              )}
            </Col>
            <Col span={12}>
              <Form.Item label="状态" className="organizationName">
                {getFieldDecorator('valid', {
                  initialValue: modalData.valid,
                  rules: [
                    {
                      required: true,
                      message: '请选择状态',
                    },
                  ],
                })(
                  //setFieldsValue={this.state.stateValue}
                  <RadioGroup
                    onChange={this.statusChange}
                    setFieldsValue={modalData.valid}
                  >
                    <Radio value={true}>启用</Radio>
                    <Radio value={false}>禁用</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
            </Col>
          </Row>
          <div className={styles['next-btn']}>
            <Button type="primary" onClick={this.editCoach}>
              完成
            </Button>
            <Button style={{ marginLeft: '20px' }} onClick={this.onCancelCoach}>
              取消
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
export default Form.create()(CoachEdit);
