//工场管理
import React from 'react';
import { Button, Form } from 'antd';
import { ORGANIZATION_LISTNAME, CUSTOM_LISTNAME } from '../../app/api';
import ListTitle from '../../components/ListTitle/listTiltle';
import MutrixList from './MutrixList';
import * as styles from './MutrixStyles.module.css';

class MutrixManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectCustoms: [],
      selectedCustom: 0,
      selectOrgs: [],
      selectedOrg: 0,
      she: '',
      visible: false,
      columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 'operation2'],
    };
  }
  // 加载数据
  componentWillMount() {
    this.setTitle();
  }
  componentDidMount() {
    const custmer = localStorage.getItem('roleid');
    let t = document.getElementById('btn');
    let element = document.getElementById('mutrixList');
    if (custmer == 3) {
      t.style.display = 'none';
    }
    if (custmer == 2) {
      element.style.display = 'none';
    }
  }
  setTitle = params => {
    ORGANIZATION_LISTNAME(params).then(res => {
      this.setState({
        selectOrgs: res.data.list,
      });
    });
    CUSTOM_LISTNAME(params).then(res => {
      this.setState({
        selectCustoms: res.data.list,
      });
    });
  };
  handleOrgChange = value => {
    this.setState({ selectedOrg: value.id }); //全部客户
    // this.mutrixList.list({'orgId':this.state.selectedOrg})
  };
  handleCustomChange = value => {
    this.setState({ selectedCustom: value.id }); //全部签约组织
  };
  onRef = ref => {
    //子组件的方法
    this.mutrixList = ref;
  };
  addModalShow = e => {
    this.mutrixList.addMainModal();
  };
  /**
   * 表头
   */
  titleSection = () => {
    return (
      <div
        style={{ display: 'flex', width: '100%', marginBottom: '20px' }}
        id="mutrixList"
      >
        <ListTitle
          selected={this.state.selectedOrg}
          title="全部客户"
          data={this.state.selectOrgs}
          callback={this.handleOrgChange}
        />
        <ListTitle
          selected={this.state.selectedCustom}
          title="全部签约组织"
          data={this.state.selectCustoms}
          callback={this.handleCustomChange}
        />
        <div
          id="btn"
          className={styles['btn-box']}
          style={{ marginRight: '20px', marginLeft: 'auto' }}
        >
          <Button
            type="primary"
            onClick={this.addModalShow}
            className="btn-add"
          >
            新增工场
          </Button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {/* 标题栏 包括下拉框、新增按钮 等*/}
        <this.titleSection />
        {/* 主表单区 */}
        <MutrixList
          orgId={this.state.selectedOrg}
          customId={this.state.selectedCustom}
          columns={this.state.columns}
          onRef={this.onRef}
        />
      </div>
    );
  }
}
export default Form.create()(MutrixManage);
