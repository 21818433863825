import React from 'react';
import { Button, Form, Tabs } from 'antd';
import { MUTRIX_LISTNAME, ORGANIZATION_LISTNAME } from '../../app/api';
import ListTitle from '../../components/ListTitle/listTiltle';
import PlcmsManage from './PlcmsManage';
import McuManage from './McuManage';
import CameraManage from './CameraManage';
import UwbManage from './UwbManage';

const TabPane = Tabs.TabPane;
class MutrixItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabButtonTitle: '新增服务器',
      selectMutrixes: [],
      selectOrgs: [],
      selectedMutrix: 0,
      addModalVisible: false,
      activeKey: '1',
    };
    this.buttonTitles = {
      '1': '新增服务器',
      '2': '新增主控',
      '3': '新增摄像头',
      '4': '新增UWB',
    };
  }

  // 加载数据
  componentWillMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.setState({ selectedMutrix: params.get('mutrixId') });
    this.initData();
    // let mutrixId = params.get("mutrixId");
  }
  componentDidMount() {}

  handleMutrixChange = value => {
    this.setState({ selectedMutrix: value.id });
  };

  initData = params => {
    MUTRIX_LISTNAME(params).then(res => {
      this.setState({
        selectMutrixes: res.data.list,
      });
    });
    ORGANIZATION_LISTNAME(params).then(res => {
      this.setState({
        selectOrgs: res.data.list,
      });
    });
  };
  addMainModal = () => {
    this.setState({ addModalVisible: true });
  };
  onTabChange = activeKey => {
    // console.log(this.buttonTitles[activeKey]);
    this.setState({
      activeKey: activeKey,
      tabButtonTitle: this.buttonTitles[activeKey],
    });
  };
  callback = () => {
    this.setState({ addModalVisible: false });
  };
  /**
   * 筛选、新增控制栏
   */
  titleSection = () => {
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <ListTitle
          selected={this.state.selectedMutrix}
          title="工场名称"
          data={this.state.selectMutrixes}
          callback={this.handleMutrixChange.bind(this)}
        />
      </div>
    );
  };
  tabSection = () => {
    const operations = (
      <Button type="primary" onClick={this.addMainModal} className="btn-add">
        {this.state.tabButtonTitle}
      </Button>
    );
    return (
      <Tabs
        onChange={this.onTabChange}
        activeKey={this.state.activeKey}
        tabBarExtraContent={operations}
      >
        <TabPane tab="服务器" key="1">
          <PlcmsManage
            mutrixId={this.state.selectedMutrix}
            addModalVisible={
              this.state.activeKey === '1' && this.state.addModalVisible
            }
            selectMutrixes={this.state.selectMutrixes}
            callback={this.callback}
          />
          {/* 如果通过改变state里的值，来改变子组件的值呢？？   */}
        </TabPane>
        <TabPane tab="主控" key="2">
          <McuManage
            mutrixId={this.state.selectedMutrix}
            addModalVisible={
              this.state.activeKey === '2' && this.state.addModalVisible
            }
            selectMutrixes={this.state.selectMutrixes}
            callback={this.callback}
          />
        </TabPane>
        <TabPane tab="摄像头" key="3">
          <CameraManage
            mutrixId={this.state.selectedMutrix}
            addModalVisible={
              this.state.activeKey === '3' && this.state.addModalVisible
            }
            selectMutrixes={this.state.selectMutrixes}
            callback={this.callback}
          />
        </TabPane>
        <TabPane tab="UWB" key="4">
          <UwbManage
            mutrixId={this.state.selectedMutrix}
            addModalVisible={
              this.state.activeKey === '4' && this.state.addModalVisible
            }
            selectMutrixes={this.state.selectMutrixes}
            callback={this.callback}
          />
        </TabPane>
      </Tabs>
    );
  };

  render() {
    return (
      <div>
        {/* 标题栏 包括下拉框、新增按钮 等*/}
        <this.titleSection />
        <this.tabSection />
      </div>
    );
  }
}

export default Form.create()(MutrixItem);
