import React from 'react';
import { Button, Form, Select, Tabs } from 'antd';
import { MUTRIX_LISTNAME, MUTRIX_QUERY } from '../../app/api';
import * as styles from '../orgManage/mutrixStyle.module.css';
import PlcmsManage from './PlcmsManage';
import McuManage from './McuManage';
import CameraManage from './CameraManage';
import UwbManage from './UwbManage';
import MutrixTheme from './MutrixTheme';
import AlarmList from '../alarmManage/AlarmList';

const Option = Select.Option;
const TabPane = Tabs.TabPane;
class MutrixDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        entity: { name: '', serialno: '', address: '', valid: false },
        org: { id: 0, name: '' },
        type: { id: 0, name: '' },
        customs: [],
        customNumber: 0,
        fullAddress: '',
      },
      selectMutrixes: [],
      selectedMutrix: '',
      addModalVisible: false,
      activeKey: '1',
      tabButtonTitle: '新增服务器',
      columns: [1, 3, 2, 5, 6, 7, 8, 'operation'], //告警信息的table
      Plcms: [1, 2, 3, 4, 7],
    };
    this.buttonTitles = {
      '1': '新增服务器',
      '2': '新增主控',
      '3': '新增摄像头',
      '4': '新增UWB',
      '5': '新增大屏定制',
    };
  }
  // 加载数据
  componentWillMount() {
    // this.setTitle();
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.setState({ selectedMutrix: params.get('id') }, this.query);
  }
  componentDidMount() {
    // console.log(this.state.selectedMutrix);
  }
  setTitle = params => {
    MUTRIX_LISTNAME(params).then(res => {
      this.setState({
        selectMutrixes: res.data.list,
      });
    });
  };
  query = () => {
    //根据工场id查询工场详情
    let params =
      this.state.selectedMutrix !== 0 ? { id: this.state.selectedMutrix } : {};
    MUTRIX_QUERY(params).then(res => {
      let data = res.data.query;
      if (data !== undefined && data != null) {
        this.setState({
          data: data,
        });
      }
    });
  };
  addMainModal = () => {
    this.setState({ addModalVisible: true });
  };
  onTabChange = activeKey => {
    this.setState({
      activeKey: activeKey,
      tabButtonTitle: this.buttonTitles[activeKey],
    });
  };
  callback = () => {
    this.setState({ addModalVisible: false });
  };
  /**
   * 筛选、新增控制栏
   */
  titleSection = () => {
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <Select
          value={'' + this.state.selectedMutrix}
          style={{ width: 240, marginRight: 20 }}
          onChange={this.handleMutrixChange}
        >
          {/*<Option key='0' >工场名称</Option>*/}
          {this.state.selectMutrixes.map(o => (
            <Option key={o.id}>{o.name}</Option>
          ))}
        </Select>
      </div>
    );
  };
  basicData = () => {
    return (
      <div>
        {/*<div className={styles['basic-data']}>*/}
        {/*    <p>基本资料</p>*/}
        {/*</div>*/}
        <div className={styles['data-list']}>
          <p>名称：{this.state.data.entity.name}</p>
          <p>所属客户：{this.state.data.org.name}</p>
          <p>
            签约组织：
            {this.state.data.customs == ''
              ? '暂无'
              : this.state.data.customs.map(o => o.name).join(' ，')}
          </p>
          <p>编号：{this.state.data.entity.serialno}</p>
          <p>工场类型：{this.state.data.type.name}</p>
          {/*<p>工场结构：四驱18*18殿里式钢架结构</p>*/}
          <p>
            经纬度：{this.state.data.entity.longitude} ,{' '}
            {this.state.data.entity.latitude}
          </p>
          <p>地区：{this.state.data.fullAddress}</p>
          <p>详细地址：{this.state.data.entity.address}</p>
          <p>状态：{this.state.data.entity.valid ? '启用' : '禁用'}</p>
        </div>
      </div>
    );
  };
  tabSection = () => {
    const customer = localStorage.getItem('roleid');
    this.operations = {};
    if (customer == 3 || customer == 2) {
      this.operations = '';
    } else {
      this.operations = (
        <Button type="primary" onClick={this.addMainModal} className="btn-add">
          {this.state.tabButtonTitle}
        </Button>
      );
    }
    return (
      <Tabs
        onChange={this.onTabChange}
        activeKey={this.state.activeKey}
        tabBarExtraContent={this.operations}
      >
        <TabPane tab="服务器" key="1">
          <PlcmsManage
            columns={
              customer == 3 || customer == 2
                ? this.state.Plcms
                : [1, 2, 3, 4, 7, 'operation']
            }
            mutrixId={this.state.selectedMutrix}
            addModalVisible={
              this.state.activeKey === '1' && this.state.addModalVisible
            }
            selectMutrixes={this.state.selectMutrixes}
            mutrixName={this.state.data.entity.name}
            callback={this.callback}
          />
          {/* 如果通过改变state里的值，来改变子组件的值呢？？   */}
        </TabPane>
        <TabPane tab="主控单元" key="2">
          <McuManage
            columns={
              customer == 3 || customer == 2
                ? [1, 2, 3, 4, 7]
                : [1, 2, 3, 4, 7, 'operation']
            }
            mutrixId={this.state.selectedMutrix}
            addModalVisible={
              this.state.activeKey === '2' && this.state.addModalVisible
            }
            selectMutrixes={this.state.selectMutrixes}
            mutrixName={this.state.data.entity.name}
            callback={this.callback}
          />
        </TabPane>
        <TabPane tab="影像系统" key="3">
          <CameraManage
            columns={
              customer == 3 || customer == 2
                ? [1, 2, 3, 5, 6, 7, 8]
                : [1, 2, 10, 3, 4, 5, 6, 7, 8, 9, 'operation']
            }
            mutrixId={this.state.selectedMutrix}
            addModalVisible={
              this.state.activeKey === '3' && this.state.addModalVisible
            }
            selectMutrixes={this.state.selectMutrixes}
            mutrixName={this.state.data.entity.name}
            callback={this.callback}
          />
        </TabPane>
        <TabPane tab="定位系统" key="4">
          <UwbManage
            columns={
              customer == 3 || customer == 2
                ? [1, 2, 3, 4, 5, 6, 7]
                : [1, 2, 3, 4, 5, 6, 7, 'operation']
            }
            mutrixId={this.state.selectedMutrix}
            addModalVisible={
              this.state.activeKey === '4' && this.state.addModalVisible
            }
            selectMutrixes={this.state.selectMutrixes}
            mutrixName={this.state.data.entity.name}
            callback={this.callback}
          />
        </TabPane>
        <TabPane tab="大屏定制" key="5">
          <MutrixTheme
            columns={
              customer == 3 || customer == 2
                ? [1, 2, 3, 4, 5, 6, 7, 8]
                : [1, 2, 3, 4, 5, 6, 7, 8, 'operation']
            }
            mutrixId={this.state.selectedMutrix}
            addModalVisible={
              this.state.activeKey === '5' && this.state.addModalVisible
            }
            selectMutrixes={this.state.selectMutrixes}
            mutrixName={this.state.data.entity.name}
            callback={this.callback}
          />
        </TabPane>
      </Tabs>
    );
  };
  render() {
    const { TabPane } = Tabs;
    return (
      <div>
        <div className="card-container">
          <Tabs type="card">
            <TabPane tab="工场详情" key="mutrixList">
              {/* 工场详细资料*/}
              <this.basicData />
              <this.tabSection />
            </TabPane>
            <TabPane tab="告警信息" key="alarmMessage">
              {/*<this.alarmMessage/>*/}
              <AlarmList
                mutrixId={this.state.selectedMutrix}
                columns={this.state.columns}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Form.create()(MutrixDetail);
