//客户管理员个人中心
import React from 'react';
import { Button, Form, Icon, Input, message, Modal, Upload } from 'antd';
import {
  CHANGEPASSWORD,
  ACCOUNT_QUERY,
  HOME_UPLOADPORTRAIT,
  WHOAMI,
} from '../../app/api';
import { jumpTo } from '../../router/Router';

const accountId = localStorage.getItem('accountId');

// function getBase64(img, callback) {
//   console.log(img);
//   console.log(callback);
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }
function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('只能上传JPG/PNG文件!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('图像必须小于2MB!');
  }
  return isJpgOrPng && isLt2M;
}
class CustomerCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: false,
      organization: [],
      portraitUrl: '',
      userDate: '',
    };
  }
  componentWillMount() {
    this.queryMutrix();
  }
  queryMutrix = () => {
    WHOAMI({ id: accountId })
      .then(res => {
        let dataList = res.data.account;
        if (res.code === 0) {
          this.setState({
            userDate: dataList,
            organization: res.data.currentOrg,
            portraitUrl: dataList.portraitUrl,
          });
        }
      })
      .catch(err => {
        message.error(err);
      });
  };
  showMutrix = () => {
    console.log('123');
  };
  showModel = () => {
    this.setState({ changePassword: true });
  };
  editOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        CHANGEPASSWORD({
          newPassword: values.newPassword,
          oldPassword: values.oldPassword,
        }).then(res => {
          if (res.code === 0) {
            this.setState({ changePassword: false });
            jumpTo('/pages/login');
            message.success('密码修改成功，请直接登录');
          } else {
            message.error(res.data.message);
          }
        });
      }
    });
  };
  editCancel = () => {
    this.setState({ changePassword: false });
  };
  //上传头像
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  handleChange = ({ file, fileList, event }) => {
    console.log('onchange');
    console.log(file);
    console.log(fileList);
  };
  handleAction = (file, fileList) => {
    const formData = new FormData();
    formData.append('file', file.file);
    HOME_UPLOADPORTRAIT(formData).then(res => {
      console.log(res.data.url);
      if (res.code === 0) {
        message.info('上传成功');
        this.setState({
          portraitUrl: res.data.url,
        });
        this.queryMutrix();
      } else {
        message.info('上传失败，请重试');
      }
    });
  };
  thisModel = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const getTwoPassword = getFieldValue('newPassword');
    return (
      <Modal
        title={this.state.title}
        visible={this.state.changePassword}
        onOk={this.editOk}
        onCancel={this.editCancel}
        destroyOnClose={true}
        footer={[
          <Button key="submit" type="primary" onClick={this.editOk}>
            确定
          </Button>,
          <Button key="cancel" onClick={this.editCancel}>
            取消
          </Button>,
        ]}
      >
        <Form>
          <Form.Item label="旧密码" className="organizationName">
            {getFieldDecorator('oldPassword', {
              rules: [
                {
                  required: true,
                  message: '请输入旧密码',
                },
              ],
            })(<Input autoComplete="off" />)}
          </Form.Item>
          <Form.Item label="新密码" className="organizationName">
            {getFieldDecorator('newPassword', {
              rules: [
                {
                  required: true,
                  message: '请输入新密码',
                },
              ],
            })(<Input.Password autoComplete="new-password" />)}
          </Form.Item>
          <Form.Item label="确认密码" className="organizationName">
            {getFieldDecorator('confirm', {
              rules: [
                { required: true, message: '请再次确认密码' },
                {
                  validator: (rule, value, callback) => {
                    if (value !== getTwoPassword) {
                      callback('两次密码不一致');
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<Input.Password autoComplete="off" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  render() {
    const { organization } = this.state;
    const { userDate } = this.state;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">点击上传头像</div>
      </div>
    );
    return (
      <div>
        <div
          style={{
            height: '30px',
            borderBottom: '1px solid #cccccc',
            marginBottom: '30px',
          }}
        >
          个人中心
        </div>
        <div style={{ display: 'inline-block' }}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            customRequest={this.handleAction}
          >
            {this.state.portraitUrl ? (
              <img
                src={this.state.portraitUrl}
                alt="userImg"
                style={{ width: '100%' }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        <div style={{ display: 'inline-block', verticalAlign: '20px' }}>
          <p>
            当前账号：{this.state.userDate.rawAccount}
            <Button style={{ marginLeft: '10px' }} onClick={this.showModel}>
              修改密码
            </Button>
          </p>
          <p>
            当前角色：
            {userDate.roleId === 1
              ? '系统管理员'
              : userDate.roleId === 2
              ? '工场管理员'
              : userDate.roleId === 3
              ? '客户管理员'
              : userDate.roleId === 4
              ? '运营管理员'
              : ''}
          </p>
          <p>
            当前客户：
            <span style={{ color: 'rgb(24, 144, 255)' }}>
              {organization.name === undefined || null
                ? '暂无'
                : organization.name}
            </span>
          </p>
        </div>
        <div style={{ height: 'calc(56vh)' }}></div>
        <this.thisModel />
      </div>
    );
  }
}

export default Form.create()(CustomerCenter);
