//告警定义
import React from 'react';
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Table,
} from 'antd';
import {
  FM_CONFIG_LIST,
  FM_CONFIG_DELETE,
  FM_CONFIG_ADD,
  FM_CONFIG_MODIFY,
  DICTIONARY_LIST,
} from '../../app/api';
import ListTitle from '../../components/ListTitle/listTiltle';
import * as styles from './Alarm.module.css';

const defaultModalData = {
  visible: false,
  title: '新增告警定义',
  modalData: {
    valid: true,
    priority: { id: 1, name: '' },
    equipType: { id: 101, name: '' },
  },
};
class AlarmConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // API查询的数据存储
      data: [],
      mainModal: Object.assign({}, defaultModalData),
      // 分页的数据存储
      pagination: {},
      equipType: [],
      priority: [],
    };

    /**
         * alarmId: 1
         alarmText: ""
         alarmTitle: "急停按下"
         facilityType: 103
         id: 1
         priority: 7
         valid: true
         vendor: 2
         * @type {*[]}
         */
    this.column = [
      {
        key: '1',
        title: 'ID',
        dataIndex: 'id',
        width: '5%',
      },
      {
        key: '2',
        title: '设备类型',
        dataIndex: 'equipTypeName',
        width: '10%',
      },
      {
        key: '3',
        title: '告警级别',
        dataIndex: 'priorityName',
        width: '10%',
      },
      {
        key: '4',
        title: '告警ID',
        dataIndex: 'alarmId',
        width: '5%',
      },
      {
        key: '5',
        title: '告警标题',
        dataIndex: 'alarmTitle',
        width: '15%',
      },
      {
        key: '6',
        title: '状态',
        dataIndex: 'validName',
        width: '5%',
      },
      {
        title: '操作',
        key: 'operation',
        width: '15%',
        render: (text, record) => (
          <span>
            <Button
              className={styles['alarm-btn']}
              onClick={this.editMainModal.bind(this, record)}
            >
              <Icon type="edit" style={{ color: '#1890ff' }} />
              编辑
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title={
                '删除【' + record.alarmTitle + '】不可恢复，你确定要删除吗?'
              }
              onConfirm={() => this.handleDelete(record)}
              okText="确定"
              cancelText="取消"
            >
              <Button className={styles['alarm-btn']} title="用户删除">
                <Icon type="delete" style={{ color: '#1890ff' }} />
                删除
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  // 加载数据
  componentWillMount() {}
  componentDidMount() {
    this.initData();
    this.list();
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.addModalVisible && !this.state.mainModal.visible) {
      this.addMainModal();
    }
  }

  initData = () => {
    DICTIONARY_LIST({ type: 4011 }).then(res => {
      this.setState({
        equipType: res.data.list,
      });
    });
    DICTIONARY_LIST({ type: 1004 }).then(res => {
      this.setState({
        priority: res.data.list,
      });
    });
  };
  /**
   * 加载数据API
   */
  list = params => {
    FM_CONFIG_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const theyData = data.map(v =>
        Object.assign({}, v.entity, {
          rowKey: v.entity.id,
          priorityName: v.priority.name,
          equipTypeName: v.equipType.name,
          priority: v.priority,
          equipType: v.equipType,
          validName: v.entity.valid ? '启用' : '禁用',
        })
      );
      this.setState({
        data: theyData,
        pagination,
      });
    });
  };

  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
      mutrixId: this.props.mutrixId,
    });
  };

  handleEquipTypeSelectChange = value => {
    let mainModal = this.state.mainModal;
    mainModal.modalData.equipType = value;
    this.setState({ mainModal: mainModal });
  };
  handlePrioritySelectChange = value => {
    let mainModal = this.state.mainModal;
    mainModal.modalData.priority = value;
    this.setState({ mainModal: mainModal });
  };

  /**
   * 新增弹窗
   */
  addMainModal = () => {
    let mainModal = this.state.mainModal;
    mainModal.modalData = Object.assign({}, defaultModalData.modalData);
    mainModal.visible = true;
    mainModal.title = defaultModalData.title;
    this.setState({ modalData: mainModal });
  };

  editMainModal = record => {
    let mainModal = this.state.mainModal;
    mainModal.modalData = record;
    mainModal.visible = true;
    mainModal.title = '编辑告警定义';
    this.setState({ modalData: mainModal });
    this.props.callback();
  };

  handleMainModalCancel = () => {
    let mainModal = this.state.mainModal;
    this.props.callback();
    mainModal.visible = false;
    this.setState({ modalData: mainModal });
  };

  handleMainModalOK = () => {
    let mainModal = this.state.mainModal;
    mainModal.visible = false;
    this.setState({ mainModal: mainModal });
    this.props.callback();
    // 提交数据发起修改了
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        let body = {
          entity: {
            id: mainModal.modalData.id,
            alarmId: values.alarmId,
            alarmTitle: values.alarmTitle,
            valid: mainModal.modalData.valid,
          },
          priority: mainModal.modalData.priority,
          equipType: mainModal.modalData.equipType,
        };
        let api = '';
        if (mainModal.title === '新增告警定义') {
          api = FM_CONFIG_ADD;
        } else {
          api = FM_CONFIG_MODIFY;
        }
        api(body)
          .then(res => {
            message.success(
              this.state.mainModal.title + '【' + values.alarmTitle + '】成功'
            );
            this.list();
          })
          .catch(err => {
            if (err) {
              message.error(err);
            }
          });
      }
    });
  };
  handleDelete = record => {
    FM_CONFIG_DELETE({ id: record.id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.alarmTitle + '】成功');
          this.list();
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };

  mainModal = () => {
    const { getFieldDecorator } = this.props.form;
    let mainModal = this.state.mainModal;
    return (
      <Modal
        title={mainModal.title}
        visible={mainModal.visible}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={this.handleMainModalCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleMainModalOK}>
            确定
          </Button>,
        ]}
      >
        <Form>
          <Form.Item label="设备类型" className="organizationName">
            <ListTitle
              selected={mainModal.modalData.equipType.id}
              data={this.state.equipType}
              callback={this.handleEquipTypeSelectChange}
            />
          </Form.Item>

          <Form.Item label="告警级别" className="organizationName">
            <ListTitle
              selected={mainModal.modalData.priority.id}
              data={this.state.priority}
              callback={this.handlePrioritySelectChange}
            />
          </Form.Item>

          <Form.Item label="告警ID" className="organizationName">
            {getFieldDecorator('alarmId', {
              initialValue: mainModal.modalData.alarmId,
              rules: [
                {
                  required: true,
                  message: '请输入告警ID',
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="告警标题" className="organizationName">
            {getFieldDecorator('alarmTitle', {
              initialValue: mainModal.modalData.alarmTitle,
              rules: [
                {
                  required: true,
                  message: '请输入告警标题',
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="状态" className="huge-form-label">
            <Radio.Group
              options={[
                { label: '启用', value: true },
                { label: '禁用', value: false },
              ]}
              onChange={e => {
                mainModal.modalData.valid = e.target.value;
                this.setState({});
              }}
              value={mainModal.modalData.valid}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  render() {
    let inputColumns = []; //根据props输入的数组进行过滤和筛选
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            rowKey: record.key,
          }),
        };
      }
    );
    return (
      <div>
        {/* 标题栏 包括下拉框、新增按钮 等*/}
        {/*<this.titleSection />*/}
        <this.mainModal />

        {/* 表格 */}
        <Table
          columns={column}
          bordered={true}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
        />
      </div>
    );
  }
}

export default Form.create()(AlarmConfig);
