import * as React from 'react';
import {
  Button,
  Form,
  Table,
  message,
  Radio,
  DatePicker,
  Row,
  Col,
  Select,
} from 'antd';
import {
  COACH_LIST,
  TEAM_LISTNAME,
  COACH_ADD,
  COACH_MODIFY,
  GEO_NATIONALITY,
} from '../../app/api';
import Input from 'antd/es/input';
import * as styles from './coach.module.css';
import GeoForm from '../../components/GeoForm';
import ListTitle from '../../components/ListTitle/listTiltle';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { jumpTo } from '../../router/Router';
//import AsyncValidator from 'async-validator'

class TeamAdd extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
  }
  state = {
    data: [],
    value: [],
    fetching: false,
    selectedMutrix: 0,
    selectMutrixes: [],
    // style:{color:'red',marginLeft:'80px',marginTop:'10px',display:'none'},
    time: '', //选中的时间
    coachData: [],
    modalData: {},
    confirmDirty: false,
    stateValue: '', //状态
    allTeam: [],
    selectedTeam: 0,
    teamId: '', //新增的球队ID
    birthday: '',
    existedId: '', //已存在教练的id
    name: '',
    phone: '',
    province: '',
    city: '',
    region: '',
    email: '',
    gender: '',
    valid: '',
    teams: '',
    nationality: '',
  };
  componentDidMount() {
    this.loadingData();
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    let this_id = params.get('id');
    if (this_id == null) {
      this.setState({
        selectedTeam: '',
        disabled: false,
      });
      //this.state.selectedOrgs = this_id;
    } else {
      this.setState({
        selectedTeam: this_id,
        disabled: true,
      });
    }
  }
  componentDidCatch(error, errorInfo) {}
  loadingData = () => {
    TEAM_LISTNAME().then(res => {
      this.setState({
        allTeam: res.data.list,
      });
    });
    GEO_NATIONALITY().then(res => {
      if (res.code === 0) {
        this.setState({ nationality: res.data.nationality });
      }
    });
  };
  organizationChange = value => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };
  onChange = (date, dateString) => {
    this.setState({
      time: dateString,
    });
  };
  /**
   * 点击下一步
   */
  nextFunction = () => {
    let t = document.getElementById('zuID'); //初始状态
    let f = document.getElementById('nextID');
    let h = document.getElementById('noID'); //不存在的情况
    this.props.form.validateFields((err, values) => {
      if (
        (!err &&
          this.state.selectedTeam !== '' &&
          values.IDnumber !== undefined &&
          values.IDnumber !== '') ||
        (values.passport !== '' && values.passport !== undefined)
      ) {
        this.setState({ style: { display: 'none' } });
        // t.style.display ='none'; //隐藏 =》 然后加载已存在的组织
        // f.style.display ='block'; //显示 =》 已存在的组织
        const text = {
          // nameMode: 1,
          // name: values.name,
          check: '',
          phone: values.phone,
          idCardCode: values.IDnumber, //身份证
          passport: values.passport, //护照
        };
        COACH_LIST(text)
          .then(res => {
            const data = res.data.list.content;
            if (data.length === 0) {
              h.style.display = 'block';
              t.style.display = 'none';
              f.style.display = 'none';
            } else {
              t.style.display = 'none'; //隐藏 => 然后加载已存在的组织
              f.style.display = 'block'; //显示已存在的组织
              const allData = data.map(v =>
                Object.assign({}, v.entity, {
                  fullAddress: v.fullAddress,
                  teams: v.teams,
                  rowKey: v.entity.id,
                  age: v.age,
                })
              );
              this.setState({
                coachData: allData,
              });
            }
          })
          .catch(err => console.log(err));
      } else if (this.state.selectedTeam === '') {
        message.error('请选择球队');
      } else if (
        values.IDnumber === undefined ||
        (values.IDnumber === '' && values.passport === undefined) ||
        values.IDnumber === ''
      ) {
        message.error('请选择一种证件号码填写');
      }
    });
  };
  /***
   * 状态启用禁用的方法
   */
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  existedOk = () => {
    //新增教练已存在【完成】
    const arr = [];
    const this_team = { id: +this.state.selectedTeam }; //绑关系的球队的id
    arr.push(this_team);
    arr.push(...this.state.teams);
    let arr2 = Array.from(new Set(arr)); //去重
    let text = {
      entity: {
        id: this.state.existedId, //已经存在的教练ID
        name: this.state.name, //教练名
        phone: this.state.phone,
        email: this.state.email,
        gender: this.state.gender,
        province: parseInt(this.state.province),
        city: parseInt(this.state.city),
        region: parseInt(this.state.region),
        birthday: this.state.birthday,
        valid: this.state.valid,
      },
      teams: arr2,
    };
    COACH_MODIFY(text).then(res => {
      //用的编辑教练的接口
      if (res.code === 0) {
        message.success('新增成功');
        jumpTo('/pages/teamManage/teamDetails?id=' + this.state.selectedTeam);
      }
    });
  };
  existedCancel = () => {
    let t = document.getElementById('zuID');
    let f = document.getElementById('nextID');
    t.style.display = 'block';
    f.style.display = 'none';
  };
  /**
   * 第二步（教练名已经存在）
   * @returns {*}
   */
  nextLoading = () => {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        if (selectedRows.length !== 0) {
          const selectId = selectedRows[0].teams;
          selectId.forEach((val, index) => {
            selectId[index] = {
              id: val.id,
            };
          });
          this.setState({
            existedId: selectedRows[0].id, //已存在教练的id
            name: selectedRows[0].name,
            phone: selectedRows[0].phone,
            province: selectedRows[0].province,
            city: selectedRows[0].city,
            region: selectedRows[0].region,
            email: selectedRows[0].email,
            gender: selectedRows[0].gender,
            birthday: selectedRows[0].birthday,
            valid: selectedRows[0].valid,
            teams: selectId,
          });
        } else {
          console.log('123');
        }
      },
    };
    const column = [
      {
        key: '1',
        title: '姓名',
        dataIndex: 'name',
        width: '12%',
      },
      {
        key: '2',
        title: '手机号码',
        dataIndex: 'phone',
      },
      {
        key: '4',
        title: '性别',
        dataIndex: 'gender',
        render: gender => {
          return gender === 1 ? '男' : '女';
        },
      },
      {
        key: '5',
        title: '出生日期',
        dataIndex: 'birthday',
      },
      {
        key: '6',
        title: '执教球队',
        dataIndex: 'teams',
        render: teams => {
          return teams.length;
        },
      },
      {
        key: '7',
        title: '通讯地址',
        dataIndex: 'fullAddress',
      },
    ];
    return (
      <div id="nextID" style={{ display: 'none' }}>
        <p
          style={{
            marginBottom: '20px',
            fontSize: '18px',
            background: '#f3f4f8',
            padding: '8px 5px',
          }}
        >
          该教练已存在
        </p>
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.coachData}
          rowSelection={rowSelection}
          onChange={this.handleTableChange}
          rowKey={record => record.key}
        />
        <div className={styles['next-btn']}>
          <Button type="primary" onClick={this.existedOk}>
            完成
          </Button>
          <Button style={{ marginLeft: '20px' }} onClick={this.existedCancel}>
            取消
          </Button>
        </div>
      </div>
    );
  };

  /**
   * 【完成】新增教练不存在
   */
  onValidateForm = () => {
    const { validateFields } = this.props.form;
    const allData = this.state.modalData;
    validateFields(
      [
        'name',
        'phone',
        'email',
        'IDnumber',
        'passport',
        'sexStatus',
        'birthday',
        'nationality',
        'address',
      ],
      (errors, values) => {
        if (values.sexStatus === undefined || values.sexStatus === '') {
          message.error('请选择性别');
        } else if (values.birthday === undefined || values.birthday === '') {
          message.error('请选择出生日期');
        } else if (values.nationality === undefined) {
          message.error('请选择国籍');
        } else if (Object.keys(allData).length === 0) {
          message.error('请选择通讯地区');
        } else if (this.state.stateValue === '') {
          message.error('请选择状态');
        } else {
          let she = { id: this.state.selectedTeam };
          let body = {
            entity: {
              name: values.name, //教练名
              phone: values.phone,
              email: values.email,
              province: parseInt(this.state.modalData.province), //
              city: parseInt(this.state.modalData.city), //
              region: parseInt(this.state.modalData.region), //
              valid: this.state.stateValue, //
              gender: values.sexStatus, //
              birthday: this.state.birthday, //
              address: values.address, //详细地址
              passport: values.passport, //护照
              idCardCode: values.IDnumber, //身份证
              nationality: values.nationality, //国籍  将国籍变成id数字类型
            },
            teams: [she],
          };
          COACH_ADD(body)
            .then(res => {
              if (res.code === 0) {
                this.setState({
                  teamId: res.data.id,
                });
                message.success('新增成功');
                jumpTo(
                  '/pages/teamManage/teamDetails?id=' + this.state.selectedTeam
                );
              } else if (res.code === 1) {
                message.error(res.message);
              }
            })
            .catch(err => {
              if (err) {
                message.error(err);
              }
            });
        }
      }
    );
  };
  /**
   * 取消 返回上一步
   */
  onAddCancel = () => {
    let t = document.getElementById('zuID');
    let h = document.getElementById('noID');
    t.style.display = 'block';
    h.style.display = 'none';
  };
  birthdayChange = (date, dateString) => {
    this.setState({
      thisMoment: dateString,
      birthday: dateString + ' 00:00:00',
    });
  };
  /**
   * 教练名不存在
   */
  noOrganiza = () => {
    const { getFieldDecorator } = this.props.form;
    const RadioGroup = Radio.Group;
    const { Option } = Select;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    let nationality = this.state.nationality;
    //球队不存在
    return (
      <div id="noID" style={{ display: 'none' }}>
        <div className={styles['basic-data']}>
          <p>基础资料</p>
        </div>
        <Form {...formItemLayout}>
          <Row>
            <Col span={12}>
              <Form.Item label="姓名" className="organizationName">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: '请输入教练姓名',
                    },
                  ],
                })(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="证件号码" className="organizationName nameless">
                {getFieldDecorator('IDnumber', {
                  rules: [
                    {
                      required: false,
                      message: '请输入证件号码',
                    },
                  ],
                })(<Input disabled placeholder="身份证" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="手机号" className="organizationName">
                {getFieldDecorator('phone', {
                  rules: [
                    {
                      required: true,
                      message: '请输入手机号',
                    },
                    {
                      pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/,
                      message: '请输入正确的手机号',
                    },
                  ],
                })(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="护照" className="organizationName nameless">
                {getFieldDecorator('passport', {
                  rules: [
                    {
                      required: false,
                      message: '请输入护照',
                    },
                  ],
                })(<Input disabled placeholder="护照" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item label="性别" className="organizationName nameless">
                {getFieldDecorator('sexStatus', {
                  rules: [
                    {
                      required: false,
                      message: '请选择性别',
                    },
                  ],
                })(
                  <RadioGroup onChange={this.sexChange}>
                    <Radio value={1}>男</Radio>
                    <Radio value={2}>女</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="出生日期" className="organizationName nameless">
                {getFieldDecorator('birthday', {
                  rules: [
                    {
                      required: false,
                      message: '请选择出生日期',
                    },
                  ],
                })(
                  <DatePicker
                    onChange={this.birthdayChange}
                    placeholder="请选择年-月-日"
                    locale={locale}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="国籍：" className="organizationName nameless">
                {getFieldDecorator('nationality', {
                  rules: [
                    {
                      required: false,
                      message: '请选择国籍',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(
                  <Select style={{ width: 120 }}>
                    {(this.state.nationality || []).map((item, key) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            {/*coachLess*/}
            <Col span={12}>
              <Form.Item label="籍贯：" className="organizationName ">
                {getFieldDecorator('nativePlace', {
                  rules: [
                    {
                      required: false,
                      message: '请选择籍贯',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(
                  <div>
                    <Select
                      style={{ width: '22%', marginRight: '10px' }}
                      value={nationality === '' ? '' : nationality[39].name}
                    >
                      {(nationality || []).map((item, key) => (
                        <Option value={item.name} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <GeoForm
                      regionId={this.state.modalData.region}
                      provinceId={this.state.modalData.province}
                      cityId={this.state.modalData.city}
                      updateGeoForm={(key, value) =>
                        this.updateGeoForm(key, value)
                      }
                    />
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="通讯地址："
                className="organizationName nameless"
              >
                {getFieldDecorator('province', {
                  rules: [
                    {
                      required: false,
                      message: '请选择地区',
                    },
                  ],
                  validateTrigger: 'onBlur',
                })(
                  <div>
                    <Select
                      style={{ width: '22%', marginRight: '10px' }}
                      value={nationality === '' ? '' : nationality[39].name}
                    >
                      {(nationality || []).map((item, key) => (
                        <Option value={item.name} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <GeoForm
                      regionId={this.state.modalData.region}
                      provinceId={this.state.modalData.province}
                      cityId={this.state.modalData.city}
                      updateGeoForm={(key, value) =>
                        this.updateGeoForm(key, value)
                      }
                    />
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="邮箱" className="organizationName">
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: false,
                      message: '请输入邮箱',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              {/*邮箱在最下面*/}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {getFieldDecorator('address', {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Input
                  style={{ width: '54%', marginLeft: '13%' }}
                  placeholder="请输入详情地址"
                />
              )}
            </Col>

            <Col span={12}>
              <div>
                <span className={styles['teamSelect']}>
                  {' '}
                  <span style={{ color: '#f5222d', fontSize: '15px' }}>
                    {' '}
                    *{' '}
                  </span>{' '}
                  状态
                </span>
                <RadioGroup
                  onChange={this.stateChange}
                  value={this.state.stateValue}
                >
                  <Radio value={true}>启用</Radio>
                  <Radio value={false}>禁用</Radio>
                </RadioGroup>
              </div>
            </Col>
          </Row>

          <div className={styles['next-btn']}>
            <Button type="primary" onClick={this.onValidateForm}>
              完成
            </Button>
            <Button style={{ marginLeft: '20px' }} onClick={this.onAddCancel}>
              取消
            </Button>
          </div>
        </Form>
      </div>
    );
  };
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
  };
  /**
   * 新增教练刚进来时状态
   */
  newChoice = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="zuID">
        <h3 className={styles['add-coach']}>新增教练</h3>
        <div
          style={{
            height: '3px',
            background: '#f0f0f0f0',
            marginBottom: '30px',
          }}
        >
          {' '}
        </div>
        <div style={{ display: 'inline-block', marginBottom: '30px' }}>
          <span style={{ color: 'rgba(0, 0, 0, 0.85)', paddingRight: '3px' }}>
            <span style={{ color: '#f5222d', fontSize: '15px' }}>*</span>{' '}
            选择球队 :{' '}
          </span>
          <div style={{ display: 'inline-block' }}>
            <ListTitle
              selected={this.state.selectedTeam}
              data={this.state.allTeam}
              disabled={this.state.disabled}
              callback={v => {
                this.setState({ selectedTeam: v.id });
              }}
            />
          </div>
        </div>
        <div
          className={styles['organiza-input']}
          style={{ marginBottom: '40px' }}
        >
          <Form>
            <Form.Item label="姓名" className="organizationName">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: '请输入教练姓名',
                  },
                ],
              })(<Input placeholder="请输入教练姓名" />)}
            </Form.Item>
            <Form.Item label="手机号" className="organizationName">
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: '请输入手机号',
                  },
                  {
                    pattern: /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/,
                    message: '请输入正确的手机号',
                  },
                ],
              })(<Input placeholder="请输入教练手机号" />)}
            </Form.Item>

            <Form.Item label="证件号码" className="organizationName">
              {getFieldDecorator('IDnumber', {
                rules: [
                  {
                    required: true,
                    message: '请输入证件号码',
                  },
                ],
              })(<Input placeholder="身份证" />)}
              {getFieldDecorator('passport', {})(<Input placeholder="护照" />)}
            </Form.Item>
          </Form>
        </div>
        <div className={styles['next-btn']}>
          <Button type="primary" onClick={this.nextFunction}>
            下一步
          </Button>
        </div>
      </div>
    );
  };
  handleChange = value => {
    console.log(`selected ${value}`);
  };
  render() {
    return (
      <div>
        <this.newChoice />
        {/*下一步组织名已经存在*/}
        <this.nextLoading />
        {/*组织名不存在*/}
        <this.noOrganiza />
      </div>
    );
  }
}
export default Form.create()(TeamAdd);
