import React, { Component } from 'react';

import SystemHome from "./SystemHome";
import CustomerManage from "../orgManage/customerManage";

export default class ParentComponent extends Component{
    constructor(props){
        super(props)

        this.state={
            child2bgcolor:'pink'
        }
    }
    onchild2bgChange(color){
        this.setState({
            child2bgcolor:color
        })
    }

    render(props){
        return(
            <div>
                <SystemHome bgcolor={this.state.child2bgcolor}  />
                <CustomerManage changeChild2Color={(color)=>{this.onchild2bgChange(color)}} />
            </div>
        );
    }
}