import React from 'react';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';

// @see https://echarts.baidu.com/examples/editor.html?c=bar-stack
// @see http://www.5tu.cn/colors/yansebiao.html
class MutrixUsage extends React.Component {
  constructor(props) {
    super(props);
    this.id = 'main';
    this.state = {
      xAxis: [],
      yData1: [],
      yData2: [],
    };
  }
  // 加载数据
  componentWillMount() {}
  componentDidMount() {
    // console.log(this.props.xAxis);
    // 初始化
    this.update();
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.update) {
      // this.update();

      this.setState(
        {
          xAxis: nextProps.xAxis,
          yData1: nextProps.yData1,
          yData2: nextProps.yData2,
        },
        this.update
      );
    }
  }
  update = () => {
    let myChart = echarts.init(document.getElementById(this.id));
    myChart.setOption({
      title: { text: '累计计划次数', left: '45%' },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        },
      },
      legend: {
        data: ['已启动计划', '未启动计划'],
        right: '20px',
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: this.state.xAxis,
          // splitLine: {show:false},
        },
      ],
      yAxis: [
        {
          type: 'value',
          max: 14,
          minInterval: 2,
          maxInterval: 2,
          // interval:4,
          // splitLine: {show:false}
        },
      ],
      series: [
        {
          name: '已启动计划',
          type: 'bar',
          stack: '计划',
          data: this.state.yData1,
          itemStyle: { color: '#ffce7b' }, // 卵色
        },
        {
          name: '未启动计划',
          type: 'bar',
          stack: '计划',
          data: this.state.yData2,
          itemStyle: { color: '#77787b' }, // 灰色
        },
      ],
    });
  };
  render() {
    return (
      <div>
        <div id={this.id} style={{ width: '100%', height: 500 }}></div>
      </div>
    );
  }
}

export default MutrixUsage;
