//组织List
import React from 'react';
import { Table, Divider, Form, Popconfirm, Icon, message } from 'antd';
import { CUSTOM_LIST, CUSTOM_RELATION_DELETE } from '../../app/api';
import { jumpTo } from '../../router/Router';
import moment from 'moment';
// import './organizationManage.module.css';

class organizationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // API查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},

      mutrixId: 0,
      orgId: 0,
      customId: 0,
      teamId: 0,

      selectCustoms: [],

      selectOrgs: [],

      mainModal: '',
      customerId: '',
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: '1',
        width: '5%',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        key: '2',
        title: '组织名称',
        dataIndex: 'name',
        width: '12%',
      },
      {
        key: '3',
        title: '联系人',
        dataIndex: 'contactName',
        width: '5%',
      },
      {
        key: '4',
        title: '联系电话',
        dataIndex: 'contactPhone',
        width: '6%',
      },
      {
        key: '5',
        title: '地区',
        dataIndex: 'fullAddress',
        width: '13%',
      },
      {
        key: '6',
        title: '详细地址',
        dataIndex: 'address',
        width: '18%',
      },
      {
        key: '7',
        title: '所属客户',
        dataIndex: 'orgNumber',
        width: '8%',
      },
      {
        key: '8',
        title: '授权工场',
        dataIndex: 'mutrixNumber',
        width: '6%',
      },
      {
        key: '9',
        title: '球队数量',
        dataIndex: 'teamNumber',
        width: '6%',
      },
      {
        key: '12',
        title: '签约时间',
        dataIndex: 'orgRelation',
        width: '11%',
        render: msg => (
          <span>{moment(msg.startDate).format('YYYY-MM-DD')}</span>
        ),
      },
      {
        key: '11',
        title: '服务期限',
        dataIndex: 'orgRelation',
        width: '11%',
        render: msg => <span>{moment(msg.endDate).format('YYYY-MM-DD')}</span>,
      },
      {
        key: '10',
        title: '状态',
        dataIndex: 'valid',
        width: '6%',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },

      {
        title: '操作',
        key: 'operation',
        width: '23%',
        render: (text, record) => (
          <span>
            <button
              onClick={() =>
                jumpTo('/pages/homePage/mutrixHome', { id: record.id })
              }
            >
              <Icon type="appstore" style={{ color: '#1890ff' }} />
              主页
            </button>
            <Divider type="vertical" />
            <button
              onClick={() =>
                jumpTo('/pages/orgManage/organizationDetails', {
                  id: record.id,
                })
              }
            >
              <Icon type="unordered-list" style={{ color: '#1890ff' }} />
              详情
            </button>
            <Divider type="vertical" />
            {/*<a href="javascript:;"  onClick={() => jumpTo("/teamManage/teamEdit",{id:record.id}) }>*/}
            {/*    <Icon type="edit" style={{color:'#1890ff'}}/>*/}
            {/*    编辑</a>*/}
            <button onClick={this.editModal.bind(this, record)}>
              <Icon type="edit" style={{ color: '#1890ff' }} />
              编辑
            </button>
          </span>
        ),
      },
      {
        title: '操作',
        key: 'operation1',
        render: (text, record) => (
          <span>
            <Popconfirm
              title={'删除【' + record.name + '】不可恢复，你确定要删除吗?'}
              okText="确定"
              cancelText="取消"
              onConfirm={() => this.handleDelete(record)}
            >
              <button title="用户删除">删除</button>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }
  // componentWillReceiveProps(nextProps, nextContext) {
  //     let param = {};
  //     let update = false;
  //     if (nextProps.mutrixId !== this.state.mutrixId) {  //工场id
  //         param.mutrixId = nextProps.mutrixId;
  //         update = true;
  //     }
  //     if (nextProps.customId !== this.state.customId) {  //组织id
  //         param.customId = nextProps.customId;
  //         update = true;
  //     }
  //     if (nextProps.orgId !== this.state.orgId) {  //客户id
  //         param.orgId = nextProps.orgId;
  //         update = true;
  //     }
  //     if (nextProps.teamId !== this.state.teamId) {  //客户id
  //         param.teamId = nextProps.teamId;
  //         update = true;
  //     }
  //
  //     if (update) {
  //         this.setState({...param}, this.list);
  //     }
  // }
  componentWillMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.state.customerId = params.get('id');
    this.list();
  }
  componentDidMount() {}
  list = params => {
    params = params === undefined ? {} : params;
    params.mutrixId = this.state.mutrixId;
    params.orgId = this.props.orgId;
    params.customId = this.state.customId;
    params.teamId = this.props.teamId;
    CUSTOM_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const allData = data.map(v =>
        Object.assign({}, v.entity, {
          rowKey: v.entity.id,
          fullAddress: v.fullAddress,
          orgNumber: v.orgNumber,
          mutrixNumber: v.mutrixNumber,
          teamNumber: v.teamNumber,
          orgRelation: v.orgRelation,
        })
      );
      this.setState({
        data: allData,
        pagination,
      });
    });
  };
  editModal = record => {
    this.setState({
      visible: true,
      modalData: record,
      title: '编辑客户',
    });
  };
  handleDelete = record => {
    CUSTOM_RELATION_DELETE({
      orgId: this.state.customerId,
      customId: record.id,
    }).then(res => {
      if (res.code === 0) {
        message.success('删除成功');
      }
      this.list();
    });

    // CUSTOM_DELETE({id: record.id}).then(res=>{   彻底删除
    //     if (res.code === 0) {
    //         message.success('删除【' + record.name + '】成功');
    //         this.list()
    //     } else {
    //         message.error(res.message);
    //     }
    // }).catch(err => message.error(err))
  };
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
    });
  };
  render() {
    let inputColumns = []; //根据props输入的数组进行过滤和筛选
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            rowKey: record.key,
          }),
        };
      }
    );
    return (
      <div>
        {/*<this.processModal />*/}
        {/* 表格 */}
        <Table
          columns={column}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
          bordered={true}
          rowKey={record => record.key}
        />
      </div>
    );
  }
}
export default Form.create()(organizationList);
