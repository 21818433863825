import * as React from 'react';
import { Table, DatePicker, Button, Divider, message, Modal } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { DATA_SERIESSUMMARY, DICTIONARY_LIST, PLAYERDATA } from '../../app/api';
import { jumpTo } from '../../router/Router';
import ListTitle from '../../components/ListTitle/listTiltle';
import moment from 'moment';

/**
 * 球员已完成的训练列表
 * 支持下拉框 训练模式、教案级别、教案类型、查询时间
 * 表格字段包括 序号、时间、工场、球队、模式、教案、结果、操作
 * 输入参数包括：下拉框的list、表格字段list、球员的id
 */
// @see https://www.echartsjs.com/examples/editor.html?c=radar
class SeriesSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 参数区
      playerId: 0,
      startDate: '',
      endDate: '',
      module: 0,

      // 下拉框数据区
      selectedModule: 0,
      moduleList: [],

      selectedCourseType: 0,
      courseTypeList: [],
      selectedDateList: 0,

      // 主数据区
      data: [],
      pagination: {},
      summary: [],
      footballer: {},
      styles: { display: 'inline-block' },
    };
    this.lists = ['module', 'courseType', 'br', 'br', 'dateList', 'datePicker'];
    // this.lists = ['courseType','br', 'br','dateList', 'datePicker'];
    /**
         * balls: 30
         courseType: "单人综合"
         difficulty: ""
         endTime: "2019-03-27 18:40:06"
         level: "U12"
         module: "校园评测"
         mutrixName: "光明工场2号"
         score: "63分"
         seriesId: 4428
         startTime: "2019-03-27 18:34:52"
         status: 2
         targetRatio: 0
         teamName: "公明一小"
         * @type {*[]}
         *
         * 序号、时间、工场、球队、模式、教案、结果、操作
         */
    this.column = [
      {
        key: '1',
        title: '序号',
        width: '5%',
        render: (text, record, index) => (
          <span>
            {index +
              1 +
              (this.state.pagination.current - 1) *
                this.state.pagination.pageSize}
          </span>
        ),
      },
      {
        key: '2',
        title: '时间',
        dataIndex: 'startTime',
        width: '12%',
      },
      {
        key: '3',
        title: '工场',
        dataIndex: 'mutrixName',
        width: '8%',
      },
      {
        key: '4',
        title: '球队',
        dataIndex: 'teamName',
        width: '12%',
      },
      {
        key: '5',
        title: '模式',
        dataIndex: 'module',
        width: '8%',
      },
      {
        key: '6',
        title: '教案',
        dataIndex: 'courseName',
        width: '15%',
        align: 'center',
      },
      {
        key: '7',
        title: '结果',
        dataIndex: 'score',
        width: '10%',
        align: 'center',
        render: (text, record) => (
          <span>
            {record.score === 'WIN'
              ? '赢'
              : record.score === 'DRAW'
              ? '平局'
              : record.score === 'LOSE'
              ? '输'
              : record.score}
          </span>
        ),
      },
      // {
      //     key:"8",
      //     title: '详细地址',
      //     dataIndex:'address',
      //     width:"15%",
      //     align:'center'
      // },
      // {
      //     key:"9",
      //     title: '状态',
      //     dataIndex:'validName',
      //     width:"5%",
      // },
      // {
      //     // 这是 客户详情列表中所用到的操作
      //     title: '操作',
      //     key: 'operation1',
      //     width: '15%',
      //     render:(text, record) => (
      //         <span>
      //          <Popconfirm title={"删除【" + record.username+ "】不可恢复，你确定要删除吗?"} onConfirm={() => this.handleDelete(record)}>
      //             <a title='用户删除'  href="javascript:;">删除</a>
      //         </Popconfirm>
      //     </span>
      //     )
      // },
      {
        // 这是工场管理页面用到的操作
        title: '操作',
        key: 'operation',
        width: '8%',
        render: (text, record) => (
          <span>
            {record.courseType === '伤后恢复' ? (
              ''
            ) : (
              <span>
                <Button
                  onClick={() =>
                    jumpTo('/pages/data/result', { seriesId: record.seriesId })
                  }
                >
                  报告
                </Button>
                <Divider type="vertical" />
              </span>
            )}
            <Button onClick={() => this.download(record)}>下载数据</Button>
          </span>
        ),
      },
    ];
  }

  // 加载数据
  componentWillMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    let playerId = params.get('id');
    let startDate = params.get('startDate');
    let endDate = params.get('endDate');
    this.setState(
      { playerId: playerId, startDate: startDate, endDate: endDate },
      this.initData
    );
  }
  componentDidMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.setState({ playerId: params.get('id') }, this.list);

    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    if (custmer === 2) {
      this.setState({
        styles: { display: 'none' },
      });
    }
  }
  /**
   * 下载球员数据
   */
  download = record => {
    const seriesId = record.seriesId;
    // event.preventDefault();
    // event.stopPropagation();
    let url = '';
    if (process.env.NODE_ENV === 'development') {
      url = '/api/web/data/download?seriesId=' + seriesId;
    } else {
      url = '/web/data/download?seriesId=' + seriesId;
    }

    fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(response => {
        response.blob().then(blob => {
          if (response.status === 200) {
            // let filename = response.headers.get('Content-Disposition').split(';', 1) + '.xls';

            let thisName = response.headers.get('Content-Disposition');
            let filename = '';
            if (thisName === null) {
              message.error('文件名为空，下载失败');
            } else {
              filename = response.headers
                .get('Content-Disposition')
                .split('filename=')[1]; //设置文件名
              const he_filename = decodeURI(filename);
              let blobUrl = window.URL.createObjectURL(blob);
              let aElement = document.getElementById('downloadDiv'); //获取a标签元素
              aElement.href = blobUrl; //设置a标签路径
              aElement.download = he_filename;
              aElement.click();
              window.URL.revokeObjectURL(blobUrl);
              message.success('下载成功');
            }
          } else {
            message.error('该账号不存在');
          }
          // let filename = response.headers.get('Content-Disposition').split('filename=')[1].split('.')+'.xls';//设置文件名
        });
      })
      .catch(error => {
        message.error('下载失败');
        console.log('下载失败', error);
      });
  };

  initData = () => {
    DICTIONARY_LIST({ type: 3005 }).then(res => {
      this.setState({
        moduleList: res.data.list,
      });
    });
    DICTIONARY_LIST({ type: 3003 }).then(res => {
      this.setState({
        courseTypeList: res.data.list,
      });
    });
  };

  /**
   *
   *
   * **/
  list = params => {
    let body = {};
    body.footballerId = this.state.playerId;
    body.module = this.state.selectedModule;
    body.courseType = this.state.selectedCourseType;
    body.startDate = this.state.startDate;
    body.endDate = this.state.endDate;
    body.pageNum = params;
    DATA_SERIESSUMMARY(body).then(res => {
      // pageNum， pageSize  放到body里面   不能放到url里面
      let footballer = res.data.footballer;
      let summary = res.data.summary;
      const data = res.data.detail; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = data.size;
      pagination.total = data.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = data.number + 1;
      const theyData = data.content.map(v =>
        Object.assign({}, v, {
          rowKey: v.seriesId,
          courseName: v.balls + '球 ' + v.courseType + ' ' + v.level,
        })
      );

      this.setState({
        data: theyData,
        pagination: pagination,
        summary: summary,
        footballer: footballer,
      });
    });
  };
  handleModuleChange = value => {
    this.setState({ selectedModule: value.id }, this.list);
  };
  handleCourseTypeChange = value => {
    this.setState({ selectedCourseType: value.id }, this.list);
  };
  /**
     * [{id:1, name:'本月'}, {id:2, name:'最近一个月'},
     {id:3, name:'近三个月'}, {id:4, name:'近六个月'}, {id:5, name:'近一年'}]
     */
  handleDateListChange = value => {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if (value.id === 1) {
      endDate = moment(new Date()).format('YYYY-MM-DD');
      startDate = moment(new Date().setDate(1)).format('YYYY-MM-DD');
    } else if (value.id === 2) {
      endDate = moment(new Date()).format('YYYY-MM-DD');
      startDate = moment(new Date())
        .add(-1, 'month')
        .format('YYYY-MM-DD');
    } else if (value.id === 3) {
      endDate = moment(new Date()).format('YYYY-MM-DD');
      startDate = moment(new Date())
        .add(-3, 'month')
        .format('YYYY-MM-DD');
    } else if (value.id === 4) {
      endDate = moment(new Date()).format('YYYY-MM-DD');
      startDate = moment(new Date())
        .add(-6, 'month')
        .format('YYYY-MM-DD');
    } else if (value.id === 5) {
      endDate = moment(new Date()).format('YYYY-MM-DD');
      startDate = moment(new Date())
        .add(-12, 'month')
        .format('YYYY-MM-DD');
    }
    this.setState(
      { selectedDateList: value.id, startDate: startDate, endDate: endDate },
      this.list
    );
  };

  handleDatePickerChange = value => {
    let startDate = moment(value[0]).format('YYYY-MM-DD');
    let endDate = moment(value[1]).format('YYYY-MM-DD');
    this.setState({ startDate: startDate, endDate: endDate }, this.list);
  };
  //'module','courseType','dateList', 'datePicker'
  switchSection = item => {
    if (item === 'module') {
      return this.moduleSection();
    }
    if (item === 'courseType') {
      return this.courseTypeSection();
    }
    if (item === 'dateList') {
      return this.dateListSection();
    }
    if (item === 'br') {
      // todo这里增加换行或者通过其他手段进行控制编剧
      return <br />;
    }
    if (item === 'datePicker') {
      return this.datePickerSection();
    }
  };
  moduleSection = () => {
    //style={this.state.styles}
    return (
      <div style={this.state.styles}>
        <ListTitle
          selected={this.state.selectedModule}
          title="选择模式"
          data={this.state.moduleList}
          callback={this.handleModuleChange}
        />
      </div>
    );
  };

  courseTypeSection = () => {
    return (
      <div style={this.state.styles}>
        <ListTitle
          selected={this.state.selectedCourseType}
          title="选择教案"
          data={this.state.courseTypeList}
          callback={this.handleCourseTypeChange}
        />
      </div>
    );
  };
  // 本月、最近一个月、近三个月、近六个月、进一年
  dateListSection = () => {
    return (
      <div style={this.state.styles}>
        <span>选择时间段 </span>
        <ListTitle
          selected={this.state.selectedDateList}
          title="全部"
          data={[
            { id: 1, name: '本月' },
            { id: 2, name: '最近一个月' },
            { id: 3, name: '近三个月' },
            { id: 4, name: '近六个月' },
            { id: 5, name: '近一年' },
          ]}
          callback={this.handleDateListChange}
        />
      </div>
    );
  };
  datePickerSection = () => {
    const dateFormat = 'YYYY-MM-DD';
    return (
      <div style={this.state.styles}>
        <span>自定义 </span>
        <DatePicker.RangePicker
          // value={[moment(this.state.startDate), moment(this.state.endDate)]}
          format={dateFormat}
          locale={locale}
          onChange={this.handleDatePickerChange}
        />
      </div>
    );
  };
  titleSection = () => {
    let inputLists = [];
    if (this.props.lists !== undefined && this.props.lists.length > 0) {
      for (let i = 0; i < this.props.lists.length; i++) {
        if (this.lists.indexOf(this.props.columns[i]) !== -1) {
          inputLists.push(this.props.columns[i]);
        }
      }
    }
    inputLists = inputLists.length > 0 ? inputLists : this.lists;

    return (
      <div style={{ width: '100%' }}>
        {inputLists.map((item, key) => this.switchSection(item, key))}
      </div>
    );
  };
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list(
      // pageSize: pagination.current,
      // pageNum: pagination.current,
      pagination.current
    );
  };

  render() {
    // 根据props输入的数组进行过滤和筛选
    let inputColumns = [];
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            rowKey: record.id,
          }),
        };
      }
    );
    return (
      <div>
        {/* 新增或编辑工场*/}
        <this.titleSection />
        <a id="downloadDiv" style={{ display: 'none' }}>
          {' '}
        </a>
        <br />
        {/* 表格 */}
        <Table
          columns={column}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
          bordered={true}
          rowKey={record => record.key}
        />
      </div>
    );
  }
}

export default SeriesSummary;
