import React from 'react';
import { Button, Input, Icon, Form, message } from 'antd';
import * as styles from './findPassword.module.css';
import logo from '../../assets/img/motou.png';
import logo1 from '../../assets/img/logo2.png';
import back2 from '../../assets/img/form-bg.jpg';
import { verifyCode, changePasswordByCode } from '../../app/api';
import { Link } from 'react-router-dom';
import { jumpTo } from '../../router/Router';
import Statement from '../../components/Statement/statement';

class FindPassword extends React.Component {
  state = {
    checkPhone: false,
    loading: false,
    yztime: 59,
    disabled: false,
  };
  componentDidMount() {}
  //倒计60s
  count = () => {
    let { yztime } = this.state;
    let siv = setInterval(() => {
      this.setState({ yztime: yztime-- }, () => {
        if (yztime <= -1) {
          clearInterval(siv); //倒计时( setInterval() 函数会每秒执行一次函数)，用 clearInterval() 来停止执行:
          this.setState({ loading: false, yztime: 59, disabled: false });
        }
      });
    }, 1000);
  };
  /**
   *  获取验证码
   * @param account：手机号； type：2 修改密码
   */
  getCode = () => {
    this.props.form.validateFields(['phone'], (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        if (this.state.yztime !== 0) {
          this.count();
          verifyCode({ account: values.phone, type: 2 })
            .then(res => {
              this.setState({
                exampleData: res,
              });
              if (res.code === 0) {
                message.success('发送成功');
                this.setState({ disabled: true });
              } else {
                message.error('发送失败，请查询手机号是否注册');
              }
            })
            .catch(err => console.log(err));
        } else {
        }
      }
    });
    // this.props.form.validateFields((err,values)=>{
    //     console.log(err);
    //     console.log(values);
    //     if(values.phone === undefined){
    //     }
    //     // verifyCode({ phone: 15002077812,type:2}).then(res => {
    //     //     console.log(res);
    //     //     this.setState({
    //     //         exampleData: res
    //     //     })
    //     //     if(res.code == 0){
    //     //         message.success('发送成功');
    //     //     }else{
    //     //         message.error("发送失败，请查询手机号是否注册");
    //     //     }
    //     // }).catch(err => console.log(err))
    //
    // })
  };
  /**
   * 确认修改密码
   */
  postMessage = e => {
    e.preventDefault(); //阻止按钮提交  以onSubmit的方式
    this.props.form.validateFields((err, values) => {
      if (!err) {
        changePasswordByCode({
          account: values.phone,
          newPassword: values.password,
          code: values.code,
        })
          .then(res => {
            this.setState({
              exampleData: res, //赋值到state里
            });
            if (res.code === 0) {
              message.success('修改成功');
              jumpTo('/pages/login', {});
              // Route.push({
              //   pathname:"/home",
              //   // query:{id:this.props.id}             //路由传参
              // })
            } else {
              message.error(res.data.message);
            }
          })
          .catch(err => console.log(err)); //http.js拦截器中定义的返回数据
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles['App']}>
        <header className={styles['App-body']}>
          <video autoPlay loop className={styles['video-1']} muted>
            <source
              src="http://mutrix.oss-cn-shenzhen.aliyuncs.com/system/mutrix1.mp4"
              type="video/mp4"
            />
          </video>
          <img
            src="http://mutrix.oss-cn-shenzhen.aliyuncs.com/system/frontpage-background.png"
            alt="木头科技"
            className={styles['back-img']}
          />
          <img src={logo1} alt="木头科技" className={styles['logo-img']} />
          <div className={styles['head']}>
            {/*<img src={logo} className="App-logo" alt="logo" />*/}
            <div className={styles['login_box']}>
              <img src={logo} className={styles['App-logo']} alt="logo" />
              <img src={back2} className={styles['App-back']} alt="" />
              <Form onSubmit={this.postMessage} className={styles['login-ok']}>
                <h5 style={{ color: '#ffffff', fontSize: '20px' }}>找回密码</h5>
                <Form.Item>
                  {getFieldDecorator('phone', {
                    rules: [
                      { required: true, message: '请输入您的手机号/账号' },
                    ],
                  })(
                    <Input
                      size="large"
                      autoComplete="off"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="请输入您的手机号/账号"
                    />
                  )}
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('code', {
                    rules: [{ required: true, message: '请输入验证码' }],
                  })(
                    <Input
                      size="large"
                      autoComplete="new-password"
                      style={{ width: '64%' }}
                      prefix={
                        <Icon
                          type="mail"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="请输入验证码"
                    />
                  )}
                  <Button
                    className={styles['btn-code']}
                    size="large"
                    onClick={this.getCode}
                    disabled={this.state.disabled}
                  >
                    {this.state.loading
                      ? this.state.yztime + '秒'
                      : '获取验证码'}
                  </Button>
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: '请输入新密码' }],
                  })(
                    <Input.Password
                      autoComplete="off"
                      size="large"
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="请输入新密码"
                    />
                  )}
                </Form.Item>
                <div>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    className={styles['login-form-button']}
                    size="large"
                  >
                    确认（Confirm）
                  </Button>
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    cursor: 'pointer',
                  }}
                >
                  <p style={{ color: '#ffffff' }}>
                    已有账号密码？Already a member?
                  </p>
                  <Link to="/pages/login">
                    <span className={styles['sign']}>返回登录 Sign in</span>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
          <Statement />
        </header>
      </div>
    );
  }
}
export default Form.create()(FindPassword);
