import React, { Component } from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import 'antd/dist/antd.css';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './styles/index.css';
import './styles/common.css';
import configureStore from './redux/store/configureStore';
import Router from './router/Router';
import Breadcrumbs from './components/Header/breadcrumbs';
//import * as history from "history";
//import Link from "react-router-dom/es/Link";
moment.locale('zh-cn');

const store = configureStore();
const token = localStorage.getItem('token');

function isIE() {
  if (window.navigator.userAgent.indexOf('MSIE') >= 1) {
    return true;
  } else {
    console.log('不是ie浏览器');
  }
}
ReactDom.render(
  //这样根组件中所有的子组件都可以获得store中的值  connect二次封装根组件
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root')
);
//react 入口文件 加载index.js  渲染到root
