// 球员报告
import * as React from 'react';
import ReactEcharts from 'echarts-for-react';
// import ReactEchartsCore from 'echarts-for-react/lib/core';
import user from '../../assets/img/user(1).png';
import time from '../../assets/img/time.png';
import video from '../../assets/img/video.png';
import err from '../../assets/img/err.jpg';
import red from '../../assets/img/red.png';
import green from '../../assets/img/green.png';
import one from '../../assets/img/people.png';
import goalkeeper from '../../assets/img/shoumenyuan.png';
import Address from '../../assets/img/address.png';
import ydmk from '../../assets/img/pattern/ydmk.png';
import twoPeo from '../../assets/img/pattern/two-peo.png';
import anyPeo from '../../assets/img/pattern/any-peo.png';
import dkmn from '../../assets/img/pattern/dkmn.png';
import dkVS from '../../assets/img/pattern/dkVS.png';
import bule from '../../assets/img/pattern/bule.png';
import yellow from '../../assets/img/pattern/yellow.png';
import { Table, Divider, Button, Carousel, Modal, Row, Col } from 'antd';
import { DATA_RESULT } from '../../app/api';
import * as styles from './index.module.css';
// import ListTitle from "../../components/ListTitle/listTiltle";
/**
 * 球员训练结果
 * 包括球员基本信息、雷达图、柱状图、条状图、饼状图
 */
// @see https://www.echartsjs.com/examples/editor.html?c=radar
// http://localhost:3000/data/result?seriesId=3796
const BarColor = ['#79D03F', '#00C1DE', '#f4a300', '#ef5472'];
const SmyColor = ['#79D03F', '#f4a300', '#ef5472'];
const DkvsColor = ['#209DFD', '#00C1DE', '#FFB10A', '#FB9B6C'];
const TargetList = ['单人综合', '移动门框', '两人配合', '多人轮换', '对抗模拟'];
const Target2List = ['守门员'];
const Target6List = ['对抗VS'];

const PieChip = {
  1: [1, 2, 3], // 单人综合
  7: [1, 2, 3], // 对抗模拟
  2: [21, 22, 23], // 守门员
  8: [11, 12, 13], // 对抗VS
  3: [1, 2, 3], // 移动门框
  5: [1, 2, 3], //两人配合
  6: [1, 2, 3], //多人轮换
};

const ColorChip = {
  // 默认
  1: { color: ['#79D03F', '#00C1DE', '#F7B824', '#ef5472'], name: '传接时长' },
  2: { color: ['#79D03F', '#00C1DE', '#F7B824', '#ef5472'], name: '传接质量' },
  3: { color: ['#00C1DE', '#F7B824', '#ef5472'], name: '传球偏差' },
  88: { color: ['#9b9b9b', '#9b9b9b', '#9b9b9b'], name: '传球偏差' },
  // 对抗vs的色值
  11: {
    color: ['#79D03F', '#209DFD', '#00C1DE', '#FF667D'],
    name: '蓝队成功时长',
  },
  12: { color: ['#209DFD', '#00C1DE', '#FFB10A', '#FB9B6C'], name: '传接质量' },
  13: {
    color: ['#79D03F', '#F7B824', '#FB9B6C', '#FF667D'],
    name: '黄队成功时长',
  },

  // 守门员的色值
  21: { color: ['#79D03F', '#00C1DE', '#F7B824', '#ef5472'], name: '命中时长' },
  22: { color: ['#79D03F', '#F7B824', '#ef5472'], name: '扑救质量' },
  23: { color: ['#79D03F', '#ef5472'], name: '扑救失败' },
};

class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesId: 0,
      footballer: [],
      series: [],
      pmSeries: [],
      shoot: [],
      radar: {
        indicator: [],
        data: [],
      },
      bar: {
        xAxis: [],
        yAxis: [],
        total: '',
        average: '',
        ratio: '',
      },
      pie: {
        chip: [],
        data: [],
        pieInfo: [],
      },
      deviationModal: {
        visible: false,
        title: '偏差详情',
        data: [],
        columns: [
          {
            key: '1',
            title: 'ID',
            dataIndex: 'ballId',
            width: '5%',
          },
          {
            key: '2',
            title: '球速',
            dataIndex: 'speedDesc',
            width: '8%',
          },
          {
            key: '3',
            title: '旋转度',
            dataIndex: 'rotationDesc',
            width: '8%',
          },
          {
            key: '4',
            title: '水平角',
            dataIndex: 'bilateralDesc',
            width: '8%',
          },
          {
            key: '5',
            title: '俯仰角',
            dataIndex: 'elevationDesc',
            width: '8%',
          },
          {
            key: '6',
            title: '传接角',
            dataIndex: 'turnAroundDesc',
            width: '8%',
          },
          {
            key: '7',
            title: '偏差值',
            dataIndex: 'deviation',
            width: '8%',
          },
        ],
      },
      urls: [],
      videoShow: false,
      ability: [],
      text: {},
      timeAll: '', //平均时长
      averageTime: '',
      hitRate: '', //命中率
      smy_hitRate: '',
      winner: '', //获胜方
      maxScore: '',
      calcData: '',
      thisTime: '', //累计时长
      minute: '', //分
      second: '', //秒
    };
  }
  componentDidMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    let rams = new URLSearchParams(window.location.search.split('&')[1]);
    this.setState(
      {
        seriesId: params.get('seriesId'),
        text: rams.get('text'),
      },
      this.query
    );
  }
  query = () => {
    let params = { seriesId: this.state.seriesId };
    DATA_RESULT(params)
      .then(res => {
        const data = res.data; //list外层面的详细地址数据
        let series = data.series;
        let pmSeries = data.pm_series;
        let footballer = data.footballer;
        let urls = data.urls;
        this.setState({
          shoot: data.shoot,
          series,
          pmSeries,
          footballer,
          maxScore: data.maxScore,
          urls,
        });
        /**
         * 雷达区数据
         */
        let radar = this.state.radar;
        if (data.radar === undefined || data.radar === '') {
        } else {
          radar.indicator = data.radar.map((v, index) =>
            Object.assign(
              {},
              {
                name: v.name,
                max: 135,
                rowKey: index != 0 ? data.radar.length - index + 1 : index + 1,
              }
            )
          );
          radar.indicator.sort((a, b) => {
            return a.rowKey - b.rowKey;
          });
          radar.data = data.radar.map((v, key) => v.value);
          this.setState({
            data: data.radar.map((v, key) => v.value),
          });
        }
        /**
         * 柱状图数据
         */
        const bar = this.state.bar;
        let hitRate = 0; //命中率
        if (data.shoot !== undefined || data.shoot !== null) {
          let shoot = data.shoot;
          let time = [];
          let smy_hitRate = 0; //守门员命中率
          let blueVs = 0; //蓝队
          let yellowVs = 0; //黄队
          let blueScore = 0; //蓝队的分数
          let yellowScore = 0; //黄队的分数

          // 重新算累计时长
          let Stime1 = Date.parse(pmSeries.startTime);
          let Stime2 = Date.parse(pmSeries.endTime);
          let sumTime = Stime2 - Stime1;
          let hour = Math.floor(sumTime / 1000 / 60 / 60);
          let minute = Math.floor(sumTime / 1000 / 60 - hour * 60);
          let second = sumTime / 1000 - hour * 60 * 60 - minute * 60;

          this.setState({
            minute: minute,
            second: second,
          });
          // let startTime = this.state.pmSeries.startTime.slice(-5);
          // let endTime = this.state.pmSeries.endTime.slice(-5);
          // console.log(startTime)
          // console.log(endTime)
          // let thisTime = endTime.replace(':','.')-startTime.replace(':','.');
          // console.log(thisTime.toFixed(2));
          // this.setState({
          //   thisTime:thisTime.toFixed(2)
          // });
          for (let i = 0; i < shoot.length; i++) {
            let { target, m2Target, m6Target } = 0;
            // bar.yAxis.push(shoot[i].spendTime);
            let num = 0; //累计时长
            let average = 0; //累计时长
            if (shoot[i].m6Target === 1 || shoot[i].m6Target === 2) {
              blueVs += 1;
            } else if (shoot[i].m6Target === 3 || shoot[i].m6Target === 4) {
              yellowVs += 1;
            }
            if (blueVs > yellowVs) {
              this.setState({
                winner: '蓝队',
              });
            } else if (blueVs < yellowVs) {
              this.setState({
                winner: '黄队',
              });
            } else {
              this.setState({
                winner: '平局',
              });
            }

            if (shoot[i].m6Target < 3) {
              blueScore += shoot[i].score;
              this.setState({
                blueScore: blueScore,
              });
            } else if (shoot[i].m6Target > 2) {
              yellowScore += shoot[i].score;
              this.setState({
                yellowScore: Math.abs(yellowScore),
              });
            }

            if (
              shoot[i].target &&
              !shoot[i].overtime &&
              series.courseType !== '守门员'
            ) {
              //命中率
              hitRate += 1;
              // this.setState({
              //   hitRate: hitRate,
              // });
            }
            if (Target2List[0] === series.courseType) {
              if (shoot[i].m2Target === 2 || shoot[i].m2Target === 1) {
                hitRate += 1;
                // this.setState({
                //   hitRate: smy_hitRate, //守门员命中率
                // });
              }
            }
            let value = shoot[i].spendTime.toFixed(1);
            // if(value > 7.5){
            //   value = 7.5
            // }
            time.push(+value);
            for (let i = 0; i < time.length; i++) {
              num = num + time[i];
              average = num / time.length;
              this.setState({
                timeAll: num.toFixed(2), //累计时长
                averageTime: average.toFixed(2), //平均时长
              });
            }
            let xAxis = {
              value: i + 1,
              // splitLine: false,
              textStyle: { padding: [-25, 0, 0, 0] },
            };
            let yAxis = {
              value: value,
              //这行代码会使数据格式化，时长显示不出来
              // label: {
              // formatter: '{b}″',
              // show: true,
              // position: 'top',
              // offset: [0, 20],
              // color: '#fff',
              // },
              itemStyle: { color: '' },
            };
            //如果target  为true  overtime 为false的情况下

            if (shoot[i].target && Target2List[0] !== series.courseType) {
              if (!shoot[i].overtime) {
                yAxis.itemStyle.color = BarColor[0];
                if (
                  shoot.target &&
                  TargetList.indexOf(series.courseType) !== -1
                ) {
                  target++;
                }
                if (
                  shoot.target &&
                  Target2List.indexOf(series.courseType) !== -1
                ) {
                  m2Target++;
                }
                //为守门员模式的话
                if (
                  !shoot.target &&
                  Target2List.indexOf(series.courseType) !== -1
                ) {
                  yAxis.itemStyle.color = SmyColor[1];
                }
                if (
                  shoot.target &&
                  Target6List.indexOf(series.courseType) !== -1
                ) {
                  m6Target++;
                }
              } else {
                yAxis.itemStyle.color = BarColor[1];
              }
            } else {
              if (!shoot[i].overtime && Target2List[0] !== series.courseType) {
                yAxis.itemStyle.color = BarColor[2];
              } else {
                yAxis.itemStyle.color = BarColor[3];
              }
            }
            //判断为守门员模式  设置颜色
            if (Target2List[0] === series.courseType) {
              if (shoot[i].m2Target === 1) {
                yAxis.itemStyle.color = SmyColor[0];
              } else if (shoot[i].m2Target === 2) {
                yAxis.itemStyle.color = SmyColor[1];
              } else {
                yAxis.itemStyle.color = SmyColor[2];
              }
            }
            //判断是否为对抗vs  设置颜色
            if (Target6List[0] === series.courseType) {
              if (shoot[i].m6Target === 1) {
                yAxis.itemStyle.color = DkvsColor[0];
              }
              if (shoot[i].m6Target === 2) {
                yAxis.itemStyle.color = DkvsColor[1];
              }
              if (shoot[i].m6Target === 3) {
                yAxis.itemStyle.color = DkvsColor[2];
              }
              if (shoot[i].m6Target === 4) {
                yAxis.itemStyle.color = DkvsColor[3];
              }
            }
            bar.xAxis.push(xAxis);
            // bar.xAxis.push(i+1);
            bar.yAxis.push(yAxis);
          }
        }
        /**
         * 饼图图数据
         */
        let pie = this.state.pie;
        pie.chip = PieChip[pmSeries.courseType]; //什么模式例：对抗模拟】
        pie.data = data.pie; //饼图的数据
        let pieInfo = {};
        data.pieInfo.forEach(o => (pieInfo[o.id] = o.name));
        pie.pieInfo = pieInfo; //饼图的下方种类数据
        /**
         * 偏差
         */
        let deviationModal = this.state.deviationModal;
        deviationModal.data = data.deviation;
        /**
         * 各项能力指标
         */
        let ability = this.state.ability;
        let newArr = [];
        if (
          data.lrr !== undefined ||
          (data.lrr !== null && data.lrr.list !== undefined)
        ) {
          for (let f = 0; f < data.lrr.list.length; f++) {
            // if (data.lrr.list[f].name !== '预判能力') {
            //删除数组  预判能力
            newArr.push(data.lrr.list[f]);
            // }
          }
          ability = newArr;
        }
        hitRate =
          bar.yAxis.length > 0
            ? ((hitRate / bar.yAxis.length) * 100).toFixed(2)
            : 0;
        this.setState({
          series: series,
          pmSeries: pmSeries,
          footballer: footballer,
          radar,
          bar,
          pie,
          hitRate,
          deviationModal,
          ability,
        });
      })
      .catch(err => console.log(err));
  };

  getOption = () => {
    return {
      radar: {
        name: {
          textStyle: {
            color: '#fff',
            backgroundColor: '#999',
            borderRadius: 2,
            // padding: [5, 8]
          },
        },
        indicator: this.state.radar.indicator,
      },
      series: [
        {
          type: 'radar',
          data: [
            {
              value: this.state.radar.data,
              name: '',
            },
          ],
        },
      ],
    };
  };

  showDeviation = () => {
    let deviationModal = this.state.deviationModal;
    deviationModal.visible = true;
    this.setState({ deviationModal });
  };

  closeDeviation = () => {
    let deviationModal = this.state.deviationModal;
    deviationModal.visible = false;
    this.setState({ deviationModal });
  };

  getRadarOption = () => {
    //雷达图数据加载
    let shes = [
      { name: '身体素质', value: 380, max: 100 },
      { name: '球感', value: 290, max: 100 },
      { name: '比赛', value: 450, max: 100 },
      { name: '踢球', value: 300, max: 100 },
      { name: '运球', value: 480, max: 100 },
    ];
    return {
      radar: {
        name: {
          textStyle: {
            color: '#383D43',
            // backgroundColor: '#ffffff',
            borderRadius: 2,
            // padding: [5, 8]
          },
        },
        indicator:
          this.state.radar.indicator == '' || null
            ? shes
            : this.state.radar.indicator,
        splitLine: { lineStyle: { color: ['#383D43'] } },
        splitArea: { show: false },
      },
      series: [
        {
          type: 'radar',
          splitNumber: 4,
          itemStyle: { normal: { areaStyle: { type: 'default' } } },

          data: [
            {
              value: this.state.radar.data,
              name: '',
              areaStyle: { color: '#00B9D9' },
              lineStyle: { color: '#00B9D9' },
              symbol: 'none',
            },
          ],
          label: {
            backgroudColor: 'transparent',
          },
        },
      ],
    };
  };

  /**
   * 柱状图
   */
  barChart = () => {
    return (
      <ReactEcharts
        option={this.getBarChartOption()}
        notMerge={true}
        lazyUpdate={true}
        onChartReady={this.onChartReadyCallback}
        theme={'theme_name'}
      />
    );
  };
  /**
   * 柱状图
   */
  getBarChartOption = () => {
    let xAxis = this.state.bar.xAxis.map((o, key) => o.value);
    // let yData = this.state.bar.yAxis.map((i, key) => i.value);
    // let yAxis = this.state.bar.yAxis.map(o=>o.value);
    // let color = this.state.bar.yAxis.map(o=>o.itemStyle.color);
    return {
      // title: { text: '累计计划次数', left: '45%' },
      // tooltip : {
      //     trigger: 'axis',
      //     axisPointer : {            // 坐标轴指示器，提示器，坐标轴触发有效
      //         type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
      //     }
      // },

      dataZoom: [
        //添加滚动条
        {
          // show: true,
          // realtime: false,
          y: 18,
          height: 18,
          start: 0,
          end: 98,
        },
        //添加鼠标滚动
        {
          type: 'inside',
          start: 94,
          end: 100,
        },
      ],
      legend: {
        data: ['命中未超时', '命中超时', '未命中未超时', '未命中超时'], //出不来的了
        left: 'center',
      },
      // calculable: true,
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      resize: {
        width: 10,
        height: 100,
      },
      xAxis: [
        {
          type: 'category',
          // autoPlay: true,
          // playInterval: 1000,
          data: xAxis,
          zlevel: 1,
          // max: 20,
          boundaryGap: true,
          // axisTick: {
          //   alignWithLabel: true,
          // },
          // data : this.state.bar.xAxis,
        },
      ],
      yAxis: [
        {
          type: 'value',
          max: 7.5,
          minInterval: 2,
          maxInterval: 2,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
        },
      ],
      series: [
        {
          name: '已启动计划',
          type: 'bar',
          stack: '计划',
          data: this.state.bar.yAxis,
          // data: yData,
          barWidth: 35,
          label: {
            normal: {
              show: true,
              position: 'inside',
            },
          },
        },

        // {
        //     name: 'Budget 2012',
        //     type: 'bar',
        //     data: this.state.bar.yAxis
        // }
      ],
    };
  };

  // setInterval =() => function (){
  //   axisData = (new Date()).toLocaleTimeString().replace(/^\D*/,'');
  //
  //   var data0 = option.series[0].data;
  //   var data1 = option.series[1].data;
  //   data0.shift();
  //   data0.push(Math.round(Math.random() * 1000));
  //   data1.shift();
  //   data1.push((Math.random() * 10 + 5).toFixed(1) - 0);
  //
  //   option.xAxis[0].data.shift();
  //   option.xAxis[0].data.push(axisData);
  //   option.xAxis[1].data.shift();
  //   option.xAxis[1].data.push(app.count++);
  //
  //   myChart.setOption(option);
  // }

  /**
   * 饼状图数据
   */
  getPieChartOption = e => {
    let data = [];
    let legend = [];
    let color = [];
    let title = '';
    let balls = 0;
    let obj = {};
    let th_name = [
      { id: '81', name: '蓝队成功命中', type: '12' },
      { id: '82', name: '蓝队未命中', type: '12' },
      { id: '83', name: '黄队成功命中', type: '12' },
      { id: '84', name: '黄队未命中', type: '12' },
    ];

    let that_name = [
      { id: '91', name: '<2.5S', type: '13' },
      { id: '92', name: '2.5-5S', type: '13' },
      { id: '93', name: '5-7.5S', type: '13' },
      { id: '94', name: '>7.5S', type: '13' },
    ];
    let name = [];
    for (let i = 0; i < e.length; i++) {
      let item = e[i];
      let newArr = [];
      // console.log('item.pieType:',item.pieType);   //11  12  13
      // console.log('pieInfo:',this.state.pie.pieInfo);   //
      // console.log('name等等等:',this.state.pie.pieInfo[item.chipType]);   //
      if (item.pieType == 12) {
        th_name.forEach(o => (obj[o.id] = o.name));
        name = this.state.pie.pieInfo = obj;
      }
      if (item.pieType == 13) {
        that_name.forEach(o => (obj[o.id] = o.name));
        name = this.state.pie.pieInfo = obj;
      }
      name = this.state.pie.pieInfo[item.chipType];
      color = ColorChip[item.pieType].color;
      title = ColorChip[item.pieType].name;
      balls = item.total;
      let calc = Math.round(item.percent);
      if (item.pieType === 3 && item.percent === 0) {
        color = ColorChip[88].color;
      }
      if (calc !== 0) {
        newArr.push(calc);
      }
      data.push({
        // value: Math.round(item.percent),
        value: calc,
        name: name,
        label: {
          show: calc == 0 ? false : true, //去除了为0的数据
        },
        labelLine: {
          show: calc == 0 ? false : true,
        },
      });
      let left = 0;
      if (i % 2 === 1) {
        left = 100;
      }
      legend.push({
        name: name,
        icon: 'circle',
        color: '#453d77',
        width: 100,
        left: left,
        top: 90,
        textStyle: {
          color: '#453d77',
          width: 180,
        },
      });
    }

    // todo 根据偏差数量，来给title中增加一个提示按钮
    // <a href="javascript:;" onClick={this.showDeviation}>❓</a>
    return {
      title: {
        text:
          '{style1| ' +
          balls +
          '}\n{style2|球数}\n{style4|}\n{style3|' +
          title +
          ' }',
        textStyle: {
          rich: {
            style1: {
              color: '#333',
              lineHeight: 20,
              fontSize: 16,
              fontFamily: 'Microsoft YaHei',
            },
            style2: {
              color: '#333',
              lineHeight: 20,
              fontSize: 16,
              fontFamily: 'Microsoft YaHei',
            },
            style3: {
              color: '#888',
              lineHeight: 20,
              fontSize: 12,
            },
            style4: {
              lineHeight: 60,
              fontSize: 12,
            },
          },
        },
        left: 'center',
        bottom: 40,
      },
      color: color,
      // formatter设置提示框显示内容
      // {a}指series.name  {b}指series.data的name
      // {c}指series.data的value  {d}%指这一部分占总数的百分比
      tooltip: {
        trigger: 'item',
        formatter: '{b}<br/>{c}%',
      },
      legend: {
        // orient: 'horizontal',
        // left: 'center',
        bottom: 0,
        top: '56%',
        width: '60%',
        data: legend,
        padding: [
          90, // 上
          0, // 右
          0, // 下
          2, // 左
        ],
      },
      series: [
        {
          // name:'传接质量',
          type: 'pie',
          radius: ['25%', '50%'],
          avoidLabelOverlap: true,
          label: {
            formatter: '{c}%',
            position: 'inside',
          },
          data: data,
          animationDelay: function(idx) {
            return idx * 10;
          },
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function(idx) {
        return idx * 5;
      },
    };
  };
  basicData = () => {
    let footballer = this.state.footballer;
    let series = this.state.series;
    let pmSeries = this.state.pmSeries;

    return (
      <div>
        {this.state.ability.length === 0 ? (
          <div>
            <div style={{ padding: '23px 8px 20px 23px', fontSize: '23px' }}>
              <span>
                {series.courseType === '对抗VS' ? (
                  <span className={styles['user-img']}>
                    <img src={bule} alt="" />
                    {footballer[0].footballerName}
                    &nbsp; VS &nbsp; <img src={yellow} alt="" />
                    {footballer[1].footballerName}
                  </span>
                ) : (
                  <span>
                    <span style={{ paddingRight: '5px' }}>
                      <img src={user} alt="用户名" />
                    </span>
                    {footballer.map((v, key) => v.footballerName + '|')}
                  </span>
                )}
              </span>

              <span style={{ display: 'inline-block', marginLeft: '80px' }}>
                <img src={time} alt="具体时间" />
                &nbsp;{pmSeries.startTime}
              </span>
              <span style={{ marginLeft: '80px' }}>
                {series.mutrixName === null ? (
                  ''
                ) : (
                  <span>
                    <img src={Address} alt="工场" /> &nbsp;{series.mutrixName}
                  </span>
                )}
              </span>
            </div>
            <div className={styles['pattern']}>
              <span>
                {series.module}&nbsp;
                {series.module === '自定义' ? '' : series.level}
              </span>
              {series.courseType === '移动门框' ? (
                <span style={{ marginLeft: '80px' }}>
                  <img src={ydmk} alt="" />{' '}
                </span>
              ) : series.courseType === '两人配合' ? (
                <span style={{ marginLeft: '80px' }}>
                  {' '}
                  <img src={twoPeo} alt="" />{' '}
                </span>
              ) : series.courseType === '多人轮换' ? (
                <span style={{ marginLeft: '80px' }}>
                  {' '}
                  <img src={anyPeo} alt="" />{' '}
                </span>
              ) : series.courseType === '对抗模拟' ? (
                <span style={{ marginLeft: '80px' }}>
                  {' '}
                  <img src={dkmn} alt="" />{' '}
                </span>
              ) : series.courseType === '对抗VS' ? (
                <span style={{ marginLeft: '80px' }}>
                  {' '}
                  <img src={dkVS} alt="" />{' '}
                </span>
              ) : (
                ''
              )}
              {series.module === '自定义' ? (
                ''
              ) : (
                <div style={{ display: 'inline-block' }}>
                  {series.difficulty === null || '' ? (
                    ''
                  ) : (
                    <span className={styles['levle']}>
                      {' '}
                      {series.difficulty}{' '}
                    </span>
                  )}
                  {series.balls === null || '' ? (
                    ''
                  ) : (
                    <span className={styles['levle']}> {series.balls} </span>
                  )}
                </div>
              )}

              <span style={{ marginLeft: '30%' }}>
                {' '}
                <img onClick={() => this.showVideo()} src={video} alt="" />{' '}
              </span>
            </div>
          </div>
        ) : (
          <div>
            <div style={{ padding: '23px 8px 20px 23px', fontSize: '23px' }}>
              <span>
                <span style={{ paddingRight: '5px' }}>
                  <img src={user} alt="用户名" />
                </span>
                {footballer.map((v, key) => v.footballerName)}

                {/*{footballer.length > 0 && footballer[0].footballerName}*/}
              </span>
            </div>
            <div style={{ paddingLeft: '23px', fontSize: '23px' }}>
              <span>
                <img src={time} alt="时间" />
                &nbsp;
                {pmSeries.startTime}
              </span>
              <p style={{ marginTop: '10px' }}>
                {series.mutrixName === null ? (
                  ''
                ) : (
                  <span>
                    <img src={Address} alt="工场" /> &nbsp;{series.mutrixName}
                  </span>
                )}
              </p>
            </div>
            <div
              style={{
                height: 'auto',
                borderTop: '3px solid #ffffff',
                fontSize: '20px',
                lineHeight: '115px',
                paddingLeft: '23px',
                marginTop: '16px',
              }}
            >
              <span>
                {series.module}&nbsp;{series.level}
                {/*className={styles['']}*/}
              </span>
              <br />
              {series.courseType === '单人综合' ? (
                <img src={one} className={styles['one-img']} alt="训练模式" />
              ) : series.courseType === '守门员' ? (
                <img
                  src={goalkeeper}
                  className={styles['one-img']}
                  alt="训练模式"
                />
              ) : (
                ''
              )}

              {series.difficulty === '' ? (
                ''
              ) : (
                <div className={styles['one-leve']}>{series.difficulty}</div>
              )}
              <div className={styles['one-leve']}>{series.balls}</div>
              {/* 点击显示所有的视频，播放所有的视频 */}
              <img
                onClick={() => {
                  this.showVideo();
                }}
                style={{ marginLeft: '10px', verticalAlign: 'top' }}
                src={video}
                alt="视频"
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  onChartReadyCallback = () => {
    if (this.state.radar.indicator) {
    }
  };
  // 播放视频
  showVideo() {
    let { urls } = this.state;
    if (urls.length > 0) {
      this.setState({ videoShow: true });
    }
  }
  /**
   * 雷达图
   */
  radarChart = () => {
    const pmSeries = this.state.pmSeries;
    const sc = pmSeries.scoreV2 ? Math.round(pmSeries.scoreV2) : '--';
    let series = this.state.series;
    return (
      <div style={{ height: '90%' }}>
        {this.state.ability.length === 0 ? (
          <div style={{ textAlign: 'center', marginTop: '36px' }}>
            {series.courseType === '对抗VS' ? (
              // 训练模式
              // 对抗模拟（右侧显示 例如：本次获胜方 蓝队）
              <span>
                <span style={{ fontSize: '36px' }}>本次获胜方</span>
                <p
                  className={styles['big']}
                  style={
                    this.state.winner === '蓝队'
                      ? { color: '#209DFD' }
                      : this.state.winner === '黄队'
                      ? { color: '#f4a300' }
                      : { color: '#000000' }
                  }
                >
                  {this.state.winner}
                </p>
              </span>
            ) : (
              // 训练模式
              // 多人-两人-对抗模拟-移动门框 （右侧显示）
              // <span>
              //   <span style={{ fontSize: '36px' }}>本次训练得分</span>
              //   <p style={{ fontSize: '49px' }}>
              //     {sc}
              //   </p>
              // </span>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <div
                  style={{
                    backgroundColor: '#00C1DD',
                    margin: '20px auto',
                    color: '#ffffff',
                    fontSize: '23px',
                    borderRadius: '20px',
                    width: '160px',
                    textAlign: 'center',
                  }}
                >
                  <span>
                    Difficulty{' '}
                    {pmSeries.coefficient
                      ? pmSeries.coefficient.toFixed(2)
                      : '1.00'}
                  </span>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      width: '50%',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    <p style={{ marginBottom: 0, fontSize: '23px' }}>完成分</p>
                    <p style={{ marginBottom: 0, fontSize: '80px' }}>{sc}</p>
                  </div>
                  <div
                    style={{ flex: 1, color: '#000000', fontWeight: 'bold' }}
                  >
                    
                    <p style={{ marginBottom: 0, fontSize: '23px' }}>
                      最终得分
                    </p>
                    <p style={{ marginBottom: 0, fontSize: '80px' }}>
                      {pmSeries.points ? Math.round(pmSeries.points) : '--'}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div style={{ height: '89%', display: 'flex', position: 'relative' }}>
            <div
              style={{
                display: 'inline-block',
                verticalAlign: '149px',
                width: '40%',
                textAlign: 'center',
                color: '#383D43',
                paddingTop: '15px',
              }}
            >
              <div style={{ color: '#000000', fontWeight: 'bold' }}>
                <p style={{ marginBottom: 0, fontSize: '23px' }}>完成分</p>
                <p style={{ marginBottom: 0, fontSize: '80px' }}>{sc}</p>
              </div>
              <div
                style={{
                  backgroundColor: '#00C1DD',
                  margin: '20px auto',
                  color: '#ffffff',
                  fontSize: '23px',
                  borderRadius: '20px',
                  width: '160px',
                  textAlign: 'center',
                }}
              >
                <span>
                  Difficulty{' '}
                  {pmSeries.coefficient
                    ? pmSeries.coefficient.toFixed(2)
                    : '1.00'}
                </span>
              </div>
              <div style={{ color: '#000000', fontWeight: 'bold' }}>
                <p style={{ marginBottom: 0, fontSize: '23px' }}>最终得分</p>
                <p style={{ marginBottom: 0, fontSize: '80px' }}>
                  {pmSeries.points ? Math.round(pmSeries.points) : '--'}
                </p>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <ReactEcharts
                option={this.getRadarOption()}
                notMerge={true}
                lazyUpdate={true}
                onChartReady={this.onChartReadyCallback}
                theme={'theme_name'}
                style={{
                  height: '100%',
                  width: '70%',
                  marginLeft: '130px',
                  display: 'inline-block',
                  marginTop: '55px',
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  /**
   * 饼状图
   */
  pieChart = () => {
    return (
      <Row style={{ position: 'relative' }}>
        {this.state.pie.chip &&
          this.state.pie.chip.map((o, index) => (
            <Col span={8} key={index}>
              <ReactEcharts
                key={index}
                option={this.getPieChartOption(
                  this.state.pie.data.filter(i => i.pieType === o)
                )}
                notMerge={true}
                lazyUpdate={true}
              />
            </Col>
          ))}
        <div
          style={{
            position: 'absolute',
            right: '0',
            bottom: '60px',
            width: '100%',
            paddingRight: '7%',
            textAlign: 'end',
            zIndex: '100',
          }}
        >
          <button onClick={this.showDeviation}>
            <img src={err} alt="" />
          </button>
        </div>
      </Row>
    );
  };

  deviationModal = () => {
    let mainModal = this.state.deviationModal;
    return (
      <Modal
        title={mainModal.title}
        visible={mainModal.visible}
        closable={false}
        destroyOnClose={true}
        width={660}
        footer={[
          <Button key="submit" type="primary" onClick={this.closeDeviation}>
            确定
          </Button>,
        ]}
      >
        <Table
          columns={mainModal.columns}
          showHeader={true}
          dataSource={mainModal.data}
          pagination={false}
          scroll={{ y: 300 }}
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-even' : 'table-row-odd'
          }
        />
      </Modal>
    );
  };

  abilityChart = () => {
    let ability = this.state.ability;
    return (
      <div>
        <div
          style={{
            textAlign: 'center',
            fontSize: '20px',
            paddingBottom: '16px',
            paddingTop: '12px',
          }}
        >
          <span>各项能力指标</span>
        </div>
        {ability.map((o, key) => this.abilityRow(o, key))}
      </div>
    );
  };
  abilityRow = e => {
    let series = this.state.series;
    let width = (e.value * 2).toFixed(0);
    let color = BarColor[0];
    if (e.value < 80 && e.value >= 60) {
      color = BarColor[2]; //60-80黄色
    } else if (e.value < 60) {
      color = BarColor[3]; //0-60  红色
    } else if (e.value >= 80) {
      color = BarColor[0]; //80-100绿色
    }
    let indicator =
      e.lrr > 0 ? (
        <img src={green} alt="" style={{ width: '12px', height: '26px' }} />
      ) : e.lrr < 0 ? (
        <img src={red} alt="" style={{ width: '12px', height: '26px' }} />
      ) : (
        ''
      );
    return (
      <div
        style={{
          display: 'flex',
          height: '40px',
          marginTop: '10px',
          fontSize: '18px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <span
          style={{ width: '120px', textAlign: 'right', marginRight: '10px' }}
        >
          {e.name}
        </span>
        <span
          style={{
            width: width + 'px',
            height: '20px',
            backgroundColor: color,
          }}
        >
          {' '}
        </span>
        <span
          style={{
            width: 200 - width + 'px',
            height: '20px',
            backgroundColor: '#fff',
            marginRight: '10px',
          }}
        >
          {' '}
        </span>
        <span style={{ width: '40px', textAlign: 'center' }}>
          {e.value.toFixed()}
        </span>
        {/*箭头了*/}
        <span>{series.module === '排位模式' ? indicator : ''}</span>
      </div>
    );
  };

  render() {
    let { series, videoShow, urls } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        {/*偏差值模态窗*/}
        <this.deviationModal />
        {/*1.左侧*/}
        <Row>
          <Col span={this.state.ability.length === 0 ? 24 : 18}>
            <div
              style={{
                // display: 'inline-block',
                // width: this.state.ability.length === 0 ? '100%' : '79%',
                height: 'calc(100vh)',
                border: '1px',
                borderRadius: '8px',
              }}
            >
              {/*1.1顶部个人信息、训练信息、雷达图*/}
              <div
                style={{
                  display: 'flex',
                  background: '#f5f5f5',
                  borderRadius: '8px',
                }}
              >
                {/*1.1.1 顶部个人信息、训练信息*/}
                <div
                  style={{
                    width: this.state.ability.length === 0 ? '75%' : '40%',
                    height: '100%',
                    borderRight: '3px solid #fff',
                  }}
                >
                  <this.basicData />
                </div>

                {/*1.1.2 雷达图*/}
                <div
                  style={
                    this.state.ability.length === 0
                      ? { width: '25%' }
                      : { width: '95%' }
                  }
                >
                  <this.radarChart />
                </div>
              </div>
              <div style={{ height: '11px' }}> </div>

              {/*1.2柱状图+饼图*/}
              <div style={{ background: '#f5f5f5', borderRadius: '8px' }}>
                {/*1.2.1 柱状图*/}
                <div>
                  <Row style={{ textAlign: 'center', fontSize: '20PX' }}>
                    <Col span={8}>
                      <p style={{ marginTop: '10px' }}>
                        累计时长：{this.state.minute}.{this.state.second}″
                      </p>
                    </Col>
                    {series.courseType === '对抗VS' ? (
                      <span>
                        <Col span={8}>
                          <p style={{ textAlign: 'center', marginTop: '10px' }}>
                            蓝队得分：{this.state.blueScore}
                          </p>
                        </Col>
                        <Col span={8}>
                          <p style={{ textAlign: 'center', marginTop: '10px' }}>
                            黄队得分：{this.state.yellowScore}
                          </p>
                        </Col>
                      </span>
                    ) : (
                      <span>
                        <Col span={8}>
                          <p style={{ marginTop: '10px' }}>
                            平均时长：{this.state.averageTime}″
                          </p>
                        </Col>
                        <Col span={8}>
                          <p style={{ marginTop: '10px' }}>
                            {series.courseType === '守门员'
                              ? '扑救成功率：'
                              : '有效命中率：'}
                            {/* {(
                              (this.state.hitRate /
                                this.state.bar.yAxis.length) *
                              100
                            ).toFixed(2)} */}
                            {this.state.hitRate}%
                          </p>
                        </Col>
                      </span>
                    )}
                  </Row>
                  {series.courseType === '守门员' ? (
                    <div className={styles['goalkeeper']}>
                      <span className={styles['goalkeeper-box']}>
                        <p> </p> <span>成功命中</span>
                      </span>
                      <span className={styles['goalkeeper-box']}>
                        <p style={{ background: '#F7B824' }}> </p>{' '}
                        <span>成功未命中</span>
                      </span>
                      <span className={styles['goalkeeper-box']}>
                        <p style={{ background: '#FB6D74' }}> </p>{' '}
                        <span>失败</span>
                      </span>
                    </div>
                  ) : series.courseType === '对抗VS' ? (
                    <div className={styles['goalkeeper']}>
                      <span className={styles['goalkeeper-box']}>
                        <p style={{ background: '#209DFD' }}> </p>{' '}
                        <span>蓝队成功命中</span>
                      </span>
                      <span className={styles['goalkeeper-box']}>
                        <p style={{ background: '#00C1DE' }}> </p>{' '}
                        <span>蓝队成功未命中</span>
                      </span>
                      <span className={styles['goalkeeper-box']}>
                        <p style={{ background: '#FFB10A' }}> </p>{' '}
                        <span>黄队成功命中</span>
                      </span>
                      <span className={styles['goalkeeper-box']}>
                        <p style={{ background: '#FB9B6C' }}> </p>{' '}
                        <span>黄队成功未命中</span>
                      </span>
                    </div>
                  ) : (
                    <div className={styles['goalkeeper']}>
                      <span className={styles['goalkeeper-case']}>
                        <p style={{ background: '#79D03F' }}> </p> 命中未超时
                      </span>
                      <span className={styles['goalkeeper-case']}>
                        <p style={{ background: '#00C1DE' }}> </p>命中超时
                      </span>
                      <span className={styles['goalkeeper-case']}>
                        {' '}
                        <p style={{ background: '#F7B824', padding: '0 10px' }}>
                          {' '}
                        </p>
                        未命中未超时
                      </span>
                      <span className={styles['goalkeeper-case']}>
                        {' '}
                        <p style={{ background: '#FB6D74', padding: '0 10px' }}>
                          {' '}
                        </p>
                        未命中超时
                      </span>
                    </div>
                  )}
                  {/*柱状图*/}
                  <this.barChart />
                </div>
                <Divider style={{ background: '#ffffff', height: '2px' }} />
                {/*1.2.2 饼图*/}
                <div>
                  <this.pieChart />
                  {/*<div*/}
                  {/*    style={{*/}
                  {/*      position: 'absolute',*/}
                  {/*      marginLeft: '56%',*/}
                  {/*      marginTop: '-6%',*/}
                  {/*      zIndex: 100,*/}
                  {/*    }}*/}
                  {/*>*/}
                  {/*  <button onClick={this.showDeviation}>*/}
                  {/*    <img src={err} alt="" />*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                </div>
                {/*偏差*/}
              </div>
            </div>
          </Col>
          <Col span={this.state.ability.length == 0 ? 0 : 6}>
            {/*右侧，能力指标*/}
            <div
              style={{
                // display: this.state.ability.length === 0 ? 'none' : 'block',
                // width: '20%',
                height: 'calc(100vh)',
                marginLeft: '18px',
                background: '#f5f5f5',
                verticalAlign: 'top',
                borderRadius: '8px',
              }}
            >
              <this.abilityChart />
            </div>
          </Col>
        </Row>

        {videoShow && (
          <div
            onClick={e => {
              e.preventDefault();
              this.setState({ videoShow: false });
            }}
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              float: 'left',
              left: 0,
              top: 0,
              backgroundColor: 'rgba(0,0,0,0.4)',
            }}
          >
            <div
              onClick={e => e.stopPropagation()}
              style={{
                width: '1000px',
                height: '500px',
                position: 'absolute',
                float: 'left',
                left: '20%',
                top: '10%',
                zIndex: '9999',
              }}
            >
              <Carousel beforeChange={(from, to) => this.SSketchpad(from, to)}>
                {urls.length > 0 &&
                  urls.map((el, index) => (
                    <div>
                      <video
                        className="videoList"
                        width="100%"
                        height="100%"
                        src={el}
                        controls="controls"
                      />
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
    );
  }
  SSketchpad(from, to) {
    const HtmlDom = document.getElementsByClassName(`videoList`);
    for (let i = 0; i < HtmlDom.length; i++) {
      HtmlDom[i].pause();
    }
  }
}

export default Result;
