import React from 'react';
import { Link } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { Breadcrumb, Badge, Icon } from 'antd';

// 更多配置请移步 https://github.com/icd2k3/react-router-breadcrumbs-hoc
const routes = [
  { path: '/homePage/systemHome', breadcrumb: '首页' },
  { path: '/homePage/MutrixHome', breadcrumb: '首页' },
  { path: '/orgManage/customerManage', breadcrumb: '客户管理' },
  { path: '/orgManage/organizationManage', breadcrumb: '组织管理' },
];

const Breadcrumbs = ({ data }) => {
  console.log(data);
  if (data || Array.isArray(data)) {
    const AntdBreadcrumb = withBreadcrumbs(data)(({ breadcrumbs }) => {
      return (
        <Breadcrumb
          separator={<Icon type="double-right" />}
          classNames="spread"
        >
          <span>123456789</span>
          {breadcrumbs.map((breadcrumb, index) => (
            <Breadcrumb.Item key={breadcrumb.key}>
              {breadcrumbs.length - 1 === index ? (
                <Badge status="processing" text={breadcrumb} />
              ) : (
                <Link
                  to={{
                    pathname: breadcrumb.props.match.url,
                    state: breadcrumb.props.match.params
                      ? breadcrumb.props.match.params
                      : {},
                    query: breadcrumb.props.location.query
                      ? breadcrumb.props.location.query
                      : {},
                  }}
                >
                  {breadcrumb}
                </Link>
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      );
    });
    return <AntdBreadcrumb />;
  }
  const DefaultBreadcrumb = withBreadcrumbs(routes)(({ breadcrumbs }) => (
    <div>
      {breadcrumbs.map((breadcrumb, index) => (
        <span key={breadcrumb.key}>
          <Link
            to={{
              pathname: breadcrumb.props.match.url,
              state: breadcrumb.props.match.params
                ? breadcrumb.props.match.params
                : {},
              query: breadcrumb.props.location.query
                ? breadcrumb.props.location.query
                : {},
            }}
          >
            {breadcrumb}
          </Link>
          {index < breadcrumbs.length - 1 && <i> / </i>}
        </span>
      ))}
    </div>
  ));
  return <DefaultBreadcrumb />;
};

export default withBreadcrumbs(Breadcrumbs);
