import React from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Table,
} from 'antd';
import { UWB_ADD, UWB_DELETE, UWB_LIST, UWB_MODIFY } from '../../app/api';
import ListTitle from '../../components/ListTitle/listTiltle';
// import './organizationManage.module.css';

const defaultModalData = {
  visible: false,
  title: '新增UWB',
  modalData: {
    valid: true,
    org: { id: 0, name: '' },
    mutrix: { id: 0, name: '' },
  },
};
class UwbManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // API查询的数据存储
      data: [],
      mainModal: Object.assign({}, defaultModalData),
      // 分页的数据存储
      pagination: {},
      selectedMutrixId: 0,
      mutrixId: '',
    };

    this.column = [
      {
        key: '1',
        title: '序号',
        // dataIndex:'id',
        width: '5%',
        render: (text, record, index) => (
          <span>
            {index +
              1 +
              (this.state.pagination.current - 1) *
                this.state.pagination.pageSize}
          </span>
        ),
      },
      {
        key: '2',
        title: '目录路径',
        dataIndex: 'home',
        width: '10%',
      },
      {
        key: '3',
        title: '启动命令',
        dataIndex: 'command',
        width: '10%',
      },
      {
        key: '4',
        title: '检测半径',
        dataIndex: 'radius',
        width: '10%',
      },
      {
        key: '5',
        title: '所属工场',
        dataIndex: 'mutrixName',
        width: '10%',
        align: 'center',
      },
      {
        key: '6',
        title: '所属组织',
        dataIndex: 'orgName',
        width: '15%',
        align: 'center',
      },
      {
        key: '7',
        title: '状态',
        dataIndex: 'validName',
        width: '5%',
      },
      {
        title: '操作',
        key: 'operation',
        width: '15%',
        render: (text, record) => (
          <span>
            <Button onClick={this.editMainModal.bind(this, record)}>
              编辑
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title={'删除【' + record.name + '】不可恢复，你确定要删除吗?'}
              onConfirm={() => this.handleDelete(record)}
              okText="确定"
              cancelText="取消"
            >
              <Button title="用户删除">删除</Button>
            </Popconfirm>
          </span>
        ),
      },
    ];

    this.axis = [
      { title: '中心坐标', xAxis: 'centerXaxis', yAxis: 'centerYAxis' },
      { title: 'DA面角坐标', xAxis: 'corner1Xaxis', yAxis: 'corner1Yaxis' },
      { title: 'AB面角坐标', xAxis: 'corner2Xaxis', yAxis: 'corner2Yaxis' },
      { title: 'BC面角坐标', xAxis: 'corner3Xaxis', yAxis: 'corner3Yaxis' },
      { title: 'CD面角坐标', xAxis: 'corner4Xaxis', yAxis: 'corner4Yaxis' },
      { title: 'A面发球机坐标', xAxis: 'side1Xaxis', yAxis: 'side1Yaxis' },
      { title: 'B面发球机坐标', xAxis: 'side2Xaxis', yAxis: 'side2Yaxis' },
      { title: 'C面发球机坐标', xAxis: 'side3Xaxis', yAxis: 'side3Yaxis' },
      { title: 'D面发球机坐标', xAxis: 'side4Xaxis', yAxis: 'side4Yaxis' },
    ];
  }

  componentWillMount() {
    // let params = new URLSearchParams(window.location.search.split('?')[1]);
    // this.state.mutrixId = params.get('id');
  }
  componentDidMount() {
    this.listByMutrixId(this.props.mutrixId);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.listByMutrixId(nextProps.mutrixId);

    if (nextProps.addModalVisible) {
      this.addMainModal();
    }
  }

  listByMutrixId = mutrixId => {
    mutrixId !== this.state.mutrixId &&
      this.setState({ mutrixId: mutrixId }, () =>
        this.list({ mutrixId: mutrixId })
      );
  };
  /**
   * 加载数据API
   */
  list = params => {
    UWB_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const theyData = data.map(v =>
        Object.assign({}, v.entity, {
          orgName: v.org.name,
          mutrixName: v.mutrix.name,
          rowKey: v.entity.id,
          mutrix: v.mutrix,
          validName: v.entity.valid ? '启用' : '禁用',
        })
      );
      this.setState({
        data: theyData,
        pagination,
      });
    });
  };

  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
      mutrixId: this.props.mutrixId,
    });
  };

  handleMutrixSelectChange = value => {
    let mainModal = this.state.mainModal;
    mainModal.modalData.mutrix = value;
    this.setState({ mainModal: mainModal });
  };

  /**
   * 新增弹窗
   */
  addMainModal = () => {
    let mainModal = this.state.mainModal;
    mainModal.modalData = Object.assign({}, defaultModalData.modalData);
    mainModal.visible = true;
    mainModal.title = defaultModalData.title;
    this.setState({ modalData: mainModal });
    this.props.callback();
  };

  editMainModal = record => {
    let mainModal = this.state.mainModal;
    mainModal.modalData = record;
    mainModal.visible = true;
    mainModal.title = '编辑UWB';

    this.setState({ modalData: mainModal });
    this.props.callback();
  };

  handleMainModalCancel = () => {
    let mainModal = this.state.mainModal;
    mainModal.visible = false;
    this.setState({ modalData: mainModal });
  };

  handleMainModalOK = () => {
    let mainModal = this.state.mainModal;
    mainModal.visible = false;
    let mutrix = {
      id: this.state.mutrixId,
      name: this.props.mutrixName,
    };
    this.setState({ mainModal: mainModal });
    this.props.callback();
    // 提交数据发起修改了
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        let body = {
          entity: {
            id: mainModal.modalData.id,
            home: values.home,
            command: values.command,
            radius: values.radius,
            centerXaxis: values.centerXaxis,
            centerYAxis: values.centerYAxis,
            corner1Xaxis: values.corner1Xaxis,
            corner1Yaxis: values.corner1Yaxis,
            corner2Xaxis: values.corner2Xaxis,
            corner2Yaxis: values.corner2Yaxis,
            corner3Xaxis: values.corner3Xaxis,
            corner3Yaxis: values.corner3Yaxis,
            corner4Xaxis: values.corner4Xaxis,
            corner4Yaxis: values.corner4Yaxis,
            side1Xaxis: values.side1Xaxis,
            side1Yaxis: values.side1Yaxis,
            side2Xaxis: values.side2Xaxis,
            side2Yaxis: values.side2Yaxis,
            side3Xaxis: values.side3Xaxis,
            side3Yaxis: values.side3Yaxis,
            side4Xaxis: values.side4Xaxis,
            side4Yaxis: values.side4Yaxis,
            valid: mainModal.modalData.valid,
          },
          mutrix: mutrix,
        };
        let api = '';
        if (mainModal.title === '新增UWB') {
          api = UWB_ADD;
        } else {
          api = UWB_MODIFY;
        }
        api(body)
          .then(res => {
            message.success(
              this.state.mainModal.title + '【' + values.name + '】成功'
            );
            this.list({ mutrixId: this.props.mutrixId });
          })
          .catch(err => {
            if (err) {
              message.error(err);
            }
          });
      }
    });
  };

  handleDelete = record => {
    UWB_DELETE({ id: record.id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.name + '】成功');
          this.list({ mutrixId: this.props.mutrixId });
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };

  mainModal = () => {
    const { getFieldDecorator } = this.props.form;
    let mainModal = this.state.mainModal;
    return (
      <Modal
        title={mainModal.title}
        visible={mainModal.visible}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={this.handleMainModalCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleMainModalOK}>
            确定
          </Button>,
        ]}
      >
        <Form>
          <Form.Item label="目录路径" className="huge-form-label">
            {getFieldDecorator('home', {
              initialValue: mainModal.modalData.home,
              rules: [
                {
                  required: true,
                  message: '请输入目录路径',
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="启动命令" className="huge-form-label">
            {getFieldDecorator('command', {
              initialValue: mainModal.modalData.command,
              rules: [
                {
                  required: true,
                  message: '请输入启动命令',
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="检测半径" className="huge-form-label">
            {getFieldDecorator('radius', {
              initialValue: mainModal.modalData.radius,
              rules: [
                {
                  required: true,
                  message: '请输入检测半径',
                },
              ],
            })(<Input />)}
          </Form.Item>

          {this.axis.map(axis => (
            <Form.Item
              label={axis.title + '(X/Y)'}
              key={axis.id}
              className="double-form-label"
            >
              <Row gutter={20}>
                <Col span={12}>
                  {getFieldDecorator(axis.xAxis, {
                    initialValue: mainModal.modalData[axis.xAxis], //填充input默认数据
                    rules: [
                      {
                        required: true,
                        message: axis.title + 'X值',
                      },
                    ],
                  })(<Input />)}
                </Col>
                <Col span={12}>
                  {getFieldDecorator(axis.yAxis, {
                    initialValue: mainModal.modalData[axis.yAxis], //填充input默认数据
                    rules: [
                      {
                        required: true,
                        message: axis.title + 'Y值',
                      },
                    ],
                  })(<Input />)}
                </Col>
              </Row>
            </Form.Item>
          ))}
          {/*所属工场*/}
          <Form.Item label="所属工场" className="huge-form-label">
            {/*<ListTitle*/}
            {/*  selected={mainModal.modalData.mutrix.id}*/}
            {/*  title="工场名称"*/}
            {/*  data={this.props.selectMutrixes}*/}
            {/*  callback={this.handleMutrixSelectChange}*/}
            {/*/>*/}
            <span>{this.props.mutrixName}</span>
          </Form.Item>

          <Form.Item label="状态" className="huge-form-label">
            <Radio.Group
              options={[
                { label: '启用', value: true },
                { label: '禁用', value: false },
              ]}
              onChange={e => {
                mainModal.modalData.valid = e.target.value;
                this.setState({});
              }}
              value={mainModal.modalData.valid}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  render() {
    let inputColumns = []; //根据props输入的数组进行过滤和筛选
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            rowKey: record.key,
          }),
        };
      }
    );
    return (
      <div>
        {/* 标题栏 包括下拉框、新增按钮 等*/}
        {/*<this.titleSection />*/}
        <this.mainModal />

        {/* 表格 */}
        <Table
          columns={column}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
        />
      </div>
    );
  }
}

export default Form.create()(UwbManage);
