import React from 'react';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Table,
} from 'antd';
import {
  THEME_ADD,
  THEME_DELETE,
  THEME_LIST,
  THEME_MODIFY,
} from '../../app/api';
import ListTitle from '../../components/ListTitle/listTiltle';
import moment from 'moment';
import MutrixThemePicture from './MutrixThemePicture';
import locale from 'antd/lib/date-picker/locale/zh_CN';

const defaultModalData = {
  visible: false,
  title: '新增主题',
  modalData: {
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    endDate: moment(new Date())
      .add(1, 'month')
      .format('YYYY-MM-DD'),
    // startDate:'',
    // endDate:'',
    valid: true,
    org: { id: 0, name: '' },
    mutrix: { id: 0, name: '' },
  },
};
class MutrixTheme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // API查询的数据存储
      data: [],
      mainModal: Object.assign({}, defaultModalData),
      // 分页的数据存储
      pagination: {},
      selectedMutrixId: 0,

      picture: {
        visible: false,
        selectedThemeId: 0,
        title: '',
      },
      mutrixId: '',
    };

    this.column = [
      {
        key: '1',
        title: '序号',
        // dataIndex:'id',
        width: '5%',
        render: (text, record, index) => (
          <span>
            {index +
              1 +
              (this.state.pagination.current - 1) *
                this.state.pagination.pageSize}
          </span>
        ),
      },
      {
        key: '2',
        title: '主题',
        dataIndex: 'name',
        width: '10%',
      },
      {
        key: '3',
        title: '优先级',
        dataIndex: 'priority',
        width: '10%',
      },
      {
        key: '4',
        title: '启用日期',
        dataIndex: 'startDate',
        width: '10%',
      },
      {
        key: '5',
        title: '停用日期',
        dataIndex: 'endDate',
        width: '10%',
      },
      {
        key: '6',
        title: '所属工场',
        dataIndex: 'mutrixName',
        width: '10%',
        align: 'center',
      },
      {
        key: '7',
        title: '所属组织',
        dataIndex: 'orgName',
        width: '15%',
        align: 'center',
      },
      {
        key: '8',
        title: '状态',
        dataIndex: 'validName',
        width: '5%',
      },
      {
        title: '操作',
        key: 'operation',
        width: '15%',
        render: (text, record) => (
          <span>
            <Button onClick={this.editMainModal.bind(this, record)}>
              编辑
            </Button>
            <Divider type="vertical" />
            <Button onClick={this.editPictureModal.bind(this, record)}>
              图库
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title={'删除【' + record.name + '】不可恢复，你确定要删除吗?'}
              onConfirm={() => this.handleDelete(record)}
              okText="确定"
              cancelText="取消"
            >
              <Button title="用户删除">删除</Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  componentWillMount() {
    // let params = new URLSearchParams(window.location.search.split('?')[1]);
    // this.state.mutrixId = params.get('id');
  }
  componentDidMount() {
    this.listByMutrixId(this.props.mutrixId);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.listByMutrixId(nextProps.mutrixId);

    if (nextProps.addModalVisible) {
      this.addMainModal();
    }
  }

  listByMutrixId = mutrixId => {
    mutrixId !== this.state.mutrixId &&
      this.setState({ mutrixId: mutrixId }, () =>
        this.list({ mutrixId: mutrixId })
      );
  };
  /**
   * 加载数据API
   */
  list = params => {
    THEME_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const theyData = data.map(v =>
        Object.assign({}, v.entity, {
          orgName: v.org.name,
          mutrixName: v.mutrix.name,
          rowKey: v.entity.id,
          mutrix: v.mutrix,
          validName: v.entity.valid ? '启用' : '禁用',
        })
      );
      this.setState({
        data: theyData,
        pagination,
      });
    });
  };

  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
      mutrixId: this.props.mutrixId,
    });
  };

  handleMutrixSelectChange = value => {
    let mainModal = this.state.mainModal;
    mainModal.modalData.mutrix = value;
    this.setState({ mainModal: mainModal });
  };

  /**
   * 新增弹窗
   */
  addMainModal = () => {
    let mainModal = this.state.mainModal;
    mainModal.modalData = Object.assign({}, defaultModalData.modalData);
    mainModal.visible = true;
    mainModal.title = defaultModalData.title;
    this.setState({ modalData: mainModal });
    this.props.callback();
  };

  editMainModal = record => {
    let mainModal = this.state.mainModal;
    mainModal.modalData = record;
    mainModal.visible = true;
    mainModal.title = '编辑主题';

    this.setState({ modalData: mainModal });
    this.props.callback();
  };

  handleMainModalCancel = () => {
    let mainModal = this.state.mainModal;
    mainModal.visible = false;
    this.setState({ modalData: mainModal });
  };

  handleMainModalOK = () => {
    let mainModal = this.state.mainModal;
    mainModal.visible = false;
    let mutrix = {
      id: this.state.mutrixId,
      name: this.props.mutrixName,
    };
    this.setState({ mainModal: mainModal });
    this.props.callback();
    // 提交数据发起修改了
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        let body = {
          entity: {
            id: mainModal.modalData.id,
            name: values.name,
            priority: values.priority,
            description: values.description,

            startDate: mainModal.modalData.startDate,
            endDate: mainModal.modalData.endDate,
            valid: mainModal.modalData.valid,
          },
          mutrix: mutrix,
        };
        let api = '';
        if (mainModal.title === '新增主题') {
          api = THEME_ADD;
        } else {
          api = THEME_MODIFY;
        }
        api(body)
          .then(res => {
            message.success(
              this.state.mainModal.title + '【' + values.name + '】成功'
            );
            this.list({ mutrixId: this.props.mutrixId });
          })
          .catch(err => {
            if (err) {
              message.error(err);
            }
          });
      }
    });
  };

  editPictureModal = record => {
    let picture = this.state.picture;
    picture.selectedThemeId = record.id;
    picture.title = record.name;
    picture.visible = true;
    picture.update = true;
    this.setState({ picture });
  };

  pictureCallback = () => {
    let picture = this.state.picture;
    picture.visible = false;
    picture.update = false;
    this.setState({ picture });
  };

  handleDelete = record => {
    THEME_DELETE({ id: record.id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.name + '】成功');
          this.list({ mutrixId: this.props.mutrixId });
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };
  handleStartDateChange = (value, dateString) => {
    let mainModal = this.state.mainModal;
    mainModal.modalData.startDate = dateString;
    this.setState({ mainModal });
  };
  handelEndDateChange = (value, dateString) => {
    let mainModal = this.state.mainModal;
    mainModal.modalData.endDate = dateString;
    this.setState({ mainModal });
  };

  mainModal = () => {
    const { getFieldDecorator } = this.props.form;
    let mainModal = this.state.mainModal;
    return (
      <Modal
        title={mainModal.title}
        visible={mainModal.visible}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={this.handleMainModalCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleMainModalOK}>
            确定
          </Button>,
        ]}
      >
        <Form>
          <Form.Item label="主题" className="organizationName">
            {getFieldDecorator('name', {
              initialValue: mainModal.modalData.name,
              rules: [
                {
                  required: true,
                  message: '请输入主题名称',
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="优先级" className="organizationName">
            {getFieldDecorator('priority', {
              initialValue: mainModal.modalData.priority,
              rules: [
                {
                  required: true,
                  message: '请输入优先级',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item>
            <div>
              <span
                style={{
                  display: 'inline-block',
                  paddingRight: '8px',
                  width: '82px',
                  textAlign: 'right',
                  color: 'rgba(0, 0, 0, 0.85)',
                }}
              >
                启用日期:
              </span>
              <DatePicker
                defaultValue={moment(mainModal.modalData.startDate)}
                onChange={this.handleStartDateChange}
                style={{ width: '73%' }}
                placeholder="请选择年/月/日"
                locale={locale}
              />
              <br />
            </div>
          </Form.Item>

          <Form.Item>
            <div>
              <span
                style={{
                  display: 'inline-block',
                  paddingRight: '8px',
                  width: '82px',
                  textAlign: 'right',
                  color: 'rgba(0, 0, 0, 0.85)',
                }}
              >
                停用日期:
              </span>
              <DatePicker
                defaultValue={moment(mainModal.modalData.endDate)}
                onChange={this.handelEndDateChange}
                style={{ width: '73%' }}
                placeholder="请选择年/月/日"
                locale={locale}
              />
              <br />
            </div>
          </Form.Item>
          <Form.Item label="备注" className="organizationName">
            {getFieldDecorator('description', {
              initialValue: mainModal.modalData.description, //填充input默认数据
            })(<Input.TextArea rows={4} />)}
          </Form.Item>
          {/*所属工场*/}
          <Form.Item label="所属工场" className="organizationName">
            {/*<ListTitle*/}
            {/*  selected={mainModal.modalData.mutrix.id}*/}
            {/*  title="工场名称"*/}
            {/*  data={this.props.selectMutrixes}*/}
            {/*  callback={this.handleMutrixSelectChange}*/}
            {/*/>*/}
            <span>{this.props.mutrixName}</span>
          </Form.Item>

          <Form.Item label="状态" className="organizationName">
            <Radio.Group
              options={[
                { label: '启用', value: true },
                { label: '禁用', value: false },
              ]}
              onChange={e => {
                mainModal.modalData.valid = e.target.value;
                this.setState({});
              }}
              value={mainModal.modalData.valid}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  render() {
    let inputColumns = []; //根据props输入的数组进行过滤和筛选
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            rowKey: record.key,
          }),
        };
      }
    );
    return (
      <div>
        {/* 标题栏 包括下拉框、新增按钮 等*/}
        {/*<this.titleSection />*/}
        <this.mainModal />

        <MutrixThemePicture
          themeId={this.state.picture.selectedThemeId}
          visible={this.state.picture.visible}
          title={this.state.picture.title}
          update={this.state.picture.update}
          callback={this.pictureCallback}
        />
        {/* 表格 */}
        <Table
          columns={column}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
        />
      </div>
    );
  }
}

export default Form.create()(MutrixTheme);
