import React from 'react';
import {
  Table,
  Divider,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Popconfirm,
  message,
  Radio,
  Icon,
} from 'antd';
import {
  MUTRIX_LIST,
  MUTRIX_ADD,
  MUTRIX_MODIFY,
  CHANGEORG,
} from '../../app/api';
import { jumpTo } from '../../router/Router';
import GeoForm from '../../components/GeoForm';

const Option = Select.Option;

const defaultModalData = {
  visible: false,
  title: '新增工场',
  modalData: {
    org: {
      id: 0,
      name: '',
    },
    type: {
      id: 1,
      name: '独占型',
    },
  },
};

class MutrixList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // API查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},
      stateValue: '', //状态
      custmer: '',
      orgId: '',
      customId: '',
      selectCustoms: [],
      mainModal: Object.assign({}, defaultModalData),
      display: 'none',
      disabled: false,
      color: { color: '#1890ff', display: 'inline-block' },
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: '序号',
        // dataIndex:'id',
        width: '5%',
        render: (text, record, index) => (
          <span>
            {index +
              1 +
              (this.state.pagination.current - 1) *
                this.state.pagination.pageSize}
          </span>
        ),
      },
      {
        key: '2',
        title: '名称',
        dataIndex: 'name',
        width: '8%',
      },
      {
        key: '3',
        title: '所属客户',
        dataIndex: 'orgName',
        width: '10%',
      },
      {
        key: '4',
        title: '签约组织',
        dataIndex: 'customNumber',
        width: '5%',
      },
      {
        key: '5',
        title: '编号',
        dataIndex: 'serialno',
        width: '8%',
      },
      {
        key: '6',
        title: '工场类型',
        dataIndex: 'typeName',
        width: '5%',
        align: 'center',
      },
      {
        key: '7',
        title: '地区',
        dataIndex: 'fullAddress',
        width: '10%',
        align: 'center',
      },
      {
        key: '8',
        title: '详细地址',
        dataIndex: 'address',
        width: '15%',
        align: 'center',
      },
      {
        key: '9',
        title: '状态',
        dataIndex: 'valid',
        width: '5%',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
      {
        key: '10',
        title: '签约时间',
        dataIndex: 'startDate',
        width: '15%',
        align: 'center',
        render: startDate => {
          if (startDate !== null) {
            const str = startDate.replace(/ 00:00:00/, '');
            return str;
          }
        },
      },
      {
        key: '11',
        title: '服务期限',
        dataIndex: 'endDate',
        width: '15%',
        align: 'center',
        render: endDate => {
          if (endDate !== null) {
            const str = endDate.replace(/ 00:00:00/, '');
            return str;
          }
        },
      },
      {
        //这是客户详情列表中所用到的操作startDate
        title: '操作',
        key: 'operation1',
        width: '15%',
        render: (text, record) => (
          <span>
            <Popconfirm
              title={'删除【' + record.name + '】不可恢复，你确定要删除吗?'}
              onConfirm={() => this.handleDelete(record)}
              okText="确定"
              cancelText="取消"
            >
              <button title="用户删除">删除</button>
            </Popconfirm>
          </span>
        ),
      },
      {
        // 这是工场管理页面用到的操作
        title: '操作',
        key: 'operation2',
        width: '15%',
        align: 'center',
        render: (text, record) => (
          <span>
            <button
              onClick={() =>
                jumpTo('/pages/mutrixManage/mutrixDetail', { id: record.id })
              }
            >
              <Icon type="unordered-list" />
              详情
            </button>

            <Divider type="vertical" />

            <span>
              <button
                disabled={this.state.disabled}
                onClick={this.editMainModal.bind(this, record)}
                style={this.state.color}
              >
                <Icon type="edit" style={this.state.color} />
                编辑
              </button>
            </span>

            {/*<Divider type="vertical"/>*/}
            {/* <Popconfirm title={"删除【" + record.username+ "】不可恢复，你确定要删除吗?"} onConfirm={() => this.handleDelete(record)}>*/}
            {/*    <a title='用户删除'  href="javascript:;">删除</a>*/}
            {/*</Popconfirm>*/}
          </span>
        ),
      },
    ];
  }
  componentWillReceiveProps(nextProps, nextContext) {
    let param = {};
    let update = false;
    if (nextProps.customId !== this.state.customId) {
      //组织id
      param.customId = nextProps.customId;
      update = true;
    }
    if (nextProps.orgId !== this.state.orgId) {
      //客户id
      param.orgId = nextProps.orgId;
      update = true;
    }
    this.setState({ ...param });

    if (update) {
      this.setState({ ...param });
      this.list(param);
    }
  }
  // 加载数据
  componentWillMount() {
    // this.list();
  }
  componentDidMount() {
    this.props.onRef(this);
    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    if (custmer == 2) {
      this.setState({
        color: { display: 'none' },
      });
    }
  }
  /**
   * 加载数据API
   */
  list = params => {
    // params = params === undefined ? {} : params;
    // params.orgId = this.props.orgId;
    // params.customId = this.state.customId;
    MUTRIX_LIST(params).then(res => {
      const data = res.data.list.content; //list外层面的详细地址数据
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      // 后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const theyData = data.map(v =>
        Object.assign({}, v.entity, {
          org: v.org,
          type: v.type,
          rowKey: v.entity.id,
          startDate: v.startDate,
          endDate: v.endDate,
          orgName: v.org.name,
          typeName: v.type.name,
          customNumber: v.customNumber,
          fullAddress: v.fullAddress,
          validName: v.entity.valid ? '启用' : '禁用',
        })
      );
      this.setState({
        data: theyData,
        pagination,
      });
    });
  };
  /**
   * 删除当前工场行
   */
  handleDelete = record => {
    let list = [
      {
        endDate: '',
        startDate: '',
        mutrixId: record.id,
        mutrixName: record.name,
        orgId: '',
      },
    ];
    CHANGEORG(list)
      .then(res => {
        if (res.code == 0) {
          message.success('删除成功');
          this.list({ orgId: record.org.id });
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
    // MUTRIX_DELETE({id: record.id}).then(res=>{
    //     if (res.code === 0) {
    //         message.success('删除【' + record.name + '】成功');
    //         this.list()
    //     } else {
    //         message.error(res.message);
    //     }
    // }).catch(err => message.error(err))
  };
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
    });
  };
  /***
   * 状态启用禁用的方法
   */
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  /**
   * 新增弹窗
   */
  addMainModal = () => {
    this.setState({
      mainModal: {
        visible: true,
        title: '新增工场',
        modalData: {
          org: {
            id: 0,
            name: '',
          },
          type: {
            id: 1,
            name: '独占型',
          },
        },
      },
    });
  };
  /**
   * 编辑弹窗
   */
  editMainModal = record => {
    if (this.state.custmer == 3) {
      this.setState({ disabled: true, color: { color: '#cccccc' } });
      message.warning('您还没有当前权限');
    } else {
      let mainModal = this.state.mainModal;
      mainModal.modalData = record;
      mainModal.visible = true;
      mainModal.title = '编辑工场';
      this.setState({
        modalData: mainModal,
        stateValue: mainModal.modalData.valid,
      });
    }
  };
  handleMainModalCancel = () => {
    //取消
    this.props.form.resetFields();
    let mainModal = this.state.mainModal;
    mainModal.visible = false;
    this.setState({ modalData: mainModal, display: 'none' });
  };
  handleMainModalOK = () => {
    let mainModal = this.state.mainModal;
    // mainModal.visible = false;
    // this.setState({ modalData: defaultModalData });
    if (
      mainModal.modalData.province === 0 ||
      mainModal.modalData.city === 0 ||
      mainModal.modalData.region === 0
    ) {
      this.setState({ display: 'block' });
    } else if (
      mainModal.modalData.province === undefined ||
      mainModal.modalData.city === undefined ||
      mainModal.modalData.region === undefined
    ) {
      this.setState({
        display: 'block',
      });
    } else {
      this.setState({ display: 'none' });
      this.props.form.validateFields((errors, values) => {
        if (!errors) {
          let body = {
            entity: {
              id: mainModal.modalData.id,
              name: values.name,
              serialno: values.serialno,
              valid: this.state.stateValue,
              province: mainModal.modalData.province,
              city: mainModal.modalData.city,
              region: mainModal.modalData.region,
              address: values.address,
              longitude: values.longitude,
              latitude: values.latitude,
            },
            type: {
              id: values.type,
              // name: values.type,
            },
          };
          let api = '';
          if (mainModal.title === '新增工场') {
            api = MUTRIX_ADD;
          } else {
            api = MUTRIX_MODIFY;
          }
          api(body)
            .then(res => {
              if (res.code === 0) {
                message.success(
                  mainModal.title + '【' + values.name + '】成功'
                );
                mainModal.visible = false;
                this.setState({ modalData: mainModal });
                this.list();
              } else {
                message.error('请检查填写数据是否有误');
              }
            })
            .catch(err => {
              if (err) {
                message.error(err);
              }
            });
        }
      });
    }
  };
  updateGeoForm = o => {
    let mainModal = this.state.mainModal;
    mainModal.modalData = Object.assign({}, mainModal.modalData, o);
    this.setState({ mainModal: mainModal });
  };
  /**
   * 工场列表编辑的弹窗
   */
  processModal = () => {
    const { getFieldDecorator } = this.props.form;
    const RadioGroup = Radio.Group;
    let mainModal = this.state.mainModal;
    return (
      <Modal
        title={mainModal.title}
        visible={mainModal.visible}
        closable={false}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={this.handleMainModalCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleMainModalOK}>
            确定
          </Button>,
        ]}
      >
        <Form>
          <Form.Item label="工场名称" className="organizationName">
            {getFieldDecorator('name', {
              initialValue: mainModal.modalData.name, //填充input默认数据
              rules: [
                {
                  required: true,
                  message: '请输入工场名称',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="工场编号" className="organizationName">
            {getFieldDecorator('serialno', {
              initialValue: mainModal.modalData.serialno,
              rules: [
                {
                  required: true,
                  message: '请输入工场编号',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="工场类型" className="organizationName">
            {getFieldDecorator('type', {
              initialValue: mainModal.modalData.type.id + '',
              rules: [
                {
                  required: true,
                  message: '请选择工场类型',
                },
              ],
            })(
              <Select>
                <Option key="1">独占型</Option>
                <Option key="2">共享型</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="地区：" className="organizationAddress">
            <GeoForm
              regionId={mainModal.modalData.region}
              provinceId={mainModal.modalData.province}
              cityId={mainModal.modalData.city}
              updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
            />
            <span style={{ color: 'red', display: this.state.display }}>
              请选择地区
            </span>
          </Form.Item>

          <Form.Item label="详细地址" className="organizationName">
            {getFieldDecorator('address', {
              initialValue: mainModal.modalData.address,
              rules: [
                {
                  required: true,
                  message: '请输入详细地址',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="经度" className="organizationName">
            {getFieldDecorator('longitude', {
              initialValue: mainModal.modalData.longitude,
              rules: [
                {
                  required: true,
                  message: '请输入经度',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="纬度" className="organizationName">
            {getFieldDecorator('latitude', {
              initialValue: mainModal.modalData.latitude,
              rules: [
                {
                  required: true,
                  message: '请输入纬度',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="状态" className="organizationName">
            {getFieldDecorator('valid', {
              initialValue: mainModal.modalData.valid,
              rules: [
                {
                  required: true,
                  message: '请选择状态',
                },
              ],
            })(
              <RadioGroup
                onChange={this.stateChange}
                setFieldsValue={mainModal.modalData.valid}
              >
                <Radio value={true}>启用</Radio>
                <Radio value={false}>禁用</Radio>
              </RadioGroup>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  render() {
    // 根据props输入的数组进行过滤和筛选
    let inputColumns = [];
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
      }
    );
    return (
      <div>
        {/* 新增或编辑工场*/}
        <this.processModal />
        {/* 表格 */}
        <Table
          columns={column}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
          bordered={true}
        />
      </div>
    );
  }
}

export default Form.create()(MutrixList);
