import * as React from 'react';
import { Menu, Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux/es/alternate-renderers';
import { switchMenu } from '../../redux/action'; //引入动态菜单
// import MenuConfig from '../../config/menuConfig'; //引入菜单导航
import * as styles from './index.module.css';
import { MENULIST } from '../../app/api';
const id = JSON.parse(localStorage.getItem('roleid'));
const SubMenu = Menu.SubMenu;
class LeftNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey: '',
      collapsed: false,
      theme: 'dark',
      current: '1',
      style: {
        flex: '0 0 200px',
        maxWidth: '200px',
        minWidth: '200px',
        width: '200px',
        fontSize: '18px',
        border: 'none',
        height: 'calc(100vh)',
        backgroundColor: '#ffffff',
      },
      styles: {
        flex: '0 0 80px',
        maxWidth: '80px',
        minWidth: '80px',
        width: '80px',
        fontSize: '18px',
        border: 'none',
        height: 'calc(100vh)',
        backgroundColor: 'red',
      },
    };
  }
  homeHandleClick = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
    if (this.state.collapsed) {
    }
  };
  /**
   *点击菜单加载方法
   * @param item
   * @param key 地址
   * @returns {boolean}
   */
  handleClick = ({ item, key }) => {
    if (key === this.state.currentKey) {
      return false;
    }
    // //事件派发，自动调用reducer，通过reducer保存到store对象中
    const { dispatch } = this.props;
    dispatch(switchMenu(item.props.title));
    this.setState({
      currentKey: key,
    });
  };
  componentWillMount() {
    //先从这里拿到后台的菜单
    MENULIST().then(res => {
      if (res.code === 0) {
        const menuTreeNode = this.renderMenu(res.data.list);
        console.log('menuTreeNode', menuTreeNode);
        this.setState({
          menuTreeNode,
        });
      }
    });
  }

  onMneuClick = e => {
    console.log(e);
    this.setState({
      current: e.key,
    });
  };

  /**
   * 菜单渲染
   */
  renderMenu = data => {
    console.log('data---------------');
    console.log(data);
    return data.map((item, index) => {
      //重新渲染  level 是1就加载一级导航
      if (item.entity.level === 1 && item.entity.dataUrl === '') {
        return (
          <SubMenu
            onClick={this.onMneuClick}
            key={
              item.entity.dataUrl === '' ? item.entity.id : item.entity.dataUrl
            }
            title={
              <span>
                <Icon type={item.entity.icon} />
                <span>{item.entity.name}</span>
                {console.log('itemIndex' + index, item)}
              </span>
            }
          >
            {this.renderMenu(item.children)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item
          title={item.entity.name}
          key={item.entity.dataUrl}
          onClick={this.onMneuClick}
        >
          <NavLink to={item.entity.dataUrl}>
            <Icon
              type={item.entity.icon}
              style={{ fontSize: '14px', verticalAlign: '-4px' }}
            />
            <span>{item.entity.name}</span>
            {console.log('item' + index, item)}
          </NavLink>
        </Menu.Item>
      );
    });
  };
  render() {
    return (
      <div>
        {/*<NavLink to ='/home' onClick={this.homeHandleClick}>  className={styles['menuName']}      */}
        {/*    <div className='logo'>*/}
        {/*        /!*<img src={logo} alt="" style={{}}/>*!/*/}
        {/*    </div>*/}
        {/*</NavLink>*/}
        {/*给菜单绑定一个方法 theme={this.state.theme} */}
        {/*<BreadcrumbCustom />*/}
        <Menu
          style={this.state.collapsed ? this.state.styles : this.state.style}
          onClick={this.handleClick}
          //黑色Dark
          selectedKeys={[this.state.current]}
          mode="inline"
          inlineCollapsed={this.state.collapsed}
        >
          {this.state.menuTreeNode}
          <div
            onClick={this.homeHandleClick}
            style={{
              position: 'fixed',
              top: '30px',
              left: '249px',
              zIndex: '9',
              marginBottom: 16,
              color: '#ffffff',
              fontSize: '18px',
              cursor: 'pointer',
            }}
          >
            <Icon
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              className={styles['icon']}
            />
          </div>
        </Menu>
      </div>
    );
  }
}

export default connect()(LeftNav);
// export default LeftNav;
