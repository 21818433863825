import * as React from 'react';
import { Button, Form, Table, message, Radio, Select } from 'antd';
import {
  TEAM_LIST,
  MUTRIX_LISTNAME,
  CUSTOM_LISTNAME,
  TEAM_ADD,
  TEAM_MODIFY,
} from '../../app/api';
import Input from 'antd/es/input';
import * as styles from './teamManage.module.css';
import GeoForm from '../../components/GeoForm';
import ListTitle from '../../components/ListTitle/listTiltle';
import { jumpTo } from '../../router/Router';
// import AsyncValidator from 'async-validator'
const { Option } = Select;

class TeamAdd extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
  }
  state = {
    data: [],
    value: [],
    fetching: false,
    selectedMutrix: 0,
    selectMutrixes: [],
    // style:{color:'red',marginLeft:'80px',marginTop:'10px',display:'none'},
    time: '', //选中的时间
    organizaData: [],
    modalData: {},
    confirmDirty: false,
    stateValue: '', //状态
    allCustom: [],
    selectedCustom: '',
    teamId: '', //新增的球队ID
    existedId: '', //已存在的id
    name: '',
    province: '',
    city: '',
    region: '',
    valid: '',
  };
  componentDidMount() {
    this.loadingData();
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    let this_id = params.get('id');
    if (this_id == null) {
      this.setState({
        selectedCustom: '',
        disabled: false,
      });
      //this.state.selectedOrgs = this_id;
    } else {
      this.setState({
        selectedCustom: this_id,
        disabled: true,
      });
    }
  }
  componentDidCatch(error, errorInfo) {}

  loadingData = () => {
    CUSTOM_LISTNAME().then(res => {
      this.setState({
        allCustom: res.data.list,
      });
    });
  };
  organizationChange = value => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };
  onChange = (date, dateString) => {
    this.setState({
      time: dateString,
    });
  };
  /**
   * 下一步
   */
  nextFunction = () => {
    let t = document.getElementById('zuID'); //初始状态
    let f = document.getElementById('nextID');
    let h = document.getElementById('noID'); //不存在的情况
    // let h = this.noOrganiza().props.id
    this.props.form.validateFields((err, values) => {
      let modals = this.state.modalData;
      if (
        !err &&
        this.state.selectedCustom !== '' &&
        Object.keys(modals).length !== 0 &&
        modals.province !== 0 &&
        modals.city !== 0 &&
        modals.region !== 0
      ) {
        this.setState({ style: { display: 'none' } });
        this.setState({
          modalData: modals, //把这个省市区存进去
        });
        // t.style.display ='none'; //隐藏 =》 然后加载已存在的球队
        // f.style.display ='block'; //显示 =》 已存在的球队
        const text = {
          // nameMode: 1,
          check: '',
          name: values.name,
          province: modals.province,
          city: modals.city,
          region: modals.region,
        };
        TEAM_LIST(text)
          .then(res => {
            const data = res.data.list.content;
            if (data.length === 0) {
              h.style.display = 'block'; //不存在的球队显示
              t.style.display = 'none';
              f.style.display = 'none';
            } else {
              t.style.display = 'none'; //隐藏 => 然后加载已存在的球队
              f.style.display = 'block'; //显示已存在的球队
              const allData = data.map(v =>
                Object.assign({}, v.entity, {
                  fullAddress: v.fullAddress,
                  orgs: v.orgs,
                  footballerNumber: v.footballerNumber,
                  coaches: v.coaches,
                  customs: v.customs,
                  rowKey: v.entity.id,
                })
              );
              this.setState({
                organizaData: allData,
                modalData: modals,
              });
            }
          })
          .catch(err => console.log(err));
      } else if (this.state.selectedCustom === '') {
        message.error('请选择组织');
      } else if (
        Object.keys(modals).length === 0 ||
        modals.province === 0 ||
        modals.city === 0 ||
        modals.region === 0
      ) {
        message.error('请选择地区');
      }
    });
  };
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  existedOk = () => {
    //新增球队已存在【完成】
    const arr = [];
    const this_custom = { id: this.state.selectedCustom }; //绑关系的组织id
    arr.push(this_custom);
    arr.push(...this.state.customs);
    let arr2 = Array.from(new Set(arr)); //去重
    let text = {
      entity: {
        id: this.state.existedId, //新增的球队ID
        name: this.state.name, //球队名
        province: parseInt(this.state.province),
        city: parseInt(this.state.city),
        region: parseInt(this.state.region),
        valid: this.state.valid,
      },
      customs: arr2,
    };
    TEAM_MODIFY(text).then(res => {
      //用的编辑球队的接口
      if (res.code === 0) {
        message.success('新增成功');
        jumpTo(
          '/pages/orgManage/organizationDetails?id=' + this.state.selectedCustom
        );
      }
    });
  };
  existedCancel = () => {
    let t = document.getElementById('zuID');
    let f = document.getElementById('nextID');
    t.style.display = 'block';
    f.style.display = 'none';
  };
  /**
   * 第二步（球队名已经存在）
   * @returns {*}
   */
  nextLoading = () => {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        if (selectedRows.length !== 0) {
          const selectId = selectedRows[0].customs;
          selectId.forEach((val, index) => {
            selectId[index] = {
              id: val.id,
            };
          });
          // console.log(selectId);
          this.setState({
            existedId: selectedRows[0].id, //已存在球队的id
            name: selectedRows[0].name,
            province: selectedRows[0].province,
            city: selectedRows[0].city,
            region: selectedRows[0].region,
            valid: selectedRows[0].valid,
            customs: selectId,
          });
        } else {
          console.log('123');
        }
      },
    };
    const column = [
      {
        key: '1',
        title: '球队名称',
        dataIndex: 'name',
        width: '12%',
      },
      {
        key: '2',
        title: '教练',
        dataIndex: 'coaches',
        render: coaches => {
          return <span> {coaches.length} </span>;
        },
      },
      {
        key: '3',
        title: '地区',
        dataIndex: 'fullAddress',
      },
      {
        key: '4',
        title: '球员',
        dataIndex: 'footballerNumber',
        render: footballerNumber => {
          return <span> {footballerNumber} </span>;
        },
      },
      {
        key: '5',
        title: '所属客户',
        dataIndex: 'orgs',
        render: orgs => {
          return <span> {orgs.length} </span>;
        },
      },
      {
        key: '6',
        title: '所属组织',
        dataIndex: 'customs',
        render: customs => {
          return <span> {customs.length} </span>;
        },
      },
      // {
      //     key: "8",
      //     title: '球队来源',
      //     dataIndex: 'teamNumber',
      // }
    ];
    return (
      <div id="nextID" style={{ display: 'none' }}>
        <p
          style={{
            marginBottom: '20px',
            fontSize: '18px',
            background: '#f3f4f8',
            padding: '8px 5px',
          }}
        >
          该球队已存在
        </p>
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.organizaData}
          rowSelection={rowSelection}
          onChange={this.handleTableChange}
          rowKey={record => record.key}
        />
        <div className={styles['next-btn']}>
          <Button type="primary" onClick={this.existedOk}>
            完成
          </Button>
          <Button style={{ marginLeft: '20px' }} onClick={this.existedCancel}>
            取消
          </Button>
        </div>
      </div>
    );
  };
  /**
   * 取消 返回上一步
   */
  onAddCancel = () => {
    let t = document.getElementById('zuID');
    let h = document.getElementById('noID');
    t.style.display = 'block';
    h.style.display = 'none';
  };
  /**
   * 【完成】新增球队不存在
   */
  onValidateForm = () => {
    //提交完成
    const { validateFields } = this.props.form;
    const allData = this.state.modalData;
    validateFields(['name', 'region', 'address'], (errors, values) => {
      if (this.state.stateValue === '') {
        message.error('请选择状态');
      } else {
        const she = {
          id: this.state.selectedCustom,
        };
        let body = {
          entity: {
            //"id":this.state.teamId,   //新增的球队ID
            name: values.name, //球队名
            province: parseInt(this.state.modalData.province),
            city: parseInt(this.state.modalData.city),
            region: parseInt(this.state.modalData.region),
            valid: this.state.stateValue,
          },
          customs: [she],
        };
        TEAM_ADD(body).then(res => {
          if (res.code === 0) {
            this.setState({ teamId: res.data.id });
            message.success('新增成功');
            jumpTo(
              '/pages/orgManage/organizationDetails?id=' +
                this.state.selectedCustom
            );
          } else {
            message.error(res.message);
          }
        });
      }
    });
  };
  /**
   * 球队名不存在
   */
  noOrganiza = () => {
    const { getFieldDecorator } = this.props.form;
    const RadioGroup = Radio.Group;
    // const formItemLayout = {
    //     labelCol: {
    //         xs: {span: 24},
    //         sm: {span: 8},
    //     },
    //     wrapperCol: {
    //         xs: {span: 24},
    //         sm: {span: 16},
    //     },
    // };
    let modalData = this.state.modalData;
    console.log('球队名不存在');
    //球队不存在
    return (
      <div id="noID" style={{ display: 'none' }}>
        {/*{...formItemLayout}*/}
        <Form>
          <Form.Item label="球队名称" className="organizationName">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: '请输入球队名称',
                },
              ],
            })(<Input disabled />)}
          </Form.Item>
          {/*新增了省市区 地址的判重*/}
          <div className={styles['province-sty']}>
            <span className={styles['teamSelect']}>
              {' '}
              <span style={{ color: '#f5222d', fontSize: '15px' }}>
                {' '}
                *{' '}
              </span>{' '}
              地区 :{' '}
            </span>
            <Input
              style={{ width: '10%' }}
              value={modalData.provinceName}
              disabled
            />
            <Input
              style={{ width: '10%' }}
              value={modalData.cityName}
              disabled
            />
            <Input
              style={{ width: '10%' }}
              value={modalData.regionName}
              disabled
            />
          </div>
          <div>
            <span className={styles['teamSelect']}>
              {' '}
              <span style={{ color: '#f5222d', fontSize: '15px' }}>
                {' '}
                *{' '}
              </span>{' '}
              状态
            </span>
            <RadioGroup
              onChange={this.stateChange}
              value={this.state.stateValue}
            >
              <Radio value={true}>启用</Radio>
              <Radio value={false}>禁用</Radio>
            </RadioGroup>
          </div>
          <div className={styles['next-btn']}>
            <Button type="primary" onClick={this.onValidateForm}>
              完成
            </Button>
            <Button style={{ marginLeft: '20px' }} onClick={this.onAddCancel}>
              取消
            </Button>
          </div>
        </Form>
      </div>
    );
  };
  /**
   * 更新地理信息到本地存储
   */
  updateGeoForm = (o, value) => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
  };
  /**
   * 新增球队刚进来时状态
   */
  newChoice = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="zuID">
        <h3
          style={{
            marginBottom: '20px',
            paddingLeft: '5px',
            display: 'inline-block',
          }}
        >
          新增球队
        </h3>
        <div
          style={{
            height: '3px',
            background: '#f0f0f0f0',
            marginBottom: '30px',
          }}
        >
          {' '}
        </div>
        <div style={{ display: 'inline-block', marginBottom: '30px' }}>
          <span style={{ color: 'rgba(0, 0, 0, 0.85)', paddingRight: '3px' }}>
            <span style={{ color: '#f5222d', fontSize: '15px' }}>*</span>{' '}
            选择组织 :{' '}
          </span>
          <div style={{ display: 'inline-block' }}>
            <ListTitle
              selected={this.state.selectedCustom}
              data={this.state.allCustom}
              disabled={this.state.disabled}
              callback={v => {
                this.setState({ selectedCustom: v.id });
              }}
            />
          </div>
        </div>
        <div
          className={styles['organiza-input']}
          style={{ marginBottom: '40px' }}
        >
          {/*<span>组织名称： </span>*/}
          {/*<Input placeholder="请输入组织名称" onChange={this.inputChange}/>*/}
          <Form>
            <Form.Item label="球队名称" className="organizationName">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: '请输入球队名称',
                  },
                ],
              })(<Input placeholder="请输入球队名称" />)}
            </Form.Item>

            <Form.Item label="地区:" className="organizationName nameless">
              <GeoForm
                regionId={this.state.modalData.region}
                provinceId={this.state.modalData.province}
                cityId={this.state.modalData.city}
                updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
              />
            </Form.Item>
          </Form>
        </div>
        <div className={styles['next-btn']}>
          <Button type="primary" onClick={this.nextFunction}>
            下一步
          </Button>
        </div>
      </div>
    );
  };
  handleChange = value => {
    console.log(`selected ${value}`);
  };
  render() {
    return (
      <div>
        <this.newChoice />
        {/*下一步组织名已经存在*/}
        <this.nextLoading />
        {/*组织名不存在*/}
        <this.noOrganiza />
      </div>
    );
  }
}

export default Form.create()(TeamAdd);
