//球员List
import React from 'react';
import { Table, Form, message, Popconfirm, Button } from 'antd';
import { FOOTBALLER_LIST, FOOTBALL_DELETERELATION } from '../../app/api';
//import './organizationManage.module.css';

class PlarList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // API球队查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},

      teamId: 0,
      orgId: 0,
      customId: 0,

      selectCustoms: [],

      selectOrgs: [],

      mainModal: '',
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'id',
        width: '6%',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        key: '2',
        title: '姓名',
        dataIndex: 'name',
        width: '7%',
      },
      {
        key: '3',
        title: '联系电话',
        dataIndex: 'phone',
        width: '10%',
      },
      {
        key: '4',
        title: '性别',
        dataIndex: 'gender',
        width: '5%',
        render: gender => (gender === 1 ? <span>男</span> : <span>女</span>),
      },
      {
        key: '5',
        title: '年龄',
        dataIndex: 'age',
        width: '5%',
        align: 'center',
        // render(birthday) {
        //   const heAge = new Date(birthday);
        //   const day = new Date();
        //   const age = day.getFullYear() - heAge.getFullYear();
        //   // -
        //   // (day.getMonth() < heAge.getMonth() ||
        //   // (day.getMonth() === heAge.getMonth() &&
        //   //   day.getDate() < heAge.getDate())
        //   //   ? 1
        //   //   : 0);
        //   return age;
        // },
      },
      {
        key: 'code',
        title: '身份证号',
        dataIndex: 'idCard',
        width: '9%',
        align: 'center',
        render(idCard) {
          return idCard;
        },
      },

      {
        key: '6',
        title: '地区',
        dataIndex: 'fullAddress',
        width: '18%',
        align: 'center',
      },
      {
        key: '9',
        title: '所属球队',
        dataIndex: 'teams',
        width: '8%',
        align: 'center',
        render: (text, record) => {
          return (
            <span style={{ color: '#1890ff', cursor: 'pointer' }}>
              {record.teams.length}
            </span>
          );
        },
      },
      {
        key: '8',
        title: '签约类型',
        dataIndex: 'source',
        width: '8%',
        align: 'center',
        render(source) {
          return <span>{source === 100 ? 'APP' : 'web签约'}</span>;
        },
      },
      {
        key: '7',
        title: '状态',
        dataIndex: 'valid',
        width: '6%',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
      {
        title: '操作',
        key: 'operation',
        width: '23%',
        render: (text, record) => (
          <span>
            {/*  暂时不要编辑 <a href="javascript:;" onClick={this.teamEdit.bind(this,text,record)}>编辑</a>*/}
            {/*<Divider type="vertical"/>*/}
            {/*<button onClick={() => jumpTo("/player/playerDetails",{id:record.id}) }>*/}
            {/*    <Icon type="unordered-list"/>*/}
            {/*详情</button>*/}
            {/*<button onClick={() => jumpTo("/player/homePage",{id:record.id})}>查看数据</button>*/}
            <Popconfirm
              title={'删除【' + record.name + '】不可恢复，你确定要删除吗?'}
              okText="确定"
              cancelText="取消"
              onConfirm={() => this.handleDelete(record)}
            >
              <Button title="用户删除">删除</Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
  }

  // 加载数据
  componentWillMount() {
    this.list();
  }
  componentDidMount() {}
  /**
   * 加载数据API
   */
  list = params => {
    params = params === undefined ? {} : params;
    params.teamId = this.props.teamId;
    params.orgId = this.state.orgId;
    params.customId = this.state.customId;
    FOOTBALLER_LIST(params).then(res => {
      const data = res.data.list.content;
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      pagination.current = res.data.list.number + 1;
      this.setState({
        fullAddress: data,
        data: data.map(v =>
          Object.assign({}, v.entity, v.coaches, {
            rowKey: v.entity.id,
            footballerNumber: v.footballerNumber,
            fullAddress: v.fullAddress,
            teams: v.teams,
            age: v.age,
          })
        ),
        pagination,
      });
    });
  };
  /**
   * 编辑弹窗
   */
  editModal = record => {
    this.setState({
      visible: true,
      modalData: record,
      title: '编辑客户',
    });
  };
  /**
   * 删除球员
   */
  handleDelete = record => {
    // /web/footballer/deleteRelation?teamId=&footballerId=
    // let teamId = record.teams[0].id;
    FOOTBALL_DELETERELATION({
      teamId: this.props.teamId,
      footballerId: record.id,
    })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.name + '】成功');
          this.list();
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };

  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = (pagination, filters, sorter) => {
    this.list({
      // pageSize: pagination.current,
      pageNum: pagination.current,
    });
  };

  /**
   * 组织列表的弹窗
   */
  processModal = () => {};

  render() {
    // 根据props输入的数组进行过滤和筛选
    let inputColumns = [];
    if (this.props.columns !== undefined && this.props.columns.length > 0) {
      for (let i = 0; i < this.props.columns.length; i++) {
        let col = this.column.filter(
          col => col.key === '' + this.props.columns[i]
        );
        if (col.length === 1) {
          inputColumns.push(col[0]);
        }
      }
    }
    const column = (inputColumns.length > 0 ? inputColumns : this.column).map(
      col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
            rowKey: record.key,
          }),
        };
      }
    );
    return (
      <div>
        {/*<this.processModal />*/}
        {/* 表格 */}
        <Table
          columns={column}
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
          bordered={true}
          rowKey={record => record.key}
        />
      </div>
    );
  }
}

export default Form.create()(PlarList);
