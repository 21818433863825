import * as React from 'react';
import {
  DatePicker,
  Icon,
  Table,
  Tabs,
  Modal,
  Button,
  Form,
  Input,
  Radio,
  message,
  Upload,
} from 'antd';
import * as styles from '../teamManage/teamManage.module.css';
import PlarList from '../player/plarList';
import OrganizationList from '../orgManage/organizationList';
import CoachList from '../coach/coachList';
import ListTitle from '../../components/ListTitle/listTiltle';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {
  MUTRIX_LISTNAME,
  TEAM_QUERY,
  TEAM_MODIFY,
  COACH_DATE,
} from '../../app/api';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import GeoForm from '../../components/GeoForm';
import { jumpTo } from '../../router/Router';

moment.locale('zh-cn');

class teamDetails extends React.Component {
  state = {
    teamId: '',
    teamList: [],
    queryList: [],
    selectedMutrix: 0,
    selectedDateList: '', //时间的id
    selecteDate: 0,
    startDate: '', //开始时间
    endDate: '', //结束时间
    visible: false, //编辑弹框
    modalData: '', //省市区数据
    teamData: '', //球队数据
    pagination: [], //分页的数据存储
    selectMutrixes: [], //工场的数据
    display: 'none',
    thisFile: false,
    theyPlear: {},
    fileList: [],
    styles: { margin: '30px 0', display: 'inline-block' },
    form: [1, 2, 3, 4, 5, 6, 8, 9, 10], //组织的table
    coachForm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 'operation'], //教练的table
  };
  componentWillMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    // this.setState({teamId: params.get("id")})
    this.state.teamId = params.get('id');
    this.queryTeam();
    this.loadingData();
    this.queryListCourse();
  }
  componentDidMount() {
    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    let s = document.getElementById('addCoach');
    let h = document.getElementById('football-ft');
    let f = document.getElementById('edit-btn');
    let w = document.getElementById('select-date');

    if (custmer == 2) {
      s.style.display = 'none';
      h.style.display = 'none';
      f.style.display = 'none';
      this.setState({
        styles: { display: 'none' },
      });
    }
  }

  queryTeam = () => {
    let params = this.state.teamId !== 0 ? { id: this.state.teamId } : {};
    TEAM_QUERY(params).then(res => {
      this.setState({
        queryList: res.data.query,
        teamList: res.data.query.entity,
      });
    });
  };
  /**
   * 更新地理信息到本地存储
   */
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
  };
  /**
   * 状态启用禁用的方法
   */
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  editCancel = () => {
    this.setState({
      visible: false,
    });
  };
  handleCancel = () => {
    this.setState({
      thisFile: false,
    });
  };
  /**
   * 编辑完成，提交表单
   */
  editOk = () => {
    if (
      this.state.modalData.province === 0 ||
      this.state.modalData.city === 0 ||
      this.state.modalData.region === 0
    ) {
      this.setState({
        display: 'block',
      });
    } else {
      this.setState({ display: 'none' });
      this.props.form.validateFields((errors, values) => {
        if (!errors) {
          let body = {
            entity: {
              id: this.state.teamList.id,
              name: values.name,
              province: parseInt(this.state.modalData.province),
              city: parseInt(this.state.modalData.city),
              region: parseInt(this.state.modalData.region),
              valid: this.state.stateValue,
            },
          };
          TEAM_MODIFY(body)
            .then(res => {
              this.setState({ visible: false });
              message.success('编辑球队【' + values.name + '】成功');
              this.queryTeam();
              this.forceUpdate(); //更新刷新数据
            })
            .catch(err => {
              if (err) {
                message.error(err);
              }
            });
        }
      });
    }
  };
  /**
   * 下载模板  注意 ！！！！！注意！！！！！！！！！！！！！！！
   * ！！！！！！！！！！！！！发布的话  需要将api去掉
   */
  batchImport = event => {
    event.preventDefault(); // 阻止任何操作
    event.stopPropagation(); //阻止冒泡到父级
    let url = '';
    if (process.env.NODE_ENV === 'development') {
      url = '/api/web/footballer/exportTemplate';
    } else {
      url = '/web/footballer/exportTemplate';
    }
    fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(response => {
        response.blob().then(blob => {
          let thisName = response.headers.get('Content-Disposition');
          if (thisName == null) {
            message.error('文件名为空，下载失败');
          } else {
            thisName = response.headers
              .get('Content-Disposition')
              .split("UTF-8''")[1]; //设置文件名
            // let filename = response.headers.get('Content-Disposition').split('filename=', 1) + '.xls'; //设置文件名
            // let filename = response.headers.get('Content-Disposition').split('filename=')[1].split('.')+'.xls';//设置文件名
            const he_filename = decodeURI(thisName);
            let blobUrl = window.URL.createObjectURL(blob);
            let aElement = document.getElementById('downloadDiv'); //获取a标签元素
            aElement.href = blobUrl; //设置a标签路径
            aElement.download = he_filename;
            aElement.click();
            window.URL.revokeObjectURL(blobUrl);
            message.success('下载成功');
          }
        });
      })
      .catch(error => {
        message.error('文件下载失败');
        console.log('文件下载失败', error);
      });
  };

  /**excal
   * 上传表格  这里注意加api的问题
   * @returns {*}
   */
  importExcel = (fileList, file) => {
    let formData = new FormData();
    formData.append('file', fileList.file);
    const url = '/web/footballer/importFile?teamId=' + this.state.teamId;
    fetch(url, {
      method: 'post',
      headers: new Headers({
        //'Content-Type': 'application/x-www-form-urlencoded',
        credentials: 'same-origin',
      }),
      body: formData,
    })
      .then(response => response.json())
      .then(result => {
        if (result.code === 0) {
          message.success('上传成功,在球员列表查看');
        } else {
          message.error(result.message);
        }
      });
  };
  uploadChange = ({ file, fileList, event }) => {
    file.status = 'done';
    this.setState({ theyPlear: file });
  };
  coachData = () => {
    const { getFieldDecorator } = this.props.form;
    const { fileList } = this.state.fileList;
    const RadioGroup = Radio.Group;
    const An = props => {
      return <a {...props}>{props.children}</a>;
    };
    const uploadButton = (
      <Button className={styles['btn-add']}>
        <Icon type="upload" />
        {/*<input className={styles['file-uploader']} type='file' accept='.xlsx, .xls' onChange={this.importExcel} />*/}
        上传表格
      </Button>
    );
    return (
      <div>
        <div className={styles['basic-data']}>
          <p>所属组织</p>
        </div>
        <OrganizationList
          teamId={this.state.teamId}
          columns={this.state.form}
        />
        <div className={styles['basic-data']}>
          <span>教练</span>
          <div
            id="addCoach"
            style={{
              display: 'inline-block',
              marginLeft: '88%',
              cursor: 'pointer',
              fontSize: '16px',
              color: '#1890ff',
            }}
            onClick={() =>
              jumpTo('/pages/coach/coachAdd', { id: this.state.teamId })
            }
          >
            <Icon type="plus-circle" /> 新增
          </div>
        </div>
        <CoachList
          teamId={this.state.teamId}
          columns={
            this.state.custmer == 2
              ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
              : this.state.coachForm
          }
          scroll={{ x: 900, y: 100 }}
        />
        <div className={styles['basic-data']}>
          <span>球员</span>
          <span id="football-ft">
            <div
              style={{
                display: 'inline-block',
                marginLeft: '83%',
                cursor: 'pointer',
                fontSize: '16px',
                color: '#1890ff',
              }}
              onClick={
                (this.she = () => {
                  this.setState({ thisFile: true });
                })
              }
            >
              <Icon type="upload" /> 批量导入
            </div>
            <div
              style={{
                display: 'inline-block',
                marginLeft: '13px',
                cursor: 'pointer',
                fontSize: '16px',
                color: '#1890ff',
              }}
              onClick={() =>
                jumpTo('/pages/player/playerAdd', { id: this.state.teamId })
              }
            >
              <Icon type="plus-circle" /> 新增
            </div>
          </span>
        </div>
        <PlarList
          teamId={this.state.teamId}
          columns={
            this.state.custmer == 2
              ? [1, 2, 3, 4, 5, 'code', 6, 7, 8, 9]
              : this.state.columns
          }
          scroll={{ x: 900, y: 100 }}
        />

        <Modal
          title="导入球员信息"
          visible={this.state.thisFile}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" type="primary" onClick={this.handleCancel}>
              关闭
            </Button>,
          ]}
        >
          {/*{...prop} */}
          <Upload
            customRequest={e => this.importExcel(e)}
            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={this.uploadChange}
            fileList={fileList}
          >
            {fileList >= 1 ? null : uploadButton}
            {/*<Button className={styles['btn-add']}>*/}
            {/*  <Icon type='upload' />*/}
            {/*  /!*<input className={styles['file-uploader']} type='file' accept='.xlsx, .xls' onChange={this.importExcel} />*!/*/}
            {/*  上传表格*/}
            {/*</Button>*/}
          </Upload>
          <Button onClick={this.batchImport} style={{ marginTop: '8px' }}>
            <Icon type="download" />
            下载模板
          </Button>
          {/*</a>*/}
          <An id="downloadDiv" style={{ display: 'none' }}></An>
        </Modal>

        <Modal
          title="编辑球队"
          visible={this.state.visible}
          onOk={this.editOk}
          onCancel={this.editCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={this.editCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.editOk}>
              确定
            </Button>,
          ]}
        >
          <Form>
            <Form.Item label="球队名称" className="organizationName">
              {getFieldDecorator('name', {
                initialValue: this.state.teamList.name,
                rules: [
                  {
                    required: true,
                    message: '请输入球队名称',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="地区:" className="organizationAddress">
              <GeoForm
                regionId={this.state.teamList.region}
                provinceId={this.state.teamList.province}
                cityId={this.state.teamList.city}
                updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
              />
              <span style={{ color: 'red', display: this.state.display }}>
                请选择省地区
              </span>
            </Form.Item>

            <Form.Item label="状态" className="organizationName">
              {getFieldDecorator('valid', {
                initialValue: this.state.teamList.valid,
                rules: [
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ],
              })(
                <RadioGroup
                  onChange={this.stateChange}
                  setFieldsValue={this.state.stateValue}
                >
                  <Radio value={true}>启用</Radio>
                  <Radio value={false}>禁用</Radio>
                </RadioGroup>
              )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  };
  loadingData = params => {
    MUTRIX_LISTNAME(params).then(res => {
      this.setState({
        selectMutrixes: res.data.list,
      });
    });
  };
  /**
   * 自定义选择的时间
   * @param date
   * @param dateString
   */
  onTimeChange = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
      },
      this.queryListCourse
    );
  };
  /**
   * 选择全部时间
   * 本月、最近一个月、近三个月、近六个月、近一年
   */
  allTime = value => {
    const dateFormat = 'YYYY-MM-DD';
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if (value.id === 0) {
      endDate = '00:00:00';
      startDate = '00:00:00';
    }
    if (value.id === 1) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date().setDate(1)).format(dateFormat);
    } else if (value.id === 2) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-1, 'month')
        .format(dateFormat);
    } else if (value.id === 3) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-3, 'month')
        .format(dateFormat);
    } else if (value.id === 4) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-6, 'month')
        .format(dateFormat);
    } else if (value.id === 5) {
      endDate = moment(new Date()).format(dateFormat);
      startDate = moment(new Date())
        .add(-12, 'month')
        .format(dateFormat);
    }
    this.setState(
      {
        selecteDate: value.id,
        startDate: startDate,
        endDate: endDate,
      },
      this.queryListCourse
    );
  };
  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = pagination => {
    this.queryListCourse({
      pageNum: pagination.current,
    });
  };
  /**
   * 球队统计数据
   */
  queryListCourse = pagination => {
    let par = this.state.teamId !== 0 ? { teamId: this.state.teamId } : {};
    par.startDate = this.state.startDate + ' 00:00:00';
    par.endDate = this.state.endDate + ' 23:59:59';
    par.mutrixId = this.state.selectedMutrix;
    if (par.mutrixId === 0) {
      delete par['mutrixId'];
    }
    let obj = Object.assign({}, pagination, par);
    COACH_DATE(obj).then(res => {
      const pagination = { ...this.state.pagination };
      //pagination.total = res.data.list.totalPages;
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      //后台返回的原始分页从0开始，未做修正，这里需要加1
      pagination.current = res.data.list.number + 1;
      const data = res.data.list.content;
      const theyData = data.map(v =>
        Object.assign({}, v.entity, {
          percent: v.percent,
          rowKey: v.entity.id,
          status: v.status,
          team: v.team,
          groupNbr: v.groupNbr,
          mutrix: v.mutrix,
        })
      );
      this.setState({
        teamData: theyData,
        pagination,
      });
    });
  };
  /**
   * 球队表格数据
   */
  teamDateList = () => {
    const columns = [
      {
        key: '1',
        title: '序号',
        width: '5%',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        key: '2',
        title: '日期',
        dataIndex: 'startTime',
        width: '8%',
      },
      {
        key: '3',
        title: '工场',
        dataIndex: 'mutrix',
        width: '8%',
        render(mutrix) {
          return mutrix.name;
        },
      },
      {
        key: '4',
        title: '队伍',
        dataIndex: 'team',
        width: '12%',
        render(team) {
          return team.name;
        },
      },
      {
        key: '5',
        title: '分组数',
        dataIndex: 'groupNbr',
        width: '12%',
      },
      {
        key: '6',
        title: '状态',
        dataIndex: 'status',
        width: '8%',
        align: 'center',
        render(status) {
          return status.name;
        },
      },
      {
        key: '7',
        title: '完成度',
        dataIndex: 'percent',
        width: '10%',
        align: 'center',
        render(percent) {
          return Math.round(percent) + '%';
        },
      },
      // {   暂时么有接口
      //     title: '操作',
      //     key: 'operation',
      //     width: '12%',
      //     render:(text, record) => (
      //         <span>
      //             <Popconfirm title={"删除【" + record.team.name+ "】不可恢复，你确定要删除吗?"} onConfirm={() => this.handleDeleteTeam(record)}
      //                         okText="确定"
      //                         cancelText="取消">
      //                 <Button title='用户删除'>删除</Button>
      //             </Popconfirm>
      //         </span>
      //     )
      // }
    ];

    const column = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          rowKey: record.key,
        }),
      };
    });

    return (
      <div>
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.teamData}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
          rowKey={record => record.key}
        />
      </div>
    );
  };
  handleDeleteTeam = () => {
    console.log('123');
  };
  /**
   * 查询球队数据（头部筛选框）
   */
  teamData = () => {
    const { RangePicker } = DatePicker;
    if (this.state.custmer == 2) {
      let title = document.getElementById('select-date');
      // title.style.display = 'none';
    }
    return (
      <div>
        <div style={this.state.styles} id="select-date">
          <ListTitle
            selected={this.state.selectedMutrix}
            title="全部工场"
            data={this.state.selectMutrixes}
            callback={v =>
              this.setState({ selectedMutrix: v.id }, this.queryListCourse)
            }
          />
          <span>选择时间段 &nbsp;&nbsp;</span>
          <ListTitle
            selected={this.state.selecteDate}
            data={[
              { id: 0, name: '全部' },
              { id: 1, name: '本月' },
              { id: 2, name: '最近一个月' },
              { id: 3, name: '近三个月' },
              { id: 4, name: '近六个月' },
              { id: 5, name: '近一年' },
            ]}
            callback={this.allTime}
          />
          <span>自定义&nbsp;&nbsp;</span>
          <RangePicker onChange={this.onTimeChange} locale={locale} />
        </div>
        <this.teamDateList />
      </div>
    );
  };
  teamTitle = () => {
    const { TabPane } = Tabs;
    return (
      <div>
        <div className="card-container">
          <Tabs type="card" onChange={this.callback}>
            <TabPane tab="球队详情" key="1">
              <div className={styles['basic-data']}>
                <span>基础资料</span>
                <div
                  id="edit-btn"
                  style={{
                    display: 'inline-block',
                    marginLeft: '88%',
                    cursor: 'pointer',
                    color: 'rgb(24, 144, 255)',
                    fontSize: '16px',
                  }}
                  onClick={this.showModal}
                >
                  <Icon type="edit" /> 编辑
                </div>
              </div>
              <div className={styles['data-list']}>
                <p>球队名称：{this.state.teamList.name}</p>
                <p>地区：{this.state.queryList.fullAddress}</p>
                <p>
                  签约类型：
                  {this.state.teamList.source === 0 ? 'WEB签约' : 'APP'}
                </p>
                <p>
                  状态：{this.state.teamList.valid === true ? '启用' : '禁用'}
                </p>
              </div>
              <this.coachData />
            </TabPane>
            <TabPane tab="球队数据" key="2">
              <this.teamData />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div>
        <this.teamTitle />
      </div>
    );
  }
}
export default Form.create()(teamDetails);
