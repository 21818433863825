//客户管理员首页 及   运营管理员首页
import React from 'react';
import { Col, Form, Row, Select, DatePicker, Divider } from 'antd';
import {
  ORGANIZATION_DASHBOARD,
  MUTRIX_LISTNAME,
  COURSE_USAGE,
  ACCOUNT_QUERY,
} from '../../app/api';
import MutrixUsage from '../../components/Echarts/MutrixUsage';
import moment from 'moment';
const Option = Select.Option;

/**
 * 使用工场管理员登录才能访问
 * 账号是：13825093333 Ss123456
 */

class CustomHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      selectMutrixes: [],
      selectedMutrix: 0,
      startDate: '',
      endDate: '',
      mutrixUsage: {
        update: false,
        xAxis: [],
        yData1: [],
        yData2: [],
      },
      customId: '',
      orgId: '',
    };
  }
  // 加载数据
  componentWillMount() {
    // 这里要删除
    let date = moment(new Date())
      .add(0, 'month')
      .toDate();
    let endDate = moment(date).format('YYYY-MM-DD');
    let startDate = moment(date)
      .add(-1, 'month')
      .format('YYYY-MM-DD');
    this.mutrixUsage({ startDate: startDate, endDate: endDate });
    this.setState({ startDate: startDate, endDate: endDate });
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.setState({ orgId: params.get('id') }, this.dashboard);
  }
  componentDidMount() {
    this.setTitle();
    //this.dashboard();
  }
  setTitle = params => {
    //console.log(params);
    // const accountId = localStorage.getItem('accountId');
    // ACCOUNT_QUERY({ id: accountId }).then(res => {
    //   this.setState({
    //     selectMutrixes: [res.data.query.organization],
    //   });
    // });
    const orgIds = sessionStorage.getItem('mutrix');
    let orgId = {};
    if (this.state.orgId === null) {
      orgId = { orgId: orgIds };
      console.log('123');
    } else {
      orgId = {
        orgId: this.state.orgId,
      };
    }
    // MUTRIX_LISTNAME({ orgId: this.state.orgId }).then(res => {
    MUTRIX_LISTNAME(orgId).then(res => {
      this.setState({
        selectMutrixes: res.data.list,
      });
    });
  };
  dashboard = () => {
    //查询客户下的工场、组织、球队、球员、计划总数
    let params = {};
    if (this.state.orgId !== null) {
      params = {
        orgId: this.state.orgId,
      };
    }
    ORGANIZATION_DASHBOARD(params).then(res => {
      let data = res.data.dashboard;
      if (data !== undefined && data != null) {
        this.setState({
          data: data,
        });
      }
    });
  };
  mutrixUsage = params => {
    let p = params || {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      mutrixId: this.state.selectedMutrix == 0 ? '' : this.state.selectedMutrix,
    };
    COURSE_USAGE(p).then(res => {
      let data = res.data.usage;
      let mutrixUsage = this.state.mutrixUsage;
      mutrixUsage.xAxis = data.date;
      mutrixUsage.yData1 = data.finished;
      mutrixUsage.yData2 = data.expired;
      mutrixUsage.update = true;
      this.setState({ mutrixUsage: mutrixUsage });
    });
  };

  // 工场的change
  handleMutrixChange = value => {
    console.log(value);
    this.setState({ selectedMutrix: value }, this.mutrixUsage);
  };
  changeDate = value => {
    let startDate = moment(value[0]).format('YYYY-MM-DD');
    let endDate = moment(value[1]).format('YYYY-MM-DD');
    this.mutrixUsage({ startDate: startDate, endDate: endDate });
    this.setState({ startDate: startDate, endDate: endDate });
  };
  /**
   * 筛选、新增控制栏
   */
  titleSection = () => {
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const dateFormat = 'YYYY-MM-DD';
    return (
      <div style={{ width: '100%', marginBottom: '3%' }}>
        <Row type="flex" justify="center" gutter={20}>
          <Col span={9}>
            <span style={{ paddingRight: '30px' }}>工场使用情况走势图</span>
            <Select
              value={'' + this.state.selectedMutrix}
              style={{ width: 240, marginRight: 20 }}
              onChange={this.handleMutrixChange}
            >
              <Option key="0">工场名称</Option>
              {this.state.selectMutrixes.map(o => (
                <Option key={o.id}>{o.name}</Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <DatePicker.RangePicker
              defaultValue={[
                moment(this.state.startDate),
                moment(this.state.endDate),
              ]}
              format={dateFormat}
              onChange={this.changeDate}
            />
          </Col>
          <Col span={8}></Col>
        </Row>
      </div>
    );
  };
  gridSection = () => {
    const data = this.state.data;
    return (
      <div style={{ height: '200px', width: '100%', marginTop: '40px' }}>
        <Row
          gutter={16}
          className="mutrix-list"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Col span={4}>
            {/*<div className='grid-dashboard' onClick={() =>jumpTo("/mutrixManage/MutrixItem",{mutrixId:this.state.selectedMutrix})}>*/}
            <div className="grid-dashboard">
              <span
                style={{ fontSize: '48px', color: '#fff', fontWeight: 900 }}
              >
                {data.mutrixCount}
              </span>
              <br />
              <span
                style={{ fontSize: '28px', color: '#fff', fontWeight: 'bold' }}
              >
                签约工场
              </span>
            </div>
          </Col>
          <Col span={4}>
            <div className="grid-dashboard">
              <span
                style={{ fontSize: '48px', color: '#fff', fontWeight: 900 }}
              >
                {data.customCount}
              </span>
              <br />
              <span
                style={{ fontSize: '28px', color: '#fff', fontWeight: 'bold' }}
              >
                签约组织
              </span>
            </div>
          </Col>
          <Col span={4}>
            <div className="grid-dashboard">
              <span
                style={{ fontSize: '48px', color: '#fff', fontWeight: 900 }}
              >
                {data.teamCount}
              </span>
              <br />
              <span
                style={{ fontSize: '28px', color: '#fff', fontWeight: 'bold' }}
              >
                球队数量
              </span>
            </div>
          </Col>
          <Col span={4}>
            <div className="grid-dashboard">
              <span
                style={{ fontSize: '48px', color: '#fff', fontWeight: 900 }}
              >
                {data.footballerCount}
              </span>
              <br />
              <span
                style={{ fontSize: '28px', color: '#fff', fontWeight: 'bold' }}
              >
                球员数量
              </span>
            </div>
          </Col>
          <Col span={4}>
            <div className="grid-dashboard">
              <span
                style={{ fontSize: '48px', color: '#fff', fontWeight: 900 }}
              >
                {data.courseCount}
              </span>
              <br />
              <span
                style={{ fontSize: '28px', color: '#fff', fontWeight: 'bold' }}
              >
                计划总数
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  render() {
    return (
      <div>
        <this.gridSection />
        <Divider />
        {/* 标题栏 包括下拉框、新增按钮 等*/}
        <this.titleSection />
        <MutrixUsage
          xAxis={this.state.mutrixUsage.xAxis}
          yData1={this.state.mutrixUsage.yData1}
          yData2={this.state.mutrixUsage.yData2}
          update={this.state.mutrixUsage.update}
        />
      </div>
    );
  }
}
export default Form.create()(CustomHome);
