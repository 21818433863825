// 组织管理
import * as React from 'react';
import {
  Table,
  Divider,
  Button,
  Modal,
  Form,
  Input,
  Select,
  message,
  Radio,
  Icon,
  Col,
} from 'antd';
import {
  TEAM_DELECT,
  CUSTOM_MODIFY,
  CUSTOM_LIST,
  ORGANIZATION_LISTNAME,
} from '../../app/api';
import GeoForm from '../../components/GeoForm'; //引入表单
import '../../styles/common.css';
import * as styles from './organizationManage.module.css';
import { jumpTo } from '../../router/Router';
// import emitter from '../../services/event'

class customerManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //显示模态窗开关
      visible: false,
      //模态窗类型
      title: '新增组织',
      //API查询的数据存储
      data: [],
      //分页的数据存储
      pagination: {},
      //模态窗省市区的数据存储
      modalData: {},
      organizationList: [],
      selectItems: '',
      value: '',
      queryOrganizaId: '', //查询组织的id
      pages: '',
      stateValue: '', //状态启用禁用
      organizaName: '',
      addId: '',
      thisData: {},
      teamManageVisible: false, //球员模态框
      teamId: '', //查询球员详细信息id
      display: 'none',
      totalElements: '',
      custmer: '',
      disabled: false,
      color: { color: '#1890ff', display: 'inline-block' },
    };
    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: '1',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        key: '2',
        title: '组织名称',
        dataIndex: 'name',
        width: '12%',
      },
      {
        key: '3',
        title: '联系人',
        dataIndex: 'contactName',
        width: '5%',
      },
      {
        key: '4',
        title: '联系电话',
        dataIndex: 'contactPhone',
        width: '6%',
      },
      {
        key: '5',
        title: '地区',
        dataIndex: 'fullAddress',
        width: '10%',
      },
      {
        key: '6',
        title: '详细地址',
        dataIndex: 'address',
        width: '18%',
      },
      {
        key: '7',
        title: '所属客户',
        dataIndex: 'orgNumber',
        width: '8%',
      },
      {
        key: '8',
        title: '授权工场',
        dataIndex: 'mutrixNumber',
        width: '6%',
      },
      {
        key: '9',
        title: '球队数量',
        dataIndex: 'teamNumber',
        width: '6%',
      },
      {
        key: '10',
        title: '状态',
        dataIndex: 'valid',
        width: '8%',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
      {
        title: '操作',
        key: 'operation',
        width: '23%',
        align: 'center',
        render: (text, record) => (
          <span>
            <span>
              <button
                disabled={this.state.disabled}
                onClick={this.showHide.bind(this, record)}
                style={this.state.color}
                // onClick={() =>
                //   jumpTo('/pages/homePage/MutrixHome', { id: record.id })
                // }
              >
                <Icon type="appstore" style={this.state.color} />
                主页
              </button>
              <Divider type="vertical" />
            </span>
            <button
              onClick={() =>
                jumpTo('/pages/orgManage/organizationDetails', {
                  id: record.id,
                })
              }
            >
              <Icon type="unordered-list" style={{ color: '#1890ff' }} />
              详情
            </button>

            {/*<a href="javascript:;"  onClick={() => jumpTo("/teamManage/teamEdit",{id:record.id}) }>*/}
            {/*    <Icon type="edit" style={{color:'#1890ff'}}/>*/}
            {/*    编辑</a>*/}
            <span>
              <Divider type="vertical" />
              <button
                style={this.state.color}
                onClick={this.editModal.bind(this, record)}
              >
                <Icon type="edit" style={{ color: '#1890ff' }} />
                编辑
              </button>
            </span>
          </span>
        ),
      },
    ];
  }
  componentWillMount() {
    this.queryTeam();
    this.organList();
  }
  componentDidMount() {
    const custmer = localStorage.getItem('roleid');

    this.setState({ custmer: localStorage.getItem('roleid') });
    let s = document.getElementById('title-org');
    if (custmer == 2) {
      s.style.display = 'none';
      this.setState({
        color: { display: 'none' },
      });
    }
  }
  showHide = record => {
    //隐藏主页
    if (this.state.custmer == 2) {
      message.warning('您还没有当前的权限');
      this.setState({
        disabled: true,
        color: { color: '#cccccc', display: 'none' },
      });
    } else {
      jumpTo('/pages/homePage/MutrixHome', { id: record.id });
    }
  };
  organList = pages => {
    let params = {}; //组织的id
    params = pages;
    CUSTOM_LIST(params).then(res => {
      const data = res.data.list.content;
      const pagination = { ...this.state.pagination };
      pagination.pageSize = res.data.list.size;
      pagination.total = res.data.list.totalElements;
      pagination.current = res.data.list.number + 1;
      const allData = data.map(v =>
        Object.assign({}, v.entity, {
          rowKey: v.entity.id,
          fullAddress: v.fullAddress,
          orgNumber: v.orgNumber,
          mutrixNumber: v.mutrixNumber,
          teamNumber: v.teamNumber,
        })
      );
      this.setState({
        data: allData,
        totalElements: res.data.list.totalElements,
        pagination,
      });
    });
  };
  // teamEdit = (text,record) => {
  //     console.log(record);
  //     this.props.history.push({
  //         pathname: '/teamManage/teamEdit',
  //         state: {thisData: record}
  //     })
  // };
  handleTableChange = pagination => {
    const paging = pagination.current;
    this.setState({
      pages: paging, //点击的当前页码
    });
    if (paging !== undefined) {
      this.organList({ pageNum: paging });
    }
  };
  /**
   * 编辑组织弹窗
   */
  editModal = record => {
    this.setState({
      visible: true,
      modalData: record,
      title: '编辑组织',
    });
  };
  editCancel = () => {
    this.setState({
      visible: false,
      display: 'none',
    });
  };
  handleDelete = record => {
    TEAM_DELECT({ id: record.id })
      .then(res => {
        if (res.code === 0) {
          message.success('删除【' + record.name + '】成功');
          this.organList();
        } else {
          message.error(res.message);
        }
      })
      .catch(err => message.error(err));
  };
  editOk = () => {
    this.props.form.validateFields((errors, values) => {
      if (
        this.state.modalData.province === 0 ||
        this.state.modalData.city === 0 ||
        this.state.modalData.region === 0
      ) {
        this.setState({
          display: 'block',
        });
      } else if (
        !errors &&
        this.state.modalData.province !== 0 &&
        this.state.modalData.city !== 0 &&
        this.state.modalData.region !== 0
      ) {
        this.setState({ display: 'none' });
        let body = {
          entity: {
            id: this.state.modalData.id,
            name: values.name,
            contactName: values.contactName,
            contactPhone: values.contactPhone,
            province: parseInt(this.state.modalData.province),
            city: parseInt(this.state.modalData.city),
            region: parseInt(this.state.modalData.region),
            address: values.address,
            valid: this.state.stateValue,
          },
        };
        CUSTOM_MODIFY(body)
          .then(res => {
            this.setState({ visible: false });
            message.success(this.state.title + '【' + values.name + '】成功');
            this.organList();
          })
          .catch(err => {
            if (err) {
              message.error(err);
            }
          });
      }
    });
  };
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
    if (
      this.state.modalData.province === 0 &&
      this.state.modalData.city === 0 &&
      this.state.modalData.region === 0
    ) {
      this.setState({ display: 'block' });
    }
  };
  queryTeam = () => {
    ORGANIZATION_LISTNAME().then(res => {
      this.setState({
        organizationList: res.data.list,
      });
    });
  };
  organizationChange = key => {
    // const thisId = key.key;
    this.setState({ queryOrganizaId: key });
    this.organList({ orgId: key });
  };
  /**
   * model里面的组织下拉change
   * @param value
   * @param key
   */
  organizaChange = (value, key) => {
    this.setState({
      organizaName: value,
      addId: key.key,
    });
  };
  searchOrganization = value => {
    this.organList({ name: value });
  };
  getInput = e => {
    this.setState({
      value: e.target.value,
    });
  };
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { organizationList } = this.state;
    const RadioGroup = Radio.Group;
    const Option = Select.Option;
    const Search = Input.Search;
    const column = this.column.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          rowKey: record.key,
        }),
      };
    });
    return (
      <div>
        <div className={styles['organizationSelect']} id="title-org">
          <Select
            defaultValue="全部客户"
            title="全部客户"
            onChange={this.organizationChange}
            // value={this.state.value}
            style={{ width: 150, marginBottom: '16px', marginRight: '10px' }}
          >
            <Option key="0">全部客户</Option>
            {organizationList.map(item => (
              <Option key={item.id}>{item.name}</Option>
            ))}
          </Select>
          <Col span={5}>
            <Search
              placeholder="请输入组织名称"
              onSearch={this.searchOrganization}
            />
          </Col>
          <div className={styles['btn-box']}>
            {/*<Button type='primary' onClick={this.addModal} className='btn-add'>新增组织</Button>*/}
            <Button
              type="primary"
              onClick={() => jumpTo('/pages/orgManage/organizationAdd')}
              className={styles['btn-add']}
            >
              新增组织
            </Button>
          </div>
        </div>
        <Modal
          title={this.state.title}
          visible={this.state.visible}
          onOk={this.editOk}
          onCancel={this.editCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={this.editCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.editOk}>
              确定
            </Button>,
          ]}
        >
          <Form>
            <Form.Item label="组织名称" className="organizationName">
              {getFieldDecorator('name', {
                initialValue: this.state.modalData.name, //填充input默认数据
                rules: [
                  {
                    required: true,
                    message: '请输入组织名称',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="联系人" className="organizationName">
              {getFieldDecorator('contactName', {
                initialValue: this.state.modalData.contactName,
                rules: [
                  {
                    required: true,
                    message: '请输入联系人',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="联系电话" className="organizationName">
              {getFieldDecorator('contactPhone', {
                initialValue: this.state.modalData.contactPhone,
                rules: [
                  {
                    required: true,
                    message: '请输入联系电话',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Form>
          <Form.Item label="地区:" className="organizationAddress">
            <GeoForm
              regionId={this.state.modalData.region}
              provinceId={this.state.modalData.province}
              cityId={this.state.modalData.city}
              updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
            />
            <span style={{ color: 'red', display: this.state.display }}>
              请输入省市区
            </span>
          </Form.Item>
          <Form.Item label="详细地址" className="organizationName">
            {getFieldDecorator('address', {
              initialValue: this.state.modalData.address,
              rules: [
                {
                  required: true,
                  message: '请输入详细地址',
                },
              ],
            })(<Input />)}
          </Form.Item>
          {/*<span className={styles['teamSelect']}>状态</span>*/}
          <Form.Item label="状态" className="organizationName">
            {getFieldDecorator('valid', {
              initialValue: this.state.modalData.valid,
              rules: [
                {
                  required: true,
                  message: '请选择状态',
                },
              ],
            })(
              <RadioGroup
                onChange={this.stateChange}
                setFieldsValue={this.state.modalData.valid}
              >
                <Radio value={true}>启用</Radio>
                <Radio value={false}>禁用</Radio>
              </RadioGroup>
            )}
          </Form.Item>
        </Modal>
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowKey={record => record.id}
        />
      </div>
    );
  }
}
export default Form.create()(customerManage);
