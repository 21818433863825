import * as React from 'react';
import { Col, Icon, Breadcrumb, Badge } from 'antd';
import Header from '../../components/Header';
import LeftNav from '../../components/LeftNav';
import { connect } from 'react-redux';
import '../../styles/common.css';
import {
  HashRouter as Router,
  Route,
  Switch,
  Link,
  withRouter,
} from 'react-router-dom';
// import Link from 'umi/link'
import breadcrumbs from '../../router/newRouter';
import * as styles from './index.module.css';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import Bread from '../../components/Breadcrumbs';
// import {Link} from "react-router-dom";
import { NavLink } from 'react-router-dom';
import BreadData from '../../components/Header/breadcrumbs';
// npm install --save react-router5-hocs

//
// class customerDetails extends React.Component {

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  BreadFunction = () => {
    if (breadcrumbs || Array.isArray(breadcrumbs)) {
      console.log(breadcrumbs);

      const AntdBreadcrumb = withBreadcrumbs(breadcrumbs)(({ breadcrumbs }) => {
        return (
          <Breadcrumb
            separator={<Icon type="double-right" />}
            classNames="spread"
          >
            <span>123456789</span>
            {breadcrumbs.map((breadcrumb, index) => (
              <Breadcrumb.Item key={breadcrumb.key}>
                {breadcrumbs.length - 1 === index ? (
                  <Badge status="processing" text={breadcrumb} />
                ) : (
                  <Link
                    to={{
                      pathname: breadcrumb.props.match.url,
                      state: breadcrumb.props.match.params
                        ? breadcrumb.props.match.params
                        : {},
                      query: breadcrumb.props.location.query
                        ? breadcrumb.props.location.query
                        : {},
                    }}
                  >
                    {breadcrumb}
                  </Link>
                )}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        );
      });
      return <AntdBreadcrumb />;
    }
  };

  render() {
    const userNamesById = { '1': 'John' };

    const DynamicUserBreadcrumb = ({ match }) => (
      <span>{userNamesById[match.params.userId]}</span>
    );
    return (
      <div className="nav-box">
        {/*头部*/}
        <div>
          <Header />
        </div>

        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <Col span={3} style={{ maxWidth: 'none', width: 'auto' }}>
            <div className="nav-left">
              {/*//引入导航*/}
              <LeftNav />
            </div>
          </Col>

          <Col span={21} className="content-box">
            <div className={styles['bread_style']}>
              <span>当前位置：</span>
              <Switch>
                {breadcrumbs.map((route, i) => (
                  <Route key={route.path} {...route}></Route>
                ))}
              </Switch>
            </div>
            {/*内容*/}
            <div className="content">
              <div className="content-list" style={{}}>
                {this.props.children}
              </div>
            </div>
          </Col>
        </div>
      </div>
    );

    return <BreadData data={breadcrumbs} />;
  }
}

export default connect()(Home);
// withBreadcrumbs(NewRouter)
