import * as React from 'react';
import {Button,Input} from 'antd';
import './passwordManage.css';




class passwordManage extends React.Component{

    render() {
        return(
            <div>
                <div className='password-box'>
                    <Input size="large" placeholder="原始密码" />
                    <Input size="large" placeholder="新密码" />
                    <Input size="large" placeholder="确认密码" />
                    <Button type="primary" size='large' className='ok-btn'>确认</Button>
                    <Button type="primary" size='large' className='password-btn'>忘记密码</Button>
                </div>
            </div>
        )
    }

}

export default passwordManage

