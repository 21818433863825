import React from 'react';
import { jumpTo, jumpGo } from './Router';
const paths = '/pages';

let data = sessionStorage.getItem('player');
console.log(data);

const newRouter = [
  {
    path: paths + '/homePage/systemHome',
    component: () => '首页',
    exact: true,
    breadcrumb: '首页',
  },
  {
    path: paths + '/homePage/customHome',
    component: () => '首页',
    breadcrumb: '首页',
  },

  {
    path: paths + '/homePage/MutrixHome',
    component: () => '首页',
    breadcrumb: '首页',
  },

  {
    path: paths + '/orgManage/customerManage',
    component: () => '用户管理 / 客户管理',
    breadcrumb: '客户管理',
  },
  {
    path: paths + '/orgManage/organizationManage',
    component: () => '用户管理 / 组织管理',
    breadcrumb: '组织管理',
  },
  {
    path: paths + '/orgManage/organizationAdd',
    component: () => (
      <span>
        用户管理 /
        <span onClick={() => jumpTo('/pages/orgManage/organizationManage', {})}>
          组织管理 /
        </span>{' '}
        新增组织
      </span>
    ),
    breadcrumb: '组织管理',
  },
  {
    path: paths + '/orgManage/customerDetails',
    // component: () => "用户管理 /" + <Link to='pages/orgManage/customerManage'>客户管理</Link> + ' / 客户详情',
    component: () => (
      <span>
        用户管理 /
        <span onClick={() => jumpTo('/pages/orgManage/customerManage', {})}>
          客户管理 /
        </span>{' '}
        客户详情
      </span>
    ),
    breadcrumb: '客户详情',
  },
  {
    path: paths + '/orgManage/organizationDetails',
    // component: () => "用户管理 /" + <Link to='pages/orgManage/customerManage'>客户管理</Link> + ' / 客户详情',
    component: () => (
      <span>
        用户管理 /
        <span onClick={() => jumpTo('/pages/orgManage/organizationManage', {})}>
          组织管理 /
        </span>{' '}
        组织详情
      </span>
    ),
    breadcrumb: '客户详情',
  },
  {
    path: paths + '/teamManage/teamManage',
    component: () => '用户管理 / 球队管理',
    breadcrumb: '球队管理',
  },

  {
    path: paths + '/teamManage/teamDetails',
    component: () => (
      <span>
        用户管理 /
        <span onClick={() => jumpTo('/pages/teamManage/teamManage', {})}>
          球队管理 /
        </span>{' '}
        球队详情
      </span>
    ),
    breadcrumb: '球队管理',
  },

  {
    path: paths + '/teamManage/teamAdd',
    component: () => (
      <span>
        用户管理 /
        <span onClick={() => jumpTo('/pages/teamManage/teamManage', {})}>
          球队管理 /
        </span>{' '}
        新增球队
      </span>
    ),
    breadcrumb: '球队管理',
  },

  {
    path: paths + '/coach/coach',
    component: () => '用户管理 / 教练管理',
    breadcrumb: '教练管理',
  },
  {
    path: paths + '/coach/coachAdd',
    component: () => (
      <span>
        用户管理 /
        <span onClick={() => jumpTo('/pages/coach/coach', {})}>教练管理 /</span>{' '}
        新增教练
      </span>
    ),
    breadcrumb: '教练管理',
  },

  {
    path: paths + '/coach/coachDetails',
    component: () => (
      <span>
        用户管理 /
        <span onClick={() => jumpTo('/coach/coach', {})}>教练管理 /</span>{' '}
        教练详情
      </span>
    ),
    breadcrumb: '教练管理',
  },
  {
    path: paths + '/coach/coachEdit',
    component: () => (
      <span>
        用户管理 /
        <span onClick={() => jumpTo('/pages/coach/coachDetails', {})}>
          教练管理 /
        </span>{' '}
        编辑教练
      </span>
    ),
    breadcrumb: '教练管理',
  },
  {
    path: paths + '/player/player',
    component: () => '用户管理 / 球员管理',
    breadcrumb: '球员管理',
  },
  {
    path: paths + '/player/playerDetails',
    component: () => (
      <span>
        用户管理 /
        <span onClick={() => jumpTo('/pages/player/player', {})}>
          球员管理 /
        </span>
        {data === 'player' ? '球员数据' : '球员详情'}
      </span>
    ),
    breadcrumb: '球员管理',
  },
  // {
  //   path: paths + '/player/playerDetails',
  //   component: () => (
  //     <span>
  //       用户管理 /
  //       <span onClick={() => jumpTo('/pages/player/player', {})}>
  //         球员管理 /
  //       </span>{data === 'player' ? '球员数据': '球员详情'}
  //       球员数据
  //     </span>
  //   ),
  //   breadcrumb: '球员管理',
  // },
  {
    path: paths + '/player/playerAdd',
    component: () => (
      <span>
        用户管理 /
        <span onClick={() => jumpTo('/pages/player/player', {})}>
          球员管理 /
        </span>{' '}
        新增球员
      </span>
    ),
    breadcrumb: '球员管理',
  },
  {
    path: paths + '/MutrixManage/mutrixManage',
    component: () => '系统管理 / 工场管理',
    breadcrumb: '工场管理',
  },
  {
    path: paths + '/mutrixManage/mutrixDetail',
    component: () => (
      <span>
        系统管理 /
        <span onClick={() => jumpTo('/pages/MutrixManage/mutrixManage', {})}>
          工场管理 /
        </span>{' '}
        工场详情
      </span>
    ),
    breadcrumb: '工场管理',
  },

  {
    path: paths + '/courseManage/courseConfigManage',
    component: () => '系统管理 / 教案管理',
    breadcrumb: '教案管理',
  },
  {
    path: paths + '/courseManage/courseConfigEdit',
    component: () => (
      <span>
        系统管理 /
        <span
          onClick={() => jumpTo('/pages/courseManage/courseConfigManage', {})}
        >
          教案管理 /
        </span>{' '}
        教案配置
      </span>
    ),
    breadcrumb: '教案管理',
  },

  {
    path: paths + '/alarmManage/alarmManage',
    component: () => '系统管理 / 告警管理',
    breadcrumb: '告警管理',
  },

  {
    path: paths + '/alarmManage/AlarmDefine',
    component: () => (
      <span>
        系统管理 /
        <span onClick={() => jumpTo('/pages/alarmManage/alarmManage', {})}>
          告警管理 /
        </span>{' '}
        告警定义
      </span>
    ),
    breadcrumb: '告警管理',
  },
  {
    path: paths + '/authorityManage/authorityManage',
    component: () => '系统管理 / 管理员账号',
    breadcrumb: '管理员账号',
  },

  {
    path: paths + '/authorityManage/authorityEdit',
    component: () => (
      <span>
        系统管理 /
        <span
          onClick={() => jumpTo('/pages/authorityManage/authorityManage', {})}
        >
          管理员账号 /{' '}
        </span>{' '}
        新增或编辑账号
      </span>
    ),
    breadcrumb: '管理员账号',
  },

  {
    path: paths + '/accountCenter/accountCenter',
    component: () => <span>个人中心</span>,
    breadcrumb: '个人中心',
  },

  {
    path: paths + '/accountCenter/customerCenter',
    component: () => <span>个人中心</span>,
    breadcrumb: '个人中心',
  },

  {
    path: paths + '/accountCenter/mutrixCenter',
    component: () => <span>个人中心</span>,
    breadcrumb: '个人中心',
  },
  {
    path: paths + '/data/result',
    component: () => <span>球员数据</span>,
    breadcrumb: '球员数据',
  },

  // const routes = [
  //     { path: '/zh_CN/userManagement', breadcrumb: '用户管理' },
  //     { path: '/zh_CN/userManagement/Detail', breadcrumb: DynamicUserBreadcrumb },
];

export default newRouter;
