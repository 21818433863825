import axios from 'axios';
import { message } from 'antd';
import { jumpTo } from '../router/Router';
// import qs from 'qs'
// import * as actions from '../store/actions'
// import store from '../store'

// 环境的切换

// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = 'http://10.0.1.20:8071';
//     axios.defaults.baseURL = 'http://localhost:3000';  //本地环境
//     axios.defaults.baseURL = 'http://127.0.0.1:3000';  //本地环境
//  Access-Control-Allow-Origin}
// else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL = 'http://10.0.1.20:8071';  //线上环境
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = 'http://request/'
// }

/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
axios.defaults.withCredentials = true; //可以跨域携带cooks
axios.defaults.timeout = 100000;
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
// 'Access-Control-Allow-Origin':'http://localhost:3000'
//axios.defaults.headers.post['Content-Type'] = 'appliation/json;charset=UTF-8';

// axios响应拦截器
axios.interceptors.response.use(response => {
  // 判断后台返回数据携带的请求码 如果成功，返回数据集合；否则，弹窗提及，业务模块不做额外处理
  if (response.data.code === 0) {
    //*代表任何域名都可以访问这个服务
    return Promise.resolve(response);
  } else if (response.data.code === 2) {
    jumpTo('/pages/login');
  } else {
    message.error(response.data.message);
  }
});

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

export function post(url, params, body) {
  const paramsUrl = encodeSearchParams(params);
  if (typeof paramsUrl !== 'undefined') {
    url = url + '?' + paramsUrl;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, body)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

export function upload(url, params, body) {
  const paramsUrl = encodeSearchParams(params);
  if (typeof paramsUrl !== 'undefined') {
    url = url + '?' + paramsUrl;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, body)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

/**
 * 拼接对象为请求字符串，并进行url编码到原始API地址上
 */
export function encodeSearchParams(obj) {
  if (typeof obj === 'undefined') {
    return undefined;
  }
  const params = [];
  Object.keys(obj).forEach(key => {
    let value = obj[key];
    // 如果值为undefined我们将其置空
    if (typeof value !== 'undefined') {
      params.push([key, encodeURIComponent(value)].join('='));
    }
    // 对于需要编码的文本（比如说中文）我们要进行编码
  });
  return params.join('&');
}
export default { get, post };
