//客户管理
import * as React from 'react';
import {
  Table,
  Divider,
  Button,
  Modal,
  Form,
  Input,
  message,
  Col,
  Icon,
  Radio,
} from 'antd';
import GeoForm from '../../components/GeoForm'; //引入表单
import {
  ORGANIZATION,
  ORGANIZATION_ADD,
  ORGANIZATION_MODIFY,
} from '../../app/api';
import * as styles from './organizationManage.module.css';
import { jumpTo } from '../../router/Router';

class OrganizationManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 显示模态窗开关
      visible: false,
      // 模态窗类型
      title: '新增组织',
      // API查询的数据存储
      data: [],
      // 分页的数据存储
      pagination: {},
      // 模态窗的数据存储
      modalData: {},
      stateValue: true, //状态启用禁用
      display: 'none',
      custmer: '',
      disabled: false,
      color: { color: '#1890ff', display: 'inline-block' },
      styles: { display: 'inline-block' },
    };
    //table 表格数据
    this.column = [
      {
        key: '1',
        title: '序号',
        dataIndex: 'id',
        width: '5%',
        render: (text, record, index) => {
          return (
            <span>
              {index +
                1 +
                (this.state.pagination.current - 1) *
                  this.state.pagination.pageSize}
            </span>
          );
        },
      },
      {
        key: '2',
        title: '客户名称',
        dataIndex: 'name',
        width: '10%',
      },
      {
        key: '3',
        title: '联系人',
        dataIndex: 'contactName',
        width: '8%',
      },
      {
        key: '4',
        title: '联系电话',
        dataIndex: 'contactPhone',
        width: '8%',
      },
      {
        key: '5',
        title: '地区',
        dataIndex: 'fullAddress',
        width: '13%',
      },
      {
        key: '6',
        title: '详细地址',
        dataIndex: 'address',
        width: '20%',
      },
      {
        key: '8',
        title: '签约组织',
        dataIndex: 'customNumber',
        width: '7%',
      },
      {
        key: '7',
        title: '签约工场',
        dataIndex: 'mutrixNumber',
        width: '7%',
      },
      {
        key: '9',
        title: '状态',
        dataIndex: 'valid',
        width: '6%',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width: '25%',
        align: 'center',
        render: (text, record) => (
          <span>
            <button
              // onClick={() =>
              //   jumpTo('/pages/homePage/customHome', { id: record.id })
              // }
              disabled={this.state.disabled}
              onClick={this.showHide.bind(this, record)}
              style={this.state.color}
            >
              <Icon type="appstore" style={this.state.color} />
              主页
            </button>
            <Divider type="vertical" />
            <button
              onClick={() =>
                jumpTo('/pages/orgManage/customerDetails', { id: record.id })
              }
            >
              <Icon type="unordered-list" />
              详情
            </button>
            <Divider type="vertical" />
            <button
              style={this.state.styles}
              onClick={this.editModal.bind(this, record)}
            >
              <Icon type="edit" />
              编辑
            </button>
            {/*产品更改暂时不要删除功能*/}
            {/* <Popconfirm title={"删除【" + record.name+ "】不可恢复，你确定要删除吗?"} onConfirm={() => this.handleDelete(record)}>*/}
            {/*    <a title='用户删除' href="javascript:;">删除</a>*/}
            {/*</Popconfirm>*/}
          </span>
        ),
      },
    ];
  }

  // 加载数据
  componentWillMount() {
    this.organList();
  }
  componentDidMount() {
    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    let t = document.getElementById('customer');
    if (custmer == 3) {
      t.style.display = 'none'; //客户管理员登录时隐藏此项
    }
    if (custmer == 2) {
      t.style.display = 'none'; //工场管理员登录时隐藏此项
      this.setState({
        color: { display: 'none' },
        styles: { display: 'none' },
      });
    }
    if (this.state.custmer == 1) {
      message.warning('您还没有当前的权限');
      this.setState({
        disabled: true,
        color: { color: '#cccccc', display: 'none' },
      });
    }
  }
  showHide = record => {
    //隐藏主页
    if (this.state.custmer == 2) {
      message.warning('您还没有当前的权限');
      this.setState({
        disabled: true,
        color: { color: '#cccccc', display: 'none' },
        styles: { display: 'none' },
      });
    } else {
      jumpTo('/pages/homePage/customHome', { id: record.id });
    }
  };
  organList = params => {
    ORGANIZATION(params)
      .then(res => {
        const data = res.data.list.content; //list外层面的详细地址数据
        const pagination = { ...this.state.pagination };
        //pagination.total = res.data.list.totalPages;
        pagination.pageSize = res.data.list.size;
        pagination.total = res.data.list.totalElements;
        //后台返回的原始分页从0开始，未做修正，这里需要加1
        pagination.current = res.data.list.number + 1;
        const theyData = data.map(v =>
          Object.assign({}, v.entity, {
            rowKey: v.entity.id,
            fullAddress: v.fullAddress,
            mutrixNumber: v.mutrixNumber,
            customNumber: v.customNumber,
          })
        );
        this.setState({
          data: theyData,
          pagination,
        });
      })
      .catch(err => console.log(err));
  };
  addModal = () => {
    this.setState({ modalData: {}, visible: true, title: '新增客户' });
  };
  editModal = record => {
    if (this.state.custmer == 2) {
      message.warning('您还没有当前权限');
      this.setState({
        disabled: true,
        color: { color: '#cccccc' },
      });
    } else {
      this.setState({
        visible: true,
        modalData: record,
        title: '编辑客户',
      });
    }
  };
  editCancel = () => {
    this.setState({
      visible: false,
      display: 'none',
    });
  };
  /**
   * 删除，确认
   */
  // handleDelete = record => {
  //     ORGANIZATION_DELECT({id:record.id}).then(res =>{
  //         if (res.code == 0) {
  //             message.success('删除【' +record.name+ '】成功');
  //            this.organList()
  //         }else {
  //             message.error(res.message);
  //         }
  //     }).catch(err => message.error(err))
  // }
  editOk = () => {
    // console.log(this.state.modalData.province) 也许可以用 ||
    if (
      this.state.modalData.province === 0 ||
      this.state.modalData.city === 0 ||
      this.state.modalData.region === 0
    ) {
      this.setState({
        display: 'block',
      });
    } else if (
      this.state.modalData.province === undefined ||
      this.state.modalData.city === undefined ||
      this.state.modalData.region === undefined
    ) {
      this.setState({
        display: 'block',
      });
    } else {
      this.setState({ display: 'none' });
      this.props.form.validateFields((errors, values) => {
        if (!errors) {
          let body = {
            entity: {
              id: this.state.modalData.id,
              name: values.name,
              province: parseInt(this.state.modalData.province),
              city: parseInt(this.state.modalData.city),
              region: parseInt(this.state.modalData.region),
              address: values.address,
              contactName: values.contactName,
              contactPhone: values.contactPhone,
              valid: this.state.stateValue,
            },
          };
          let api = '';
          if (this.state.title === '新增组织') {
            api = ORGANIZATION_ADD;
          } else {
            api = ORGANIZATION_MODIFY;
          }
          api(body)
            .then(res => {
              this.setState({ visible: false });
              message.success(this.state.title + '【' + values.name + '】成功');
              this.organList();
            })
            .catch(err => {
              if (err) {
                message.error(err);
              }
            });
        }
      });
    }
  };
  /**
   *  改变分页后的提交查询请求；这里的pageNum从1开始，后台自行修正
   */
  handlePaginationChange = pagination => {
    this.organList({
      // pageSize: pagination.current,
      pageNum: pagination.current,
    });
  };
  /**
   * 更新地理信息到本地存储
   */
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
    if (
      this.state.modalData.province !== undefined &&
      this.state.modalData.city !== undefined &&
      this.state.modalData.region !== undefined
    ) {
      this.setState({ display: 'none' });
    }
    if (
      this.state.modalData.province === 0 &&
      this.state.modalData.city === 0 &&
      this.state.modalData.region === 0
    ) {
      this.setState({ display: 'block' });
    }
  };
  searchCustomer = value => {
    this.organList({
      name: value,
    });
  };
  /***
   * 状态启用禁用的方法
   */
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  // selectAddress = (value,rules,callback) =>{
  //     console.log(value)
  //     if(this.state.modalData.province === undefined && this.state.modalData.city === undefined && this.state.modalData.region === undefined){
  //         callback('请输入省市区');
  //         return;
  //     }
  //     callback();
  // };
  /**
   * 新增 编辑的弹框
   */
  showModel = () => {
    const { getFieldDecorator } = this.props.form;
    const RadioGroup = Radio.Group;
    return (
      <div>
        <Modal
          title={this.state.title}
          visible={this.state.visible}
          onOk={this.editOk}
          onCancel={this.editCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={this.editCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.editOk}>
              确定
            </Button>,
          ]}
        >
          <Form>
            <Form.Item label="客户名称" className="organizationName">
              {getFieldDecorator('name', {
                initialValue: this.state.modalData.name, //填充input默认数据
                rules: [
                  {
                    required: true,
                    message: '请输入客户名称',
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="联系人" className="organizationName">
              {getFieldDecorator('contactName', {
                initialValue: this.state.modalData.contactName,
                rules: [
                  {
                    required: true,
                    message: '请输入联系人',
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="联系电话" className="organizationName">
              {getFieldDecorator('contactPhone', {
                initialValue: this.state.modalData.contactPhone,
                rules: [
                  {
                    required: true,
                    message: '请输入联系电话',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Form>

          <Form.Item label="地区:" className="organizationAddress">
            <GeoForm
              regionId={this.state.modalData.region}
              provinceId={this.state.modalData.province}
              cityId={this.state.modalData.city}
              updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
            />
            <span style={{ color: 'red', display: this.state.display }}>
              请选择省地区
            </span>
          </Form.Item>

          <Form.Item label="详细地址" className="organizationName">
            {getFieldDecorator('address', {
              initialValue: this.state.modalData.address,
              rules: [
                {
                  required: true,
                  message: '请输入详细地址',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="状态" className="organizationName">
            {getFieldDecorator('valid', {
              initialValue: this.state.modalData.valid,
              rules: [
                {
                  required: true,
                  message: '请选择状态',
                },
              ],
            })(
              <RadioGroup
                onChange={this.stateChange}
                setFieldsValue={this.state.modalData.valid}
              >
                <Radio value={true}>启用</Radio>
                <Radio value={false}>禁用</Radio>
              </RadioGroup>
            )}
          </Form.Item>
        </Modal>
      </div>
    );
  };
  render() {
    const Search = Input.Search;
    const column = this.column.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
          rowKey: record.key,
        }),
      };
    });
    return (
      <div>
        <div id="customer">
          <Col span={5}>
            <Search
              placeholder="请输入客户名称"
              onSearch={this.searchCustomer}
            />
          </Col>
          <div className={styles['btn-box']}>
            <Button
              type="primary"
              onClick={this.addModal}
              className={styles['btn-add']}
            >
              新增客户
            </Button>
          </div>
        </div>
        <this.showModel />
        <Table
          columns={column}
          bordered
          showHeader={true}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handlePaginationChange}
          rowKey={record => record.key}
        />
      </div>
    );
  }
}
export default Form.create()(OrganizationManage);
