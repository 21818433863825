import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
// import BreadcrumbCustom from './components/Header/BreadcrumbCustom';
// import './App.module.css';
import Breadcrumbs from './components/Header/breadcrumbs';
import NewRouter from './router/newRouter';
import { Route, Switch } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <div>
        {/*<Breadcrumbs/>*/}
        {/*{this.props.children}*/}
        {/*<button onClick={() => {*/}
        {/*    this.context.router.history.push("/homePage/systemHome");*/}
        {/*}}>Go to 首页</button>*/}
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default App;
