//组织详情
import * as React from 'react';
import * as styles from './mutrixStyle.module.css';
import TeamList from '../teamManage/teamList';
import {
  Button,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Radio,
  Popover,
  Table,
} from 'antd';
import { CUSTOM_MODIFY, CUSTOM_QUERY, MUTRIX_LIST } from '../../app/api';
import GeoForm from '../../components/GeoForm';
import { jumpTo } from '../../router/Router';

class organizationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: 0, //组织的ID
      customerData: [],
      queryList: [],
      columns: [],
      visible: false, //编辑弹框
      modalData: {}, //省市区的数据
      stateValue: '', //状态启用禁用
      MutrixData: [], //工场数据存储
      display: 'none',
    };
  }
  componentWillMount() {
    let params = new URLSearchParams(window.location.search.split('?')[1]);
    this.state.customerId = params.get('id');
    this.query();
    this.queryMutrix();
  }
  componentDidMount() {
    const custmer = localStorage.getItem('roleid');
    this.setState({ custmer: localStorage.getItem('roleid') });
    let t = document.getElementById('edit-btn');
    let f = document.getElementById('teamAdd');
    if (custmer == 2) {
      t.style.display = 'none'; //工场管理员登录时隐藏此项
      f.style.display = 'none';
    }
  }

  query = () => {
    let params =
      this.state.customerId !== 0 ? { id: this.state.customerId } : {};
    CUSTOM_QUERY(params).then(res => {
      this.setState({
        customerData: res.data.query.entity,
        queryList: res.data.query,
      });
    });
  };
  /**
   * 查询组织下的授权工场
   */
  queryMutrix = () => {
    MUTRIX_LIST({ customId: this.state.customerId }).then(res => {
      const data = res.data.list.content;
      const allData = data.map(v =>
        Object.assign({}, v.entity, {
          fullAddress: v.fullAddress,
          org: v.org,
          contactName: v.contactName,
          contactPhone: v.contactPhone,
          rowKey: v.key,
        })
      );
      this.setState({
        MutrixData: allData,
      });
    });
  };
  updateGeoForm = o => {
    this.setState({ modalData: { ...this.state.modalData, ...o } });
  };
  stateChange = e => {
    this.setState({
      stateValue: e.target.value,
    });
  };
  /**
   * 编辑组织弹框
   */
  editModel = () => {
    this.setState({
      visible: true,
      modalData: this.state.customerData,
    });
  };
  editCancel = () => {
    this.setState({
      visible: false,
      display: 'none',
    });
  };
  editOk = () => {
    if (
      this.state.modalData.province === 0 ||
      this.state.modalData.city === 0 ||
      this.state.modalData.region === 0
    ) {
      this.setState({
        display: 'block',
      });
    } else {
      this.setState({ display: 'none' });
      this.props.form.validateFields((errors, values) => {
        if (!errors) {
          let body = {
            entity: {
              id: this.state.customerData.id,
              name: values.name,
              contactName: values.contactName,
              contactPhone: values.contactPhone,
              province: parseInt(this.state.modalData.province),
              city: parseInt(this.state.modalData.city),
              region: parseInt(this.state.modalData.region),
              address: values.address,
              valid: this.state.stateValue,
            },
          };
          CUSTOM_MODIFY(body)
            .then(res => {
              this.setState({ visible: false });
              //需重新加载数据渲染一遍
              message.success('编辑客户【' + values.name + '】成功');
              this.query();
              this.forceUpdate(); //更新刷新数据
            })
            .catch(err => {
              if (err) {
                message.error(err);
              }
            });
        }
      });
    }
  };
  basicData = () => {
    const column = [
      {
        key: '1',
        title: '序号',
        dataIndex: '1',
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        },
      },
      {
        key: '2',
        title: '授权工场',
        dataIndex: 'name',
      },
      {
        key: '3',
        title: '地区',
        dataIndex: 'fullAddress',
      },
      {
        key: '4',
        title: '详细地址',
        dataIndex: 'address',
      },
      {
        key: '5',
        title: '所属客户',
        dataIndex: 'org',
        render: org => {
          return <span>{org.name}</span>;
        },
      },
      {
        key: '6',
        title: '联系人',
        dataIndex: 'contactName',
      },
      {
        key: '7',
        title: '联系电话',
        dataIndex: 'contactPhone',
      },
      {
        key: '8',
        title: '状态',
        dataIndex: 'valid',
        render: msg =>
          msg === true ? (
            <span style={{ color: 'green' }}>启用</span>
          ) : (
            <span style={{ color: 'orange' }}>禁用</span>
          ),
      },
    ];
    const content = (
      <Table
        columns={column}
        bordered
        showHeader={true}
        dataSource={this.state.MutrixData}
        onChange={this.handleTableChange}
        rowKey={record => record.index}
      />
    );
    return (
      <div>
        <div className={styles['basic-data']}>
          <span>基础资料</span>
          <div
            id="edit-btn"
            style={{
              display: 'inline-block',
              marginLeft: '88%',
              cursor: 'pointer',
              color: '#1890ff',
              fontSize: '16px',
            }}
            onClick={this.editModel}
          >
            <Icon type="edit" /> 编辑
          </div>
        </div>
        <div className={styles['data-list']}>
          <p>组织名称：{this.state.customerData.name}</p>
          <p>联系人：{this.state.customerData.contactName}</p>
          <p>联系电话：{this.state.customerData.contactPhone}</p>
          <p>地区：{this.state.queryList.fullAddress}</p>
          <p>详细地址：{this.state.customerData.address}</p>
          <p>
            状态：{this.state.customerData.valid === true ? '启用' : '禁用'}
          </p>
          <p>
            签约信息：
            <Popover
              content={content}
              title="签约信息"
              placement="right"
              trigger="click"
            >
              <Button
                icon="file-text"
                theme="file-text"
                style={{ fontSize: '20px', color: '#1890ff', border: 'none' }}
              >
                {/*<Icon type="file-text" theme="file-text" />*/}
              </Button>
            </Popover>
          </p>
        </div>
      </div>
    );
  };
  ContractWorkshop = () => {
    return (
      <div>
        <div className={styles['basic-data']}>
          <span>球队</span>
          <div
            id="teamAdd"
            style={{
              display: 'inline-block',
              marginLeft: '88%',
              cursor: 'pointer',
              fontSize: '16px',
              color: '#1890ff',
            }}
            onClick={() =>
              jumpTo('/pages/teamManage/teamAdd', { id: this.state.customerId })
            }
          >
            <Icon type="plus-circle" /> 新增
          </div>
        </div>
      </div>
    );
  };
  showModal = () => {
    const { getFieldDecorator } = this.props.form;
    const RadioGroup = Radio.Group;
    return (
      <div>
        <Modal
          title="编辑组织"
          visible={this.state.visible}
          onOk={this.editOk}
          onCancel={this.editCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={this.editCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.editOk}>
              确定
            </Button>,
          ]}
        >
          <Form>
            <Form.Item label="组织名称" className="organizationName">
              {getFieldDecorator('name', {
                initialValue: this.state.customerData.name, //填充input默认数据
                rules: [
                  {
                    required: true,
                    message: '请输入组织名称',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="联系人" className="organizationName">
              {getFieldDecorator('contactName', {
                initialValue: this.state.customerData.contactName,
                rules: [
                  {
                    required: true,
                    message: '请输入联系人',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="联系电话" className="organizationName">
              {getFieldDecorator('contactPhone', {
                initialValue: this.state.customerData.contactPhone,
                rules: [
                  {
                    required: true,
                    message: '请输入联系电话',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="地区:" className="organizationAddress">
              <GeoForm
                regionId={this.state.customerData.region}
                provinceId={this.state.customerData.province}
                cityId={this.state.customerData.city}
                updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
              />
              <span style={{ color: 'red', display: this.state.display }}>
                请选择省地区
              </span>
            </Form.Item>
            <Form.Item label="详细地址" className="organizationName">
              {getFieldDecorator('address', {
                initialValue: this.state.customerData.address,
                rules: [
                  {
                    required: true,
                    message: '请输入详细地址',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Form>
          <Form.Item label="状态" className="organizationName">
            {getFieldDecorator('valid', {
              initialValue: this.state.customerData.valid,
              rules: [
                {
                  required: true,
                  message: '请选择状态',
                },
              ],
            })(
              <RadioGroup
                onChange={this.stateChange}
                setFieldsValue={this.state.stateValue}
              >
                <Radio value={true}>启用</Radio>
                <Radio value={false}>禁用</Radio>
              </RadioGroup>
            )}
          </Form.Item>
        </Modal>
      </div>
    );
  };
  render() {
    return (
      <div>
        <this.basicData />
        <this.ContractWorkshop />
        <this.showModal />
        <TeamList
          customId={this.state.customerId}
          columns={
            this.state.custmer == 2
              ? [1, 2, 3, 4, 5, 6, 7, 8]
              : this.state.columns
          }
        />
      </div>
    );
  }
}
export default Form.create()(organizationDetails);
