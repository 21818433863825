//账号管理
import * as React from 'react';
import {Table, Divider, Button, Modal, Form, Input, Select, Popconfirm, message,Radio} from 'antd';
import {
    TEAM_ADD,
    TEAM_DELECT,
    TEAM_MODIFY,
    TEAM_LIST,
    ORGANIZATION_LISTNAME,
} from "../../app/api";
import GeoForm from '../../components/GeoForm'; //引入表单
import '../../styles/common.css'
import {jumpTo} from "../../router/Router";
const Option = Select.Option;
class AccountNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // 显示模态窗开关
            visible: false,
            // 模态窗类型
            title: '新增组织',
            // API查询的数据存储
            data: [],
            // 分页的数据存储
            pagination: {},
            // 模态窗的数据存储
            modalData: {},
            organizationList: [],
            selectItems: '',
            value: '',
            queryOrganizaId: '', //查询组织的id
            pages: '',
            stateValue: true, //状态启用禁用
            organizaName:'',
            addId:'',
            thisData:{},
            teamManageVisible:false,  //球员模态框
            teamId:'',    //查询球员详细信息id
        };

        this.column = [
            {
                key: "1",
                title: '球队ID',
                dataIndex: 'id',
            },
            {
                key: "2",
                title: '名称',
                dataIndex: 'name',
                width: "15%"
            },
            {
                key: "3",
                title: '教练',
                dataIndex: 'coaches',
                width: "10%",
                render: (text, record) => {
                    return <span style={{color:'#1890ff',cursor:'pointer'}}>{record.coaches.length}</span>
                }
            },
            {
                key: "4",
                title: '授权组织',
                dataIndex: 'orgs',
                width: "10%",
                render: (text, record) => {
                    return <span style={{color:'#1890ff',cursor:'pointer'}}>{record.orgs.length}</span>
                }
            },
            {
                key: "5",
                title: '地区',
                dataIndex: 'fullAddress',
                width: "18%",
                align: 'center'
            },
            {
                key: "6",
                title: '球员',
                dataIndex: 'footballerNumber',
                width: "20%",
                align: 'center',
                render: (text, record) => {
                    return <span style={{color:'#1890ff',cursor:'pointer'}}
                                 onClick={() => jumpTo("/pages/player/player",{teamId:record.id})}>{record.footballerNumber}</span>
                }
            },
            {
                key: "7",
                title: '状态',
                dataIndex: 'valid',
                width: "8%",
                render: msg => msg === true ? <span style={{color: "green"}}>启用</span> :
                    <span style={{color: "orange"}}>禁用</span>
            },
            {
                title: '操作',
                key: 'operation',
                width: '23%',
                render: (text, record) => (
                    <span>
                        <button onClick={() => jumpTo("/pages/teamManage/teamEdit",{teamId:record.id})}>编辑</button>
                        {/*<a href="javascript:;" onClick={this.teamEdit.bind(this,text,record)}>编辑</a>*/}
                        <Divider type="vertical"/>
                    <Popconfirm title={"删除【" + record.name + "】不可恢复，你确定要删除吗?"}
                                okText="确定"
                                cancelText="取消"
                                onConfirm={() => this.handleDelete(record)}>
                        <button title='用户删除'>删除</button>
                    </Popconfirm>
                </span>
                )
            }
        ];
    }
    componentWillMount() {
        this.queryTeam();
        this.organList();
    }
    organList = (pages) => {
        console.log(pages);
        let params = {};
        if (pages !== '') {     //组织的id
            params = pages
        }
        TEAM_LIST(params).then(res => {
            console.log(res);
            const data = res.data.list.content;
            const pagination = {...this.state.pagination};
            pagination.pageSize = res.data.list.size;
            pagination.total = res.data.list.totalElements;
            pagination.current = res.data.list.number + 1;
            const allData = data.map(v => Object.assign({}, v.entity, {
                rowKey:v.entity.id,
                footballerNumber: v.footballerNumber,
                fullAddress: v.fullAddress,
                orgs: v.orgs,
                coaches: v.coaches
            }))
            this.setState({
                data: allData,
                pagination,
            });
        })
    }
    /**
     * 路由跳转
     */
    teamEdit = (text,record) => {
        console.log(record);
        this.props.history.push({
            pathname: '/teamManage/teamEdit',
            state: {thisData: record}
        })
    };
    /**
     * 分页的方法
     */
    handleTableChange = (pagination) => {
        const paging = pagination.current;
        this.setState({
            pages: paging   //点击的当前页码
        });
        if (paging !== undefined) {
            this.organList({pageNum: paging})
        }

    }


    /**
     * 新增弹窗
     */
    addModal = () => {
        this.setState({modalData: {}, visible: true, title: '新增球队'});
    };
    /**
     * 编辑弹窗
     */
    // editModal = record => {
    //     this.setState({
    //         visible: true,
    //         modalData: record,
    //         title: '编辑组织'
    //     });
    // };
    /**
     * 编辑取消
     */
    editCancel = () => {
        this.setState({
            visible: false,
        });
    };
    /**
     * 删除，确认
     */
    handleDelete = record => {
        TEAM_DELECT({id: record.id}).then(res => {
            if (res.code === 0) {
                message.success('删除【' + record.name + '】成功');
                this.organList()
            } else {
                message.error(res.message);
            }
        }).catch(err => message.error(err))
    }

    /**
     * 编辑完成，提交表单
     */
    editOk = () => {
        this.props.form.validateFields((errors, values) => {
            console.log(values);
            if (!errors) {
                let body = {
                    "entity": {
                        "id": this.state.modalData.id,
                        "name": values.name,
                        "province": parseInt(this.state.modalData.province),
                        "city": parseInt(this.state.modalData.city),
                        "region": parseInt(this.state.modalData.region),
                        "valid": this.state.stateValue
                    },
                    "orgs":[
                        {
                            "id":this.state.addId
                        }
                    ],
                };
                let api = '';
                if (this.state.title === '新增球队') {
                    api = TEAM_ADD;
                } else {
                    api = TEAM_MODIFY;
                }
                api(body).then(res => {
                    this.setState({visible: false});
                    message.success(this.state.title + '【' + values.name + '】成功');
                    this.organList();
                }).catch(err => {
                    if (err) {
                        message.error(err)
                    }

                })
            }
        })
    };
    /**
     * 更新地理信息到本地存储
     */
    updateGeoForm = o => {
        this.setState(
            {modalData: {...this.state.modalData, ...o}}
        )
    };
    /**
     * 查询所有组织
     */
    queryTeam = () => {
        ORGANIZATION_LISTNAME().then(res => {
            this.setState({
                organizationList: res.data.list
            })
        })
    };
    /**
     * 组织下拉选择查询数据
     */
    organizationChange = key => {
        console.log(key);  //ok了
        // const thisId = key.key;
        this.setState({queryOrganizaId: key});
        console.log(this.state.queryOrganizaId);
        this.organList({orgId:key});
    };
    /**
     * model里面的组织下拉change
     * @param value
     * @param key
     */
    organizaChange =(value,key) =>{
        this.setState({
            organizaName:value,
            addId:key.key
        })
    }
    /**
     * 点击按钮 查询 组织
     */
    searchOrganization = parmas => {
        console.log(this.state.value);
        this.organList({name:this.state.value});
    };
    /**
     * 获取queryInput的值
     * @param e
     */
    getInput = (e) => {
        this.setState({
            value: e.target.value
        })

    };

    /***
     * 状态启用禁用的方法
     */
    stateChange = e =>{
        console.log('状态', e.target.value);
        this.setState({
            stateValue: e.target.value,
        });
    };

    // showModel= record =>{
    //     this.setState({
    //         teamManageVisible:true,
    //         teamId:record.id
    //     })
    //     FOOTBALLER_LIST( {teamId:record.id} ).then(res =>{
    //         console.log(res.data.list.content);
    //         const data = res.data.list.content;
    //         //const theyData = data.map(v => Object.assign({},v.entity, {fullAddress:v.fullAddress,mutrixNumber:v.mutrixNumber }));
    //         data.map(v => Object.assign({},v.entity,{}))
    //
    //     })
    //
    //  }
    render() {
        const {getFieldDecorator} = this.props.form;
        const {organizationList} = this.state;
        const RadioGroup = Radio.Group;
        const column = this.column.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (
            <div>
                <div className='organizationSelect'>
                    <Select defaultValue="全部来源"
                            title='全部来源'
                            onChange={this.organizationChange}
                        // value={this.state.value}
                            style={{width: 150, marginBottom: '16px', marginRight: '10px'}}>
                        <Option key = '0'>全部来源</Option>
                        <Option key= '1' >APP注册</Option>
                        <Option key= '2' >web签约</Option>

                    </Select>
                    <Input placeholder="请输入姓名/联系电话"
                           onChange={this.getInput}
                           style={{width: 200, marginRight: '16px'}}/>
                    <Button type='primary' onClick={this.searchOrganization}> 查询</Button>
                </div>
                <Modal
                    title={this.state.title}
                    visible={this.state.visible}
                    onOk={this.editOk}
                    onCancel={this.editCancel}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" onClick={this.editCancel}>
                            取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.editOk}>
                            确定
                        </Button>,
                    ]}
                >
                    <Form>
                        <Form.Item label="球队名称" className='organizationName'>
                            {getFieldDecorator('name', {
                                initialValue: this.state.modalData.name,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入球队名称',
                                    },
                                ],
                            })(<Input/>)}
                        </Form.Item>

                        <span className='teamSelect' >所属组织</span>
                        <Select placeholder='所属组织'
                                value={this.state.organizaName}
                                onChange={this.organizaChange}
                                style={{width: 150, marginBottom: '16px', marginRight: '10px'}}>
                            {organizationList.map(item => (
                                <Option key={item.id} value={item.name}>{item.name}</Option>
                            ))
                            }
                        </Select>

                        <GeoForm regionId={this.state.modalData.region}
                                 provinceId={this.state.modalData.province}
                                 cityId={this.state.modalData.city}
                                 updateGeoForm={(key, value) => this.updateGeoForm(key, value)}
                        />

                        <div>
                            <span className='teamSelect'>状态</span>
                            <RadioGroup onChange={this.stateChange} value={this.state.stateValue}>
                                <Radio value={true}>启用</Radio>
                                <Radio value={false}>禁用</Radio>
                            </RadioGroup>
                        </div>
                    </Form>

                </Modal>
                <Table columns={column}
                       showHeader={true}
                       dataSource={this.state.data}
                       pagination={this.state.pagination}
                       onChange={this.handleTableChange}
                />

            </div>
        )
    }
}
export default Form.create()(AccountNumber);
